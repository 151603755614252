import { useState } from 'react';
import SharedSidebar from './SharedSidebar'
import SharedPanel from './SharedPanel'
import { Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import './shared.css'
//import useLockBodyScroll from '../hooks/useLockBodyScroll'

const SharedView = ({following=false, followers=false, useNothing=false}) => {

   const [ siteSource, setSiteSource ] = useState("")
   const [ iframeTitle, setIframeTitle ] = useState("Selected SibSite: ")
   //useLockBodyScroll(true)

   return (
     <>
     <Section className="columns" style={{padding: "0 1%", marginBottom: "0px"}}>
        <div className="aside"><SharedSidebar className="menu"/></div>
     <Container id="browse-scroll-target column is-9" className="scrollable">
     <Container className="column" style={{margin: 0}}>
        <SharedPanel setSibSite={src => { setSiteSource(src)}} setIframeTitle={setIframeTitle} following={following} followers={followers} useNothing={useNothing} />
     </Container>
     </Container>
     </Section>
      <Section style={{padding: "0 1%", marginBottom: "0px"}}>
      {/*eslint-disable-next-line*/}
      <a name="sibsite" id="sibsite" aria-label="hidden anchor link" href="/">&nbsp;&nbsp;</a>
         <Container className="has-text-centered">
             <div className="has-text-primary is-size-5 has-text-centered" style={{marginBottom:"5px"}}>
                {iframeTitle}  (<a href={siteSource}><small><span className="nowspace">Fullscreen View</span></small></a>) (<a href="#top"><small><span className="nospace">SibSite List</span></small></a>) 
             </div>
             <div> <iframe title="sibsite" style={{width:"100%", height:"100vh", overflow:"auto"}} src={siteSource}> title="title" </iframe> </div>
         </Container>
     </Section>
     </>
   )
  }

export default SharedView;

