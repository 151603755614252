import axios from 'axios'
import { augmentApi } from '../components/loggedIn/functions/location_fcns'
import { CAROUSEL_ACTIVE_INDEX, CAROUSEL_BUTTON_LABEL, UPDATE_CAROUSEL_PAGE, UPDATE_CAROUSEL_PAGE_NODISK } from './types'
import { UPDATE_VCAROUSEL_PAGE, UPDATE_VCAROUSEL_PAGE_NODISK } from './types'

export const getCarouselPage = async (publicV=false) =>
{       const res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'carousel', pageInstance: 'singleton', publicV }}) 
        const ct = JSON.parse(res.data.pageData);
        const content = (typeof ct != "object") ? JSON.parse(ct) : ct;
        return content
}
export const getVideoCarouselPage = async (publicV=false) => 
{       const res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'vcarousel', pageInstance: 'singleton', publicV }}) 
        const ct = JSON.parse(res.data.pageData);
        const content = (typeof ct != "object") ? JSON.parse(ct) : ct;
        return content
}

  //Action creator function to return Plain Object containing action with dynamic data
export const updateCarouselPage = (pageContents, updateDisk=true) => {
   return { type: updateDisk?UPDATE_CAROUSEL_PAGE:UPDATE_CAROUSEL_PAGE_NODISK,  payload: pageContents }  //type is a required field
};
export const updateVideoCarouselPage = (pageContents, updateDisk=true) => {
   return { type: updateDisk?UPDATE_VCAROUSEL_PAGE:UPDATE_VCAROUSEL_PAGE_NODISK,  payload: pageContents }  //type is a required field
};

  //setButtonLabel
export const setButtonLabel = label => {
   return { type: CAROUSEL_BUTTON_LABEL,  payload: label }  //type is a required field
};

 //setActiveIndex
export const setCarouselActiveIndex = index => {
   return { type: CAROUSEL_ACTIVE_INDEX,  payload: index }  
};

  //Middleware : 
export const updateCarouselPageMiddleware = ({ getState, dispatch }) => next => async action =>
{  const result = next(action);
   switch (action.type) {
     case UPDATE_CAROUSEL_PAGE:
       await axios.post('/api/save_page', { pageType: 'carousel', pageInstance: 'singleton', pageData: action.payload });
          //dispatch(anotherAction(asyncResult));
       break;
       /***
       case SOME_OTHER_ACTION:
         const { slice: { stateVariable } } = getState();
         await someProcess(stateVariable);
         break;
      ***/
      default:
   }
   return result
}
export const updateVideoCarouselPageMiddleware = ({ getState, dispatch }) => next => async action =>
{  const result = next(action);
   switch (action.type) {
     case UPDATE_VCAROUSEL_PAGE:
       await axios.post('/api/save_page', { pageType: 'vcarousel', pageInstance: 'singleton', pageData: action.payload });
          //dispatch(anotherAction(asyncResult));
       break;
       /***
       case SOME_OTHER_ACTION:
         const { slice: { stateVariable } } = getState();
         await someProcess(stateVariable);
         break;
      ***/
      default:
   }
   return result
}


/** normally, if synchronous)
  request = axios.get(...)
  return {
      type: FETCH_USER,
      payload: requests
}**/
