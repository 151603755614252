import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Container, Form, Section, Button, Box, Heading } from "react-bulma-components";
import Loader from "react-spinners/BeatLoader";
import axios from 'axios';
import { checkName } from '../common/formFunctions'

const BugForm = (props) => {  

     //**State
  const [loading, setLoading] = useState(false);
  const auth = useSelector(state => state.auth); //Logged in?
  const user = useSelector(state => state.user)

  const history = useHistory();
  const { handleSubmit, errors, control } = useForm();
  const onSubmit = async data => {
    setLoading(true);  //Put on spinner

    const { Email, FirstName, LastName, Message } = data;
    let pageName = '/bugs/submit';
    const { status } = await axios.post('/api/bug-form', {
          firstName: FirstName,
          lastName: LastName,
          email: Email.toLowerCase(), 
          account_id:  user.account_id,
          message: Message,
        },
        { validateStatus: (status) => {
           return true;
        }})

        if (status === 200) {
           console.log("Bug Form submission successful");
        }
       setLoading(false);  //disable spinner
       history.push(pageName);
  };

  if (auth === null || isEmpty(user))
     return <div>Loading...</div>
  if (auth === false)
    return <div>Please Login to Continue</div>

  return (
    <Container>
    <Section>
      <Box>
        <Heading subtitle size={4}>Feedback, Bugs, Suggestions and Requests </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
 
          <Form.Field>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="FirstName"
                    placeholder="Your First Name"
                    defaultValue={user.first_name}
                    readOnly={true}
                    control={control}
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc }
                          }} 
                  />
             </Form.Control>
             <Form.Help textColor="danger">
                  {errors.FirstName && errors.FirstName.type === "required" && "Your first name is required"}
                  {errors.FirstName && errors.FirstName.type === "validate" && "Name must contain between 2-31 valid letters." }
             </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Label>Last Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="LastName"
                    control={control}
                    defaultValue={user.last_name}
                    placeholder="Your Last Name"
                    readOnly={true}
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc  }
                          }} 
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.LastName && errors.LastName.type === "required" && "Your last name is required"}
                  {errors.LastName && errors.LastName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>

          <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>

          <Form.Field>
            <Form.Label>Email Address</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="Email"
                    control={control}
                    defaultValue={user.email}
                    readOnly={true}
                    placeholder="Your Email"
                    type="email"
                    rules={{ required: true }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.Email && "Your email address is required"}
            </Form.Help>
          </Form.Field>

          <Form.Field>
            <Form.Label>Description of Bug, Idea, Suggestion or Request</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Textarea}
                    name="Message"
                    control={control}
                    defaultValue=""
                    placeholder="Describe in as much detail as possible"
                    type="text"
                    rows={10}
                    rules={{ required: true }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.Message && "Your desription cannot be empty"}
            </Form.Help>
          </Form.Field>

          <Form.Field>
            <Form.Control>
              <Button  fullwidth={false}>Send</Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </Section>
    </Container>
  );
}

export default BugForm;
