import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import Textarea from 'react-textarea-autosize'
import '../../break.css'
import { Container, Box } from 'react-bulma-components';
import ConfirmPopup from '../popup/ConfirmPopup'

/** Idea
    Show comment (maybe light gray)
    Always Show empty reply (following it), but have it display:none and if the reply link is chosen, change to display:block

 convert a Date object to a Unix timestamp with date.getTime() / 1000, and convert a timestamp into a date with new Date(timestamp * 1000).

**/

const IndentStarts = 600

const OneComment  = ({index, pageType, pageInstance, id, parentId, author, dateTime, commentText, isAuthor, onChange, onReplyChange, onDelete, onClose}) => {

    const user = useSelector(state => state.user);

    const [ hiddenCommentDisplay, setHiddenCommentDisplay ] = useState("none");
    const [ replyText, setReplyText ] = useState("Reply");
    const [ changeText, setChangeText ] = useState("Edit");
    const [ isReadOnly, setIsReadOnly ] = useState(true);
    const [ value, setValue ] = useState(commentText);  //Controlled text area to ensure properly updated between invocations

    const [width, setWidth]   = useState(window.innerWidth);

    useEffect(() => {
      const updateDimensions = () => {
       setWidth(window.innerWidth);
      }
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
       setValue(commentText);
     }, [commentText]);

       //**For delete popup
    const [openDeletePopup, setOpenDeletePopup] = useState(false); //**Start out popup not-open
    const onEndDeletePopup = (data) =>
    {   if (data.answer === true)
            onDelete(index);
        setOpenDeletePopup(false); //**Close the popup
        if (data.answer === true)
           onClose();  //**Close up this entire popup
    }

    const hiddenCommentValue = "Enter reply";
    const myRef = useRef()
    const hiddenRef = useRef()
    
    const styles = {
      textarea:  {width: "min(425px, 80vw)", border: "1px var(--primary-invert) solid"},
      replyarea: {width: "min(400px, 80vw)"},
      hiddenTextArea: {width: "min(425px, 80vw)"},
      noIndentReplyArea: {width: "min(425px, 80vw)"},
    }
    const lineBreak = <div className="break"></div>

    let textStyle = parentId>0?styles.replyarea:styles.textarea
    if (parentId > 0 && width < IndentStarts) textStyle = styles.noIndentReplyArea

    const textarea = 
         <>
            <Textarea
              readOnly={isReadOnly}
              value={value}
              ref={myRef}
              minRows={6}
              maxRows={10}
              className="comment-textarea"
              style={textStyle}
              maxLength={3000}
              onChange={e => { setValue(e.target.value); onChange(index, e.target.value)} }
            />
            {lineBreak}
         </>

    const hiddenTextarea = 
         <>
            <Textarea
              placeholder={hiddenCommentValue}
              ref={hiddenRef}
              minRows={6}
              maxRows={10}
              style={styles.hiddenTextArea}
              className="comment-textarea"
              maxLength={3000}
              onChange={e => onReplyChange(index, e.target.value)}
            />
            {lineBreak}
         </>

    const spanDef = (width >= IndentStarts) ? <span style={{color:"white"}}> ----- </span>
                  : ""

    const hiddenReplyArea= 
         <>
            <Textarea
              placeholder={hiddenCommentValue}
              ref={hiddenRef}
              minRows={6}
              maxRows={10}
              style={width > IndentStarts ? styles.replyarea : styles.noIndentReplyArea}
              className="comment-textarea"
              maxLength={3000}
              onChange={e => onReplyChange(index, e.target.value)}
            />
            {lineBreak}
         </>

    const hiddenReply =
         <Container style={{margin:0, padding:"2px 10px", lineHeight:1.1}}>
         <div style={{display:hiddenCommentDisplay}}>
               {spanDef}<small><i>{`${user.first_name} ${user.last_name}`} replying,</i></small><br/> 
               {spanDef}<small><i>{new Date().toLocaleString()}</i></small>
           {lineBreak}
           {spanDef}
           {hiddenReplyArea}
        </div>
        </Container>

    const ownerLinks = 
                  <> {/*eslint-disable-next-line*/}
                  <a style={{padding:"0 5px"}} href="#" onClick={()=>allowChange()}>{changeText}</a> {/*eslint-disable-next-line*/}
                  <a href="#" onClick={()=>setOpenDeletePopup(true)}>Delete</a>
                  </> 

    const comment =
         <>
         <Box style={{margin:"6px 0", padding:"2px 10px", lineHeight:1.1}}>
               <small><i style={{color:"black"}}>{author} commented,<br/> {dateTime}</i></small>  {/*eslint-disable-next-line*/}
               <small className="is-pulled-right"><a href="#" onClick={()=>showHidden()}>{replyText}</a> {isAuthor?ownerLinks:""}</small>
            {lineBreak}
            {textarea}
         </Box>
         {hiddenReply}
         </>

    const reply =
         <>
         <Container style={{margin:0, padding:"2px 10px", lineHeight:1.1}}>
              {spanDef}<i><small>{author} replied, </small></i><br/>
              {spanDef}<i><small>{dateTime}</small></i>{/*eslint-disable-next-line*/}
              <span className="is-pulled-right"><small><a href="#" onClick={()=>showHidden()}>{replyText}</a> {isAuthor?ownerLinks:""}</small></span>
           {lineBreak}
           {spanDef}
           {textarea}
        </Container>
        {hiddenReply}
        </>

    const showHidden = () =>
    {  setReplyText("")
       setHiddenCommentDisplay("block")
       //hiddenRef.current.focus()
    }

    const allowChange = () =>
    {  setChangeText("")
       setIsReadOnly(false);
    }

    const deletePopup = 
       <ConfirmPopup
           initialState={openDeletePopup}
           setCloseData={data => onEndDeletePopup(data) }
           setClose={()=>setOpenDeletePopup(false) }
           prompt="Are you sure you want to delete this comment?"
           title="Really Delete?"
           affirmativeButtonText="Yes! Delete it"
           declineButtonText="Cancel"
       />

    const replyReturn =
       <>
       {reply}
       {deletePopup}
       </>

    const commentReturn =
       <>
       {comment}
       {deletePopup}
       </>

    if (parentId > 0)
       return  replyReturn
    else
       return commentReturn 
}

export default OneComment;
