import React, {useState} from 'react';
import { Card} from 'react-bulma-components';
import ConfirmPopup from '../../common/popup/ConfirmPopup'


const DeleteButton = ({deleteFunc, itemDescription, buttonText="Delete Entry"}) => {  
   const [open, setOpen] = useState(false); //**Start out popup not-open

   const onEnd = (data) =>
   {  if (data.answer === true)
          deleteFunc()
       setOpen(false); //**Close the popup
   }

   return (
      <>
        <a href="#" className="card-footer-item footer-buttons" style={{backgroundColor:'#fde4d3'}} onClick={() => setOpen(true)}>
             { buttonText } 
        </a>
        <ConfirmPopup
           initialState={open}
           setCloseData={data => onEnd(data) }
           setClose={()=>setOpen(false) }
           prompt={`Are you sure you want to delete ${itemDescription}?`} 
           title="Really Delete?"
           affirmativeButtonText="Yes! Delete it"
           declineButtonText="Cancel"
       />
    </>
    )
  }

export default DeleteButton;
