//**This is the SibsForever sidebar for Carousel Preview

import isEmpty from 'lodash/isEmpty'
import { getHeaderText, isVideoCarouselPage } from '../functions/location_fcns'
import { useSelector } from 'react-redux';
import React, { useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'react-bulma-components';
import usePagePerms from '../hooks/usePagePerms'
import {isMobile} from 'react-device-detect';
import PreviewPageHelp from '../common/help/PreviewPageHelp'

const helpHeading = "Gallery Preview"

const CarouselPreviewSidebar = ({videoGallery=false}) => { 

const styles = {
    hrStyle: { borderTop: "2px solid #bbb" },
    spanStyle: { marginRight: "2px", marginTop:"5px", marginBottom:"5px" }
}
    const pageName=videoGallery||isVideoCarouselPage()?'vcarousel':'carousel'

    const [ , canEdit, canView, isResolved ] = usePagePerms(pageName, 'singleton')

    const [ editLinks, setEditLinks ] = useState("")
    const [ viewLinks, setViewLinks ] = useState("")

    const [ isInit, setIsInit ] = useState(false)
    const user = useSelector(state => state.user);

    useEffect(() => {
       if (isResolved && !isEmpty(user) && !isEmpty(user.email))
       {  
          if (canView) setViewLinks(
             <>
             <div><NavLink className="xmenu-block" activeClassName="is-active" to={`/auth/${pageName}/view`}>
	         <span className="nospace"><span className="menu-icon">
	         <i className="fa fa-eye"  style={styles.spanStyle}></i>
	         </span>
	         View as Self</span> 
	         </NavLink></div>
             <div><NavLink className="xmenu-block" activeClassName="is-active" to={`/eshare/${user.account_id}/${pageName}`}>
             <span className="nospace"><span className="menu-icon">
             <i className="fa fa-at"  style={styles.spanStyle}></i>
             </span>
             View as Follower</span>
             </NavLink></div>
             </>
          )
           if (canEdit) setEditLinks(
              <>
               <hr className="is-hidden-mobile" style={styles.hrStyle} />
               <div><NavLink className="xmenu-block" activeClassName="is-active" to={`/auth/${pageName}/edit`}>
               <span className="nospace"><span className="menu-icon">
                 <i className="fa fa-arrow-left"  style={styles.spanStyle}></i>
               </span>
               Edit</span>
               </NavLink></div>
              </>
          )

          setIsInit(true);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isResolved, canEdit, canView ])

    if (!isInit)
      return <div>Loading...</div>

    const help = <PreviewPageHelp header={helpHeading}/>

    return (
  
    <Menu>
      <p className="menu-label is-hidden-mobile">{getHeaderText()} {help}</p>
        {viewLinks}
        {editLinks}
    </Menu>

    )
  }

export default CarouselPreviewSidebar

