import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep'
import { UPDATE_ACCOUNT_SETTINGS, UPDATE_PAGE_SETTINGS, UPDATE_PAGE_SETTINGS_NODISK } from '../actions/types'

const initialState = null

export default function settingsReducer(state=initialState, action={} )
{
  switch(action.type) {
    case UPDATE_PAGE_SETTINGS: 
    case UPDATE_PAGE_SETTINGS_NODISK: 
    {
//**Lookup pageName/Instance and change that one row
       const filtered = filter(state, function(rec) { return rec.pageName !== action.payload.pageName || rec.pageInstance !== action.payload.pageInstance })
       filtered.push(action.payload);
       return filtered;
       //return cloneDeep(filtered);
    }
    case UPDATE_ACCOUNT_SETTINGS:
        return action.payload;
        //return cloneDeep(action.payload);
    default:
        return state;
  }
};
