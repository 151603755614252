import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import { Button, Heading, Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import 'react-responsive-modal/styles.css';
import useLockBodyScroll from '../hooks/useLockBodyScroll'
import { getLocalStorage, updateLocalStorage, BROWSE_QUERY } from '../functions/localStore'

const NewSite24 = 1
const NewData24 = 2

const Age1_10 = 1
const Age11_18 = 2
const Age19_30 = 3
const Age31_50 = 4
const Age51_Plus = 5

const bit_test = (num, bit) => { return ((num>>bit) % 2 !== 0) }

const bit_set = (num, bit) => { return num | (1<<bit) }

const bit_clear = (num, bit) => { return num & ~(1<<bit)  }

const bit_toggle = (num, bit) =>  { return bit_test(num, bit) ? bit_clear(num, bit) : bit_set(num, bit); }
     
const Checkbox = ({ label, value, onChange }) => {
  return (
    <label className="checkbox">
      <input type="checkbox" checked={value} onChange={onChange} />
      &nbsp;{label}&nbsp;&nbsp;&nbsp;&nbsp;
    </label>
  );
};  
/*
const Radio = ({ label, value, onChange }) => {
  return (
    <label className="radio">
      <input type="radio" checked={value} name="aud" onChange={onChange} />
      &nbsp;{label}&nbsp;&nbsp;
    </label>
  );
};  
*/

const SearchPopup = ({ useSavedQuery = false, initialState, setCloseData, setClose } ) => {

  useEffect(() => {
     if (useSavedQuery === false)  return;

     const qs = getLocalStorage(BROWSE_QUERY)
     
     if (!isEmpty(qs))
     {  const url = new URL(`https://sibsforever.org?${qs}`)
        const name = url.searchParams.get('name');
        if (!isEmpty(name))
           setName(decodeURIComponent(name))
        const changes24 = url.searchParams.get('changes24');
        if (changes24 && changes24 > 0)
           setChanges24(changes24)
        const departedAge = url.searchParams.get('dage');
        if (departedAge && departedAge > 0)
           setDepartedAge(departedAge)
        const sibAgeLoss = url.searchParams.get('sage1');
        if (sibAgeLoss && sibAgeLoss > 0)
           setSibAgeLoss(sibAgeLoss)
        const sibAgeNow = url.searchParams.get('sage2');
        if (sibAgeNow && sibAgeNow > 0)
           setSibAgeNow(sibAgeNow)
     }
  }, [useSavedQuery] )

  const { handleSubmit } = useForm();
  const onSubmit = data => {
     let url = ""
     if (!isEmpty(name))
        url = `name=${encodeURIComponent(name)}&`
     if (changes24 > 0)
        url = url + `changes24=${changes24}&`
     if (departedAge > 0)
        url = url + `dage=${departedAge}&`
     if (sibAgeLoss > 0)
        url = url + `sage1=${sibAgeLoss}&`
     if (sibAgeNow > 0)
        url = url + `sage2=${sibAgeNow}&`

     if (!isEmpty(url))
     {   url = url.slice(0, -1)  //Remove the tailing &
         updateLocalStorage(BROWSE_QUERY, url)
     }

     data.url = url;
     //console.log("Popup", data);

     setCloseData(data);
  }

  const [ name, setName ] = useState("")
  const [ changes24, setChanges24 ] = useState(0)
  const [ departedAge, setDepartedAge ] = useState(0)
  const [ sibAgeLoss, setSibAgeLoss ] = useState(0)
  const [ sibAgeNow, setSibAgeNow ] = useState(0)

  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        center
      >
        <Heading>Search</Heading>
        <p>
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <Form.Control className="has-icons-right">
               <Form.Input
                 value={name}
                 placeholder="Name ..."
                 onChange={e => { setName(e.target.value)}}
               />
              <span className="icon is-small is-right">
                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#ddd" d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z"></path></svg>
            </span>
            </Form.Control>
            </Form.Field>
<hr />
          <Form.Field>
            <Form.Control>
            <Form.Label> SibsForever updates in last 24 hours: </Form.Label>
                <Checkbox value={bit_test(changes24,NewSite24)} label="New Sites" onChange={()=>{ setChanges24(bit_toggle(changes24, NewSite24)) }} />
                <Checkbox value={bit_test(changes24,NewData24)} label="Sites with new content" onChange={()=>{ setChanges24(bit_toggle(changes24, NewData24))}} />
            </Form.Control>
          </Form.Field>
<hr />
          <Form.Field>
            <Form.Label> Age of departed: </Form.Label>
            <Form.Control>
                <Checkbox value={bit_test(departedAge, Age1_10)} label="1-10" onChange={()=>{setDepartedAge(bit_toggle(departedAge, Age1_10))}} />
                <Checkbox value={bit_test(departedAge, Age11_18)} label="11-18" onChange={()=>{setDepartedAge(bit_toggle(departedAge, Age11_18))}} />
                <Checkbox value={bit_test(departedAge, Age19_30)} label="19-30" onChange={()=>{setDepartedAge(bit_toggle(departedAge, Age19_30))}} />
                <Checkbox value={bit_test(departedAge, Age31_50)} label="31-50" onChange={()=>{setDepartedAge(bit_toggle(departedAge, Age31_50))}} />
                <Checkbox value={bit_test(departedAge, Age51_Plus)} label="51+" onChange={()=>{setDepartedAge(bit_toggle(departedAge, Age51_Plus))}} />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label> Age of sibling at time of loss: </Form.Label>
            <Form.Control>
                <Checkbox value={bit_test(sibAgeLoss, Age1_10)} label="1-10" onChange={()=>{setSibAgeLoss(bit_toggle(sibAgeLoss, Age1_10))}} />
                <Checkbox value={bit_test(sibAgeLoss, Age11_18)} label="11-18" onChange={()=>{setSibAgeLoss(bit_toggle(sibAgeLoss, Age11_18))}} />
                <Checkbox value={bit_test(sibAgeLoss, Age19_30)} label="19-30" onChange={()=>{setSibAgeLoss(bit_toggle(sibAgeLoss, Age19_30))}} />
                <Checkbox value={bit_test(sibAgeLoss, Age31_50)} label="31-50" onChange={()=>{setSibAgeLoss(bit_toggle(sibAgeLoss, Age31_50))}} />
                <Checkbox value={bit_test(sibAgeLoss, Age51_Plus)} label="51+" onChange={()=>{setSibAgeLoss(bit_toggle(sibAgeLoss, Age51_Plus))}} />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label> Age of sibling today: </Form.Label>
            <Form.Control>
                <Checkbox value={bit_test(sibAgeNow, Age1_10)} label="1-10" onChange={()=>{setSibAgeNow(bit_toggle(sibAgeNow, Age1_10))}} />
                <Checkbox value={bit_test(sibAgeNow, Age11_18)} label="11-18" onChange={()=>{setSibAgeNow(bit_toggle(sibAgeNow, Age11_18))}} />
                <Checkbox value={bit_test(sibAgeNow, Age19_30)} label="19-30" onChange={()=>{setSibAgeNow(bit_toggle(sibAgeNow, Age19_30))}} />
                <Checkbox value={bit_test(sibAgeNow, Age31_50)} label="31-50" onChange={()=>{setSibAgeNow(bit_toggle(sibAgeNow, Age31_50))}} />
                <Checkbox value={bit_test(sibAgeNow, Age51_Plus)} label="51+" onChange={()=>{setSibAgeNow(bit_toggle(sibAgeNow, Age51_Plus))}} />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <center><Button fullwidth={false}>Find SibSites</Button></center>
            </Form.Control>
          </Form.Field>

        </form>
      </Modal>
    </>
  );
};


export default SearchPopup;
