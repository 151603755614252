import React, { useState, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Container, Form, Section, Button, Box, Heading } from "react-bulma-components";
import ReCAPTCHA from 'react-google-recaptcha'
import Loader from "react-spinners/BeatLoader";
import axios from 'axios';
import { checkName } from '../common/formFunctions'

const ContactForm = (props) => {  

     //**State
  const [ captcha, setCaptcha ] = useState("");
  const [loading, setLoading] = useState(false);

      //**Ref
  const recaptcha = useRef();

  const history = useHistory();
  const { handleSubmit, errors, control } = useForm();
  const onSubmit = async data => {
    setLoading(true);  //Put on spinner

    const { Email, FirstName, LastName, Message } = data;
    let pageName = '/contact/submit';
    const { status } = await axios.post('/api/contact-form', {
          firstName: FirstName,
          lastName: LastName,
          email: Email.toLowerCase(), 
          message: Message,
          captcha
        },
        { validateStatus: (status) => {
           return true;
        }})

        if (status === 200) {
           console.log("Contact Form submission successful");
        }
       setLoading(false);  //disable spinner
       history.push(pageName);
  };

  return (
    <Container>
    <Section>
      <Box>
        <Heading subtitle size={4}>Contact Sibsforever</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
 
          <Form.Field>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="FirstName"
                    placeholder="Your First Name"
                    defaultValue=""
                    control={control}
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc }
                          }} 
                  />
             </Form.Control>
             <Form.Help textColor="danger">
                  {errors.FirstName && errors.FirstName.type === "required" && "Your first name is required"}
                  {errors.FirstName && errors.FirstName.type === "validate" && "Name must contain between 2-31 valid letters." }
             </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Label>Last Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="LastName"
                    control={control}
                    defaultValue=""
                    placeholder="Your Last Name"
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc  }
                          }} 
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.LastName && errors.LastName.type === "required" && "Your last name is required"}
                  {errors.LastName && errors.LastName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>

          <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>

          <Form.Field>
            <Form.Label>Email Address</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="Email"
                    control={control}
                    defaultValue=""
                    placeholder="Your Email"
                    type="email"
                    rules={{ required: true }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.Email && "Your email address is required"}
            </Form.Help>
          </Form.Field>

          <Form.Field>
            <Form.Label>Message</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Textarea}
                    name="Message"
                    control={control}
                    defaultValue=""
                    placeholder="Your Message"
                    type="text"
                    rows={10}
                    rules={{ required: true }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.Message && "Your message cannot be empty"}
            </Form.Help>
          </Form.Field>

          <Form.Field>
          <ReCAPTCHA
            ref={() => recaptcha }
            sitekey="6LcR3V0aAAAAAM6uO6Wa8X4P4XV56cmdTZ81PvBj"
            onChange={value => setCaptcha(value)}
          />
          </Form.Field>

          <Form.Field>
            <Form.Control>
              <Button  disabled={!captcha} fullwidth={false}>Send</Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </Section>
    </Container>
  );
}

export default ContactForm;
