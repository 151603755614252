import React from 'react'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import SibPanel from './SibPanel'
import useLockBodyScroll from '../hooks/useLockBodyScroll'

const SibPopup = ({ initialState, setClose, setCloseData, sibFirst, sibDob} ) => {

  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        center
      >
        <SibPanel  first={sibFirst} 
                   dob={sibDob} 
                   onEnd={setCloseData} 
       />

      </Modal>
    </>
  );
};

export default SibPopup

