import isEmpty from 'lodash/isEmpty'
import axios from 'axios';

/***** Field checking form functions
***/

     //**Ensure email is not being used
export const uniqueEmail = async email => {
     const res = await axios.get('/api/is_email_in_use',  { params:  { email: email }})
     const content = res.data;
     if (!isEmpty(content) && (content.in_use === true || content.in_use === 'true'))
        return false;
     else
        return true;
  }

  //No longer used when we introduced react-date-picker which has min/max support (calendar gone for birthdates)
export const checkYear = dd => {
      const year = dd.getFullYear()
      if (year < 1925 || year > new Date().getFullYear())
         return false
      else
        return true;
  }

export const checkName = name => {
     name = name.trim()
     if (name.length < 2 || name.length > 31)   return false;   //Length is outside of passport name length
     if (/\s/.test(name)) return false   //No whitespace characters
     if (/\d/.test(name)) return false;  //**No numbers
 // eslint-disable-next-line
     if (/[\u0000-\u0008,\u000A-\u001F,\u007F-\u00A0]/.test(name))  return false  //No unprintable characters
     return true
 }

