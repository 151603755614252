import isEmpty from 'lodash/isEmpty'

/** Get IDs from URLs : https://flaviocopes.com/react-router-data-from-route/
                        https://css-tricks.com/the-hooks-of-react-router/
   <Route path="/share/:account_override/home">
   <Route path="/share/:account_override/wall">
   <Route path="/share/:account_override/carrousel">
   <Route path="/share/:account_override/diary">
   <Route path="/share/:account_override/diary/:diary_instance">
FUTURE
   <Route path="/share/:account_override/timeline">
   <Route path="/share/:account_override/scrapbook">
//Scrapbook is more like wall - shown all on a single page
END FUTURE

   <Route path="/auth/diary/:diary_instance/edit>
   <Route path="/auth/diary/:diary_instance/view>
   <Route path="/auth/diary/:diary_instance/settings>

   import { useHistory, useMatchRoute, useParams } from 'react-router-dom'
   const { diary_instance, account_overide } = useParams()

NOTE:  Putting this in session store although it could work fine in Redux store
   
**/

export const augmentApi = url =>
{
   const accountOverride = getAccountOverride();
   const isRO = isPageRO();
   const browser = window.location.pathname;
   const publicA = isPublicA();
   let qs = window.location.search
   if (!isEmpty(qs))
     qs = `&${qs.substring(1)}`
    
   return publicA ? `${url}?override=${accountOverride}&ro=${isRO}&url=${browser}&publicA=true`
                  : `${url}?override=${accountOverride}&ro=${isRO}&url=${browser}${qs}`
}

export const isPublicA = (auth=false) => {

   const accountOverride = getAccountOverride();
   const publicA = (accountOverride === "1" || accountOverride === 1) && isSharePage() && auth === false?true:false
   return publicA
}

export const getAccountOverride = () =>  { 

     let val = -1;

        //const match = useRouteMatch('/share/:account_override');
     const pathArray = window.location.pathname.split('/');

     if (pathArray[1] === 'share' || pathArray[1] === 'eshare')
        val = pathArray[2]
     else
        val = 0;

     return val;
}


export const getDiaryInstance = () => {

   let val = -1;

     //const match = useRouteMatch('/auth/diary/:diary_instance')
     //const match1 = useRouteMatch('/share/:account_override/diary/:diary_instance')
   const pathArray = window.location.pathname.split('/');
   if (pathArray[1] === "auth" && pathArray[2] === 'diary')
     val = pathArray[3]
   else if (pathArray[1] === "allpages" && pathArray[2] === 'diary')
     val = pathArray[3]
   else if (pathArray[1] === "share" && pathArray[3] === 'diary')
     val = pathArray[4]
   else if (pathArray[1] === "eshare" && pathArray[3] === 'diary')
     val = pathArray[4]
   else
     val = 0;
     
   return val;
}

export const isAllPages = () => {
   const pathArray = window.location.pathname.split('/');
   return pathArray[1] === 'allpages';
}

export const isSharePage = () => {
   const pathArray = window.location.pathname.split('/');
   return pathArray[1] === 'share';
}

export const isEsharePage = () => {
   const pathArray = window.location.pathname.split('/');
   return pathArray[1] === 'eshare';
}

export const isAuthPage = () => {
   const pathArray = window.location.pathname.split('/');
   return pathArray[1] === 'auth';
}

export const isViewPage = verb => {
   const pathArray = window.location.pathname.split('/');
   return pathArray[pathArray.length-1] === verb;
}

export const isPageRO = () => {
   const pathArray = window.location.pathname.split('/');
   if (pathArray[1] === "auth" && pathArray[pathArray.length-1] !== "view" && pathArray[pathArray.length-1] !== "see" &&  pathArray[pathArray.length-1] !== "vsummary" && pathArray[pathArray.length-1] !== 'preview')
         return false;
   return true;
}

export const isCarouselPage = () => {
   const pathArray = window.location.pathname.split('/');
   if (pathArray[pathArray.length-1] === "carousel" || pathArray[pathArray.length-2] === "carousel")
     return true;
   else if (pathArray[pathArray.length-1] === "vcarousel" || pathArray[pathArray.length-2] === "vcarousel")
     return true;
   else
    return false
}

export const isPictureCarouselPage = () => {
   const pathArray = window.location.pathname.split('/');
   if (pathArray[pathArray.length-1] === "carousel" || pathArray[pathArray.length-2] === "carousel")
     return true;
   else
    return false
}

export const isVideoCarouselPage = () => {
   const pathArray = window.location.pathname.split('/');
   if (pathArray[pathArray.length-1] === "vcarousel" || pathArray[pathArray.length-2] === "vcarousel")
     return true;
   else
    return false
}

export const getParam = name => {
   return new URL(window.location.href).searchParams.get(name)
}

export const calcDiaryUrlBase = () =>
{   const accountOverride = getAccountOverride();
    if (accountOverride > 0 && isSharePage())
       return `/share/${accountOverride}/diary`
    else if (accountOverride > 0 && isEsharePage())
       return `/eshare/${accountOverride}/diary`
    else if (isAuthPage())
       return `/auth/diary`
    else //if (isAllPages())
       return `/allpages/diary`
}

export const calcDiaryUrl = () =>
{   const instanceId = getDiaryInstance()
    const base = calcDiaryUrlBase()
    return `${base}/${instanceId}`
}

export const calcDiaryUrlPage = (instanceId, toggle=false)  =>
{   const base = calcDiaryUrlBase()
    const pathArray = window.location.pathname.split('/');
    if (toggle)  //*Change URL for prev/next so it is different and therefore prompts a refresh
    {  if (pathArray[pathArray.length-1] === "view")
          return `${base}/${instanceId}/see`
       else return `${base}/${instanceId}/view`
    }
    else if (pathArray[pathArray.length-1] === 'view')
       return `${base}/${instanceId}/view`
    else if (pathArray[pathArray.length-1] === 'see')
       return `${base}/${instanceId}/see`
    else
       return `${base}/${instanceId}/view`
}

export const getHeaderText = () => {
    const pathArray = window.location.pathname.split('/');
    if (isSharePage())
       return "SibsForever Shared Site"
    else if (isAllPages())
       return "My Site"
    else if (pathArray[pathArray.length-1] === "home" || (pathArray.length >= 2 && pathArray[pathArray.length-2] === "home"))
       return "Home Page "
    else if (pathArray[pathArray.length-1] === "wall" || (pathArray.length >= 2 && pathArray[pathArray.length-2] === "wall"))
       return "Wall "
    else if (pathArray[pathArray.length-1] === "vcarousel" || (pathArray.length >= 2 && pathArray[pathArray.length-2] === "vcarousel"))
       return "Video Gallery "
    else if (pathArray[pathArray.length-1] === "carousel" || (pathArray.length >= 2 && pathArray[pathArray.length-2] === "carousel"))
       return "Picture Gallery "
    else if ((pathArray.length >= 3 && pathArray[pathArray.length-3] === "diary") && 
            (pathArray[pathArray.length-1] === "see" ||
             pathArray[pathArray.length-1] === "view" ||
             pathArray[pathArray.length-1] === "vsummary"))
      return "See Journal Entries"
    else if ((pathArray.length >= 3 && pathArray[pathArray.length-3] === "diary") && 
            (pathArray[pathArray.length-1] === "edit" ||
             pathArray[pathArray.length-1] === "eview" ||
             pathArray[pathArray.length-1] === "summary"))
      return "Journal Entry "
    else if (pathArray[pathArray.length-1] === "diary" || (pathArray.length >= 2 && pathArray[pathArray.length-2] === "diary") ||  (pathArray.length >= 3 && pathArray[pathArray.length-3] === "diary"))
       return "Journal "
    else if (pathArray[pathArray.length-1] === "browse" || (pathArray.length >= 2 && pathArray[pathArray.length-2] === "browse")
       || pathArray[pathArray.length-1] === "browse1" || (pathArray.length >= 2 && pathArray[pathArray.length-2] === "browse1")
       || pathArray[pathArray.length-1] === "community" || (pathArray.length >= 2 && pathArray[pathArray.length-2] === "community")
      || (pathArray.length >= 2 && pathArray[pathArray.length-2] === 'shared'))
       return "SibSite Community "
    else
      return ""
}

export const getBannerText = (firstPart, obj)  => {

   if (isEmpty(obj)) return ""
   let dod = obj.departed_dod
   if (!isEmpty(dod) && dod.indexOf("T") >= 0)
   {    const d = new Date(dod)
        dod = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
   }
   let dob = obj.departed_dob
   if (!isEmpty(dob) && dob.indexOf("T") >= 0)
   {    const d = new Date(dob)
        dob = (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
   }
   const sep = firstPart === "" ? "" : " - "
   return `${firstPart}${sep}${obj.sibling_first_name} and ${obj.departed_first_name} ( ${dob} - ${dod} 
)`

}
