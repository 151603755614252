import isEmpty from 'lodash/isEmpty'
import { FETCH_USER } from '../actions/types'

const initialState = null; //don't know yet if user logged in (isPending)

export default function authReducer (state=initialState, action={})
{
  switch(action.type) {
    case FETCH_USER:
        return (!isEmpty(action.payload) && action.payload.user_id !== 0) || false; //when user not logged, empty string is returned
    default:
        return state;
  }
};
