//**This is the SibsForever sidebar for the Diary

import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react'
import usePagePerms from '../hooks/usePagePerms'
import { NavLink } from 'react-router-dom';
import { Menu } from 'react-bulma-components';
import { getHeaderText } from '../functions/location_fcns'
import { useSelector } from 'react-redux';
import PreviewPageHelp from '../common/help/PreviewPageHelp'

const helpHeading = "Preview Journal Table of Contents"

const DiaryPreviewSidebar = ({isActive}) => {  

const styles = {
    hrStyle: { borderTop: "2px solid #bbb" },
    spanStyle: { marginRight: "2px", marginTop:"5px", marginBottom:"5px" }
}

   const [ , canEdit, canView, isResolved ] = usePagePerms('diary', 'singleton')

   const [ editLinks, setEditLinks ] = useState("")
   const [ viewLinks, setViewLinks ] = useState("")

   const [ isInit, setIsInit ] = useState(false)
   const user = useSelector(state => state.user);

   useEffect(() => {
      if (isResolved && !isEmpty(user) && !isEmpty(user.email))
      {
         if (canView) setViewLinks(
            <>
               <div><NavLink className="xmenu-block" activeClassName="is-active" to="/auth/diary/view">
               <span className="nospace"><span className="menu-icon">
                 <i className="fa fa-eye"  style={styles.spanStyle}></i>
               </span>
               View as Self </span>
               </NavLink></div>
               <div><NavLink className="xmenu-block" activeClassName="is-active" to={`/eshare/${user.account_id}/diary`}>
               <span className="nospace"><span className="menu-icon">
                 <i className="fa fa-at"  style={styles.spanStyle}></i>
               </span>
               View as Follower</span>
               </NavLink></div>
               </>
             )
         if (canEdit) setEditLinks(
             <>
             <hr className="is-hidden-mobile" style={styles.hrStyle} />
	         <div><NavLink className="xmenu-block" activeClassName="is-active" to="/auth/diary/toc">
	         <span className="nospace"><span className="menu-icon">
	         <i className="fa fa-arrow-left"  style={styles.spanStyle}></i>
	         </span>
	         Table of Contents</span>
	         </NavLink></div>
             </>
             )

         setIsInit(true);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isResolved, canEdit, canView ])

    if (!isInit)
     return <div>Loading...</div>

    const help = <PreviewPageHelp header={helpHeading}/>
    return (
  
  <Menu>
     <p className="menu-label is-hidden-mobile">{getHeaderText()}{help}</p>
        {viewLinks}
        {editLinks}
  </Menu>
    )
  }

export default DiaryPreviewSidebar

