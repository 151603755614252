import  { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import isEqual from 'lodash/isEqual'
import differenceWith from 'lodash/differenceWith'
import axios from 'axios'
import { updateAccountSettings, updatePageSettings } from '../../../actions/settings'
import { augmentApi } from '../functions/location_fcns'
import useIsMounted from './useMount'

export default function usePageSettings(pageName, pageInstance, fetch=true) 
{
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)

    const [ pageSettings,  setPageSettings ] = useState({});
    const [ isResolved, setIsResolved ] = useState(false) //**needed since everything is asynchronous

    const accountSettings = useSelector(state => state.accountSettings, shallowEqual); //Get from Redux Store (if there)
    const isMounted = useIsMounted();

    const calcDefaultSettings = (pageName, pageInstance) => {
       if (pageName === 'home')
          return { pageName, pageInstance, sharedPage: false, editPagePrivacy: 2, viewPagePrivacy: 3 }
       else if (pageName === 'carousel' || pageName === 'vcarousel')
          return { pageName, pageInstance, sharedPage: true, editPagePrivacy: 2, viewPagePrivacy: 3 }
       else if (pageInstance === 'singleton' && pageName === 'wall')
          return { pageName, pageInstance, sharedPage: false, editPagePrivacy: 3, viewPagePrivacy: 3 }
       else if (pageInstance === 'singleton' && pageName === 'diary')
          return { pageName, pageInstance, sharedPage: false, editPagePrivacy: 2, viewPagePrivacy: 3 }
       else if (pageInstance !== 'singleton' && pageName === 'diary')
          return { pageName, pageInstance, sharedPage: false, editPagePrivacy: 1, viewPagePrivacy: 1 }
       else if (pageInstance !== 'singleton' && pageName === 'wall')
          return { pageName, pageInstance, sharedPage: true, editPagePrivacy: 1, viewPagePrivacy: 3 }
       }

    useEffect( () => {
        let savedContent = []
        const fetchData = async () => {
            if (isEmpty(accountSettings) ) {
               const res = await axios.get(augmentApi('/api/get_account_settings'))
               const content = res.data; 
               if (isEmpty(content))
               {    const obj = calcDefaultSettings(pageName, pageInstance)
                    dispatch(updatePageSettings(...values(obj)))  //Turn into an array and then spread
                    savedContent = [obj]
               }
               else   {
                 dispatch(updateAccountSettings(content))
                 savedContent = content
               }
            }
            else
                savedContent = accountSettings
        }
        const processData = async () => {
           const pn = pageName;
           const pi = pageInstance;   
           const p =  savedContent.find( ({pageName, pageInstance}) => pageName === pn && pageInstance === pi);
           if (isEmpty(p) && isMounted.current)
           {   const obj = calcDefaultSettings(pageName, pageInstance)
               savedContent.push(obj);
               dispatch(updatePageSettings(...values(obj)))
               setPageSettings(obj);
           }
           else if (isMounted.current)
               setPageSettings(p);
        }
        const init = async () => {
          await(fetchData());
          await processData();
          if (isMounted.current) setIsResolved(true)
        }
        if (fetch && !isResolved && auth)
          init();

        else if (!isEmpty(accountSettings) && !isResolved && !fetch && auth)
        {   savedContent = accountSettings
            processData();
            if (isMounted.current) setIsResolved(true)
        }
        else if (!isEmpty(accountSettings) && isResolved && auth)
        {   const pn = pageName;
            const pi = pageInstance;   
            const p =  accountSettings.find( ({pageName, pageInstance}) => pageName === pn && pageInstance === pi);
            if (!isEmpty(p) && !isEmpty(differenceWith([p], [pageSettings], isEqual)) && isMounted.current)
                setPageSettings(p);
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, accountSettings])

   return [ pageSettings, isResolved ];
}
