import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import DragPopupVideo from '../../common/popup/VideoPopup1'
import { getVideoCarouselPage, updateVideoCarouselPage } from '../../../actions/carouselPage'
import { augmentApi } from '../functions/location_fcns'
import { CAROUSEL_NEWBY_ACCOUNT, VCAROUSEL_INDEX, updateSessionStorage } from '../functions/session_fcns'

const VideoCarouselNew = (props) => {  

   const dispatch = useDispatch();
   const history = useHistory();
   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user); 
   const videoCarouselContent = useSelector(state => state.videoCarouselPageContent);

   const [ popupOpen, setPopupOpen] = useState(true); //**Start out popup open
   const [ pageLoadDone,  setPageLoadDone ] = useState(false);


   useEffect( () => {
      const loadAndSyncData = async () => {
         if (auth && isEmpty(videoCarouselContent) && pageLoadDone === false) {
            dispatch(updateVideoCarouselPage(await getVideoCarouselPage(), false)); 
            setPageLoadDone(true);
         }
         else if (auth && pageLoadDone === false)
            setPageLoadDone(true);
      }
      loadAndSyncData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth])

       // video : { isVideo: "true", url1, url2, thumb, poster, posterp, poster300, poster300p, w300, poster400, poster400p, w400  } 
   const onPopupEnd = async data => {
       let globalArray = await getVideoCarouselPage(); //isEmpty(videoCarouselContent) ? [] : videoCarouselContent.slice();  
       const oldSize = globalArray.length;
       const newby = {...data.video}
       const pageInstance = uuidv4()
       newby.pageInstance = pageInstance
       newby.key = pageInstance
       newby.caption = ""
       newby.user_id =  user.user_id
       globalArray.push(newby);
       dispatch(updateVideoCarouselPage(globalArray));
       updateSessionStorage(CAROUSEL_NEWBY_ACCOUNT, user.account_id)
       updateSessionStorage(VCAROUSEL_INDEX, globalArray)
       await axios.post('/api/save_page', { pageType: 'vcarousel', pageInstance, pageData: newby });
       setPopupOpen(false);
       const start = (globalArray.length > oldSize) ? oldSize : -1

       history.push("/auth/vcarousel/edit?current="+start)
   }

   if (auth === false) {
      return <div>Please Login to continue...</div>
   }

   const onPopupClose = () =>
   {   setPopupOpen(false)
       history.push("/auth/vcarousel/edit")
   }

   return (
       <DragPopupVideo
        initialState={popupOpen}
        setCloseData={data => onPopupEnd(data) }
        setClose={() => onPopupClose() }
        prompt="Upload new video for Video Gallery"
      />
  )
}

export default VideoCarouselNew;
