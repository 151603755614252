import React, { useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Columns, Hero, Card, Box, Section, Container, Heading } from 'react-bulma-components';
import './landing.css'
import CarouselGallery from './loggedIn/carousel/CarouselGallery'
import { useSelector } from 'react-redux'
import WhatsNew from './WhatsNew'
import GettingStartedHelpLink from './loggedIn/common/help/GettingStartedHelpLink'

const styles =
   { bshadow: { boxShadow:"rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"},
     gshadow: { backgroundColor: "var(--card-footer-color)", boxShadow:"rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"},
     rounded:  { borderRadius: "30px" },
   }


const Landing = (props) => {

   const history = useHistory();
   const vRef = useRef(null)
   const videoPlaying = useRef(false)

   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user);

   if (auth === null)
      return <div>Loading...</div>
   if (auth === true && (user === null || user.email === null ))
      return <div>Loading...</div>

   let  buttons =
         <>
         <Button type="reset" className="button is-link is-medium" style={{margin: "0 0 10px 0"}} size="medium" href="/account" fullwidth={false} onClick={()=>history.push('/account')}>Create Account</Button>
         &nbsp;&nbsp;
         <Button type="reset" className="button is-link is-light is-medium" style={{margin: "0 0"}} size="medium" fullwidth={false} onClick={()=>history.push('/share/1/home')}>See Example</Button>
         </>

    /*width -> width2 when there are enough pics to force multiple lines*/
   const top = auth===false? 
     <Hero className="is-halfheight has-bg-img"> 
       <center>
       <div className="hero-body" style={{paddingLeft: 0, maxWidth: "1600px"}}> 
          <Columns>
             <Columns.Column className="is-half">
                <img src="https://sibsforever.org/home/logo.png" style={{padding:0, margin: 0}} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
                <center className="logo-text">
                  A Community for Remembering and Sharing Sibling Relationships
               </center>
               <br />
                <center className="logo-text2">
                Many of us have lost a sibling and it is life changing. This is your space to preserve  memories and capture precious moments.
               </center>
               <br />
                {buttons}
                <br />
             </Columns.Column>
             <Columns.Column className="is-pulled-right is-hidden-touch is-one-quarter" style={{width: "min(150px,15vw)"}}>
             </Columns.Column>
             <Columns.Column className="is-pulled-right is-hidden-touch is-hidden-desktop-only is-one-quarter" style={{width: "min(150px,15vw)"}}>
             </Columns.Column>
             <Columns.Column className="is-pulled-right is-hidden-touch width" style={{width: "min(400px,40vw)"}} >
                <CarouselGallery lightboxView={true} publicV={true} />
             </Columns.Column>
             <Columns.Column className="is-hidden-desktop" style={{width: "min(768px,100vw)"}}>
                <center><CarouselGallery lightboxView={true} publicV={true} /></center>
             </Columns.Column>
          </Columns>
       </div> 
       </center>
     </Hero>
    : ""
      
   const gettingStarted = <GettingStartedHelpLink linkText='Getting started' isLink={false} />

   if (auth === true && user !== null && user.email !== null) buttons = 
         <>
        {gettingStarted}
         &nbsp;&nbsp;
         <Button type="reset" className="button is-link is-light is-medium" style={{margin: "0 0"}} size="medium" fullwidth={false} onClick={()=>history.push('/share/1/home')}>See Example</Button>
         </>

   const homePage = '<h2 style="text-align: center;">Audree and Robin Ralston</h2><p style="text-align: left;"><strong><img src="https://sibsforever.org/home/17d6103b-ae93-4023-9908-e5c709a563a2.jpeg" oncontextmenu="return false" ondragstart="return false" style="margin: 12px; border-radius: 30px; vertical-align: top; float: left; pointer-events: auto; user-select: auto;"><div style="display: inline-block; height: 0; width: 6em;"></div><span class="after">&nbsp;</span>We were sisters, 2.5 years apart in age.</strong>&nbsp; She was the <strong>older sib</strong>, although we were just one school grade apart since Robin repeated Kindergarten.<span>&nbsp;</span><strong>We shared a room at first</strong>&nbsp;since we lived in a two-bedroom garden apartment until I was five years old.&nbsp; We put up a homemade room divider as a way to claim our individual spaces.</p><p style="text-align: left;"><br></p><p style="text-align: left;"><strong>Our childhood was not an easy one</strong>.&nbsp; There were many reasons for this. Robin fought hard for our childhood freedoms.&nbsp; I was able to just walk through all the doors that she managed to pry open.</p><br clear="all"/>' 

   return (
     <>
       <br/>
     {top}

     {auth===false?
     <Section style={{marginBottom: "0px", padding:"5vmin 1vw 0 1vw"}}>
       <Container className="has-text-centered">

       <video ref={vRef} controls controlsList="nodownload" width="100%" height="100%" disablePictureInPicture playsInline preload="metadata" poster="http://sibsforever.org/home/poster.jpg"
             style={{maxWidth: "1024px", objectPosition: "50% 50%", border: "thick solid black"}}
             onClick={e => { e.preventDefault(); if (videoPlaying.current) vRef.current.pause(); else vRef.current.play(); videoPlaying.current = !videoPlaying.current}}
             onTouchStart={e => { e.stopPropagation(); if (videoPlaying.current) vRef.current.pause(); else vRef.current.play(); videoPlaying.current = !videoPlaying.current}}
          >
                  <source src="https://sibsforever.org/home/sibs.mp4" type="video/mp4" />
                  <source src="https://sibsforever.org/home/sibs.webm" type="video/webm" />
                    Sorry, your browser doesn't support embedded videos.
           </video>

       </Container>
     </Section>
     :""}
   
     {auth===false?
     <Section style={{margin: "0px", padding: "0px 1vw" }}>
       <center>
       <div style={{paddingLeft: 0, maxWidth: "1600px"}}>
           <Columns> 
              <Columns.Column className="is-full">
              <Box className="column is-narrow front-page is-shadowless">
                 <center><Heading size={2} className="is-size-2 subtitle" style={{margin: "15px", color: "var(--burnt-umber)"}}>Siblings Forever: <br />Death doesn't end the relationship</Heading></center>
                 <p className="is-size-5 mx-6 has-text-centered">
                    Some sibling relationships were short, while others were long. Some relationships were more complicated than others were. But all sibling relationships deserve to be remembered. It doesn’t matter how old you were when your sibling died or how long ago you lost your sibling, <span className="has-text-weight-semibold">SibsForever is here for you</span>.
                </p>
             </Box>
             </Columns.Column>
           </Columns>
           <Columns> 
              <Columns.Column className="is-8 is-offset-2 is-hidden-tablet-only">
               <img src="https://sibsforever.org/home/sibsLeft.jpg" onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
             </Columns.Column>
           </Columns>
           <Columns> 
              <Columns.Column className="is-10 is-offset-1 is-hidden-mobile is-hidden-desktop">
               <img src="https://sibsforever.org/home/sibsLeft.jpg" onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
             </Columns.Column>
           </Columns>
           <Columns> 
              <Columns.Column className="is-10 is-offset-1">
              <Box className="column is-narrow front-page1" style={styles.rounded}>
                  <p className="is-size-5 has-text-centered">
                  SibsForever is a private and safe community for all ages to memorialize their sibling relationships through words, pictures and video. Everything posted, down to individual images, has privacy settings so that intimate moments stay intimate while other moments can be shared with family, friends, or with the SibsForever community at large.
                  </p>
             <br/>
             <center>{buttons}</center>
             <br />
       <p>
       SibsForever was created in honor of Hayden Parker Bielenberg (July 2009 – August 2020) and his surviving twin sister Brenna, and Robin Ralston (June, 1953 - November, 1967), whose surviving sister Audree, created this application.
       </p>
             </Box>
             </Columns.Column>
           </Columns>
         </div>
         </center>
       </Section>
       : 
     <Section style={{margin: "0px", padding: "0px" }}>
       <center>
       <div style={{paddingLeft: 0, maxWidth: "1600px"}}>
       <Columns> 
         <Columns.Column className="is-12">
           <Container className="column front-page" style={{padding:0, margin:0}} >
                <span className="is-size-3 is-primary is-full column has-text-centered" style={{padding: "10px 0"}}>{`Welcome back, ${user.first_name} ${user.last_name}`}</span>
                  <div className="is-size-4 has-text-left" style={{margin: "2vh 5vw 0 5vw"}}>SibsForever is <b>your private and safe space</b> to memorialize your sibling relationship through words, pictures and video. Everything posted, down to individual images, has privacy settings so that intimate moments stay intimate while other moments can be shared with family, friends, or with the SibsForever community at large.
                  </div>
              <br/>
              <div className="column is-full front-page1" style={{padding: "20px 0"}}>
              <center>{buttons}</center>
              </div>
             <br />
           </Container>
         </Columns.Column>
       </Columns>
      </div>
      </center>
     <WhatsNew />
      </Section>
     }

       <center><Section style={{margin: "40px 0px", padding: "10px 2vw 25px 2vw", backgroundColor: "var(--primary)", maxWidth: "1200px" }}>
       <center>
                <center><Heading size={2} className="is-size-2 subtitle" style={{margin: "15px", color: "var(--background-color)"}}>How it Works</Heading></center>
       <div style={{paddingLeft: 0, maxWidth: "1200px"}}>
              <Box className="column" style={styles.bshadow}>
           <Columns className="is-vcentered"> 
              <Columns.Column className="is-4">
                <div size={4} className="is-size-4 has-text-primary subtitle" style={{margin: "15px"}}>Create your Memorial Home Page</div>
              </Columns.Column>
              <Columns.Column >
                <Box className="column is-narrow" style={styles.gshadow}>
                   <div style={{padding:'8px 8px', margin:0, textAlign:"left"}} className="jodit-wysiwyg" dangerouslySetInnerHTML={{__html: homePage}} />
                </Box>
              </Columns.Column>
              </Columns>
          </Box>
              <Box className="column" style={styles.bshadow}>
           <Columns className="is-vcentered reverse-columns"> 
              <Columns.Column className="is-8">
                <Box className="column is-narrow" style={styles.gshadow}>
                <img src="https://sibsforever.org/home/gallery-new.jpg" style={{padding:0, margin: 0}} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
                </Box>
              </Columns.Column>
              <Columns.Column className="is-4">
                <div size={4} className="is-size-4 has-text-primary subtitle" style={{margin: "15px"}}>Preserve Memories with Photos and Videos</div>
              </Columns.Column>
              </Columns>
          </Box>
              <Box className="column" style={styles.bshadow}>
           <Columns className="is-vcentered reverse-columns"> 
              <Columns.Column className="is-4">
                <div size={4} className="is-size-4 has-text-primary subtitle" style={{margin: "15px"}}>Document Memories by Journaling</div>
              </Columns.Column>
              <Columns.Column className="is-8">
                <Box className="column is-narrow" style={styles.gshadow}>
                <img src="https://sibsforever.org/home/journal-new.jpg" style={{padding:0, margin: 0}} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
                </Box>
              </Columns.Column>
              </Columns>
          </Box>
              <Box className="column" style={styles.bshadow}>
           <Columns className="is-vcentered"> 
              <Columns.Column >
                <Box className="column is-narrow" style={styles.gshadow}>
                <img src="https://sibsforever.org/home/wall-new-1.jpg" style={{padding:0, margin: 0, maxHeight:"28vh"}} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
                <hr style={{maxWidth: "50%"}}/>
                <img src="https://sibsforever.org/home/wall-new-2.jpg" style={{padding:0, margin: 0, maxHeight: "25vh"}} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
                </Box>
              </Columns.Column>
              <Columns.Column className="is-4">
                <div size={4} className="is-size-4 has-text-primary subtitle" style={{margin: "15px"}}>Highlight Memories on your Remembrance Wall</div>
              </Columns.Column>
              </Columns>
          </Box>
              <Box className="column" style={styles.bshadow}>
           <Columns className="is-vcentered"> 
              <Columns.Column className="is-4">
                <div size={4} className="is-size-4 has-text-primary subtitle" style={{margin: "15px"}}>Invite Friends and Family to Participate</div>
              </Columns.Column>
              <Columns.Column >
                <Box className="column is-narrow" style={styles.gshadow}>
                <img src="https://sibsforever.org/home/invite-new.jpg" style={{padding:0, margin: 0}} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
                <img src="https://sibsforever.org/home/invite-button.png" style={{padding:0, margin: 0, width:"455px"}} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
                </Box>
              </Columns.Column>
              </Columns>
          </Box>
              <Box className="column" style={styles.bshadow}>
           <Columns className="is-vcentered reverse-columns"> 
              <Columns.Column className="is-8">
                <Box className="column is-narrow" style={styles.gshadow}>
                <img src="https://sibsforever.org/home/sibs_logo.jpg" style={{padding:0, margin: 0}} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} />
                <br /><br />
                {buttons}
                <br /><br />
                </Box>
              </Columns.Column>
              <Columns.Column className="is-4">
                <div size={4} className="is-size-4 has-text-primary subtitle" style={{margin: "15px"}}>Be a part of the SibsForever Community</div>
              </Columns.Column>
              </Columns>
          </Box>


         </div>
         </center>
       </Section>
       </center>
    </>
    )
  }

export default Landing;
