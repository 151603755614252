import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from "react-router-dom";
import { fetchUser, loginInvite } from  '../../actions'
import { useSelector } from 'react-redux';

const InviteLogin = (props) => {

   const first = useRef(true)
   const dispatch = useDispatch();
   const location = useLocation();
   const history = useHistory();
   const query = new URLSearchParams(location.search);
   const token = query.get('token')

   const auth = useSelector(state => state.auth); //Logged in

     //Trigger the login action
   if (first.current)
      dispatch(loginInvite(token)) 
   first.current = false

   useEffect(() => {
       if (auth !== null && auth === true)
       {  dispatch(fetchUser());  
          history.push("/home")
       }
   }, [auth])
   
  return <></>
} 


export default InviteLogin;
