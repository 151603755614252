import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { useForm, Controller } from "react-hook-form";
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import Textarea from 'react-textarea-autosize'
import { Form, Button } from 'react-bulma-components';
import { v4 as uuidv4 } from 'uuid';
import { updateDiaryIndexPage, updateDiaryPage, setDiaryInstance } from '../../../actions/diaryPage'
import { DIARY_NEWBY_JSON, DIARY_NEWBY_ACCOUNT, DIARY_ENTRY_ACCOUNT, DIARY_ENTRY_SUMMARY, DIARY_ENTRY_TITLE, getSessionStorage, clearSessionStorage, updateSessionStorage } from '../functions/session_fcns'
import { isPublicA, augmentApi, calcDiaryUrl, getDiaryInstance, isPageRO  } from '../functions/location_fcns'
import useLockBodyScroll from '../hooks/useLockBodyScroll'

/* This popup does 1) Add a new entry, 2) change summary/title  and 3) Show Summary/title */

const ADD = 1
const CHANGE = 2
const SHOW = 3

const SummaryPopup = ({initialState=false}) => {
  
   const { handleSubmit, errors, control, getValues } = useForm();
   const publicA = isPublicA()

   const diaryInstance = getDiaryInstance()
   let action = diaryInstance === "toc" || diaryInstance === "new" || diaryInstance === "view" || diaryInstance === 'preview'? ADD : CHANGE;
   if (action === CHANGE && isPageRO())
     action = SHOW;

     //**Default values - could change if we use this for updating and values are passed in (ADD)
   const accountId = useRef(getSessionStorage(DIARY_ENTRY_ACCOUNT))
   const summaryValue = useRef(getSessionStorage(DIARY_ENTRY_SUMMARY))
   const titleValue = useRef(getSessionStorage(DIARY_ENTRY_TITLE))

    //**Keeping values in session storage so they are never lost
   const changeValueSumm = () => {
     const value = getValues("diarySummary")  //Get the form value
     updateSessionStorage(DIARY_ENTRY_SUMMARY, value)
     updateSessionStorage(DIARY_ENTRY_ACCOUNT, user.account_id)
   }
   const changeValueTitle = () => {
     const value = getValues("diaryTitle")  //Get the form value
     updateSessionStorage(DIARY_ENTRY_TITLE, value)
     updateSessionStorage(DIARY_ENTRY_ACCOUNT, user.account_id)
   }

     //hooks
   useLockBodyScroll(initialState);
   const dispatch = useDispatch();
   const history = useHistory();
   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user);
   const diaryContent = useSelector(state => state.diaryIndexPage);

     //Get default values from session store
   const getDefault = key => 
   {   if (action === ADD && accountId.current !== user.account_id)
       {  clearSessionStorage([DIARY_ENTRY_ACCOUNT, DIARY_ENTRY_SUMMARY, DIARY_ENTRY_TITLE])
          summaryValue.current = getSessionStorage(DIARY_ENTRY_SUMMARY)
          titleValue.current = getSessionStorage(DIARY_ENTRY_TITLE)
       }
       else if (!isEmpty(diaryInstance) && (action === CHANGE || action === SHOW))
       {    const index  = diaryContent.findIndex(elt => elt.pageInstance === diaryInstance)
            titleValue.current = diaryContent[index].title 
            summaryValue.current = diaryContent[index].summary
       }
       if (key === DIARY_ENTRY_TITLE)
          return titleValue.current
       else
          return summaryValue.current
   } 

      //State
   const [ popupOpen, setPopupOpen] = useState(initialState); //**Start out popup open
   const [ pageLoadDone,  setPageLoadDone ] = useState(false);

   const onAdd = async data => {
      let globalArray = isEmpty(diaryContent) ? [] : diaryContent.slice()
      const pageInstance = uuidv4()
      const newby = { title: data.diaryTitle, summary:data.diarySummary, pageInstance, key: pageInstance }
      const newby1 = { title: data.diaryTitle, summary:data.diarySummary, pageInstance, key: pageInstance, author: `${user.first_name} ${user.last_name}` }
      globalArray.push(newby);
      updateSessionStorage(DIARY_NEWBY_JSON, newby1)
      updateSessionStorage(DIARY_NEWBY_ACCOUNT, user.account_id)

         //**Make synchronous (take out of middleware)
      dispatch(updateDiaryIndexPage(globalArray, false));
      await axios.post('/api/save_page', { pageType: 'diary', pageInstance: 'singleton', pageData: globalArray});
      dispatch(updateDiaryPage(pageInstance, `<html><h2 style="text-align: center;">${data.diaryTitle}</h2><p>`))
      dispatch(setDiaryInstance(pageInstance));
      clearSessionStorage([DIARY_ENTRY_ACCOUNT, DIARY_ENTRY_SUMMARY, DIARY_ENTRY_TITLE])
      setPopupOpen(false)
      history.push("/auth/diary/toc")
   }

   const onChange = async data => {
       let toc = diaryContent.slice()
       const index = toc.findIndex(elt => elt.pageInstance === diaryInstance)
       toc[index].title = data.diaryTitle
       toc[index].summary = data.diarySummary
       dispatch(updateDiaryIndexPage(toc));
       setPopupOpen(false)
       const base = calcDiaryUrl()
       history.push(`${base}/edit`)
   }

   const onShow = async data => {
       setPopupOpen(false)
       const base = calcDiaryUrl()
       history.push(`${base}/view`)
   }

   const onSubmit = async data => {
       if (action === ADD)
         onAdd(data)
       else if (action === CHANGE)
         onChange(data)
       else if (action === SHOW)
         onShow(data)
   }

   const onPopCancel = () =>
   {
      //clearSessionStorage([DIARY_ENTRY_SUMMARY, DIARY_ENTRY_TITLE])
     setPopupOpen(false)
     if (action === ADD)
        history.push("/auth/diary/toc")
     else if (action === CHANGE)
     {  const base = calcDiaryUrl()
        history.push(`${base}/edit`)
     }
     else if (action === SHOW)
     {  const base = calcDiaryUrl()
        history.push(`${base}/view`)
     }
   }

   useEffect( () => {
      const loadAndSyncData = async () => {
         if ((auth||publicA) && isEmpty(diaryContent) && pageLoadDone === false) {
            let res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'diary', pageInstance: 'singleton' }}) 
            const ct = JSON.parse(res.data.pageData);
            const content = (typeof ct != "object") ? JSON.parse(ct) : ct;
            dispatch(updateDiaryIndexPage(content, false));
            setPageLoadDone(true);
         }
         else if ((auth||publicA) && pageLoadDone === false)
            setPageLoadDone(true);
      }
      loadAndSyncData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth])

   if (auth === false && !publicA) {
      return <div>Please Login to continue....</div>
   }
   if (pageLoadDone === false) {
      return <div>Loading...</div>
   }
   let popupTitle = action === ADD ? "Add a Journal Page" : "Edit Title and Summary"
   if (action === SHOW)
      popupTitle = "View Title and Summary"
   let buttonTitle = action === ADD ? "Add Page" : "Make Changes"
   if (action === SHOW)
      buttonTitle = "All Done"

   return (
    <>
      <Modal
        open={popupOpen}
        onClose={() => onPopCancel()}
        closeOnOverlayClick={false}
        center
      >
         <h4 className="title is-4">{popupTitle}</h4>
         <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <Form.Label>Title</Form.Label>
            <Form.Control>
                  <Controller
                     control={control}
                     name="diaryTitle"
                     defaultValue={getDefault(DIARY_ENTRY_TITLE)}
                     rules={{ required: true, minLength:3, maxLength:120 }}
                     render={(props) => (
                       <Textarea
                          onChange={ value => { props.onChange(value); if (action === ADD) changeValueTitle() } }
                          value={props.value}
                          placeholder="Journal Entry Title"
                          minRows={1}
                          maxRows={2}
                          className="comment-textarea"
                          maxLength={160}
                          style={{width: "min(400px,80vw)", margin: 0, padding: 0 }} 
                          readOnly={action === SHOW ? true : false}
                       /> )}
                   />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.diaryTitle && errors.diaryTitle.type === "required" && "Title of journal entry is required"}
                  {errors.diaryTitle && errors.diaryTitle.type === "minLength" && "Title must be between 3 - 120 characters"}
                  {errors.diaryTitle && errors.diaryTitle.type === "maxLength" && "Title must be between 3 - 120 characters"}
            </Form.Help>
          </Form.Field>

          <Form.Field>
            <Form.Label>Summary</Form.Label>
            <Form.Control>
               <Controller
                  control={control}
                  name="diarySummary"
                  defaultValue={getDefault(DIARY_ENTRY_SUMMARY)}
                  rules={{ required: true, minLength:3, maxLength:2048 }}
                  render={(props) => (
                    <Textarea
                       onChange={ value => { props.onChange(value); if (action === ADD) changeValueSumm() } }
                       value={props.value}
                       placeholder="Enter summary here ..."
                       minRows={8}
                       maxRows={16}
                       className="comment-textarea"
                       style={{width: "min(400px, 80vw)", margin: 0, padding: 0 }} 
                       maxLength={2048}
                       readOnly={action === SHOW ? true : false}
                    /> )}
                 />
             </Form.Control>
             <Form.Help textColor="danger">
                  {errors.diarySummary && errors.diarySummary.type === "required" && "Diary Summary is required"}
                  {errors.diarySummary && errors.diarySummary.type === "minLength" && "Title must be between 3 - 2048 characters"}
                  {errors.diarySummary && errors.diarySummary.type === "maxLength" && "Title must be between 3 - 12048 characters"}
             </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Control>
               <Button type="reset" style={{backgroundColor: '#B0B0B0', margin: "0 0"}} size="small" fullwidth={false} onClick={()=>onPopCancel()}>Cancel</Button>
               <Button type="submit" style={{margin: "0 0"}} size="small" className="is-pulled-right" fullwidth={false}>{buttonTitle}</Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Modal>
    </>
  );
};

export default SummaryPopup

