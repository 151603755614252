import { UPDATE_VCAROUSEL_PAGE, UPDATE_VCAROUSEL_PAGE_NODISK } from '../actions/types'

const initialState = null
export const videoCarouselPageReducer = (state=initialState, action={} ) =>
{
  switch(action.type) {
    case UPDATE_VCAROUSEL_PAGE: 
    case UPDATE_VCAROUSEL_PAGE_NODISK: 
          //This is an array of objects.  The application has already done a slice() and made the change before dispatching it
          //return cloneDeep(action.payload)
        return  action.payload 
    default:
        return state;
  }
};

