import axios from 'axios'
import { UPDATE_ACCOUNT_SETTINGS, UPDATE_PAGE_SETTINGS, UPDATE_PAGE_SETTINGS_NODISK } from './types'

/**
export const getAccountSettings = () => async dispatch => //dispatches html that is the page contents
  {
        const res = await axios.get(augmentApi('/api/get_account_settings'))
        dispatch(updateAccountSettings(res.data))
  }
**/

export const updateAccountSettings = accountSettings => {
  return { type: UPDATE_ACCOUNT_SETTINGS, payload: accountSettings }
}

  //Action creator function to return Plain Object containing action with dynamic data
export const updatePageSettings = (pageName, pageInstance, sharedPage, editPage, viewPage, updateDisk=true) => {
   const obj = { pageName, pageInstance, sharedPage, editPagePrivacy: editPage, viewPagePrivacy: viewPage}
   return { type: updateDisk?UPDATE_PAGE_SETTINGS:UPDATE_PAGE_SETTINGS_NODISK,  payload: obj }  
};

  //Middleware : 
export const updateSettingsMiddleware = ({ getState, dispatch }) => next => async action =>
{  const result = next(action);
   switch (action.type) {
     case UPDATE_PAGE_SETTINGS:
       await axios.post('/api/store_settings', action.payload );
          //dispatch(anotherAction(asyncResult));
       break;
       /***
       case SOME_OTHER_ACTION:
         const { slice: { stateVariable } } = getState();
         await someProcess(stateVariable);
         break;
      ***/
      default:
   }
   return result
}


/** normally, if synchronous)
  request = axios.get(...)
  return {
      type: FETCH_USER,
      payload: requests
}**/
