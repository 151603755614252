import { SET_ACCOUNT_OBJECT } from '../actions/types'

const initialState = {} 
export default function accountObjectReducer (state=initialState, action={} )
{
  switch(action.type) {
    case SET_ACCOUNT_OBJECT:   //account object
        return action.payload
    default:
        return state;
  }
};

