import isEmpty from 'lodash/isEmpty'
import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import WallSidebar from './WallSidebar'
import WallNewEntryCard from './WallNewEntryCard'
import { Heading, Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import { getBannerText, augmentApi } from '../functions/location_fcns'
import InfiniteScroll from "react-infinite-scroll-component";

const BatchSize = 8

const WallEdit = (props) => {

      //Redux
   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText("Remembrance Wall", user) 
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }
      //State
      //State - minimize state to reduce redrawing and losing the scroll position
   const [ , setRefs ] = useState([]);
   const [ pageLoadDone,  setPageLoadDone ] = useState(false);

   const pageRefs = useRef([]) //There was a timing delay with state that caused the InfiniteScroll component to get confused
   const pageLoadDone2 = useRef(false)
   const minId = useRef(0)
   const hasMore = useRef(true)

   const getMoreData = () => { pageLoadDone2.current = false; loadData() }

   const loadData = async () => {
      if (auth && !isEmpty(user) && !isEmpty(user.email) && (pageLoadDone === false || pageLoadDone2.current === false)) {
          let res = (user.role === 'sibling' || user.role === 'owner') 
               ? await axios.get(augmentApi('/api/get_all_pageRefs'), { params:  { pageType: 'wall', maxId: minId.current, maxRecords: BatchSize  }})
               : await axios.get(augmentApi('/api/get_all_my_pageRefs'), { params:  { pageType: 'wall', maxId: minId.current, maxRecords: BatchSize  }})
          let content = res.data.pageRefs;
          if (content.length < BatchSize)
             hasMore.current = false
          else
             minId.current = content[content.length-1].id
          if (pageLoadDone2.current === false)
              pageRefs.current = pageRefs.current.concat(content)
          else
              pageRefs.current = content;
          setRefs(pageRefs.current);
          setPageLoadDone(true);
          pageLoadDone2.current = true
      }
   }

   useEffect( () => {
      loadData();
       // eslint-disable-next-line
   }, [auth, user])

   if (auth === false) {
      return <div>Please Login to continue...</div>
   }
   else if (!pageLoadDone)
      return <div>Loading ....</div>

   else return (

     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     <div className="aside"><WallSidebar isActive="Edit Wall" className="menu"/></div>

     <Container className="column is-9" style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
        <InfiniteScroll
          dataLength={pageRefs.current.length}
          next={getMoreData}
          hasMore={hasMore.current}
          loader={<div>Loading....</div>}
        >
        {pageRefs.current.map(item => <WallNewEntryCard key={item.pageInstance} numComments={item.numComments} instance={item.pageInstance} firstName={item.firstName} lastName={item.lastName} isAuthor={item.isAuthor} url={item.url} num={item.num}  newby={false}/>)}
      </InfiniteScroll>
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default WallEdit;

