import { POST_NEW_COMMENT, SEE_COMMENTS } from './types'

//Action creator function to return Plain Object containing action with dynamic data
export const postNewComment = (comment_count) => {
   return { type: POST_NEW_COMMENT,  payload:comment_count }  //type is a required field
};

export const seeComments = (comment_count) => {
   return { type: SEE_COMMENTS,  payload:comment_count }  //type is a required field
};

