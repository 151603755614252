//**This is the Diary Entry Viewer

import isEmpty from 'lodash/isEmpty'
import React, {useEffect} from 'react';
import DiaryEntrySidebar from './DiaryEntrySidebar'
import DiaryEntryViewPanel from './DiaryEntryViewPanel'
import { Container, Section } from 'react-bulma-components';
import { isViewPage, isEsharePage, isAuthPage, isSharePage, getAccountOverride, isAllPages } from '../functions/location_fcns'
import '../sidebar.css'
import { DIARY_BREADCRUMB_PAGE, getSessionStorage } from '../functions/session_fcns'
import { useLocation } from "react-router-dom";

const DiaryEntryViewPage = (props) => {

    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0,0);
    }, [location]);

   let lastPage = getSessionStorage(DIARY_BREADCRUMB_PAGE)
   if (isEmpty(lastPage))
      lastPage = ""

     //**Set up breakcrumb trail
   let DiaryBreadcrumb = "";
   if (isAllPages() )  DiaryBreadcrumb = 
     <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
       <ul>
        <li><a href="/allpages/diary/toc/toc">&nbsp;Diary Table of Contents</a></li>
        <li className="is-active"><a href="/" aria-current="page">View Diary Entry</a></li>
      </ul>
      </nav>
   else if (isAuthPage() && (isViewPage('view') || isViewPage('see')) && lastPage === 'DiaryPreviewPage') DiaryBreadcrumb = 
     <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
       <ul>
        <li><a href="/auth/diary/toc/toc">&nbsp;Diary Table of Contents</a></li>
        <li><a href="/auth/diary/view">Preview</a></li>
        <li className="is-active"><a href="/" aria-current="page">View Diary Entry</a></li>
      </ul>
      </nav>
   else if (isAuthPage() && (isViewPage('view') || isViewPage('see')) && lastPage !== 'DiaryPreviewPage') DiaryBreadcrumb = 
     <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
       <ul>
        <li><a href="/auth/diary/toc/toc">&nbsp;Diary Table of Contents</a></li>
        <li className="is-active"><a href="/" aria-current="page">View Diary Entry</a></li>
      </ul>
      </nav>
   else if (isAuthPage() && isViewPage('eview')) DiaryBreadcrumb = 
     <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
       <ul>
        <li><a href="/auth/diary/toc/toc">&nbsp;Diary Table of Contents</a></li>
        <li className="is-active"><a href="/" aria-current="page">View Diary Entry</a></li>
      </ul>
      </nav>
   else if (isAuthPage()) DiaryBreadcrumb = 
     <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
       <ul>
        <li><a href="/auth/diary/toc/toc">&nbsp;Diary Table of Contents</a></li>
        <li><a href="/auth/diary/view">Preview</a></li>
        <li className="is-active"><a href="/" aria-current="page">View Diary Entry</a></li>
      </ul>
      </nav>
   else if (isEsharePage()) DiaryBreadcrumb = 
     <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
       <ul>
        <li><a href="/auth/diary/toc/toc">&nbsp;Diary Table of Contents</a></li>
        <li><a href={`/eshare/${getAccountOverride()}/diary`}>Preview</a></li>
        <li className="is-active"><a href="/" aria-current="page">View Diary Entry</a></li>
      </ul>
      </nav>
   else if (isSharePage())  DiaryBreadcrumb = 
      <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
       <ul>
        <li><a href={`/share/${getAccountOverride()}/diary/toc/toc`}>&nbsp;Diary Table of Contents</a></li>
        <li className="is-active"><a href="/" aria-current="page">View Diary Entry</a></li>
      </ul>
      </nav>

   return (
     <>
     {DiaryBreadcrumb}
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
        <div className="aside"><DiaryEntrySidebar className="menu"/></div>
     <Container className="column is-9" style={{paddingTop:0}}>
       <DiaryEntryViewPanel/>
     </Container>
     </Section>
     </>
   )
  }

export default DiaryEntryViewPage;

