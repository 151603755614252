//**This is the SibsForever sidebar for All Pages
//**To work with shared, API's need to pass account_overrider

import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom';
import { Menu } from 'react-bulma-components';
import usePagePerms from './hooks/usePagePerms'
import { augmentApi, getHeaderText, getAccountOverride } from './functions/location_fcns'
import './sidebar.css'
import useIsMounted from './hooks/useMount';
import useAccountOverridePages from './hooks/useAccountOverridePages'

const AllPagesSidebar = ({isActive}) => { 

const styles = {
    spanStyle: { marginTop:"5px", marginBottom:"5px", marginRight:"2px" },
    newDataStyle: { marginLeft: "2px", marginTop:"5px", marginBottom:"5px" }
}

   const auth = useSelector(state => state.auth); //Logged in?
   const see_cc = useSelector(state => state.seeCommentCount); //Logged in?
   useAccountOverridePages(false, true)

     //Visual display of changed data
   const newCommentsHome = useRef(false)
   const newCommentsWall = useRef(false)
   const newCommentsCarousel = useRef(false)
   const newCommentsVcarousel = useRef(false)
   const newCommentsDiary = useRef(false)
   const newDataWall= useRef(false)
   const newDataDiary= useRef(false)
   const newDataCarousel= useRef(false)
   const newDataVcarousel= useRef(false)

      //Permissions
   const [ , , canViewHome, isResolvedHome ] = usePagePerms('home', 'singleton', false)
   const [ , , canViewCarousel, isResolvedCarousel ] = usePagePerms('carousel', 'singleton', false)
   const [ , , canViewVcarousel, isResolvedVcarousel ] = usePagePerms('vcarousel', 'singleton', false)
   const [ , , canViewWall, isResolvedWall ] = usePagePerms('wall', 'singleton', false)
   const [ , , canViewDiary, isResolvedDiary ] = usePagePerms('diary', 'singleton', false)
      //Links based on permissions
   const [ homeLink, setHomeLink ] = useState("")
   const [ carouselLink, setCarouselLink ] = useState("")
   const [ vcarouselLink, setVcarouselLink ] = useState("")
   const [ wallLink, setWallLink ] = useState("")
   const [ diaryLink, setDiaryLink ] = useState("")

   const [ isInit, setIsInit ] = useState(false)
   const [ isRefresh, setRefresh ] = useState(0)
   const isLoaded = useRef(0)
   const isMounted = useIsMounted();

   const accountOverride = getAccountOverride()
   const prefix = (accountOverride === 0) ? "/allpages" : `/share/${accountOverride}`

     //Changed data
   const loadData = async () => {
       if (auth === null || auth === false) return;
      let res = await axios.get(augmentApi('/api/is_new_page_content'), { params:  { pageType: "wall"}}) 
      if (!isEmpty(res.data) && isMounted.current)
          newDataWall.current = res.data.newContent
      res = await axios.get(augmentApi('/api/is_new_page_content'), { params:  { pageType: "diary"}}) 
      if (!isEmpty(res.data) && isMounted.current)
          newDataDiary.current = res.data.newContent
   
      res = await axios.get(augmentApi('/api/get_comment_activity_details'), { params:  { pageType: "home", unseen: true, num: false }}) 
      if (!isEmpty(res.data) && isMounted.current)
          newCommentsHome.current = res.data.unseenComments;

      res = newDataWall.current?null:await axios.get(augmentApi('/api/get_comment_activity_details'), { params:  { pageType: "wall", unseen: true, num: false }}) 
      if (res && !isEmpty(res.data) && isMounted.current)
          newCommentsWall.current = res.data.unseenComments;

      res = newDataCarousel.current?null:await axios.get(augmentApi('/api/get_comment_activity_details'), { params:  { pageType: "carousel", unseen: true, num: false }}) 
      if (res && !isEmpty(res.data) && isMounted.current)
          newCommentsCarousel.current = res.data.unseenComments;

      res = newDataVcarousel.current?null:await axios.get(augmentApi('/api/get_comment_activity_details'), { params:  { pageType: "vcarousel", unseen: true, num: false }}) 
      if (res && !isEmpty(res.data) && isMounted.current)
          newCommentsVcarousel.current = res.data.unseenComments;

      res = newDataDiary.current?null:await axios.get(augmentApi('/api/get_comment_activity_details'), { params:  { pageType: "diary", unseen: true, num: false }}) 
      if (res && !isEmpty(res.data) && isMounted.current)
          newCommentsDiary.current = res.data.unseenComments;

      if (isMounted.current) {
          isLoaded.current = isLoaded.current+1
          setRefresh(isRefresh+1); //Force redraw
      }
   }

     //get links
   useEffect(() => {
       let homeReady = false;
       let carouselReady = false;
       let vcarouselReady = false;
       let wallReady = false;
       let diaryReady = false;
       if (isResolvedHome) {
          if (canViewHome && isMounted.current) {
              setHomeLink(
                 <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${prefix}/home`}>
                   <span className="menu-icon">
                     <i className="fa fa-home"  style={styles.spanStyle}></i>
                   </span>
                   Home
                   { newCommentsHome.current ? newDataMarkup : "" }
                 </NavLink></div>)
          }
          homeReady = true;
       }
       if (isResolvedCarousel) {
          if (canViewCarousel && isMounted.current) {
             setCarouselLink(
	        <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${prefix}/carousel`}>
	          <span className="menu-icon">
	            <i className="fa fa-picture-o"  style={styles.spanStyle}></i>
	          </span>
	          Picture Gallery 
                  {newDataCarousel.current ? newContentMarkup : "" }
                  {!newDataCarousel.current && newCommentsCarousel.current ? newDataMarkup : "" }
	        </NavLink></div>)
          }
          carouselReady = true;
       }
       if (isResolvedVcarousel) {
          if (canViewVcarousel && isMounted.current) {
             setVcarouselLink(
	        <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${prefix}/vcarousel`}>
	          <span className="menu-icon">
	            <i className="fa fa-film"  style={styles.spanStyle}></i>
	          </span>
	          Video Gallery 
                  {newDataVcarousel.current ? newContentMarkup : "" }
                  {!newDataVcarousel.current && newCommentsVcarousel.current ? newDataMarkup : "" }
	        </NavLink></div>)
          }
          vcarouselReady = true;
       }
       if (isResolvedWall) {
          if (canViewWall && isMounted.current) {
             setWallLink(
	        <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${prefix}/wall`}>
	          <span className="menu-icon">
	            <i className="fa fa-bars"  style={styles.spanStyle}></i>
	          </span>
	          Wall 
                  {newDataWall.current ? newContentMarkup : "" }
                  {!newDataWall.current && newCommentsWall.current ? newDataMarkup : "" }
	        </NavLink></div>)
          }
          wallReady = true;
       }
       if (isResolvedDiary) {
          if (canViewDiary && isMounted.current) {
             setDiaryLink(
	        <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${prefix}/diary`}>
	          <span className="menu-icon">
	            <i className="fa fa-book"  style={styles.spanStyle}></i>
	          </span>
	          Journal 
                  { newDataDiary.current ? newContentMarkup : "" }
                  { !newDataDiary.current && newCommentsDiary.current ? newDataMarkup : "" }
	        </NavLink></div>)
          }
          diaryReady = true;
       }
       if (homeReady && carouselReady && vcarouselReady && wallReady && diaryReady)
          setIsInit(true);
       if (isLoaded.current >= 1) {
          setRefresh(isRefresh+1); //Force redraw
       }

        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [ isResolvedHome, isResolvedCarousel, isResolvedVcarousel, isResolvedWall, isResolvedDiary, canViewHome, canViewCarousel, canViewWall, canViewDiary, isLoaded.current ])

      //Call loadData if user looks at comments or switches page
   useEffect(() => {
       loadData();
   }, [auth, see_cc]); // eslint-disable-line react-hooks/exhaustive-deps

      //Get changed data
   useEffect(() => {
       if (auth === null || auth === false) return;
       loadData();
       let numMinutes = 1;
       const interval = setInterval(() => {
             if (numMinutes % 3 === 0) loadData(); //Look for new data every N minutes
             else if (newCommentsHome.current || newCommentsWall.current || newCommentsCarousel.current || newCommentsVcarousel.current || newCommentsDiary.current || newDataWall.current || newDataDiary.current)  //Ditto
                loadData();
             numMinutes++;
       }, 2*60000);  //Check for new comments every N minutes
       return () =>  { clearInterval(interval); }
   }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

   const newDataMarkup = 
          <span className="menu-icon">
            <i className="fa fa-comments fa-cog"  style={styles.newDataStyle}></i>
          </span>

   const newContentMarkup = 
          <span className="is-size-7" style={{color: "red"}}>
            &nbsp;New
          </span>

   if (!isInit)
        return <div>Loading...</div>

   return (
  
   <Menu>
     <p className="menu-label is-hidden-mobile">{getHeaderText()}</p>
        {homeLink}
        {carouselLink}
        {vcarouselLink}
        {diaryLink}
        {wallLink}
   </Menu>
    )
  }

export default AllPagesSidebar

