/** Preview Page Help **/

import PageHelp from './PageHelp'

const PreviewHelpBody='<p><img src="https://sibsforever.org/img/preview-side.png" alt="Preview Sidebar" width="237" oncontextmenu="return false" ondragstart="return false" style="margin: 12px; vertical-align: top; float: left; pointer-events: auto; user-select: auto;" height="156"><strong style="color: rgb(236, 64, 122);">&nbsp;</strong><span style="color: rgb(236, 64, 122);"><strong style="color: rgb(236, 64, 122); font-size: 18px;">Page Preview&nbsp;</strong><span style="color: rgb(0,0,0);">lets you view your SibsForever content in <strong>two different ways:</strong></span></span></p><p><span style="color: rgb(236, 64, 122);"><span style="color: rgb(0,0,0);"><br></span></span></p><p><strong style="font-style: inherit; font-size: 1em;">1.&nbsp; As yourself</strong><span style="color: rgb(0,0,0); font-size: 1em;">.&nbsp; &nbsp;This view includes all of the content you have configured to be viewable </span><strong style="font-style: inherit; font-size: 1em;">only by the author</strong><span style="color: rgb(0,0,0); font-size: 1em;">&nbsp;along with&nbsp;</span><strong style="font-style: inherit; font-size: 1em;">content not shared </strong><span style="color: rgb(0,0,0); font-size: 1em;">with the wider SibsForever community.&nbsp; All </span><strong style="font-style: inherit; font-size: 1em;">shared content</strong><span style="color: rgb(0,0,0); font-size: 1em;"><span style="font-size: 1em;"> is included in this view as well.&nbsp; However, it does not include any private content authored by other users</span><span style="font-size: 1em;">.</span></span></p><p><br></p><p><span style="color: rgb(236, 64, 122);"><span style="color: rgb(0,0,0);"><strong>2. As follower</strong>:&nbsp; This view contains <strong>only</strong> content <strong>shared to the wider SibsForever community</strong>.</span></span></p>'

const PreviewPageHelp = ({header}) => {

       return <PageHelp header={header} body={PreviewHelpBody} />
}


export default PreviewPageHelp
