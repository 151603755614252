import React from 'react';
import { Section } from 'react-bulma-components';
import WallView  from '../loggedIn/wall/WallView'

const Blog = (props) => {

   return (
     <>
     <Section>
      <h2 className="title has-text-centered mb-6">SibsForever Blog</h2>
       <WallView  publicV={true}/>
     </Section>
     </>
    )
  }

export default Blog;
