//**This is the SibsForever sidebar for the Diary

import React from 'react'
import { NavLink } from 'react-router-dom';
import { Menu } from 'react-bulma-components';
import { getHeaderText } from '../functions/location_fcns'
import { useSelector } from 'react-redux'
import { CommunityPageHelp } from '../common/help/CommunityHelp'

const SharedSidebar = ({isActive}) => {  

const styles = {
    spanStyle: { marginRight: "2px", marginTop:"5px", marginBottom:"5px" },
    hrStyle: { borderTop: "2px solid #bbb" },
}

    const auth = useSelector(state => state.auth); //Logged in?

    if (auth === null)
	   return <div>Loading...</div>
    if (auth === false)
	   return <div style={{paddingTop: "20px"}}>Please Login to continue...</div>

    const help = <CommunityPageHelp />

    return (
  
  <Menu>
         <p className="menu-label is-hidden-mobile">{getHeaderText()}{help}</p>
         <div><NavLink className="xmenu-block" activeClassName="is-active" to="/community">
           <span className="nospace"><span className="menu-icon">
           <i className="fa fa-table"  style={styles.spanStyle}></i>
           </span>
           Browse</span>
           </NavLink></div>
	     <div><NavLink className="xmenu-block" activeClassName="is-active" to="/auth/shared/search">
	       <span className="nospace"><span className="menu-icon">
	       <i className="fa fa-search"  style={styles.spanStyle}></i>
	       </span>
	       Search</span>
	     </NavLink></div>
         <div><NavLink className="xmenu-block" activeClassName="is-active" to="/auth/shared/last_search">
	       <span className="nospace"><span className="menu-icon">
	       <i className="fa fa-bookmark"  style={styles.spanStyle}></i>
	       </span>
	       Last Search</span>
	     </NavLink></div>
         <hr className="is-hidden-mobile" style={styles.hrStyle} />
         <div><NavLink className="xmenu-block" activeClassName="is-active" to="/auth/shared/following">
           <span className="nospace"><span className="menu-icon">
           <i className="fa fa-check"  style={styles.spanStyle}></i>
           </span>
           Following</span>
         </NavLink></div>
         <div><NavLink className="xmenu-block" activeClassName="is-active" to="/auth/shared/followers">
           <span className="nospace"><span className="menu-icon">
           <i className="fa fa-user-plus"  style={styles.spanStyle}></i>
           </span>
           Followers</span>
         </NavLink></div>
  </Menu>

    )
}

export default SharedSidebar

