import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import  reducers from './reducers';  //index.js automatically found
import { updateHomePageMiddleware } from './actions/homePage'
import { updateVideoCarouselPageMiddleware, updateCarouselPageMiddleware } from './actions/carouselPage'
import { updateSettingsMiddleware } from './actions/settings'
import { updateAccountMiddleware } from './actions/account'
import { updateDiaryPageMiddleware } from './actions/diaryPage'
import reduxThunk from 'redux-thunk'

const DEBUG=false
const logger = createLogger();
const middleware = [ reduxThunk, updateAccountMiddleware, updateHomePageMiddleware, updateCarouselPageMiddleware, updateVideoCarouselPageMiddleware, updateSettingsMiddleware, updateDiaryPageMiddleware,
                     DEBUG && logger, ].filter(Boolean);
const middleWareEnhancer = applyMiddleware(...middleware)

  //Args:  reducer function, initial state, enhancers
export const store = createStore( reducers, {commentCount: 0, seeCommentCount: 0}, middleWareEnhancer);

