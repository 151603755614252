import isEmpty from 'lodash/isEmpty'
import React from 'react';
import { useSelector } from 'react-redux'
import CarouselSidebar from './CarouselSidebar'
//import CarouselEditPanel from './CarouselEditPanel'
import CarouselGallery from './CarouselGallery'
import { Heading, Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import { getHeaderText, getBannerText } from '../functions/location_fcns'

const CarouselEditPage = (props) => {

   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText(getHeaderText(), user) 
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     <div className="aside"><CarouselSidebar isActive="Edit" className="menu"/></div>
     <Container className="column is-9"  style={{paddingTop:0}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <CarouselGallery lightboxEdit={true} />
     </Container>
     </Section>
     </>
   )
  }

export default CarouselEditPage;

