//**Editor for Diary Page
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import React, { Suspense, useCallback, useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
//import JoditEditor from '../../common/editor/JoditEditor2'
const JoditEditor = React.lazy(()=>import('../../common/editor/JoditEditor2'))
import { updateDiaryPage, setDiaryInstance } from '../../../actions/diaryPage'
import usePagePerms from '../hooks/usePagePerms'
import { augmentApi, getDiaryInstance } from '../functions/location_fcns'
import { processDeletedImages } from '../functions/editor_fcns'
import ChangeSummaryPopup from './SummaryPopup'
import {isMobile} from 'react-device-detect';

const DiaryEntryEditor = ({isChange=false}) => {

const pageInstance = getDiaryInstance()
//State
const [ pageLoadDone,  setPageLoadDone ] = useState(false);
const [ currentContent, setCurrentContent ] = useState("");
//Redux State
const diaryInstance = useSelector(state => state.diaryInstance); 
const editorContent = useSelector(state => state.diaryPageContent); //Get from Redux Store (if there)
const auth = useSelector(state => state.auth); //Logged in?
//Hooks
const [ , canEditDiary, , isResolvedDiary ] = usePagePerms('diary', pageInstance, false)
const dispatch = useDispatch();

  //Automatically save using debouncing
const persistContent = () => { if (editorContent !== currentContent) { processDeletedImages(editorContent, currentContent); dispatch(updateDiaryPage(pageInstance, currentContent)); console.log("SAVE"); } }
               // eslint-disable-next-line react-hooks/exhaustive-deps
const delayedPersist = useCallback(debounce(persistContent, 350), [currentContent])

useEffect( () => {
   let isCorrectInstance = "unknown"
   const checkInstance = () => {
       if (!isEmpty(diaryInstance))
       {    if (diaryInstance !== pageInstance)
               isCorrectInstance = "false"
            else
               isCorrectInstance = "true"
       }
   }
   const loadAndSyncData = async () => {
      if (auth && isCorrectInstance !== "unknown" && (isCorrectInstance === "false" || isEmpty(editorContent)) && pageLoadDone === false) {
         let res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'diary', pageInstance: pageInstance }})
         let content = res.data.pageData;
         if (!isEmpty(content))
            content = JSON.parse(content);  
         if (isMobile) {  //Make images unselectable to avoid moving and dragging images
           content = content.replaceAll('pointer-events:auto; user-select:auto;', 'pointer-events: none; user-select: none;');
           content = content.replaceAll('pointer-events: auto; user-select: auto;', 'pointer-events: none; user-select: none;');
         }
         else  {  //disable making the images selectable
           content = content.replaceAll('pointer-events:none; user-select:none;', 'pointer-events: auto; user-select: auto;');
           content = content.replaceAll('pointer-events: none; user-select: none;', 'pointer-events: auto; user-select: auto;');
         }
         setCurrentContent(content); dispatch(updateDiaryPage(pageInstance, content, false)); dispatch(setDiaryInstance(pageInstance))
         setPageLoadDone(true); 
      }
      else if (isCorrectInstance !== "unknown" && auth && pageLoadDone === false)
      {  setCurrentContent(editorContent); 
         setPageLoadDone(true); 
      }
      else if (auth && isCorrectInstance !== "unknown")
      {  delayedPersist(); }
   }
   if (isCorrectInstance === "unknown")
      checkInstance()
   loadAndSyncData();
   return () =>  delayedPersist.cancel() 
            // eslint-disable-next-line react-hooks/exhaustive-deps
}, [auth, currentContent, delayedPersist, diaryInstance ])

if (auth === false) {
      return <div>Please Login to continue...</div>
   }
if (isResolvedDiary && !canEditDiary) {
      return <div>You are not authorized to view this page</div>
}
if (!pageLoadDone) {
      return <div>Loading</div>
   }
return (
  <>
    <div style={{position: "sticky", top: "0px", overflowY: "visible", zIndex: "11"}}>
    <Suspense fallback={<span>Loading...</span>}>
    <JoditEditor style={{margin: "3px"}} 
                 onChange={content=>{setCurrentContent(content); }} 
                 startingContent={editorContent}
                 placeholder='Continue writing this journal entry.  <p>Upload  any pictures or video that add to your narrative.</p>'
                 imagePrompt='Upload Image for Journal Entry'
                 videoPrompt='Upload Video for Journal Entry'
                 readOnly={isChange}
    />
    </Suspense>
    <ChangeSummaryPopup  initialState={isChange} />
    </div>
  </>
)
}


export default DiaryEntryEditor;

