//**This is the Diary TOC page

import React, {useEffect} from 'react'
import { Heading } from 'react-bulma-components';
import DiaryPreviewSidebar from './DiaryPreviewSidebar'
import AllPagesSidebar from '../AllPagesSidebar'
import DiaryTOC from './DiaryTOC'
import { Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import useGetHeader from '../hooks/useGetHeader'
import { isSharePage, isAllPages } from '../functions/location_fcns'
import { DIARY_BREADCRUMB_PAGE, updateSessionStorage } from '../functions/session_fcns'
import { useLocation } from "react-router-dom";

const HeadingPiece = "Journal"

const DiaryViewPage = (props) => {

   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   const location = useLocation();
   useEffect(() => {
     window.scrollTo(0,0);
   }, [location]);

   if (!isAllPages() && !isSharePage())
      updateSessionStorage(DIARY_BREADCRUMB_PAGE, "DiaryPreviewPage")

   const DiaryPageSide = (isAllPages() || isSharePage())
       ? <AllPagesSidebar isActive="Journal" className="menu"/>
       : <DiaryPreviewSidebar isActive="toc" className="menu"/>

   const heading = useGetHeader(HeadingPiece)

   const DiaryBreadcrumb = (isAllPages() || isSharePage())
   ? ""
   : <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
     <ul>
        <li><a href="/auth/diary/toc">&nbsp;Diary Table of Contents</a></li>
        <li className="is-active"><a href="/auth/diary/view" aria-current="page">Preview</a></li>
      </ul>
    </nav>

   return (
     <>
     {DiaryBreadcrumb}
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
      <div className="aside">{DiaryPageSide}</div>
     <Container className="column is-9" style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <DiaryTOC/>
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default DiaryViewPage;

