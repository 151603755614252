import isEmpty from 'lodash/isEmpty'
import React from 'react';
import { useSelector } from 'react-redux'
import WallSidebar from './WallSidebar'
import SettingsPage from '../common/SettingsPage'
import { Heading, Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import { getBannerText } from '../functions/location_fcns'

const WallSettings = (props) => {

      //Redux
   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText("Remembrance Wall", user) 
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   return (

     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     <div className="aside"><WallSidebar isActive="Settings" className="menu"/></div>
     <Container className="column is-9" style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <SettingsPage pageType="wall" />
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default WallSettings;

