//**This is the Popup for adding a new Diary Page

import React from 'react';
import DiarySidebar from './DiarySidebar'
import DiaryTOC from './DiaryTOC'
import { Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import usePagePerms from '../hooks/usePagePerms'

const DiaryNewPage = (props) => {

   const [ , canEdit, , isResolved ] = usePagePerms('diary', 'singleton')

   if (!isResolved)
     return <div>Loading...</div>

   else if (!canEdit)
     return <Container className="column is-9">
            <div>You are not authorized to edit this page</div>
            </Container>

   else return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
      <div className="aside"><DiarySidebar isActive="new" className="menu"/></div>
     <Container className="column is-9" style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <DiaryTOC isNew={true}/>
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default DiaryNewPage;

