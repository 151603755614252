import axios from 'axios'
import { FETCH_USER, GET_USER } from './types'

export const fetchUser = () => async dispatch => //fetchUser is a function that returns a function with a single arg (dispatch)
  {
        axios.get('/api/user') //returns promise, user is passed as a cookie header attribute
             .then( res => {
                    dispatch({type: FETCH_USER, payload: res.data})
                    dispatch({type: GET_USER, payload: res.data})
                   })
             .catch(()=> console.log("api/user failed"))
  }

export const loginAccount = (token) => async dispatch =>
  {
        axios.get('/api/account-login', { params: { token: token } }) 
             .then( res => {
                    dispatch({type: FETCH_USER, payload: res.data})
                    dispatch({type: GET_USER, payload: res.data})
                   })
              .catch(() => { axios.get('/api/link-login', { params: { token: token }})
                               .then( res => { dispatch({type: FETCH_USER, payload: res.data}) })
                               .catch(()=> { console.log("account-login auth failed"); window.location.href="/unauthorized/link" })
                           })
  }

export const loginInvite = (token) => async dispatch =>
  {
        axios.get('/api/invite-login', { params: { token: token } }) 
             .then( res => {
                    dispatch({type: FETCH_USER, payload: res.data})
                    dispatch({type: GET_USER, payload: res.data})
                   })
              .catch(() => { axios.get('/api/link-login', { params: { token: token }})
                               .then( res => { dispatch({type: FETCH_USER, payload: res.data}) })
                               .catch(()=> { console.log("invite-login auth failed"); window.location.href="/unauthorized/link" })
                           })
  }

export const loginLink = (token) => async dispatch =>
  {
        axios.get('/api/link-login', { params: { token: token }})
             .then( res => {
                    dispatch({type: FETCH_USER, payload: res.data})
                    dispatch({type: GET_USER, payload: res.data})
                   })
             .catch(()=> { console.log("link-login failed"); window.location.href="/unauthorized/link" } )
  }


/** normally, if synchronous)
  request = axios.get(...)
  return {
      type: FETCH_USER,
      payload: requests
}**/
