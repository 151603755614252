import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import React, {useState, useEffect } from "react";
import { StripeProvider, Elements } from 'react-stripe-elements'
import StripeCheckoutForm from "./StripeCheckoutForm";

const StripeContainer = (props) => {

   const [ pubKey, setPubKey ] = useState("")

    //Front-end has no dependency on environment variables -> get the published key from the server
   useEffect(() => {

      const f = async () => {
        let res = await axios.get('/api/getStripePubKey')
        setPubKey(res.data.key)
      }

      f();
    }, [])

    if (isEmpty(pubKey))
       return "Loading..."

    return (
       <StripeProvider apiKey={pubKey}>
         <Elements>
           <StripeCheckoutForm />
         </Elements>
       </StripeProvider>
   )
};

export default StripeContainer;
