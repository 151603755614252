import React from 'react';
import { Box, Section, Container } from 'react-bulma-components';
import ExternalLink from '../ExternalLink';


const LINKS = [
  {
    url:  'https://sibsforever.org/',
    title:'Various Articles and Books',
    realTitle: true,
    image: 'https://sibsforever.org/img/resources.jpeg',
    pageType: 'diary',
    pageInstance: 'fa3029a4-63d3-40b5-ae4a-218c9a3f4e23',
    description: () => (
      <>
        <ul><li><a href="https://griefwords.com/index.cgi?action=page&amp;page=articles%2Fadult_sibling.html&amp;site_id=3" target="_blank" rel="noreferrer">Helping Yourself Heal When an Adult Sibling Dies</a>&nbsp;- a short article by<span><span>&nbsp;</span>Alan D. Wolfelt, Ph.D. It's a worthwhile read and is equally applicable to children.</span></li><li><a href="http://www.drchristinahibbert.com/dealing-with-grief/siblings-grief-10-things-everyone-should-know/" target="_blank" rel="noreferrer">Siblings &amp; Grief: 10 Things Everyone Should Know</a>&nbsp;-&nbsp; I was able to relate to everything this author said.&nbsp; She lost two sisters at different times of her life and has gained some perspective about sibling grief over time.</li><li>There are a lot of books written by surviving siblings whose experiences could be helpful and comforting.&nbsp;&nbsp;<a href="https://hereforyou.co/pages/sibling-loss-resources" target="_blank" rel="noreferrer">https://hereforyou.co/pages/sibling-loss-resources</a>&nbsp;include lists of books, private Facebook groups, podcasts, and websites that support sibling loss and bereavement.</li><li><a href="https://griefhaven.org/resources/recommended-books-for-siblingloss/" target="_blank" rel="noreferrer">A booklist centered around sibling loss</a>, from <a href="https://griefhaven.org/" target="_blank" rel="noreferrer">https://griefhaven.org/</a>&nbsp;- a&nbsp;<span>nonprofit foundation dedicated to providing grief support and education to those who have loved and lost.</span></li></ul>
      </>
    )
  },
  {
    url:  'https://www.dougy.org/',
    title:'Dougy Center',
    realTitle: false,
    image: 'https://www.dougy.org/assets/uploads/global/logo_primary@3x.svg',
    pageType: 'diary',
    pageInstance: 'f12e7fe3-9ca7-4036-aca9-95f617df1c41',
    description: () => (
      <>
         provides support in a safe place where <b>children, teens, young adults, 
         and families who are grieving</b> can share their experiences before and after a death. 
         They provide support and training locally, nationally, and internationally to individuals 
         and organizations seeking to assist children in grief.
      </>
    )
  },
]


const Library = () => (
    <Container>
    <Section style={{padding:0, margin:0}}>
    <Container>
      <h2 className="title has-text-centered mb-6">Resources</h2>
      <div> 
        {LINKS.map(({ url, title, realTitle, image, pageType, pageInstance, description }) => (
            <Box key={url} style={{paddingBottom: 0}}>
            <ExternalLink href={url}>
            <img src={image} alt={title} style={{float:"left", padding:"2%"}}/>
            </ExternalLink>
            <div className="subtitle is-6" style={{padding:"2em 2% 0 2%"}}>
	    {realTitle?<h3 className="title is-4">{title}</h3>:<><ExternalLink href={url}>{title}</ExternalLink> &nbsp; </>}
            { description() }
            </div>
            <br clear="all"/>
            </Box>
        ))}
      </div>
    </Container>
    </Section>
    </Container>
)

export default Library
