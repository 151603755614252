import { Card, Box, Section, Container, Heading } from 'react-bulma-components';
import './landing.css'

const Privacy = (props) => {

   const styles =
   { borders: { border:"thick double var(--primary)", margin:".5rem"},
     bshadow: { boxShadow:"rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"},
     shim:  {  display: "inline-block", height: 0,  width: "10em "},
   }

   return (
     <>
<Section style={{padding:"2vw"}}>
<div className="sibs-subheading has-text-primary-NOT is-size-3 has-text-centered" style={{padding:0, margin:0}}>
Privacy Policy
</div>

<center>
<Box className="column is-narrow width" style={{boxShadow:"3px 3px var(--footer-background-color), -1em 0 .4em olive"}}>
<div style={{textAlign:"left", margin:"1%"}}>

<strong className="is-size-4">Overview</strong><br/><br/>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
<strong>SibsForever</strong> places a high priority on protecting your privacy. This privacy policy was created to demonstrate our commitment to <strong>your privacy</strong> and to show how <strong>data privacy </strong> 
has been a central pillar in the design and implementation of the <strong>SibsForever web platform</strong> since its' inception. This policy will also explain what types of information is collected and how this information is used.
</div>

<strong className="is-size-4">What Personally Identifiable Information is Collected?</strong><br/><br/>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
Users who sign up for a SibsForever account and/or submit an inquiry form, voluntarily provide contact information (such as name and e-mail address). SibsForever will reply to any inquries received and then archive the request which would include any contact information. SibsForever users who login to the system can always <strong>opt out of any communications</strong> sent by the organization.
</div>

<strong className="is-size-4">Cookies</strong><br/><br/>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
SibsForever <strong>does not use cookies</strong> for users who do not login to the platform.  For users who do create a secure session by logging into <a href="http://sibsforever.org">sibsforever.org</a>, <strong>temporary cookies</strong> are used primarily for security purposes.  These cookies typically expire when the user logs off or closes the browser. The cookies  are only temporarily stored.
</div>

<strong className="is-size-4">Email Privacy</strong><br/><br/>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
Sibsforever <strong>does not provide, sell, or rent email addresses</strong> to anyone.
</div>

<strong className="is-size-4">Privacy of Uploaded Multimedia files and Other Content</strong><br/><br/>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
Authenticated SibsForever users have the ability to upload images and enter content as part of the application flow.  All content is <strong>encrypted in transit and at rest</strong> using state-of-the-art security practices.  Furthermore,
<li>No images can be accessed outside of the sibsforever.org secure domain</li>
<li>Images cannot be downloaded, dragged or otherwise copied from within the application</li>
<li>The SibsForever application includes privacy settings so users can configure exclusive access to their content if they so choose</li>
</div>

<strong className="is-size-4">External Links</strong><br/><br/>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
<strong>Sibsforever.org</strong> includes links to external websites under the <strong>Resources</strong> and <strong>Blog</strong> menu options. These links do not fall under the sibsforever.org domain, and the company is not responsible for the privacy practices or the content of these external websites. The use of any linked website is solely at the user's own risk.
</div>

<strong className="is-size-4">Modifications</strong><br/><br/>

<div className="subtitle mb-6" style={{marginLeft: "0"}}>
<b>SibsForever may amend this privacy policy from time to time</b>; please review it periodically. When it's modified, SibsForever will post a notice online to alert users of policy changes. The continued use of sibsforever.org after the date that such notices are posted will be deemed to be an agreement to the changed terms.
</div>

<strong className="is-size-5">Published date of this Privacy Policy:  October 31, 2021</strong>


</div>
</Box>

</center>
</Section>

     </>
    )
  }

export default Privacy;
