import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bulma-components';
import SeeCommentsPopup from './SeeCommentsPopup'
import useIsMounted from '../../loggedIn/hooks/useMount';
import { augmentApi } from '../../loggedIn/functions/location_fcns'
import { useSelector } from 'react-redux'

const SeeCommentsButton = ({pageType, pageInstance, numComments, onClickFunc=undefined}) => {  //Could use object destructuring for const XXX = ({prop1, prop2}) =>

   const auth = useSelector(state => state.auth); //Logged in?
   const cc = useSelector(state => state.commentCount);

   const [open, setOpen] = useState(false); //**Start out popup not-open
   const [ numCom, setNumCom ] = useState(numComments)
   const [ newCom, setNewCom ] = useState(false)
 
   const isMounted = useIsMounted();
   
   const loadData = async () => {
      if (auth === null || auth === false) return
      let res = await axios.get(augmentApi('/api/get_comment_activity_details'), { params:  { pageType, pageInstance, unseen: true, num: true }})
      if (!isEmpty(res.data) && isMounted.current)
      {   setNewCom(res.data.unseenComments);
          setNumCom(res.data.numComments);
      }
   }

   const onEnd = (data) =>
   {  setOpen(false); //**Close the popup
      loadData();
   }

      //*update count if user posted a comment
   useEffect(() => {
       loadData();
     }, [cc, auth]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
       if (auth === null || auth === false) return;
       const NumMinutes = 2
       const interval = setInterval(() => {
           loadData();
       }, NumMinutes*60000);  //Check for new comments every N minute
       return () => clearInterval(interval);
     }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

   if (auth === null)
      return <div>Loading...</div>

   return (
    <>
    <a href="#" className="card-footer-item footer-buttons" onClick={() => { if (onClickFunc !== undefined) onClickFunc(); setOpen(true)}}> 
        All Comments ({auth===true?numCom:0}) <sup style={{color:"#FF0000"}}><small>{newCom&&auth===true ? 'New': ""}</small></sup>
    </a>
     <SeeCommentsPopup
           initialState={open}
           setCloseData={data => onEnd(data) }
           setClose={() => onEnd(null) }
           pageType={pageType}
           pageInstance={pageInstance}
       />
    </>
    )
  }

export default SeeCommentsButton;

