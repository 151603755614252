import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import DragPopup from '../../common/popup/DragPopupN'
import { getCarouselPage, updateCarouselPage } from '../../../actions/carouselPage'
import { augmentApi } from '../functions/location_fcns'
import { CAROUSEL_NEWBY_ACCOUNT, CAROUSEL_INDEX, updateSessionStorage } from '../functions/session_fcns'

  //**width/height will be constant for thumbs and images for smooth handling in carousel and sorting of images (width will be increments of 50)
const imageWidth = 1200
const imageHeight = 900
const thumbnailHeight = 200

const CarouselNew = (props) => {  

   const dispatch = useDispatch();
   const history = useHistory();
   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user); 
   const carouselContent = useSelector(state => state.carouselPageContent);

   const [ popupOpen, setPopupOpen] = useState(true); //**Start out popup open
   const [ pageLoadDone,  setPageLoadDone ] = useState(false);


   useEffect( () => {
      const loadAndSyncData = async () => {
         if (auth && isEmpty(carouselContent) && pageLoadDone === false) {
            const content = await getCarouselPage();
            dispatch(updateCarouselPage(content, false)); 
            setPageLoadDone(true);
         }
         else if (auth && pageLoadDone === false)
            setPageLoadDone(true);
      }
      loadAndSyncData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth])

   const onPopupEnd = async data => {
       const lgth = data.length; 
       let globalArray = await getCarouselPage(); //isEmpty(carouselContent) ? [] : carouselContent.slice();  
       const currentURL = window.location.href
       let protocol = "https"
       if (currentURL.startsWith("http://"))
          protocol = "http"
       const oldSize = globalArray.length;

       for (let i = 0;  i < lgth; i++)
       {  const pageInstance = uuidv4() 
          let thumbnailUrl = data[i].url
             //Calculate thumbnail URL
          const pieces = data[i].url.split("cf.sibsforever.org/");
          const pieces2 = pieces[pieces.length-1].split(".");
          let thumbPath = "";
          if (pieces.length === 2 && pieces2.length === 2) {
             thumbPath = `${pieces2[0]}.thumb.${pieces2[1]}`
             thumbnailUrl = `${protocol}://cf.sibsforever.org/${thumbPath}`
          }
          const newby = { imageUrl: data[i].url, exactHeight: imageHeight, exactWidth: imageWidth, pageInstance, caption: "" , thumbnailUrl, thumbHeight: thumbnailHeight, ...data[i], user_id: user.user_id, key: pageInstance } 
          globalArray.push(newby);
          await axios.post('/api/save_page', { pageType: 'carousel', pageInstance, pageData: newby });
       }
       dispatch(updateCarouselPage(globalArray));
       updateSessionStorage(CAROUSEL_NEWBY_ACCOUNT, user.account_id)
       updateSessionStorage(CAROUSEL_INDEX, globalArray)
       setPopupOpen(false);
       const start = (globalArray.length > oldSize) ? oldSize : -1

       history.push("/auth/carousel/edit?current="+start)
   }

   if (auth === false) {
      return <div>Please Login to continue...</div>
   }

   const onPopupClose = () =>
   {   setPopupOpen(false)
       history.push("/auth/carousel/edit")
   }

   return (
     <>
       <DragPopup
        initialState={popupOpen}
        setCloseData={data => onPopupEnd(data) }
        setClose={() => onPopupClose() }
        prompt="Upload Image(s) for Gallery"
        resize={{exactHeight: imageHeight, exactWidth: imageWidth, thumbHeight: thumbnailHeight, dhash: true}}
   />
     </>
  )
}

export default CarouselNew;
