import React from 'react';
import usePagePerms from '../hooks/usePagePerms'
import { NavLink } from 'react-router-dom';
import Tooltip from 'rc-tooltip'
//import 'rc-tooltip/assets/bootstrap_white.css';
import 'rc-tooltip/assets/bootstrap.css';

  //AllSettings (needs to be admin)
const AllSettingsNav = ({pageType, pageInstance='singleton', toLink}) =>
{
    const styles = {
       spanStyle: { marginRight: "2px", marginTop:"5px", marginBottom:"5px" },
    }
   
   const [ canAdmin, , , isResolved ] = usePagePerms(pageType, pageInstance)

   if (!isResolved)
     return <div>Loading...</div>
   if (!canAdmin)
     return <div>You are not Authorized</div>

   return (
     <>
     <div>
       <NavLink className="xmenu-block" activeClassName="is-active" to={toLink}>
       <span className="nospace"><span className="menu-icon">
          <i className="fa fa-cogs"  style={styles.spanStyle}></i>
       Settings &nbsp;</span>
          <Tooltip className="is-hidden-mobile" placement="right" trigger={['click']} 
                                    overlay={<span>These settings can limit and expand viewing and editing priviledges<br/>including keepng content restricted to only members of your account</span>}>
              <i className="is-hidden-mobile fa fa-question-circle"></i>
          </Tooltip>
     </span></NavLink> 
     </div>
     </>
    )
}

export default AllSettingsNav
