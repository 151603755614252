import { SET_DIARY_INSTANCE, UPDATE_DIARY_PAGE, UPDATE_DIARY_PAGE_NODISK, UPDATE_DIARY_INDEX_PAGE, UPDATE_DIARY_INDEX_PAGE_NODISK } from '../actions/types'

const instance_initialState = "none" 
export const diaryInstanceReducer = (state=instance_initialState, action={} ) =>
{
  switch(action.type) {
    case SET_DIARY_INSTANCE:   
        return action.payload
    default:
        return state;
  }
};

const page_initialState = "" 
export const diaryPageReducer = (state=page_initialState, action={} ) =>
{
  switch(action.type) {
    case UPDATE_DIARY_PAGE:
    case UPDATE_DIARY_PAGE_NODISK:
           //Action.payload is a string, which is immutable (instance != 'singleton').
        return  action.payload.pageContents
    default:
        return state;
  }
};

const index_initialState = ""
export const diaryIndexPageReducer = (state=index_initialState, action={} ) =>
{
  switch(action.type) {
    case UPDATE_DIARY_INDEX_PAGE:
    case UPDATE_DIARY_INDEX_PAGE_NODISK:
           //Singleton instances have already been sliced
        return  action.payload.pageContents
    default:
        return state;
  }
};


