import { UPDATE_HOME_PAGE, UPDATE_HOME_PAGE_NODISK } from '../actions/types'

const initialState = null

export default function homePageReducer(state=initialState, action={} )
{
  switch(action.type) {
    case UPDATE_HOME_PAGE: 
    case UPDATE_HOME_PAGE_NODISK: 
	   //Action.payload is a string, which is immutable
        return  action.payload 
    default:
        return state;
  }
};
