import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import donorReducer from './donorReducer';
import homePageReducer from './homePageReducer';
import { setCarouselActiveIndex, carouselPageReducer, setCarouselButtonText} from './carouselPageReducer';
import { videoCarouselPageReducer, setVideoCarouselButtonText} from './videoCarouselPageReducer';
import settingsReducer from './settingsReducer';
import accountReducer from './accountReducer';
import pageTypesReducer from './pageTypesReducer';
import { seeCommentReducer, postCommentReducer } from './commentReducers';
import { diaryInstanceReducer, diaryPageReducer, diaryIndexPageReducer } from './diaryReducers'

//This is a function to combine the reducers
export default combineReducers({
  auth: authReducer,  //key name (auth) is the name of the piece within the store (getState().auth)
  donor: donorReducer,
  homePageContent: homePageReducer,
  carouselPageContent:carouselPageReducer,
  videoCarouselPageContent:videoCarouselPageReducer,
  user: userReducer,
  accountSettings: settingsReducer,
  accountObject: accountReducer,
  diaryIndexPage: diaryIndexPageReducer,
  diaryInstance: diaryInstanceReducer,
  diaryPageContent: diaryPageReducer,
  sharedPageTypes: pageTypesReducer,
  commentCount: postCommentReducer,
  seeCommentCount: seeCommentReducer,
  carouselButtonText: setCarouselButtonText,
  carouselActiveIndex: setCarouselActiveIndex,
  
});
