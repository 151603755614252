import React from 'react';
import { Card } from 'react-bulma-components';
import WriteCommentButton from './WriteCommentButton'
import SeeCommentsButton from './SeeCommentsButton'

const CommentButtons = ({pageType, pageInstance, numComments, onClickFunc=undefined}) => {  //Could use object destructuring for const XXX = ({prop1, prop2}) =>

   return (
     <Card className="is-paddingless">
        <Card.Footer>
           <WriteCommentButton pageType={pageType} pageInstance={pageInstance} onClickFunc={onClickFunc} />
           <SeeCommentsButton pageType={pageType} pageInstance={pageInstance} numComments={numComments} onClickFunc={onClickFunc}/>
       </Card.Footer>
     </Card>
    )
  }

export default CommentButtons;

