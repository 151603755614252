import React from 'react';
import { useSelector } from 'react-redux';
import SharedView from './SharedView'

const Following = (props) => {  

   const auth = useSelector(state => state.auth); //Logged in?

   if (auth === null) 
      return <div>Loading...</div>
   if (auth === false) 
      return <div>Please Login to continue...</div>

   return (
     <>
       <SharedView following={true}/>

     </>
  )
}

export default Following;
