import isEmpty from 'lodash/isEmpty'
import React from 'react';
import { Navbar } from 'react-bulma-components';

const LoginTail = ({auth, user}) => {

     if (auth !== null && auth !== true && isEmpty(auth))
        auth = false;
     if (auth === null || (auth === true && (user === null || isEmpty(user) || isEmpty(user.email))))
        return null;

     switch (auth){
       case null:
          return;
       case false:
           return  (<>
               <Navbar.Item href="/faq" alt="FAQ">FAQ</Navbar.Item>
               <Navbar.Item href="/donate" alt="Donate">Donate</Navbar.Item>
               <Navbar.Item href="/contact" alt="Contact">Contact</Navbar.Item>
               {/*<Navbar.Item href="/library" alt="ResourceLibrary"className="is-hidden is-hidden-touch is-hidden-desktop-only">Resources</Navbar.Item>*/}
-              {/*<Navbar.Item href="/blog" alt="Blog" className="is-hidden is-hidden-touch is-hidden-desktop-only">Blog</Navbar.Item>*/}
           </> )
       default:
       {
           const adminLinks = isEmpty(user) || user.role === 'editor' || user.role === 'guest' || isEmpty(user.role) ? ""
                      : <>  
                         <Navbar.Item href="/invite">Invite Users</Navbar.Item>
                         <Navbar.Item href="/auth/admin">Update Account</Navbar.Item>
                        </>
           return  (<>

           <Navbar.Item dropdown hoverable href="#" >
           <Navbar.Link>
              Account
            </Navbar.Link>
            <Navbar.Dropdown>
               {adminLinks}
               <Navbar.Item renderAs="a" href={window.location.hostname !== 'home.sibsforever.org'?"https://node.sibsforever.org/api/logout":"/api/logout"} alt="Logout">Logout</Navbar.Item>
            </Navbar.Dropdown>
           </Navbar.Item>

           <Navbar.Item href="/community" alt="browse">Community</Navbar.Item>
          
           <Navbar.Item className="is-hidden-xtouch" dropdown hoverable href="#" >
           <Navbar.Link>
              Support
            </Navbar.Link>
            <Navbar.Dropdown>
               <Navbar.Item href="/requests" alt="Contact">Contact</Navbar.Item>
               <Navbar.Item href="/faq" alt="FAQ">FAQ</Navbar.Item>
               <Navbar.Item href="/library" alt="ResourceLibrary" className="is-hidden">Resources</Navbar.Item>
               <Navbar.Item href="/home/photo" alt="Photo" className="is-hidden">Send Front Page Photo</Navbar.Item>
               <Navbar.Item href="/donate" alt="Donate" className="is-hidden">Donate</Navbar.Item>
            </Navbar.Dropdown>

           <Navbar.Item dropdown className="is-hidden" hoverable href="#">
              <Navbar.Link>
  {/*<span style={{verticalAlign: "middle"}}> //Might be needed for Firebox */}
                    Connections
  {/*</span>*/}
              </Navbar.Link>
              <Navbar.Dropdown>
                 <Navbar.Item href="/auth/shared/following" alt="followers">Following</Navbar.Item>
                 <Navbar.Item href="/auth/shared/followers" alt="followers">Followers</Navbar.Item>
              </Navbar.Dropdown>
           </Navbar.Item>
           </Navbar.Item>
           </> )
    } }
  }

export default LoginTail;
