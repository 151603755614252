import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import axios from 'axios'
import React, { useRef, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'
import { useSelector } from 'react-redux'
import { Heading } from 'react-bulma-components';
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmPopup from '../../common/popup/ConfirmPopup'
import { augmentApi } from '../functions/location_fcns'
import { BROWSE_LAST_FETCH, updateSessionStorage } from '../functions/session_fcns'
import useIsMounted from '../hooks/useMount';

const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

const BatchSize = 20

const Styles = styled.div`
  padding-bottom: 1rem;

  table {
    border-spacing: 0;
    background-color: white;

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      }
    }
  }
`

const utc2local = dt => {
    const d = new Date(dt)
    const  dtOffset = new Date(d.setMinutes(d.getMinutes() - d.getTimezoneOffset()));
    return (dtOffset.getMonth() + 1) + '-' + dtOffset.getDate() + '-' +  dtOffset.getFullYear();
}

function Table({ columns, data, getMoreData, hasMore, accountId, following=false, followers=false }) {

  const auth = useSelector(state => state.auth);
  let heading = "SibsForever Sites"
  if (following) heading = "SibsForever Sites You Are Following"
  if (followers) heading = "SibsForever Users Following Your SibSite"

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map(column => {
            if (column.show === false) return column.accessor || column.id;
            else return null;
        }),
    },
    },
    useSortBy
  )

  if (auth === false) 
      return <div>You are not authorized to view this page</div>

  return (
    <>
      <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
      <InfiniteScroll
          dataLength={data.length}
          next={getMoreData}
          hasMore={hasMore.current}
          loader={<div>Loading....</div>}
          scrollableTarget="browse-scroll-target"
          style={{overflow: "inherit"}}
        >
      <table className="table is-hoverable is-striped is-bordered" {...getTableProps()} style={{padding: "0px", margin: "10px 0"}}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr className={accountId === row.original.account_id?"is-selected":""} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
      </InfiniteScroll>
    </>
  )
}

function SharedPanel({setSibSite, setIframeTitle, following=false, followers=false, useNothing=false}) {

  const [width, setWidth]   = useState(window.innerWidth);

  useEffect(() => {
    const updateDimensions = () => {
       setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


   const auth = useSelector(state => state.auth); //Logged in?
   const isMounted = useIsMounted();

    //State - minimize state to reduce redrawing and losing the scroll position
   const [ , setRows ] = useState([]);
   const [ pageLoadDone,  setPageLoadDone ] = useState(false);
   const [ selectedAccountId, setSelectedAccountId] = useState(0)
   const [ isFollowingDialog, setIsFollowingDialog] = useState(false)
   const [ isUnfollowDialog, setIsUnfollowDialog] = useState(false)
   const [ originalRow, setOriginalRow ] = useState([])
   const [ refresh, setRefresh ] = useState(0)

   const sibsiteRows = useRef([]) //There was a timing delay with state that caused the InfiniteScroll component to get confused
   const pageLoadDone2 = useRef(false)
   const minTime = useRef(0)
   const hasMore = useRef(true)

   const getMoreData = () => { pageLoadDone2.current = false; loadData() }

   const loadData = async () =>  {
       if (auth && (pageLoadDone === false || pageLoadDone2.current === false)) {
          let qs = ""
          if (!useNothing)  {
             let save = ""
             if (following) 
                 save="following"
             else if (followers)
                 save="followers"
             else 
                 save = window.location.search.substring(1);
             updateSessionStorage(BROWSE_LAST_FETCH, save)
          }
          let res = null;
          if (useNothing === true)
	      ;
          else if (following)
             res = await axios.get(augmentApi('/api/get_my_following'), { params: { maxRecords: BatchSize, maxTime: minTime.current }})
          else if (followers)
             res = await axios.get(augmentApi('/api/get_followers'), { params: { maxRecords: BatchSize, maxTime: minTime.current }})
          else
             res = await axios.get(augmentApi('/api/get_sibsite_list'), { params: { maxRecords: BatchSize, maxTime: minTime.current, qs }})
          let content = res === null ? [] : res.data;
          if (content.length < BatchSize)
             hasMore.current = false
          else
             minTime.current = content[content.length-1].realLastSharedUpdateTime
           
              //Line: accountId, departedFirstName, departedLastName, departedDob, departedDod, departedAgeAtDeath, siblingFirstName, siblingAgeAtDeath, siblingAgeToday, realLastSharedUpdateTime, lastSharedUpdateTime, accountPictUrl isFollowing
              //maybe: newSharedWallEntries, newSharedDiaryEntries, newSharedCarouselEntries
          const data = content.map(e => {
            const url = isEmpty(e.accountPictUrl) ? "https://bulma.io/images/placeholders/128x128.png" : e.accountPictUrl;
            let newby = { pict: url, account_id: e.accountId, departed: `${e.departedFirstName} ${e.departedLastName}`, sibs: `${e.departedFirstName} ${e.departedLastName} (and ${e.siblingFirstName})`,
                          ddates: `${e.departedAgeAtDeath} years old, ${e.departedDob}-${e.departedDod}`, living: e.siblingFirstName, ldates: `${e.siblingAgeAtDeath} years old in ${e.departedDod}, ${e.siblingAgeToday} years old today`,
                          update_time: e.lastSharedUpdateTime, isFollowing: e.isFollowing  }
            if (followers)
            {  newby.firstName = e.firstName
               newby.lastName = e.lastName
               newby.email = e.email
            }
            newby.newData = ((following||followers) && (e.newSharedWallEntries || e.newSharedDiaryEntries))?true:false;
            newby.newComments = (following||followers) && e.newSharedComments
            return newby
          } )

            //*associated display Array
         if (pageLoadDone2.current === false)
             sibsiteRows.current = sibsiteRows.current.concat(data)
         else
             sibsiteRows.current = data;

         setRows(sibsiteRows.current);  //*trigger redraw
         setPageLoadDone(true);
         pageLoadDone2.current = true
       }
   }

     //Add a follower
   const onFollowingEnd = async data => {
      await axios.post('/api/add_follower', { accountOverride: selectedAccountId });
      originalRow.isFollowing = true
      setIsFollowingDialog(false)
   }

     //Unfollow
   const onUnfollowEnd = async data => {
      await axios.post('/api/remove_follower', { accountOverride: selectedAccountId });
      originalRow.isFollowing = false
      setIsUnfollowDialog(false)
      const rows = sibsiteRows.current.slice()
      sibsiteRows.current = filter(rows, function(rec) { return rec.account_id !== selectedAccountId })
      setRows(sibsiteRows.current);  //*trigger redraw
   }

   useEffect( () => {
        //Load data for infinite scrolling
      loadData();
        // eslint-disable-next-line
   }, [auth])

     //**Screen visuals for new data
   const accountIdRef = useRef(0)
   const interval = useRef(0)
   useEffect( () => {
       const checkNew = async accountId => {
            if (originalRow.newData === false && originalRow.newComments === false) return;
            let res = await axios.get(augmentApi('/api/is_new_page_content'), { params:  { accountOverride:  selectedAccountId }})
            let changed = false
            if (!isEmpty(res.data) && isMounted.current && res.data.newContent !== originalRow.newData && accountIdRef.current === selectedAccountId) {
                originalRow.newData = res.data.newContent
                changed = true
            }
            res = await axios.get(augmentApi('/api/get_comment_activity_details'), { params:  { accountOverride:  selectedAccountId, unseen: true }})
            if (!isEmpty(res.data) && isMounted.current && res.data.unseenComments !== originalRow.newComments && accountIdRef.current === selectedAccountId) {
                originalRow.newComments = res.data.unseenComments
                changed = true
            }
            if (changed)
                setRefresh(refresh+1)
       }
       if (selectedAccountId === 0)  return
       if (accountIdRef.current === selectedAccountId)  return;
       accountIdRef.current = selectedAccountId;
       
       if (interval.current !== 0)
            clearInterval(interval.current)
       interval.current = setInterval(() => {
           checkNew(selectedAccountId)
       }, 15000);
       return () =>  { if (interval.current !== 0) clearInterval(interval); }
        // eslint-disable-next-line
   }, [selectedAccountId])

   const columns = useMemo( () => 
      [
          {
            Header: 'SibsForever User',
            accessor: 'user',
            sortDescFirst:true,
            show: followers&&width>600?true:false,
            Cell: ({ row: { original } }) => {
               return (
               <>
                  <a href={`mailto:${original.email}`} rel="noreferrer" target="_blank">{`${original.firstName} ${original.lastName}`}</a>
               </>
             )},
          },
          {
            Header: 'Account',
            accessor: 'account_id',
            show: false,
          },
          {
            Header: 'Sibs',
            accessor: 'pict',
            sortDescFirst:false,
            show: width>=900?true:false,
            Cell: tableProps => (
            <img
              src={tableProps.row.original.pict}
              width={72}
              alt='sibs'
            />
            )
          },
          {
            Header: 'Sib Survivor',
            accessor: 'living',
            disableSortBy: true,
            show: width>=850&&!followers?true:false
          },
          {
            Header: 'Siblings',
            accessor: 'sibs',
            sortDescFirst:true,
            show: width<850||followers?true:false
          },
          {
            Header: 'Departed Sibling',
            accessor: 'departed',
            sortDescFirst:true,
            show: width>=850&&!followers?true:false
          },
          {
            Header: 'Departed Sib Dates',
            accessor: 'ddates',
            sortDescFirst:true,
            show:true,
            Cell: ({ row: { original } }) => {
               const lines = original.ddates.split(",")
               if (lines.length !== 2)
                  return (
                    <>
                    {original.ddates}
                    </>
                  )
               else return (
                 <>
                 {lines[0]}<br/><br/>{lines[1]}
                 </>
             )},
          },
          {
            Header: 'Surviving Sib Dates',
            accessor: 'ldates',
            sortDescFirst:true,
            show:true,
            Cell: ({ row: { original } }) => {
               const lines = original.ldates.split(",")
               if (lines.length !== 2)
                  return (
                    <>
                    {original.ldates}
                    </>
                  )
               else return (
                 <>
                 {lines[0]}<br/><br/>{lines[1]}
                 </>
             )},
          },
          {
            Header: 'Last Updated',
            accessor: 'update_time',
            sortDescFirst:true,
            show: followers||width > 600?true:false,
            Cell: ({ row: { original } }) => {
               const v_ref = useRef()
               const newData = (original.newData||original.newComments) ? <><br/><span style={{color: "red"}}><small>(new data)</small></span></> : ""
               const seeButton = !followers ? "" :
                   <button className="button is-small is-primary is-outlined" ref={v_ref} style={{margin: "8px 3px 3px 0"}} onClick={() => { v_ref.current.blur(); setSelectedAccountId(original.account_id); setOriginalRow(original); setSibSite(`/share/${original.account_id}`);  document.querySelector('[id="sibsite"]').scrollIntoView({behavior: 'smooth'});}} >
                     View Site
                   </button>
               return (
               <>
                  {utc2local(original.update_time)}
                  {newData}<br/>
                  {seeButton}
               </>
             )},
          },
          {
            Header: width > 600 ? 'SibSite Actions' : 'Last Updated',
            accessor: 'actions',
            disableSortBy: width > 600 ? true : false,
            show:followers?false:true,
            Cell: ({ row: { original } }) => { 
               const v_ref = useRef()
               const f_ref = useRef()
               const ddate = width <= 600 ? utc2local(original.update_time) : ""
               const brk = width <= 600 ? <><br/><br/></> : ""
               const newData = (width <= 600 && (original.newData||original.newComments)) ? <><br/><span style={{color: "red"}}><small>(new data)</small></span></> : ""
                                         
               let myFollowing = original.isFollowing ? "" 
                    : <button className="button is-small is-primary is-outlined" ref={f_ref} style={{margin: "0 3px 3px 0"}} 
                       onClick={() => { f_ref.current.blur(); 
                                        setSelectedAccountId(original.account_id);
                                        setOriginalRow(original)
                                        setIsFollowingDialog(true)
                                       }} 
                      >
                        Follow
                     </button>
               if (following)
                  myFollowing = <button className="button is-small is-primary is-outlined" ref={f_ref} style={{margin: "0 3px 3px 0"}}
                       onClick={() => { f_ref.current.blur();
                                        setSelectedAccountId(original.account_id);
                                        setOriginalRow(original)
                                        setIsUnfollowDialog(true)
                                       }}
                      >
                        Unfollow
                     </button>

               return (
               <>
               {ddate}
               {newData}
               {brk}
               {myFollowing}
               <button className="button is-small is-primary is-outlined" ref={v_ref} style={{margin: "0 3px 3px 0"}} onClick={() => { v_ref.current.blur(); setSelectedAccountId(original.account_id); setOriginalRow(original); setSibSite(`/share/${original.account_id}`);  document.querySelector('[id="sibsite"]').scrollIntoView({behavior: 'smooth'});}} >
                  View Site
               </button>
               </>
             ) },
          },
        ], [setSibSite, following, followers, width]
  )
   if (auth === false) 
      return <div>Please Login to continue...</div>

   if (!pageLoadDone)
      return <div>Loading ....</div>

   return (
     <>
      <Styles>
        <Table columns={columns} data={sibsiteRows.current} getMoreData={getMoreData} hasMore={hasMore} accountId={selectedAccountId} followers={followers} following={following} />
      </Styles>
      <ConfirmPopup
           initialState={isFollowingDialog}
           setCloseData={data => onFollowingEnd(data) }
           setClose={()=>setIsFollowingDialog(false) }
           prompt="Are you sure you want to follow this SibSite?"
           title="Follow?"
           affirmativeButtonText="Yes! Follow... "
           declineButtonText="Cancel"
       />
      <ConfirmPopup
           initialState={isUnfollowDialog}
           setCloseData={data => onUnfollowEnd(data) }
           setClose={()=>setIsUnfollowDialog(false) }
           prompt="Are you sure you want to unfollow this SibSite?"
           title="Unfollow?"
           affirmativeButtonText="Yes! Unfollow... "
           declineButtonText="Cancel"
       />
      </>
  )
}

export default SharedPanel

