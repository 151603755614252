import React from 'react';
import { Section, Container } from 'react-bulma-components';
import FaqItem from './FaqItem';
import GettingStartedHelpLink from '../loggedIn/common/help/GettingStartedHelpLink'
import { InvitingUsersHelpLink } from '../loggedIn/common/help/InviteHelp'
import { CommunityHelpLink } from '../loggedIn/common/help/CommunityHelp'
//import ExternalLink from '../ExternalLink';

const FAQS = [
  {
    title: 'What is sibsforever.org?',
    content: () => (
      <>
       SibsForever is a site <b>dedicated to supporting and remembering past sibling relationships</b>.  Each user has a memorial homepage and can create picture galleries, timelines, journal entries and more. <b>Your content is private</b> by default, but items can be individually <b>shared with select friends or with the SibsForever community</b>.
      </>
    ),
  },
  {
    title: 'I want to use SibsForever, what do I do next?',
    content: () => (
      <>
         Using the top navigation bar, select <b>Create Account</b>.  This will bring up the <b>Account Creation form</b> which when completed, will create your <b>SibsForever account honoring your sibling relationship</b>. A one-time link will be sent to your email address which when tapped/clicked will log you into your new account.  More detail can be found at this  <GettingStartedHelpLink linkText="Getting Started" showNotAuth={true} /> link.
      </>
    ),
  },
  {
    title: 'My sibling died a long time ago, can I still participate?',
    content: () => (
      <>
      <b>Yes! Legacies are timeless</b> and regardless of when your sibling passed, you are welcome and encouraged to be part of the SibsForever community.
      </>
    ),
  },
  {
    title: 'How is sibsforever.org different from Facebook or other social media sites?',
    content: () => (
      <>
        SibsForever is <b>not</b> competing wih Facebook, Facebook groups, or any other social media platform. Instead, the goal for SibsForever is to <b>complement these other platforms</b> by providing a safe and 
        private place to <b>memorialize and honor a specific sibling relationship</b>.
      </>
    ),
  },
  {
    title: 'Will my photos be able to be found on Google and then downloaded?',
    content: () => (
      <>
     No. <b>SibSite content will <b>not</b> be indexed by Google</b> or any other search engine. Only pages and entries that you have <b>explicitely shared will be viewable</b> by users in the SibsForever community when they are logged into SibsForever. Individual <b>photos cannot be dragged, copied or downloaded</b>. Attempts to access photos by URL from outside the SibsForever application will fail.
      </>
    ),
  },
  {
    title: 'What is an account?',
    content: () => (
      <>
       An account is your <b>private place to build a SibSite commemorating your sibling relationship</b>.  You can invite family and friends to join you as <b>account members</b>. Every member of an account is <b>assigned a specific role</b> which is described below:
<div style={{overflowX:"auto"}}>
<table className="table">
  <thead>
    <tr>
      <th>Role</th>
      <th>View & Comment</th>
      <th>Create Content</th>
      <th>Manage the account</th>
      <th style={{maxWidth:"20%"}}>Create the account, manage admins, able to hide inappropriate shared content</th>
    </tr>
  </thead>
  <tbody>
     <tr><td>Creator</td><td style={{textAlign:"center"}}>X</td><td style={{textAlign:"center"}}>X</td><td style={{textAlign:"center"}}>X</td><td>X</td></tr>
     <tr><td>Admin</td><td style={{textAlign:"center"}}>X</td><td style={{textAlign:"center"}}>X</td><td style={{textAlign:"center"}}>X</td><td></td></tr>
     <tr><td>Editor</td><td style={{textAlign:"center"}}>X</td><td style={{textAlign:"center"}}>X</td><td></td><td></td></tr>
     <tr><td>Guest</td><td style={{textAlign:"center"}}>X</td><td></td><td></td><td></td></tr>
  </tbody>
</table>
</div>
        An account has a <b>single creator role</b> (the person who created the account). <b>Other roles</b> are assigned when users are invited to join the account.  <b>Additional details</b> can be found <InvitingUsersHelpLink linkText="on this page about Inviting Users"/>
      </>
    ),
  },
  {
    title: 'Do I need a user ID and password to use SibsForever?',
    content: () => (
      <>
        No. After creating your SibsForever account, you can login by selecting Login from the top navigation bar. A list of choices will appear including logging in using your <b>Google or Facebook credentials</b>, or optionally, you can <b>request a link</b> to be emailed which when selected will log you into your SibsForever site. More detail can be found at this  <GettingStartedHelpLink linkText="Getting Started" showNotAuth={true} /> link.
      </>
    ),
  },
  {
    title: 'How do I create my Homepage?',
    content: () => (
      <>
         As <GettingStartedHelpLink linkText="described here" showAuth={true} />, choose '<b>Edit Site</b>' from the top navigation bar which will present a list of choces. Choose the first option (<b>SibsForever Home Page</b>).  This will take you to a familiar <b>easy-to-use editor</b>.  You can enter content, upload photo's or paste content from other documents or pages. <b>All changes are automatically saved</b>. 
      </>
    ),
  },
  {
    title: 'How do I allow others to see my content?',
    content: () => (
      <>
        You can <b>share content</b> with members of your account or the SibsForever community at large. You can invite family and friends to become members of your account as <InvitingUsersHelpLink linkText="described here"/>.  Anyone (from any account) who can login to <a href="https://sibsforever.org">sibsforever.org</a> is part of the <b>SibsForever community</b>.
      </>
    ),
  },
  {
    title: 'Can all my content be private?',
    content: () => (
      <>
        Yes. There is no pressure or expectation to share any of your SibsForever content. <b>All content can be private</b> or you can share specific entries and/or pages with members of your account or with the SibsForever community.  
      </>
    ),
  },
  {
    title: 'What is the SibsForever community?',
    content: () => (
      <>
      The <b>SibsForever community</b> is made up of anyone who can login to <a href="https://sibsforever.org">sibsforever.org</a>, which equates to <b>any  person assigned any role in any account</b>. 
      As the number of users grow, participation and collaboration within SibsForever will grow as you:
        <ul>
        <li>Share your content</li>
        <li>Add comments to shared content including journal entries, photos, wall entries, or home pages. SibsForever users will be able to read your comments and reply.</li>
        <li>Follow other SibsForever users</li>
        </ul>
        <b>Additional information</b> about the <CommunityHelpLink linkText="SibsForever community can be found here" />. 
      </>
    ),
  },
  {
    title: 'How can I help SibsForever?',
    content: () => (
      <>
    There are many ways to help, some of which are listed below:
    <ol style={{marginLeft: "16px"}}>
       <li>If you know <b>someone who has experienced sibling loss</b>, let them <b>know about <a href="https://sibsforever.org">sibsforever.org</a></b></li>
       <li><b>Submit Resources</b> to be added to the Resources page. SibsForever wants to be able to provide reliable resources throughout the country. To accomplish this, we need user submissions and feedback.</li>
       <li><b>Submit pictures</b> for the home page.</li>
       <li>Feedback: <b>Tell us what you think</b> – how can we improve the site and make it more helpful?</li>
    </ol>
    More information about these options can be found in the middle of <GettingStartedHelpLink linkText="this Getting Started page" showAuth={true} scrolltop={false} />.
      </>
    ),
  },
  {
    title: 'How is SibsForever funded?',
    content: () => (
      <>
        SibsForever is entirely <b>funded through donations</b>. SibsForever is a <b>U.S. registered 501(c)(3) tax-exempt non-profit</b> organization (EIN: 85-2863304). Any and all donations are tax deductable.
      </>
    ),
  },
];

const FAQ = () => (
    <Section style={{padding: "3em .5em"}}>
    <Container className="py-4">
      <h2 className="title has-text-centered mb-6">Frequently Asked Questions</h2>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
    </Section>
);

export default FAQ;
