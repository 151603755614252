//No relative path - assumes the path is relative to node_modules/
import axios from 'axios'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Provider } from 'react-redux';
import { store } from './stores'

   //**Production  only
if (window.location.hostname !== 'home.sibsforever.org')
{   axios.defaults.baseURL = 'https://node.sibsforever.org'
    axios.defaults.withCredentials = true
    //axios.defaults.headers.post['Content-Type'] = 'application/json';
}

ReactDOM.render(
   <Provider store={store}>
    <App />
   </Provider>,
  document.getElementById('root')
);


//Front-end has no dependency on environment variables
//let res = await axios.get('/api/getStripePubKey')
//const pubKey = res.data.key

