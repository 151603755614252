import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { Button, Box, Columns, Form, Section, Heading } from "react-bulma-components";
import { checkName } from '../../common/formFunctions'
import { augmentApi } from '../functions/location_fcns'

const UserPanel = ({account_id, onEnd}) => {  

const newUser = id => 
{  
   if (id > 0)
   {   const r =  filter(userList, function(rec) { return rec.userId == id })
       setUserNameDisplay("block")
       if (r[0].role === 'owner' || r[0].role === 'sibling')
          setUserRoleDisplay("none")
       else if (r[0].userId === user.user_id)
          setUserRoleDisplay("none")
       else {
          setUserRoleDisplay("block")
          setValue("role", r[0].role)
       }
       setSelectedUser(r[0])
       setValue("firstName", r[0].firstName)
       setValue("lastName", r[0].lastName)
       setValue("user", { value: r[0].userId, label: r[0].email })  //**This didn't work
   }
   return true;
}

const auth = useSelector(state => state.auth); //Logged in?
const user = useSelector(state => state.user);

const [ userList,  setUserList ] = useState(null);
const [ selectedUser, setSelectedUser ] = useState({})
const [ userNameDisplay, setUserNameDisplay ] = useState("none")
const [ userRoleDisplay, setUserRoleDisplay ] = useState("none")

useEffect( () => {
   const loadData = async () => {
         let res = await axios.get(augmentApi('/api/get_users'))
         let users = res.data
         setUserList(users)
   }
   if (auth === true)
      loadData();
     // eslint-disable-next-line
}, [auth])


const { handleSubmit, errors, control, setValue } = useForm();
  const onSubmit = async data => {
    onEnd(data)
  }

  if (userList === null || isEmpty(user) || isEmpty(user.email))
    return <div>Loading...</div>

  return (
    <>
    <Section style={{padding:0}}>
      <Box style={{padding:"1% 2%"}}>
 
      <Heading subtitle size={4}>Change User Details</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
          <Columns>
          <Columns.Column className="is-one-half">
	  <Form.Field style={{marginLeft:"10px"}}>
            <Form.Control>
                <Controller
                   as={({ onChange, value }) => {
                    return (
                   <Form.Select
                     onChange={e => { onChange(e.target.value); value=e.target.value; newUser(value); return e.target.value }}
                   >
                     {isEmpty(value)?<option key={0} value="">Select User</option>:""}
                     {isEmpty(value)?userList.map(f => <option key={f.key} value={f.userId}>{f.email}</option>) 
                                    : <option key={selectedUser.key} value={selectedUser.userId}>{selectedUser.email}</option> 
                     }
                   </Form.Select>
                   ) }}
                  style={{marginBottom:"8px"}}
                  control={control}
                  name="user"
                  defaultValue=""
                  rules={{ required: true,
                           validate: id => { const rc = newUser(id); return rc }
                          }}

                />
            </Form.Control>
            <Form.Help textColor="danger"> {errors.user && errors.user.type === 'required' && "User is required"}
            </Form.Help>

            </Form.Field>
          </Columns.Column>

          <Columns.Column className="is-one-half">
          <Form.Field style={{marginLeft:"10px", display:userRoleDisplay }}>
             <Form.Control>
                <Controller
                  as={
                   <Form.Select>
                     <option value="">Select New Role</option>
                     <option value="admin">Admin</option>
                     <option value="editor">Editor</option>
                     <option value="guest">Guest</option>
                     <option value="none">None</option>
                   </Form.Select>
                   }
                  style={{marginBottom:"8px"}}
                  control={control}
                  name="role"
                  defaultValue=""
                  rules={{ validate: name => { if (selectedUser.role !== 'owner' && selectedUser.role !== 'sibling' && isEmpty(name)) return false; else return true }}}
                />
            </Form.Control>
            <Form.Help textColor="danger"> {errors.role && "Role is required"} </Form.Help>
          </Form.Field>
          </Columns.Column>
          </Columns>

          <Columns>
          <Columns.Column className="is-one-half">
	       <Form.Field style={{marginLeft:"10px", display: userNameDisplay}}>
           <Form.Label>First Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="firstName"
                    placeholder="User's First Name"
                    defaultValue={isEmpty(selectedUser)?"":selectedUser.firstName}
                    control={control}
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.firstName && errors.firstName.type === "required" && "User's first name is required"}
                  {errors.firstName && errors.firstName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>
          </Columns.Column>

          <Columns.Column className="is-one-half">
	       <Form.Field style={{marginLeft:"10px", display: userNameDisplay}}>
           <Form.Label>Last Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="lastName"
                    placeholder="User's Last Name"
                    defaultValue={isEmpty(selectedUser)?"":selectedUser.lastName}
                    control={control}
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.lastName && errors.lastName.type === "required" && "User's last name is required"}
                  {errors.lastName && errors.lastName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>
          </Columns.Column>
         </Columns>

          <Form.Field>
            <Form.Control>
              <center><Button fullwidth={false}>Save Changes</Button></center>
            </Form.Control>
          </Form.Field>
       </form>
       </Box>
     </Section>
     </>
  );
}

export default UserPanel;
