import react, {useEffect} from 'react'
import CarouselPreviewSidebar from './CarouselPreviewSidebar'
import CarouselGallery from './CarouselGallery'
import { Heading, Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import AllPagesSidebar from '../AllPagesSidebar'
import { isVideoCarouselPage, isSharePage, isAllPages } from '../functions/location_fcns'
import useGetHeader from '../hooks/useGetHeader'
import { useLocation } from "react-router-dom";


const CarouselViewPage = (props) => {

   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   const location = useLocation();
   useEffect(() => {
      window.scrollTo(0,0);
   }, [location]);

   const isVideo = isVideoCarouselPage()
   const pageName = isVideo ? "vcarousel" : "carousel"
   const HeadingPiece = isVideo ? "Video Gallery" : "Picture Gallery"
   const heading = useGetHeader(HeadingPiece)

   const carouselSide = (isAllPages() || isSharePage())
       ? <AllPagesSidebar isActive="carousel" className="menu"/>
       : <CarouselPreviewSidebar isActive="View" className="menu"/>

   const CarouselBreadcrumb = (isAllPages() || isSharePage())
   ? ""
   : <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
     <ul>
        <li><a href={isVideo ? "/auth/vcarousel/edit" :  "/auth/carousel/edit"}>&nbsp;{isVideo ? "Video Carousel Edit" : "Picture Carousel Edit"}</a></li>
        <li className="is-active"><a href={isVideo ? "/auth/vcarousel/view" :  "/auth/carousel/view"} aria-current="page">Preview</a></li>
      </ul>
    </nav>

   return (
     <>
     {CarouselBreadcrumb}
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     <div className="aside">{carouselSide}</div>
     <Container className="column is-9"  style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <CarouselGallery lightboxView={true}/>
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default CarouselViewPage;

