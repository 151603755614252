import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { Card, Columns, Content } from 'react-bulma-components';
import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable'
import DragPopupSingle from '../../common/popup/DragPopup1'
import DragPopupVideo from '../../common/popup/VideoPopup1'
import DeleteButton from '../common/DeleteButton'
import SettingsButton from '../common/SettingsButton'
import WriteCommentButton from '../../common/comments/WriteCommentButton'
import SeeCommentsButton from '../../common/comments/SeeCommentsButton'
 
import { NEW_WALL_ENTRY_KEY, NEW_WALL_ENTRY_RECORD, getLocalStorage, updateLocalStorage, clearLocalStorage } from '../functions/localStore'
import { WALL_ENTRY_KEY, WALL_ENTRY_RECORD, updateSessionStorage, clearSessionStorage } from '../functions/session_fcns'

import './card.css'

const titleDefault = "Enter title of your memory"
const bodyDefault = "Write your memory (changes automatically saved)"

  //Litle sleep function - need to await for it
//function sleep({delay}) { return new Promise(resolve => { setTimeout(() => { resolve('resolved'); }, delay); }); }

class WallNewEntryCard extends Component {
  constructor(props)
  {   super(props);
      this.state = {
         title: "",
         body: "",
         image: "",
         video: {},  //url1, url2, thumb, poster, posterp, w300, w400, poster300, poster300p, poster400, poster400p
         date: isEmpty(props.instance) || props.newby ? new Date().toJSON() : "",
         pageInstance: isEmpty(props.instance)?uuidv4():props.instance,
         author_first: "",
         author_last: "",
         popupOpen: false,
         videoPopupOpen: false,
         pageLoadDone: false,
         pageDeleted: false,
         imageWidth: 400,
         poster: "",
         vkey: 0,
         }
         // This binding is necessary to make `this` work in the callback
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeBody = this.onChangeBody.bind(this);
    this.onPopupEnd = this.onPopupEnd.bind(this);
    this.save = this.save.bind(this);
    this.debounceSave = this.debounceSave.bind(this);
    this.vidRef = null;
    this.videoLoaded = false;
    this.isPlaying = false;
  }

     //Don't redraw
  resetState() {
     if (isEmpty(this.props.instance) || (this.props.newby && isEmpty(getLocalStorage(NEW_WALL_ENTRY_RECORD)))) {
        this.setState({title: ""});
        this.setState({body: ""});
        this.setState({image: ""});
        this.setState({video: {}});
        this.setState({date: new Date().toJSON()});
        this.setState({pageInstance: isEmpty(this.props.instance)?uuidv4():this.props.instance})
        this.setState({popupOpen: false});
        this.setState({videoPopupOpen: false});
        //this.setState({author_first: ""});
        //this.setState({author_last: ""});
        //this.setState({pageLoadDone: false});
        //this.setState({pageDeleted: false});
        this.setState({imageWidth: 400});
     }
  }

  componentDidMount() 
  {  if (!isEmpty(this.props.instance) && !this.props.newby)  //This is an edit, so go get the page
     {    const loadData = async () => {
            if (this.state.pageLoadDone === false) {

               let content = {}
               try
               {   let res = await axios.get(this.props.url)  //*This is a direct URL to S3
                   const ct = res.data
                   content = (typeof ct != "object") ? JSON.parse(ct) : ct;
               }
               catch (err) {
                   const key = sessionStorage.getItem(WALL_ENTRY_KEY)
                   if (!isEmpty(key) && key === this.props.instance)
                       content = sessionStorage.getItem(WALL_ENTRY_RECORD)
               }
                   
               if (!isEmpty(content))  //Don't refresh until the end
               {   this.setState({title:  content.title});
                   this.setState({body:  content.body});
                   this.setState({image:  isEmpty(content.image)?"":content.image});
                   this.setState({video:  isEmpty(content.video)?{}:content.video});
                   this.setState({poster:  isEmpty(content.video)||isEmpty(content.video.poster400)?"":content.video.poster400});
                   this.setState({imageWidth:  content.imageWidth});
                   this.setState({date:  content.date});
                   this.setState({author_first:  this.props.firstName});
                   this.setState({author_last:  this.props.lastName});
               }
               else
                  this.setState({pageDeleted: true})
               this.setState({"pageLoadDone": true})
            }
            else
               this.setState({pageDeleted: true})
         }
         loadData();
     }
     else
     {   const content = getLocalStorage(NEW_WALL_ENTRY_RECORD)  
         if (isEmpty(content))
             this.resetState()
         else {
             this.setState({title:  content.title});
             this.setState({body:  content.body});
             this.setState({image:  isEmpty(content.image)?"":content.image});
             this.setState({video:  isEmpty(content.video)?{}:content.video});
             this.setState({poster:  isEmpty(content.video) || isEmpty(content.video.poster400)?"":content.video.poster400});
             this.setState({imageWidth:  content.imageWidth});
             this.setState({date:  content.date});
             this.setState({author_first:  this.props.firstName});
             this.setState({author_last:  this.props.lastName});
         }
         this.setState({"pageLoadDone": true})
     }
  }

  onChangeTitle(evt) { this.setState({ title: evt.target.value }); this.debounceSave() }
  onChangeBody(evt)  { this.setState({ body: evt.target.value }); this.debounceSave() }

//**New Image popup
  onPopupEnd(data) 
  {   this.setState({image:  !isEmpty(data.url)?data.url:""})
      this.setState({video:  !isEmpty(data.video)?data.video:{}})
      this.setState({poster: !isEmpty(data.video)?this.state.video.poster400p:""})
      const w = !isEmpty(data.video) ? data.video.w400 : data.width;
      if ( (typeof w !== 'undefined') && w !== null && w > 0 )
          this.setState({imageWidth: w})
         //close popup
      this.videoLoaded = false;
      if (this.props.newby)
      {   this.props.setImagePopState(false)
          this.props.setVideoPopState(false)
      }
      this.setState({popupOpen:  false}) 
      this.setState({videoPopupOpen:  false}) 
      this.debounceSave();
  }

  debounceSave = debounce(() => this.save(), 350)
  save = async () => 
  {  console.log("save") 
     const payload = { 
        title: this.state.title,
        body: this.state.body,
        image: this.state.image,
        video: this.state.video,
        date: this.state.date,
        imageWidth: this.state.imageWidth
    }
    if (this.props.newby === false)
       await axios.post('/api/save_page', { pageType: 'wall', pageInstance: this.state.pageInstance, pageData: payload });
    else
    {   updateLocalStorage(NEW_WALL_ENTRY_RECORD, payload)
        //console.log("STORAGE", getLocalStorage(NEW_WALL_ENTRY_RECORD))
    }
  }

  clear = async () => {
     clearLocalStorage([NEW_WALL_ENTRY_RECORD])
     this.resetState()
  }

  post = async () =>
  {  const payload = { 
        title: this.state.title,
        body: this.state.body,
        image: this.state.image,
        video:  this.state.video,
        date: this.state.date,
        imageWidth: this.state.imageWidth
     }
     await axios.post('/api/save_page', { pageType: 'wall', pageInstance: this.state.pageInstance, pageData: payload });
     updateSessionStorage(WALL_ENTRY_RECORD, payload)
     updateSessionStorage(WALL_ENTRY_KEY, this.state.pageInstance)
     clearLocalStorage([NEW_WALL_ENTRY_KEY, NEW_WALL_ENTRY_RECORD])
        //redirect to edit
     const { history: { push } } = this.props;
     push('/auth/wall/edit');
  }

  delete = async () => 
  {   await axios.post('/api/delete_page', { pageType: 'wall', pageInstance: this.state.pageInstance }); 
      this.setState({pageDeleted: true})
  }

  render() {
      
  if (this.state.pageDeleted)
      return <></>	
  if (this.props.auth === false) 
      return <div>Please Login to continue...</div>
  if (isEmpty(this.state.date))
      return <></>	
  const userInstance=
             <small>{`${this.state.author_first} ${this.state.author_last}, ${new Date(this.state.date).toLocaleString()}`}</small>

  const userNew=isEmpty(this.props.user)?"":
            <small>{`${this.props.user.first_name} ${this.props.user.last_name}, ${new Date(this.state.date).toLocaleString()}`}</small>

  const image = isEmpty(this.state.image)?"":
         <Columns.Column className="is-narrow" style={{padding:"1% .5%", margin: "0 .5rem"}}>
            <img src={this.state.image} alt="Wall entry" width={this.state.imageWidth} onContextMenu={e => e.preventDefault()} onDragStart={e => e.preventDefault()}/>
         </Columns.Column>

  const video = isEmpty(this.state.video)?"":
         <Columns.Column className="is-narrow" style={{padding:"1% .5%", margin: "0 .5rem"}}>
            <video ref={ref => this.vidRef = ref} controls width={this.state.video.w400} controlsList="nodownload" disablePictureInPicture playsInline poster={this.state.poster} 
                     key={this.state.video.url1+this.state.vkey} preload="metadata" 
                     autoPlay={false}
                         onClick={e => { e.preventDefault(); if (this.videoLoaded !== true) this.setState({vkey:this.state.vkey+1 }); if (this.isPlaying) this.vidRef.pause(); else this.vidRef.play(); this.isPlaying = !this.isPlaying}} 
                         onLoadedMetadata={(e) => { this.videoLoaded = true; this.setState({poster: this.state.video.poster400})}}
                         onError={(e) => { console.log("OnError", this.videoLoaded); if (this.videoLoaded !== true){ setTimeout(() => this.setState({vkey:this.state.vkey+1 }), 5000)}}}
                         onTouchStart={e => { e.stopPropagation(); if (this.videoLoaded !== true) this.setState({vkey:this.state.vkey+1 }); if (this.isPlaying) this.vidRef.pause(); else this.vidRef.play(); this.isPlaying = !this.isPlaying}}
            >
                <source src={this.state.video.url2} type="video/mp4" />
-               <source src={this.state.video.url1} type="video/webm" />
                Sorry, your browser doesn't support embedded videos.
            </video>
         </Columns.Column>

  const deleteButton = !this.props.isAuthor||this.props.newby?"":
        <DeleteButton itemDescription="this Wall entry" deleteFunc={()=>this.delete()}/>

  const settingsButton = this.props.newby?"":
        <SettingsButton pageType='wall' pageInstance={this.props.instance} />

  const commentButtons = (this.props.showComments === true || this.props.isAuthor === false) ?
     <>
     <WriteCommentButton pageType="wall" pageInstance={this.state.pageInstance} />
     <SeeCommentsButton pageType="wall" pageInstance={this.state.pageInstance} numComments={this.props.numComments} />
     </>
     : ""

  const imageButton = !this.props.isAuthor?"":
       <>
       <a href="#" className="card-footer-item footer-buttons" onClick={() => { if (this.props.newby) this.props.setImagePopState(true); this.setState({popupOpen: true})}} >
                 {isEmpty(this.state.image)?"Upload Image":"Replace Image"}
       </a>
       <a href="#" className="card-footer-item footer-buttons" onClick={() => { if (this.props.newby) this.props.setVideoPopState(true); this.setState({videoPopupOpen: true})}} >
          {isEmpty(this.state.video)?"Upload Video":"Replace Video"}
       </a>
       </>

  const newbyButtons = !this.props.newby?"":
       <>
       <a href="#" className="card-footer-item footer-buttons" onClick={() => this.clear()}>
                Clear Entry 
       </a>
       <a href="#" className="card-footer-item footer-buttons" onClick={() => this.post()}>
                <strong>Post Entry</strong>
       </a>
       </>

  return (
   <>
      <Card.Footer>
       {imageButton}
       {deleteButton}
       {settingsButton}
       {commentButtons}
       {newbyButtons}
      </Card.Footer>
   <Card className="box" style={{padding:"1% 2%"}}>
      <Columns>
         <Columns.Column style={{padding:"1em 2%",margin:0}}>
            <span className="is-italic is-pulled-right">
               {this.props.newby?userNew:userInstance}
            </span>
         </Columns.Column>
      </Columns>
      <Columns className="is-centered is-vcentered">
       {image}
       {video}
        <Columns.Column style={{padding:"1% 12px", margin:0}}>
           <ContentEditable
              html={this.state.title} // innerHTML of the editable div
              placeholder={titleDefault}
              disabled={this.props.isAuthor?false:true} // use true to disable edition
              className={this.props.isAuthor?"ctitle":"vtitle"}
              onChange={this.onChangeTitle} // handle innerHTML change
           />
         </Columns.Column>
      </Columns>
      <Content>
         <ContentEditable
            html={this.state.body} // innerHTML of the editable div
            placeholder={bodyDefault}
            disabled={this.props.isAuthor?false:true} // use true to disable edition
            className={this.props.isAuthor?"cbody":"vbody"}
            onChange={this.onChangeBody} // handle innerHTML change
         />
      </Content>
     </Card>
      <DragPopupSingle
	initialState={this.props.newby?this.props.imagePopState:this.state.popupOpen}
	setCloseData={data => this.onPopupEnd(data) }
	setClose={()=>{ if (this.props.newby) this.props.setImagePopState(false); else this.setState({popupOpen: false })}}
	prompt="Upload Image for Remembrance Wall"
	resize={{maxDimension: 400, dcopy: true}}
      />
      <DragPopupVideo
	initialState={this.props.newby?this.props.videoPopState:this.state.videoPopupOpen}
	setCloseData={data => this.onPopupEnd(data) }
	setClose={()=>{ if (this.props.newby) this.props.setVideoPopState(false); else this.setState({videoPopupOpen: false })}}
	prompt="Upload Video for Remembrance Wall"
    resize={{maxDimension: 400 }}
      />
  </>
)
}
}

const  mapStateToProps = state => { return { auth: state.auth, user: state.user } };

export default connect(mapStateToProps)(withRouter(WallNewEntryCard));

