import isEmpty from 'lodash/isEmpty'
import React, { useRef, useState } from 'react'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import Textarea from 'react-textarea-autosize'
import '../../break.css'
import { Button } from 'react-bulma-components';
import useLockBodyScroll from '../hooks/useLockBodyScroll'

const CaptionPopup = ({ initialState, setCloseData, setClose, pageInstance, oldValue="" } ) => {
  
  const textareaRef = useRef()
  const [captionValue, setCaptionValue] = useState(oldValue); 

  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        center
      >
        <div><h2><b>{isEmpty(oldValue) ? "Add Caption" : "Change Caption"}</b></h2></div>
        <Textarea
           onChange={e => setCaptionValue(e.target.value)}
           placeholder="Enter picture caption ..."
           value={captionValue}
           ref={textareaRef}
           minRows={2}
           maxRows={2}
           className="textarea"
           maxLength={200}
           style={{width: "min(400px, 75vw)"}}
        />
       <div className="break"></div>
       <Button style={{backgroundColor: '#B0B0B0', margin: "0 0"}} size="small" fullwidth={false} onClick={()=>setClose()}>Cancel</Button>
       <Button style={{margin: "0 0"}} size="small" className="is-pulled-right" fullwidth={false} onClick={()=>setCloseData({captionValue: captionValue, pageInstance})}>Save Caption</Button>
      </Modal>
    </>
  );
};

export default CaptionPopup
