import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import axios from 'axios'
import React, { useRef, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import AddDiaryEntryPopup from './SummaryPopup'
import { isPublicA, augmentApi, calcDiaryUrlBase, calcDiaryUrlPage, isPageRO } from '../functions/location_fcns'
import { updateDiaryIndexPage, curateDiaryPages } from '../../../actions/diaryPage'
import ConfirmPopup from '../../common/popup/ConfirmPopup'
import { FaLock } from "@react-icons/all-files/fa/FaLock";
import { FaComments } from "@react-icons/all-files/fa/FaComments";
import SettingsPopup from '../common/SettingsPopup'

const Styles = styled.div`
  padding-bottom: 1rem;

  table {
    border-spacing: 0;
    background-color: white;

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      }
    }
  }
`
 
function Table({ columns, data }) {

  const auth = useSelector(state => state.auth);
  const publicA = isPublicA(auth)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map(column => {
            if (column.show === false) return column.accessor || column.id;
            else return null;
        }),
    },
    },
    useSortBy
  )

  if (auth === false && !publicA) 
     return <div>You are not authorized to view this page</div>

  return (
    <>
      <table className="table is-hoverable is-striped is-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
    </>
  )
}

const formatDate = d => {  const a = d.split('-'); return `${a[1]}-${a[2]}-${a[0]}` }

  //**Here is the function to put up the TOC
function DiaryTOC({isNew=false}) {

  const [width, setWidth]   = useState(window.innerWidth);

  useEffect(() => {
    const updateDimensions = () => {
       setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

   //Fcns
  const isRO = isPageRO();
  const publicA = isPublicA()

    //Redux
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth); //Logged in?
  const user = useSelector(state => state.user); 
  const toc = useSelector(state => state.diaryIndexPage)
  const accountSettings = useSelector(state => state.accountSettings)

     //State
  const [pageLoadDone,  setPageLoadDone ] = useState(false)
  const [curatedLines, setCuratedLines ] = useState([])
  const [forceFlg, setForceFlg ] = useState(true)
  const [isDelete, setIsDelete ] = useState(false)
  const [deletedInstance, setDeletedInstance] = useState("")
  const [isSettings, setIsSettings ] = useState(false)
  const [settingsInstance, setSettingsInstance] = useState("")

    //Ref

   const history = useHistory();

      //Settings changed, update the curatedLines[i].private so the lock icon matches the data that is being stored
   const onSettingsEnd = () => 
   {  if (!isEmpty(accountSettings))
      {   const row = accountSettings.find(rec => rec.pageName === 'diary' && rec.pageInstance === settingsInstance)
          let tlines = curatedLines.slice()
          const index = tlines.findIndex(e => e.pageInstance === settingsInstance)
          if (!isEmpty(row) && row.viewPagePrivacy === 1 && index >= 0 && tlines[index].private === false)
          {  tlines[index].private = true; setCuratedLines(tlines); }
          else if (!isEmpty(row) && row.viewPagePrivacy > 1 && index >= 0 && tlines[index].private === true)
          {  tlines[index].private = false; setCuratedLines(tlines);  }
      }
      setIsSettings(false)  //Close popup
   }

      //**Delete a diary page
   const onDeleteEnd = async data =>
   {  if (data.answer === true)
      {    const newToc = filter(toc, function(rec) { return rec.pageInstance !== deletedInstance })
           dispatch(updateDiaryIndexPage(newToc));
           await axios.post('/api/delete_page', { pageType: 'diary', pageInstance: deletedInstance });
           setForceFlg(true)
      }
      setIsDelete(false); //**Close the popup
   }

  useEffect(() => {
    const loadAndSyncData = async () => {
       const myToc = isEmpty(toc) ? [] : toc
       if ((auth||publicA) && ((!isEmpty(user)&&!isEmpty(user.email))||publicA) && (pageLoadDone === false || forceFlg)) {
           const res = await curateDiaryPages(publicA?0:user.account_id, myToc, forceFlg) 
           const rToc = res.toc;
           let tlines = res.displayedRows
           if (isEmpty(myToc) && !isEmpty(rToc)) 
              dispatch(updateDiaryIndexPage(rToc, false))
           if (!isEmpty(deletedInstance))
           {  const tlines1 = filter(tlines, function(rec) { return rec.pageInstance !== deletedInstance })
              tlines = tlines1
              setDeletedInstance("")
           }
           setCuratedLines(tlines)
           setForceFlg(false)
           setPageLoadDone(true)
           }
       if (!isEmpty(curatedLines) && isPageRO() && !publicA)
       {   const lgth = curatedLines.length;
           const results = !isPageRO()||auth===false ? false
                           : await axios.get(augmentApi('/api/get_all_decorated_instances'), { params: { page_name: 'diary', unseenCommentPages: true, allCommentPages: false }})
           const changes = results ? results.data.unseenCommentInstances : []

           let tlines = curatedLines.slice()
           for (let i = 0; i < lgth; i++)
           {  if (changes.includes(tlines[i].pageInstance))
                  tlines[i].unseenComment = true;
               else if (tlines[i].numComments > 0)
                  tlines[i].comment = true;
           }
           setCuratedLines(tlines)
       } }
    loadAndSyncData();
          // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, user, forceFlg] )

  const columns = useMemo( () => 
      [
          {
            Header: 'Instance',
            accessor: 'instance_id',
            show: false,
          },
          {
            Header: 'displayValue',
            accessor: 'idate',
            show: false,
          },
          {
            Header: 'Last Updated',
            accessor: 'date',
            sortDescFirst:true,
            show:true,
            Cell: ({ row: { original } }) =>  {
                     const tline = curatedLines.find(e => e.pageInstance === original.instance_id)
                     const p = (tline.private === true) ? <FaLock style={{paddingRight: "5px"}}/> : ""
                     let c = (isPageRO() && tline.unseenComment === true) ? <FaComments className="fa-cog" /> : ""
                     if (isPageRO() && c === "" && tline.comment === true)
                         c = <FaComments />
                     const newby = tline.unseen ? <span className="has-text-danger"><small>New</small></span> : ""
                     return ( <>
                              <p>{original.idate}</p>
                              {p}
                              {c}
                              {newby}
                              </>
                  ) }
          },
          {
            Header: 'HTitle',
            accessor: 'htitle',
            disableSortBy: true,
            show:false,
          },
          {
            Header: 'Title',
            accessor: 'title',
            disableSortBy: true,
            show:true,
            Cell: ({ row: { original } }) =>  {
                     const url=calcDiaryUrlPage(original.instance_id)
                     return (<Link to={{ pathname: `${url}` }}>{original.htitle}</Link>)
                  }
          },
          {
            Header: 'Author',
            accessor: 'author',
            sortDescFirst:true,
            show: width>600?true:false,
          },
          {
            Header: 'Summary',
            accessor: 'summary',
            disableSortBy: true,
            show: width>500?true:false,
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            disableSortBy: true,
            show: isRO ? false : true,
            Cell: ({ row: { original } }) => { 
               const e_ref = useRef();
               const d_ref = useRef();
               const s_ref = useRef();
               const url=`${calcDiaryUrlBase()}/${original.instance_id}`
               const tline = curatedLines.find(e => e.pageInstance === original.instance_id)
               const bsize = (width>=768) ? "1.6em" : "2em"
               if (!isRO && tline.editable)
                  return (
                   <>
                   <button className="button is-small is-primary is-link is-inverted" ref={e_ref} style={{margin:"0 10px 3px 0", background:"transparent"}} 
                                      onClick={() => { e_ref.current.blur(); history.push(`${url}/summary`)}} 
                                      >
                     <span className="icon"> <i className="fa fa-edit" style={{fontSize: bsize, position: "absolute", top: "2px", left: "5px"}}></i> </span>
                   </button>
                   <button className="button is-small is-primary is-link is-inverted" ref={d_ref} style={{margin:"0 3px 3px 0", background:"transparent"}} 
                                      onClick={() => { d_ref.current.blur(); setDeletedInstance(original.instance_id); setIsDelete(true) }} 
                                      >
                     <span className="icon"> <i className="fa fa-trash" style={{fontSize: bsize, position: "absolute", top: "2px", left: "10px"}}></i> </span>
                   </button>
                   <button className="button is-small is-primary is-inverted is-link" ref={s_ref} style={{margin:"0 3px 3px 0", background:"transparent"}} 
                                      onClick={() => { s_ref.current.blur(); setSettingsInstance(original.instance_id); setIsSettings(true) }} >
                      <span className="icon"> <i className="fa fa-cogs" style={{fontSize: bsize, position: "absolute", top: "2px", left: "12px"}}></i> </span>
                   </button>
                   </>)
                 //**Allow sibling/owner to change privacy of unauthored entry that has visibiity and may be innapropriate
               else if (!isRO && !isEmpty(user) && !isEmpty(user.email) && (user.role === 'sibling' || user.role === 'owner'))
                  return (
                   <button className="button is-small is-primary is-inverted is-link" ref={s_ref} style={{margin:"0 10px 3px 0", background:"transparent"}} 
                                      onClick={() => { s_ref.current.blur(); setSettingsInstance(original.instance_id); setIsSettings(true) }} >
                      <span className="icon"> <i className="fa fa-cogs" style={{fontSize: bsize, position: "absolute", top: "2px", left: "8px"}}></i> </span>
                   </button> )
               else return ""
             },
          },
        ], [width, curatedLines, isRO, history, user ]
  )
//https://stackoverflow.com/questions/61312195/how-to-customize-a-column-in-react-table-7  //Default Column

   if (auth === false && !publicA) {
       return <div>Please Login to continue...</div>
   }
   if (pageLoadDone === false) {
      return <div>Loading...</div>
    }

      //{ pageInstance: p.pageInstance, numComments: p.numComments, author: `${p.firstName} ${p.lastName}`, title: tline.title, summary: tline.summary, editable: p.editble, updatedAt: p.updatedAt, private: p.private, unseen: p.newby }
    const data = curatedLines.map(e => {
             return { instance_id: e.pageInstance, date: e.updatedAt, idate: formatDate(e.updatedAt.split('T')[0]), htitle: e.title, author: e.author, summary: e.summary, private: e.private, unseen: e.unseen }
             })

   if (data.length === 0 && isNew === false) {
      return ""
    }

   return (
     <>
     <Styles>
       <Table columns={columns} data={data} />
     </Styles>
     <AddDiaryEntryPopup  initialState={isNew} />
     <ConfirmPopup
           initialState={isDelete}
           setCloseData={data => onDeleteEnd(data) }
           setClose={()=>setIsDelete(false) }
           prompt="Are you sure you want to delete this diary entry?"
           title="Really Delete?"
           affirmativeButtonText="Yes! Delete it"
           declineButtonText="Cancel"
       />
     <SettingsPopup 
           initialState={isSettings} 
           setClose={() => onSettingsEnd() } pageType='diary' pageInstance={settingsInstance}
      />
     </>
  )
}

export default DiaryTOC

