import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Columns, Form, Section, Heading } from "react-bulma-components";
import DragPopupSingle from '../../common/popup/DragPopup1'
import AdminButtons from './AdminButtons'
import DepartedPopup from './DepartedPopup'
import SibPopup from './SibPopup'
import UserPopup from './UserPopup'
import {updateAccount, updateAccountPict} from '../../../actions/account'
import { getBannerText } from '../functions/location_fcns'


const EditAccount = (props) => {  

//{departed_first_name":"Robin","departed_last_name":"Ralston","sibling_first_name":"Audree","sibling_dob":"9/20/1955","account_pict_url":"","departed_dob":"6/17/1953","departed_dod":"11/17/1967"}

   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch()
  const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText("Update Account", user)

     //**State
  const [ departedFirst,setDepartedFirst ] = useState("")
  const [ departedLast, setDepartedLast ] = useState("")
  const [ departedDob, setDepartedDob ] = useState("")
  const [ departedDod, setDepartedDod ] = useState("")
  const [ siblingFirst, setSiblingFirst ] = useState("")
  const [ siblingDob, setSiblingDob ] = useState("")
  const [ accountPictUrl, setAccountPictUrl ] = useState("")
  const [ loading, setLoading ] = useState(true);
  const [ imagePopupOpen, setImagePopupOpen ] = useState(false);
  const [ departedPopupOpen, setDepartedPopupOpen ] = useState(false);
  const [ siblingPopupOpen, setSiblingPopupOpen ] = useState(false);
  const [ userPopupOpen, setUserPopupOpen ] = useState(false);

  useEffect( () => {
   if (!isEmpty(user) && !isEmpty(user.email) && loading)
   {  setDepartedFirst(user.departed_first_name)
      setDepartedLast(user.departed_last_name)
      setDepartedDob(new Date(user.departed_dob).toISOString().split('T')[0])
      setDepartedDod(new Date(user.departed_dod).toISOString().split('T')[0])
      setSiblingFirst(user.sibling_first_name)
      setSiblingDob(new Date(user.sibling_dob).toISOString().split('T')[0])
      setAccountPictUrl(!isEmpty(user.account_pict_url) ? user.account_pict_url : "https://bulma.io/images/placeholders/128x128.png")
      setLoading(false)
   }
        // eslint-disable-next-line
   }, [user])

  const onPictPopupEnd = (data) =>
  {    setAccountPictUrl(data.url)
       dispatch(updateAccountPict({account_pict_url: data.url}) )
       //Popup down
      setImagePopupOpen(false);
  }
  const onDepartedPopupEnd = (data) =>
  {   const {departFirst, departLast, departDob, departDod} = data
      setDepartedFirst(departFirst)
      setDepartedLast(departLast)
      let dob=`${departDob.getMonth()+1}/${departDob.getDate()}/${departDob.getFullYear()}`
      let dod=`${departDod.getMonth()+1}/${departDod.getDate()}/${departDod.getFullYear()}`
      setDepartedDob(dob)
      setDepartedDod(dod)
      setLoading(true)
      dispatch(updateAccount({departed_first_name: departFirst, departed_last_name: departLast, departed_dob: dob, departed_dod: dod}) )
       //Popup down
      setDepartedPopupOpen(false);
  }
  const onSiblingPopupEnd = (data) =>
  {   const {sibFirst, sibDob} = data
      let dob=`${sibDob.getMonth()+1}/${sibDob.getDate()}/${sibDob.getFullYear()}`
      setSiblingFirst(sibFirst)
      setSiblingDob(dob)
      setLoading(true)
      dispatch(updateAccount({sibling_first_name: sibFirst, sibling_dob: dob})) 
       //Popup down
      setSiblingPopupOpen(false);
  }
  const onUserPopupEnd = async data =>
  {   const {user, role, firstName, lastName} = data
      await axios.post('/api/change_user', { user_id: user.value, role, firstName, lastName});
      setUserPopupOpen(false);
  }

  if (loading)
    return <div>Loading</div>

  if (!auth)
      return <div>Please Login to continue...</div>

    //Check permissions
  if (user.role !== 'sibling' && user.role !== 'admin' && user.role !== 'owner')
      return <div>You are not authorized to view this page</div>

  return (
    <>
   <Container>
    <Section>
      <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
      <AdminButtons existingPhoto={!isEmpty(user.account_pict_url)} 
                                   photoOpen={()=>setImagePopupOpen(true)} 
                                   departedOpen={()=>setDepartedPopupOpen(true)}
                                   sibOpen={()=>setSiblingPopupOpen(true)}
                                   userOpen={()=>setUserPopupOpen(true)}
      />
      <Card className="box" style={{padding:"1% 2%"}}>
          <Heading subtitle size={4} style={{paddingTop: "1%"}}>
          Account Photo&nbsp;&nbsp;
          </Heading>
          <figure className="image is-96x96">
            <img src={accountPictUrl} alt="Sibs Avatar" onClick={()=> setImagePopupOpen(true)} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()}/>
          </figure>
          <Heading subtitle size={4} style={{paddingTop: "1%"}}>
          Departed Sibling &nbsp;&nbsp;
          </Heading>
 
          <Columns>
          <Columns.Column className="is-one-quarter">
	  <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
               <Form.Input isStatic={true} type="text" value={departedFirst}  onChange={e => setDepartedFirst(e.target.value)}/>
            </Form.Control>
          </Form.Field>
          </Columns.Column>
          <Columns.Column className="is-one-quarter">
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control>
               <Form.Input isStatic={true} type="text" value={departedLast}  onChange={e => setDepartedLast(e.target.value)} />
            </Form.Control>
          </Form.Field>
          </Columns.Column>
          <Columns.Column className="is-one-quarter">
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control>
               <Form.Input isStatic={true} type="text" value={user.departed_dob}  onChange={e => setDepartedDob(e.target.value)} />
            </Form.Control>
          </Form.Field>
          </Columns.Column>
          <Columns.Column className="is-one-quarter">
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Death</Form.Label>
            <Form.Control>
               <Form.Input isStatic={true} type="text" value={user.departed_dod}  onChange={e => setDepartedDod(e.target.value)} />
            </Form.Control>
          </Form.Field>
          </Columns.Column>
          </Columns>

          <Heading subtitle size={4}>
          Sib Survivor&nbsp;&nbsp;
          </Heading>
          <Columns>
          <Columns.Column className="is-one-quarter">
	  <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
               <Form.Input isStatic={true} type="text" value={siblingFirst}  onChange={e => setSiblingFirst(e.target.value)} />
            </Form.Control>
          </Form.Field>
          </Columns.Column>

          <Columns.Column className="is-one-quarter">
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control>
               <Form.Input isStatic={true} type="text" value={user.sibling_dob} onChange={e => setSiblingDob(e.target.value)} />
            </Form.Control>
          </Form.Field>
          </Columns.Column>
          </Columns>
      </Card>
    </Section>
    <DragPopupSingle
        initialState={imagePopupOpen}
        setCloseData={data => onPictPopupEnd(data) }
        setClose={()=>setImagePopupOpen(false)}
        prompt="Upload Account Picture"
        resize={{exactHeight: 128, exactWidth: 128, dcopy: true}}
    />
    <DepartedPopup 
        initialState={departedPopupOpen}
        setClose={()=>setDepartedPopupOpen(false)}
        setCloseData={data=>onDepartedPopupEnd(data)}
        departedFirst={departedFirst}  
        departedLast={departedLast} 
        departedDob={departedDob}
        departedDod={departedDod}
    />
    <SibPopup 
        initialState={siblingPopupOpen}
        setClose={()=>setSiblingPopupOpen(false)}
        setCloseData={data=>onSiblingPopupEnd(data)}
        sibFirst={siblingFirst}  
        sibDob={siblingDob}
    />
    <UserPopup 
        initialState={userPopupOpen}
        setClose={()=>setUserPopupOpen(false)}
        setCloseData={data=>onUserPopupEnd(data)}
        account_id={user.account_id}
    />
   </Container>
   </>

  );
}

export default EditAccount;
