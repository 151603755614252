/** Page Help **/

import React, { useRef, useState } from 'react'
import HelpLink from './HelpLink'
import PageHelp from './PageHelp'

const helpHtml='<h2 style="text-align: center;">The SibsForever Community</h2><p>The SibsForever community is made up of<strong> SibSites, with shared content only</strong>.&nbsp; Authenticated users can <strong>browse SibSIte content, &nbsp; and comment on pages, posts and&nbsp;pictures.</strong>&nbsp; A <strong>SibsForever facebook group</strong> has been started (<a href="https://www.facebook.com/groups/sibsforever">facebook.com/groups/sibsforever</a>) for the community to discuss, collaborate and brainstorm about <a href="https://sibsforever.org">sibsforever.org.</a></p><p><img src="https://sibsforever.org/img/sharing/community-sidebar.png" alt="Connections" width="204" oncontextmenu="return false" ondragstart="return false" style="margin: 12px; vertical-align: top; float: left; pointer-events: auto; user-select: auto;" height="365"><div style="display: inline-block; height: 0; width: 6em;"></div></p><p><br></p><p>The<strong> Community options</strong> (shown left) are:</p><ul><li><strong>Browse SibSites,&nbsp;</strong> from&nbsp;newest to oldest.&nbsp; Optionally choose to <strong>Follow</strong> a SibSite</li><li><strong>Search&nbsp;</strong>&nbsp;for SibSites that <strong>match the criteria you specified</strong></li><li>Access the <strong>Last Search</strong>&nbsp;criteria to re-execute or modify</li></ul><p><br></p><ul><li><strong>Following</strong>: See a listing of <strong>SibSites you are following</strong></li><li><strong>Followers</strong>:&nbsp; See a listing of <strong>SibSIte users who are following your SibSite</strong></li></ul>'

const helpHeading = "Community Help"


export const CommunityHelpLink = ({linkText})=> {

    return (
       <HelpLink linkText={linkText} header={helpHeading} body={helpHtml}></HelpLink>
    )
}

export const CommunityPageHelp = () => {

   return (
      <PageHelp header={helpHeading} body={helpHtml}/>
   )
}
