import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react';
import { Card } from 'react-bulma-components';
import CaptionPopup from './CaptionPopup'

const AddCaptionButton = ({pageInstance, caption="", onCaptionEnd}) => {  

   const [open, setOpen] = useState(false); //**Start out popup not-open

   const onEnd = (data) =>
   {  onCaptionEnd(data)  
      setOpen(false); //**Close the popup
   }

   return (
    <>
     <a href="#" className="card-footer-item footer-buttons" onClick={() => setOpen(true)}>{isEmpty(caption) ? "Add Caption" : "Change Caption"}</a> 
     <CaptionPopup
         initialState={open}
         setCloseData={data => onEnd(data) }
         setClose={()=>setOpen(false) }
         pageInstance={pageInstance}
         oldValue={caption}
     />
    </>
    )
  }

export default AddCaptionButton

