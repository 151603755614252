//**This is the SibsForever sidebar for Wall

import isEmpty from 'lodash/isEmpty'
import { getHeaderText } from '../functions/location_fcns'
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react'
import usePagePerms from '../hooks/usePagePerms'
import { NavLink } from 'react-router-dom';
import { Menu } from 'react-bulma-components';
import PreviewPageHelp from '../common/help/PreviewPageHelp'

const helpHeading = "Remembrance Wall Preview"

const WallPreviewSidebar = () => {  

const styles = {
    hrStyle: { borderTop: "2px solid #bbb" },
    spanStyle: { marginRight: "2px", marginTop:"5px", marginBottom:"5px" }
}

   const [ , canEdit, canView, isResolved ] = usePagePerms('wall', 'singleton')

   const [ editLinks, setEditLinks ] = useState("")
   const [ viewLinks, setViewLinks ] = useState("")

   const [ isInit, setIsInit ] = useState(false)
   const user = useSelector(state => state.user);

   useEffect(() => {
      if (isResolved && !isEmpty(user) || !isEmpty(user.email))
      {
          if (canEdit) setEditLinks(
             <>
             <hr className="is-hidden-mobile" style={styles.hrStyle} />
             <div><NavLink className="xmenu-block" activeClassName="is-active" to="/auth/wall/edit">
	         <span className="nospace"><span className="menu-icon">
	         <i className="fa fa-arrow-left"  style={styles.spanStyle}></i>
	         </span>
	         Edit</span>
	         </NavLink></div>
             </>
             )
         if (canView) setViewLinks(
             <>
             <div><NavLink className="xmenu-block" activeClassName="is-active" to="/auth/wall/view">
	         <span className="nospace"><span className="menu-icon">
	         <i className="fa fa-eye"  style={styles.spanStyle}></i>
	         </span>
	         View as Self </span>
	         </NavLink></div>
             <div><NavLink className="xmenu-block" activeClassName="is-active" to={`/eshare/${user.account_id}/wall`}>
                <span className="nospace"><span className="menu-icon">
                 <i className="fa fa-at"  style={styles.spanStyle}></i>
                </span>
                View as Follower</span>
             </NavLink></div>
             </>
             )
         setIsInit(true);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isResolved, canEdit, canView ])

    const help = <PreviewPageHelp header={helpHeading}/>

    if (!isInit)
     return <div>Loading...</div>

    else return (
  
  <Menu>
     <p className="menu-label is-hidden-mobile">{getHeaderText()}{help}</p>
        {viewLinks}
        {editLinks}
  </Menu>

    )
  }

export default WallPreviewSidebar

