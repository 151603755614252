import axios from 'axios'
import React, { useState, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Container, Columns, Form, Section, Button, Box, Heading } from "react-bulma-components";
import ReCAPTCHA from 'react-google-recaptcha'
import Loader from "react-spinners/BeatLoader";
import { uniqueEmail, checkName } from '../common/formFunctions'
import AccountPopups from '../common/AccountPopups'
import ReactDatePicker from 'react-date-picker'

const DatePicker = ( date, onChange ) => {
    return (
        <ReactDatePicker
            className="input"
            showLeadingZeros={true}
            isOpen={false}
            openCalendarOnFocus={false}
            clearIcon={null}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            disableCalendar={true}
            onChange={onChange}
            maxDate={new Date()}
            minDate={new Date(1925, 0, 1)}
        />
    )
};

const AccountForm = (props) => {  

     //**State
  const [ captcha, setCaptcha ] = useState("");
  const [ loading, setLoading] = useState(false);
  const [ tosPopup, setTosPopup] = useState(false);
  const [ privacyPopup, setPrivacyPopup] = useState(false);
  const birthDate = useRef(null)
  const deathDate = useRef(null)

  const checkBDate = d  => {
      birthDate.current = d
      if (deathDate.current !== null && d.getTime() >= deathDate.current.getTime())  return false
      return true
  }
  const checkDDate = d  => {
      deathDate.current = d
      if (birthDate.current !== null && d.getTime() <= birthDate.current.getTime())  return false
      return true
  }

      //**Ref
  const recaptcha = useRef();

  const history = useHistory();
  const { handleSubmit, errors, control } = useForm();
  const onSubmit = async data => {
    setLoading(true);  //Put on spinner
/*
DSBirthDate: "1953-06-17"
DSDeathDate: "1967-11-17"
DSFirstName: "Robin"
LSFirstName: "Audree"
LSBirthDate: "9-4-1955"
DSLastName: "Ralston"
Email: "audree1@gmail.com"
FirstName: "Audree"
LastName: "Kropen"
Role: "Sibling"
*/
      //Zero-pad
    const month = d => (d.getMonth()+1).toString().padStart(2, '0')
    const day = d => d.getDate().toString().padStart(2, '0')

    const { DSBirthDate, DSDeathDate, DSFirstName, DSLastName, LSFirstName, LSBirthDate, Email, FirstName, LastName, Role } = data;

     /* 
          const siblingDob = LSBirthDate.getFullYear() + '-' + month(LSBirthDate) + '-' + day(LSBirthDate)
          const departedDob = DSBirthDate.getFullYear() + '-' + month(DSBirthDate) + '-' + day(DSBirthDate)
          const departedDod =  DSDeathDate.getFullYear() + '-' + month(DSDeathDate) + '-' + day(DSDeathDate)
          const email = Email.toLowerCase();
          console.log(departedDob, departedDod, DSFirstName, DSLastName, LSFirstName, siblingDob, email, FirstName, LastName, Role) 
     */

    let pageName = '/account/failed';
    const { status } = await axios.post('/api/new-account', {
          departedDob: DSBirthDate.getFullYear() + '-' + month(DSBirthDate) + '-' + day(DSBirthDate),
          departedDod: DSDeathDate.getFullYear() + '-' + month(DSDeathDate) + '-' + day(DSDeathDate),
          departedFirstName: DSFirstName, 
          departedLastName: DSLastName, 
          siblingFirstName: LSFirstName, 
          siblingDob: LSBirthDate.getFullYear() + '-' + month(LSBirthDate) + '-' + day(LSBirthDate),
          firstName: FirstName,
          lastName: LastName,
          email: Email.toLowerCase(), 
          role: Role,
          captcha
        },
        { validateStatus: (status) => {
           return true;
        }})

        if (status === 200) {
           console.log("Account creation successful");
           pageName = '/account/success';
        }
       setLoading(false);  //disable spinner
       history.push(pageName);
  };

  return (
     <>
    <Container>
    <Section>
      <Box>
        <Heading>Account Creation Form</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading subtitle size={4}>
          Departed Sibling
          </Heading>
 
	  <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="DSFirstName"
                    placeholder="Departed Sibling's First Name"
                    defaultValue=""
                    control={control}
                    maxLength={24}
                    rules={{ required: true, 
                             validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.DSFirstName && errors.DSFirstName.type === "required" && "Sibling's first name is required"}
                  {errors.DSFirstName && errors.DSFirstName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="DSLastName"
                    control={control}
                    defaultValue=""
                    placeholder="Departed Sibling's Last Name"
                    maxLength={24}
                    rules={{ required: true, 
                             validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.DSLastName && errors.DSLastName.type === "required" && "Sibling's last name is required"}
                  {errors.DSLastName && errors.DSLastName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>
          <Columns>
          <Columns.Column narrow>
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control>
                  <Controller
                    as={DatePicker(null, date => date)}
                    onChange={(date) => date}
                    type="date"
                    style={{width:'12em'}}
                    name="DSBirthDate"
                    control={control}
                    defaultValue=""
                    placeholder="Departed Sibling's Birth Date"
                    rules={{ required: true, 
                             validate: dd => { const rc = checkBDate(dd); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.DSBirthDate && errors.DSBirthDate.type === "required" && "Sibling's birth date is required"}
                  {errors.DSBirthDate && errors.DSBirthDate.type === "validate" && "Birth date must come before death date" }
            </Form.Help>
          </Form.Field>
          </Columns.Column>

          <Columns.Column>
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Death</Form.Label>
            <Form.Control>
                  <Controller
                    as={DatePicker(null, date => date)}
                    onChange={(date) => date}
                    type="date"
                    style={{width:'12em'}}
                    name="DSDeathDate"
                    control={control}
                    defaultValue=""
                    placeholder="Departed Sibling's Death Date"
                    rules={{ required: true, 
                             validate: dd => { const rc = checkDDate(dd); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.DSDeathDate && errors.DSDeathDate.type === "required" && "Sibling's death date is required"}
                  {errors.DSDeathDate && errors.DSDeathDate.type === "validate" && "Death date must come after birth date" }
            </Form.Help>
          </Form.Field>
          </Columns.Column>
          </Columns>

          <Heading subtitle size={4}>
          Sib Survivor
          </Heading>
          <Columns>
          <Columns.Column narrow>
	  <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="LSFirstName"
                    style={{width:'20em'}}
                    placeholder="Live Sibling's First Name"
                    defaultValue=""
                    control={control}
                    maxLength={24}
                    rules={{ required: true, 
                             validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.LSFirstName && errors.LSFirstName.type === "required" && "Sibling's first name is required"}
                  {errors.LSFirstName && errors.LSFirstName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>
          </Columns.Column>

          <Columns.Column>
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control>
                  <Controller
                    as={DatePicker(null, date => date)}
                    onChange={(date) => date}
                    type="date"
                    style={{width:'12em'}}
                    name="LSBirthDate"
                    control={control}
                    defaultValue=""
                    placeholder="Live Sibling's Birth Date"
                    rules={{ required: true , }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.LSBirthDate && errors.LSBirthDate.type === "required" && "Sibling's birth date is required"}
            </Form.Help>
          </Form.Field>
          </Columns.Column>
          </Columns>

          <hr style={{marginTop:"0"}}/>
          <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>
          <Heading subtitle size={4}>
          Account Creator (that's you)
          </Heading>

          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="FirstName"
                    placeholder="Your First Name"
                    defaultValue=""
                    control={control}
                    maxLength={24}
                    rules={{ required: true, 
                             validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
             </Form.Control>
             <Form.Help textColor="danger">
                  {errors.FirstName && errors.FirstName.type === "required" && "Your first name is required"}
                  {errors.FirstName && errors.FirstName.type === "validate" && "Name must contain between 2-31 valid letters." }
             </Form.Help>
          </Form.Field>
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="LastName"
                    control={control}
                    defaultValue=""
                    placeholder="Your Last Name"
                    maxLength={24}
                    rules={{ required: true, 
                             validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.LastName && errors.LastName.type === "required" && "Your last name is required"}
                  {errors.LastName && errors.LastName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>

          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Email Address</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="Email"
                    control={control}
                    defaultValue=""
                    placeholder="Your Email"
                    type="email"
                    rules={{ required: true,
                             validate: async (value) => { const rc = await uniqueEmail(value); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                 {errors.Email && errors.Email.type === "required" && "Email address is required" }
                 {errors.Email && errors.Email.type === "validate" && "Email address is already being used" }
            </Form.Help>
          </Form.Field>
          <Form.Field style={{marginLeft:"10px"}}>
           <Form.Label>You are:</Form.Label>
           <Form.Control>
                <Controller
                  as={
                   <Form.Select>
                     <option value="">Select dropdown</option>
                     <option value="sibling">Sibling</option>
                     <option value="owner">Other Family</option>
                     <option value="owner">Friend</option>
                   </Form.Select>
                   }
                  style={{marginBottom:"8px"}}
                  control={control}
                  name="Role"
                  defaultValue=""
                  rules={{ required: true }}
                />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.Role && "Your relationship is required"}
            </Form.Help>
          </Form.Field>

          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Control>
                  <Controller
                    as={<label className="checkbox">
                          <input type="checkbox" className="checkbox"/>&nbsp;&nbsp;I agree to the <a href="#" onClick={()=>setTosPopup(true)}>Terms of Use</a> and the <a href="#" onClick={()=>setPrivacyPopup(true)}>Privacy policy</a>
                        </label>}
                    name="agreement"
                    control={control}
                    defaultValue={false}
                    rules={{ required: true }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                 {errors.agreement && errors.agreement.type === "required" && "Please confirm you have read and agree to the SibsForever Terms of Use and Privacy policy" }
            </Form.Help>
          </Form.Field>
          <Form.Field>
          <ReCAPTCHA
            ref={() => recaptcha }
            sitekey="6LcR3V0aAAAAAM6uO6Wa8X4P4XV56cmdTZ81PvBj"
            onChange={value => setCaptcha(value)}
          />
          </Form.Field>

          <Form.Field>
            <Form.Control>
              <Button  disabled={!captcha} fullwidth={false}>Create Sibsforever Account</Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </Section>
    </Container>
    <AccountPopups initialState={tosPopup} setClose={()=>setTosPopup(false)} tos={true} privacy={false} />
    <AccountPopups initialState={privacyPopup} setClose={()=>setPrivacyPopup(false)} tos={false} privacy={true} />
    </>
  );
}

export default AccountForm;
