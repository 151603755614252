import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import WallPreviewSidebar from './WallPreviewSidebar'
import AllPagesSidebar from '../AllPagesSidebar'
import WallViewEntryCard from './WallViewEntryCard'
import WallNewEntryCard from './WallNewEntryCard'
import { Heading, Container, Section} from 'react-bulma-components';
import useGetHeader from '../hooks/useGetHeader'
import '../sidebar.css'
import { augmentApi, isPublicA, isSharePage, isAllPages } from '../functions/location_fcns'
import InfiniteScroll from "react-infinite-scroll-component";
import { seeComments } from '../../../actions/comment'
import { useLocation } from "react-router-dom";

const BatchSize = 8
const HeadingPiece = "Remembrance Wall"

const WallView = ({publicV=false, publicA=isPublicA()}) => {

   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

      //**Playing whether ot have a combined edit/view or have separate tabs for consistency
   const showEditable = false;

      //Redux
   const auth = useSelector(state => state.auth); //Logged in?
   const see_cc = useSelector(state => state.seeCommentCount);
   const dispatch = useDispatch()
   const location = useLocation();

      //State - minimize state to reduce redrawing and losing the scroll position
   const [ , setRefs ] = useState([]);
   const [ pageLoadDone,  setPageLoadDone ] = useState(false);

   const heading = useGetHeader(HeadingPiece)

   const pageRefs = useRef([]) //There was a timing delay with state that caused the InfiniteScroll component to get confused
   const pageLoadDone2 = useRef(false)
   const minId = useRef(0)
   const hasMore = useRef(true)

   const getMoreData = () => { pageLoadDone2.current = false; loadData() }

   const loadData = async () => {

      if ((auth||publicV||publicA) && (pageLoadDone === false || pageLoadDone2.current === false)) {
         let res = await axios.get(augmentApi('/api/get_viewable_pageRefs'), { params:  { pageType: 'wall', publicV, maxId: minId.current, maxRecords: BatchSize }})
         let content = res.data.pageRefs;
         if (content.length < BatchSize)
            hasMore.current = false
         else
            minId.current = content[content.length-1].id
         if (pageLoadDone2.current === false)
             pageRefs.current = pageRefs.current.concat(content)
         else
             pageRefs.current = content;
         setRefs(pageRefs.current);
         setPageLoadDone(true);
         pageLoadDone2.current = true
         dispatch(seeComments(see_cc)) //Cause sidebar to update decorators
         }
   }

   useEffect( () => {
        //Load data for infinite scrolling
      loadData();
      window.scrollTo(0,0);
        // eslint-disable-next-line
   }, [auth, location])

   const wallside = (isAllPages() || isSharePage())
       ?  <div className="aside"><AllPagesSidebar isActive="Wall" className="menu"/></div>
       :  <div className="aside"><WallPreviewSidebar isActive="View Wall" className="menu"/></div>

   const WallBreadcrumb = (isAllPages() || isSharePage())
   ? ""
   : <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
     <ul>
        <li><a href="/auth/wall/edit">&nbsp;Wall Edit</a></li>
        <li className="is-active"><a href="/auth/wall/view" aria-current="page">Preview</a></li>
      </ul>
    </nav>

   if (auth === false && !publicV && !publicA) 
      return <div>Please Login to continue...</div>

   if (!pageLoadDone)
      return <div>Loading ....</div>
    
   else return (

     <>
     {auth?WallBreadcrumb:""}
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     {publicV?"":wallside}

     <Container className="column is-9" style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
       <InfiniteScroll
          dataLength={pageRefs.current.length}
          next={getMoreData}
          hasMore={hasMore.current}
          loader={<div>Loading....</div>}
        >
        {pageRefs.current.map(item => 
            showEditable && item.isAuthor ? <WallNewEntryCard key={item.pageInstance} numComments={item.numComments} instance={item.pageInstance} firstName={item.firstName} lastName={item.lastName} showComments={true} url={item.url} num={item.num} newby={false} />
                                          : <WallViewEntryCard key={item.pageInstance} instance={item.pageInstance} numComments={item.numComments} firstName={item.firstName} lastName={item.lastName} isAuthor={item.isAuthor} url={item.url} num={item.num} publicV={publicV} isPrivate={item.isPrivate}/>)}
      </InfiniteScroll>
      </div>
     </Container>
     </Section>
     </>
   )
  }

export default WallView;

