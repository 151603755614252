//**This is the Diary TOC page

import { Heading } from 'react-bulma-components';
import DiarySidebar from './DiarySidebar'
import DiaryTOC from './DiaryTOC'
import { Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import useGetHeader from '../hooks/useGetHeader'
import { isSharePage, isAllPages } from '../functions/location_fcns'
import { DIARY_BREADCRUMB_PAGE, updateSessionStorage } from '../functions/session_fcns'

const HeadingPiece = "Journal"

const DiaryTOCPage = (props) => {

   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   updateSessionStorage(DIARY_BREADCRUMB_PAGE, "DiaryTOCPage")

   const heading = useGetHeader(HeadingPiece)
   

   return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
      <div className="aside"><DiarySidebar/></div>
     <Container className="column is-9" style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <DiaryTOC/>
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default DiaryTOCPage;

