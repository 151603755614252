import isEmpty from 'lodash/isEmpty'
import React, { useRef,useEffect,useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import { Button } from 'react-bulma-components';
import 'react-responsive-modal/styles.css';
//import '../../modal_styles.css'
import Loader from "react-spinners/BeatLoader"
import OneComment from './OneComment.js'
import { getAccountOverride } from '../../loggedIn/functions/location_fcns'
import { isPublicA, augmentApi } from '../../loggedIn/functions/location_fcns'
import { seeComments } from '../../../actions/comment' 
import useLockBodyScroll from '../../loggedIn/hooks/useLockBodyScroll'
import InfiniteScroll from "react-infinite-scroll-component";

const BatchSize = 12

const SeeCommentsPopup = ({ initialState, setClose, pageType, pageInstance } ) => {

   const accountOverride = getAccountOverride()
   const publicA = isPublicA()
   const dispatch = useDispatch()

      //**Changes to push when the popup is over
   let changeArray = useRef([]);
   let replyArray = useRef([]);
   let deleteArray = useRef([]);
   let dataArray = useRef([]);

   const commit = async ()  =>  {
     let lgth = changeArray.current.length;
     for (let i = 0; i < lgth; i++)
        if (changeArray.current[i] != null && !isEmpty(changeArray.current[i].trim()))
        {  //console.log(`Changes found at ${i} = ${changeArray.current[i]}`)
             
           const payload = {
              id: dataArray.current[i].id,
              page_name: pageType,
              page_instance: pageInstance,
              date_time: Date.now(),
              comment: changeArray.current[i].trim()
           }

           changeArray.current[i] = null;
           await axios.post('/api/update_comment', { details: payload, accountOverride } );
       }

     lgth = replyArray.current.length;
     for (let i = 0; i < lgth; i++)
        if (replyArray.current[i] != null && !isEmpty(replyArray.current[i].trim()))
        {   //console.log(`Reply Changes found at ${i} = ${replyArray.current[i]}`)
            
           const payload = {
              parent_id: dataArray.current[i].parentId > 0? dataArray.current[i].parentId : dataArray.current[i].id,
              page_name: pageType,
              page_instance: pageInstance,
              date_time: Date.now(),
              comment: replyArray.current[i].trim()
           }

          replyArray.current[i] = null;
          await axios.post('/api/new_comment', { details: payload, accountOverride} );
       }

     lgth = deleteArray.current.length;
     for (let i = 0; i < lgth; i++)
        if (!isEmpty(deleteArray.current[i]) && deleteArray.current[i] === "GONE")
        {  //console.log("DELETE", i, dataArray.current);	
           const payload = {
              id: dataArray.current[i].id,
              page_name: pageType,
              page_instance: pageInstance
           }

           deleteArray.current[i] = null;
           await axios.post('/api/delete_comment', { details: payload, accountOverride } );
        }
   }

   //State
   const [ , setRows ] = useState([]);
   const [ pageLoadDone,  setPageLoadDone ] = useState(false);
      //**Loader - could be slow to commit - give a visual indicator
   const [loading, setLoading] = useState(false);

   const rows = useRef([]);
   const pageLoadDone2 = useRef(false)
   const minId = useRef(0)
   const hasMore = useRef(true)

      //Redux
   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user);
   const see_cc = useSelector(state => state.seeCommentCount);

   const getMoreData = () => { pageLoadDone2.current = false; loadData(); }

   const loadData = async () => {
      if (auth && initialState && (pageLoadDone === false || pageLoadDone2.current === false)) {
         const res = await axios.get(augmentApi('/api/get_comments'), { params:  { page_name: pageType, page_instance: pageInstance, maxRecords: BatchSize, maxId: minId.current}})
         const content = res.data;
         const c = JSON.parse(content.data);
         if (c.length < BatchSize)
            hasMore.current = false
         else
            minId.current = c[c.length-1].parentId > 0 ? c[c.length-1].parentId : c[c.length-1].id

         if (pageLoadDone2.current === false)
            dataArray.current = dataArray.current.concat(c);
         else
            dataArray.current = c;
         const lgth = c.length;
         const r = []
            //Allow sibs/owners to have access to comments in case there is innappropriate content
         for (let i = 0; i < lgth; i++)
         {   r.push(<OneComment key={c[i].key} index={i} id={c[i].id} parentId={c[i].parentId} commentText={c[i].comment} isAuthor={c[i].authorId === user.user_id||user.role === 'sibling'||user.role === 'owner'} 
                               onDelete={index => deleteArray.current[i]="GONE"}
                               onChange={(index, data)=>changeArray.current[index] = data } 
                               onReplyChange={(index, data)=>replyArray.current[index] = data }
                               onClose={closePopup}
                               author={`${c[i].firstName} ${c[i].lastName}`} dateTime={new Date(c[i].dateTime).toLocaleString()} pageType={pageType} pageInstance={pageInstance}/>);
         }
         rows.current = rows.current.concat(r)
         setRows(rows.current)  //trigger refresh
         pageLoadDone2.current = true
         setPageLoadDone(true);
      }
   }

   useEffect( () => {
      loadData();
      dispatch(seeComments(see_cc))
    // eslint-disable-next-line
   }, [auth, initialState])

   useLockBodyScroll(initialState);

   const closePopup = async () =>
   {    setLoading(true);  //Put on spinner
        await commit(); 
        setLoading(false);  //disable spinner
        setPageLoadDone(false) 
        pageLoadDone2.current = false
        dataArray.current = rows.current = []
        minId.current = 0
        hasMore.current = true
        setClose(); 
   }
   const topClose = rows.current.length>2 ? 
                    <Button className="is-pulled-right" size="small" onClick={()=>closePopup()}>Done</Button>
                    : ""

   if (auth === false && !publicA) {
      return <div>Please Login to continue...</div>
   }

   if (initialState && !pageLoadDone && !publicA)
      return ""

   const noComments = publicA && !auth ? true : dataArray.current.length === 0;
   const footer = noComments ? <center><Button size="small" onClick={()=>closePopup()}>Done</Button></center> :
                 <footer className="modal-card-foot" style={{justifyContent: "center", padding:"6px 0 0 0", backgroundColor: "white"}}>
                     <Button size="small" onClick={()=>closePopup()}>Done</Button>
                 </footer>
   return (
    <>
      <Modal
        open={initialState}
        onClose={closePopup}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        blockScroll={false}
        containerId="modal-container"
        center
      >
        <header className="modal-card-head is-primary" style={{padding:"10px", margin:0}}>
          <p className="modal-card-title" style={{color:"white"}}>{!noComments?"Comments":"No Comments"}</p>
          { topClose }
        </header>
        <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>
        <section>
           <InfiniteScroll
               dataLength={dataArray.current.length}
               next={getMoreData}
               hasMore={hasMore.current}
               loader={publicA? "" : <div>Loading....</div>}
               scrollableTarget="modal-container"
               style={{overflow: "inherit"}}
          >
              {rows.current}
        </InfiniteScroll>
        </section>
        {footer}
      </Modal>
    </>
  )
}

export default SeeCommentsPopup
