import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import React, { Component, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router'
import { useSelector, connect } from 'react-redux';
import axios from 'axios'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { setCarouselActiveIndex, getCarouselPage, getVideoCarouselPage, updateVideoCarouselPage, updateCarouselPage, setButtonLabel } from '../../../actions/carouselPage'
import { Card } from 'react-bulma-components';
import DeleteButton from '../common/DeleteButton'
import SettingsButton from '../common/SettingsButton'
import WriteCommentButton from '../../common/comments/WriteCommentButton'
import SeeCommentsButton from '../../common/comments/SeeCommentsButton'
import AddCaptionButton from './AddCaptionButton'
import SlideshowButton from './SlideshowButton'
import { isVideoCarouselPage, isSharePage, isEsharePage, augmentApi } from '../functions/location_fcns'
import { CAROUSEL_NEWBY_ACCOUNT, CAROUSEL_INDEX, VCAROUSEL_INDEX, updateSessionStorage } from '../functions/session_fcns'

import './slideshow.css'

 //**props.videoGallery = true/false
class CarouselEditPanel extends Component {
   constructor(props)
   {   super(props);
       this.state = {
         carouselContent: props.carouselContent,
         videoCarouselContent: props.videoCarouselContent,
         auth: props.auth,
         user: props.user,
         items: [],
         key: 0,
         slideShow: props.autoplay || props.publicV || isSharePage() || isEsharePage() ? true : false,
         currentIndex: this.props.activeIndex,
       }

          //*non-state variables
       this.isVideo =  isVideoCarouselPage()
       this.pageName = this.isVideo ? 'vcarousel' : 'carousel'
       this.pageLoadDone = false;
       this.commentButtons = props.view ? true : false 
       this.timerId = 0

       this.editButtons = props.edit ? true : false
       this.showSlideshowButton = props.edit ? false : true
       this.activeIndex=(this.props.activeIndex !== null && typeof this.props.activeIndex !== 'undefined')?this.props.activeIndex:0
       this.lightbox=(this.props.lightbox !== null && typeof this.props.lightbox !== 'undefined')?this.props.lightbox:false
       this.buttonLabel=(this.props.autoplay || this.props.publicV || isSharePage() || isEsharePage()) ? "Pause Slideshow" : "Start Slideshow"
 
         // This binding is necessary to make `this` work in the callback
       this.loadAndSyncData = this.loadAndSyncData.bind(this);
       this.onLoad = this.onLoad.bind(this);
       this.delete = this.delete.bind(this);
       this.toggleSlideshowFunc = this.toggleSlideshowFunc.bind(this);
       this.commentToggleFunc = this.commentToggleFunc.bind(this);
       this.onCaptionChange = this.onCaptionChange.bind(this);
   }

   slideNext = () => { this.activeIndex++; if (this.activeIndex >= this.state.items.length) this.activeIndex = 0; this.setState({ currentIndex: this.activeIndex}); this.props.dispatch(setCarouselActiveIndex(this.activeIndex)); }
   slidePrev = () => { this.activeIndex--; if (this.activeIndex < 0) this.activeIndex = this.state.items.length-1; this.setState({ currentIndex: this.activeIndex}); this.props.dispatch(setCarouselActiveIndex(this.activeIndex)) }

   toggleSlideshowFunc = () => {   this.buttonLabel = !this.state.slideShow ? "Pause Slideshow" : "Resume Slideshow"
                                   this.setState({slideShow:  !this.state.slideShow}) 
                                   this.props.dispatch(setButtonLabel(this.buttonLabel))
                               }
   commentToggleFunc = () => { if (this.state.slideShow) this.toggleSlideshowFunc() }

   loadAndSyncData = async () =>
   {  const photos = []
      if ((this.state.auth || this.props.publicV) && isEmpty(this.state.carouselContent) && this.pageLoadDone === false) {
          const content = this.isVideo ? await getVideoCarouselPage() : await getCarouselPage()
          this.props.dispatch(this.isVideo ? updateVideoCarouselPage(content, false) : updateCarouselPage(content, false)); 
          this.setState({carouselContent: content});
          this.pageLoadDone = true;
      }
      else if ((this.state.auth||this.props.publicV) && this.pageLoadDone === false)
      {   this.pageLoadDone = true; }

             //**Set up the photo array for Carousel
      if (!isEmpty(this.state.carouselContent) && isEmpty(this.state.items))
      {   const lgth = this.state.carouselContent.length;
          
          for (let i = 0; i < lgth; i++)
          {   const c = this.state.carouselContent[i];
              if ((isSharePage() || isEsharePage()) && this.props.lockedArray.includes(c.pageInstance))  //**Locked
                 continue
                 //Calculate wait time for lazyLoader - could cycle slideshow in either direction
              const waitTime = 250 
              const delta =  Math.abs(this.activeIndex-i)
              let delay=(delta<lgth/2)?delta*waitTime:(lgth-delta)*waitTime
              const elt = 
                 <LazyLoader showCommentButtons={this.commentButtons} 
                             showEditButtons={this.editButtons} 
                             showSlideshowButton={this.showSlideshowButton} 
                             slideState={this.state.slideShow}
                             slideshowFunc={this.toggleSlideshowFunc}
                             commentFunc={this.commentToggleFunc}
                             buttonLabel={this.buttonLabel}
                             pictureObject={c} 
                             onDelete={this.delete} 
                             onLoad={this.onLoad} 
                             onCaptionChange={this.onCaptionChange}
                             delay={delay}
                             lightbox={this.lightbox}
                             publicV={this.props.publicV}
                             pageName={this.pageName}
                             myIndex={i}
                 />
              photos.push(elt);
          }
          this.setState({items: photos})
      }
   }

   componentDidMount()  {  this.loadAndSyncData(); this.timerId = setInterval(() => {  if (this.state.slideShow) this.slideNext() }, 4000) } 
   componentDidUpdate() {  this.loadAndSyncData();  }
   componentWillUnmount() {  this.props.dispatch(setButtonLabel("")); if (this.timerId !== 0) clearTimeout(this.timerId) }
   static getDerivedStateFromProps(props,  state)  
   {    let ret = {}    
        if (props.auth != null && state.auth === null)
            ret.auth = props.auth
        if (props.user != null && state.user === null)
            ret.user = props.user
        if (props.carouselContent != null && state.carouselContent === null)
            ret.carouselContent = props.carouselContent;
        if (props.videoCarouselContent != null && state.videoCarouselContent === null)
            ret.videoCarouselContent = props.videoCarouselContent;
        if (!isEmpty(ret)) return ret;
        return null;
   }

   onLoad = () => this.setState({key: Date.now()})
 
   delete = async (pageInstance) =>
   {  await axios.post('/api/delete_page', { pageType: this.pageName, pageInstance });
      const index = this.state.carouselContent.findIndex(elt => elt.pageInstance === pageInstance)
      if (index >= 0)   //Delete LazyLoader
      {  this.state.items.splice(index, 1);
         this.setState({items: this.state.items.slice()});
      }
      const newContent = filter(this.state.carouselContent, function(rec) { return rec.pageInstance !== pageInstance }) 
      this.setState({carouselContent: newContent});
      this.props.dispatch(this.isVideo ?  updateVideoCarouselPage(newContent) : updateCarouselPage(newContent));
      updateSessionStorage(CAROUSEL_NEWBY_ACCOUNT, this.state.user.account_id)
      updateSessionStorage(this.isVideo ? VCAROUSEL_INDEX : CAROUSEL_INDEX, newContent)
      if (this.lightbox)
          this.props.onExit();  //**All of the captions are off with the activeIndex being ambigous
      else
          this.slideNext()
   }

   onCaptionChange = (pageInstance, captionValue) => {
      const index = this.state.carouselContent.findIndex(elt => elt.pageInstance === pageInstance)
      if (index >= 0)   
      {   const newContent = this.state.carouselContent.slice();  
          newContent[index].caption = captionValue
          this.setState({carouselContent: newContent});
          this.props.dispatch(this.isVideo ?  updateVideoCarouselPage(newContent) : updateCarouselPage(newContent));
      }
   }

   render()
   {   
      if (this.state.auth === false && this.props.publicV !== true)  
         return <div>Please Login to continue...</div>
      if (this.state.items.length === 0)
         return <> </>

         //**The lightbox has some weird right padding where a piece of the next slide shows, this fixes that although there is a refresh
       const responsive = { 0: { items: 1 },
                            1024: { items: 1 },
                            2048: { tems: 1 },
                            3072: { tems: 1 }
                          }

       return (
           <div className={this.lightbox?"slideshow-container":"slideshow-container-base"}>
           <AliceCarousel mouseTracking 
                          activeIndex={this.activeIndex}
                          renderKey={this.state.key}
                          infinite
                          animationType="fadeout"
                          touchMoveDefaultEvents={false}
                          items={this.state.items} 
                          disableButtonsControls={true}
                          disableDotsControls={true}
                          disableSlideInfo={true}
                          autoPlayControls={false}
                          keyboardNavigation={!this.editButtons}
                          responsive={this.lightbox?responsive:undefined}
                          onSlideChanged={e => { this.activeIndex = e.slide; this.props.dispatch(setCarouselActiveIndex(e.slide)) }}
                          onSlideChange={e => { this.activeIndex = e.slide;  this.props.dispatch(setCarouselActiveIndex(e.slide))  }}
                          ref={ el => this.Carousel = el }
           />  {/* eslint-disable-next-line */} 
           <a className="prev is-hidden-touch" onClick={() => this.slidePrev()}>{'\u276e'}</a> {/* eslint-disable-next-line */}
           <a className="next is-hidden-touch" onClick={() => { this.slideNext(); }}>{'\u276f'}</a>
          </div>
      )
  }
}

function LazyLoader(props) {
    const { pictureObject, delay=0, lightbox=false, onLoad, onDelete, onCaptionChange, showEditButtons=false, showCommentButtons=false, showSlideshowButton=false, slideState=false, slideshowFunc, commentFunc, buttonLabel, publicV=false, pageName, myIndex } = props;

    const isVideo = pageName==='vcarousel' ? true : false
    const [isMounted, setMounted] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [numComments, setNumComments] = useState(0);
    const [newCaption, setNewCaption] = useState(0);
    const [vKey, setVkey] = useState(0);
    const [poster, setPoster] = useState(pictureObject.posterp);
    const vRef = useRef()
    const videoLoaded = useRef(false)
    const videoPlaying = useRef(false)
    const currentIndex = useSelector(state => state.carouselActiveIndex); 

       //Distinguishing drag/swipe from click/tap to start/stop play
    const toggleVideo = () => { if (videoLoaded.current !== true) setVkey(vKey+1); if (videoPlaying.current) { vRef.current.pause(); videoPlaying.current  = false }  else { vRef.current.play(); videoPlaying.current = true }; commentFunc() }
    const stopVideo  = () => {  if (videoPlaying.current) { vRef.current.pause(); videoPlaying.current = false }}

       //Lazy Loading
    function loadImage() {
        const image = new Image();

        image.src = pictureObject.imageUrl;
        image.onload = () => {
           setLoading(false);
           onLoad();
        }
        image.onerror = () => {
           setLoading(false);
        }
    }

      //Slide changed, video no longer active
    useEffect( () => {
       if (isVideo && myIndex !== currentIndex)
           stopVideo();

    }, [currentIndex]);

       //Get the number of comments to display
    useEffect( () => {
        let timerId = 0;
        async function fetchData() {
           let res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: pageName, pageInstance: pictureObject.pageInstance, publicV }})
           setNumComments(res.data.numComments);
        }
        if (!isMounted && !isVideo) {
            setMounted(true);
            delay ? (timerId = setTimeout(loadImage, delay)) : loadImage();
        }
        fetchData();
        return () => { if (timerId !== 0) clearTimeout(timerId); }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

       //Delete button
    const deleteButton = showEditButtons ? 
         <DeleteButton buttonText={isVideo ? "Delete Video" : "Delete Picture"} itemDescription={isVideo ? "this gallery video" : "this gallery carousel picture"} deleteFunc={() => onDelete(pictureObject.pageInstance)}/>
         : ""

      //Settings button
    const settingsButton = showEditButtons ? 
         <SettingsButton pageType={pageName} pageInstance={pictureObject.pageInstance} />
         : ""

        //Caption button
    const captionButton = showEditButtons ?
       <AddCaptionButton pageType={pageName} caption={pictureObject.caption} pageInstance={pictureObject.pageInstance} 
                         onCaptionEnd = { data => {  onCaptionChange(data.pageInstance, data.captionValue);
                                                     pictureObject.caption = data.captionValue;
                                                     setNewCaption(newCaption+1)
                                                } }
       />
       : ""
    const media = !isVideo ? 
        <img className="slideshow-container" src={pictureObject.imageUrl} alt={pictureObject.caption} 
             style={{width: "100%", height: "70vmin", objectPosition: "50% 50%"}}
             onDragStart={e => e.preventDefault()}
             onContextMenu={e => e.preventDefault()}
        /> :
        <video className="slideshow-container" ref={vRef} controls controlsList="nodownload" width="100%" height="100%" disablePictureInPicture playsInline preload="metadata" poster={poster} key={pictureObject.key+vKey}
           style={{width: "100%", height: "70vmin", objectPosition: "50% 50%"}}
           onClick={e => { e.preventDefault(); toggleVideo(); }}
           onTouchStart={e => {  e.stopPropagation(); toggleVideo(); }}
           onLoadedMetadata={e => { videoLoaded.current = true; setPoster(pictureObject.poster)}}
           onError={e => { console.log("OnError", e, videoLoaded.current); if (videoLoaded.current !== true){ setTimeout(() =>  setVkey(vKey+1) , 5000)}}}
        >
                <source src={pictureObject.url2} type="video/mp4" />
                <source src={pictureObject.url1} type="video/webm" />
                  Sorry, your browser doesn't support embedded videos.
         </video>

       //Comment Buttons
    const commentButtons = showCommentButtons ?
        <>
        <WriteCommentButton pageType={pageName} pageInstance={pictureObject.pageInstance} onClickFunc={commentFunc}/>
        <SeeCommentsButton pageType={pageName} pageInstance={pictureObject.pageInstance} numComments={numComments} onClickFunc={commentFunc} />
        </>
     : ""

    const slideShowButton =  showSlideshowButton ? 
                              <SlideshowButton state={slideState} toggleSlideshowFunc={ () => slideshowFunc()} buttonLabel={buttonLabel} />
                              : ""

    return isLoading && !lightbox ? 
        <div>Loading...</div> 
        : 
        <figure>
        <Card.Footer>
           {captionButton}
           {deleteButton}
           {settingsButton}
           {commentButtons}
           {slideShowButton}
        </Card.Footer>

        {media}

           {/*Disable drag and R) context menu for images*/}
        <center><figcaption className="text">{pictureObject.caption}</figcaption></center>
        </figure>
}

const  mapStateToProps = state => { return { auth: state.auth, user: state.user, carouselContent: state.carouselContent, videoCarouselContent: state.videoCarouselContent } };
export default connect(mapStateToProps)(withRouter(CarouselEditPanel));


