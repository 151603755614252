import styled from 'styled-components';
//import '../overrides.scss'

export const Wrapper = styled.div`

.faq-title {

        border-radius: 10px;
        border: 1px solid #708090;
        /*border-bottom: 1px solid #ededed; */
        display: block!important;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
        padding-top: .75rem!important;
        padding-bottom: .75rem!important;
        box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
        font-size: 1rem;
        font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
        color: black; /*var(--footer-green)*/
        background-color: var(--card-footer-color); 
   }

.down-arrow{}
.down-arrow::after {

      content: '^';
      float: right;
      transform: rotate(180deg);
}

.up-arrow{}
.up-arrow::after {

      content: '^';
      float: right;
}

.faq-content {

      padding-left: 1rem!important;
      padding-right: 1rem!important;
      padding-top: .75rem!important;
      padding-bottom: .75rem!important;
      background-color:  white;
      /*font-style: italic;*/
  }

.faq-title:hover {

    cursor: pointer;
    background-color: var(--primary);
    color: white;
}

.faq 
{
    /*border-bottom: 1px solid black;*/
}

`;
