import axios from 'axios'
import { UPDATE_HOME_PAGE, UPDATE_HOME_PAGE_NODISK } from './types'

/*
export const getHomePage = () => async dispatch => //dispatches html that is the page contents
  {
        const res = await axios.get('/api/get_page', { params:  { pageType: 'home', pageInstance: 'none' }}) 
        dispatch(updateHomePage(res.data.pageData, false));
  }
*/

  //Action creator function to return Plain Object containing action with dynamic data
export const updateHomePage = (pageContents, updateDisk=true) => {
   return { type: updateDisk?UPDATE_HOME_PAGE:UPDATE_HOME_PAGE_NODISK,  payload: pageContents }  //type is a required field
};

  //Middleware : 
export const updateHomePageMiddleware = ({ getState, dispatch }) => next => async action =>
{  const result = next(action);
   switch (action.type) {
     case UPDATE_HOME_PAGE:
       await axios.post('/api/save_page', { pageType: 'home', pageInstance: 'none', pageData: action.payload });
          //dispatch(anotherAction(asyncResult));
       break;
       /***
       case SOME_OTHER_ACTION:
         const { slice: { stateVariable } } = getState();
         await someProcess(stateVariable);
         break;
      ***/
      default:
   }
   return result
}


/** normally, if synchronous)
  request = axios.get(...)
  return {
      type: FETCH_USER,
      payload: requests
}**/
