import isEmpty from 'lodash/isEmpty'
import React from 'react';
import { useSelector } from 'react-redux'
import CarouselSidebar from './CarouselSidebar'
import CarouselGallery from './CarouselGallery'
import { Heading, Container, Section} from 'react-bulma-components';
import usePagePerms from '../hooks/usePagePerms'
import '../sidebar.css'
import { getHeaderText, getBannerText } from '../functions/location_fcns'

const CarouselNewPage = (props) => {

   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText(getHeaderText(), user) 
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   const [ , canEdit, , isResolved ] = usePagePerms('carousel', 'singleton')

   if (!isResolved)
     return <div>Loading...</div>

   else if (!canEdit)
     return <Container className="column is-9">
            <div>You are not authorized to edit this page</div>
            </Container>

   else return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     <div className="aside"><CarouselSidebar isActive="New Pictures" className="menu"/></div>

     <Container className="column is-9"  style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <CarouselGallery isNew={true}/>
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default CarouselNewPage;

