import React from 'react'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import CarouselEditPanel from './CarouselEditPanel'
import './slideshow.css'
import useLockBodyScroll from '../hooks/useLockBodyScroll'

const LightboxOverlay = ({ initialState, setClose, activeIndex=0, view=true, edit=false, publicV=false, lockedArray=[] }) => {
  
  const styles = {
  modal: {
    backgroundColor: "#DDDDDD",
    maxWidth: "90vw",
    margin:0, padding:"1% 3% 3% 3%",
  },
};

  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        styles={styles}
        showCloseIcon={false}
        center
      >
       <div style={{paddingBottom: "1%"}}>
       <button id="close" onClick={() => setClose()} className="button is-primary is-inverted" style={{marginBottom: "5px"}}>Close</button>
       &nbsp;&nbsp;<i>You can swipe right or left to see more</i>
       <CarouselEditPanel lightbox={true} activeIndex={activeIndex} view={view} edit={edit} publicV={publicV} lockedArray={lockedArray} onExit={() => setClose()} />
       </div>
      </Modal>
    </>
  );
};

export default LightboxOverlay
