//**This is the first page of "All Pages" SibsForever "My Site"
//*It works with shared

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import usePagePerms from './hooks/usePagePerms'
import { getAccountOverride } from './functions/location_fcns'
import useAccountOverridePages from './hooks/useAccountOverridePages'

const AllPages = () => { 

   const accountOverride = getAccountOverride()
   const history = useHistory();
   const auth = useSelector(state => state.auth); //Logged in?
   useAccountOverridePages(false, true)

   const [ , , canViewHome, isResolvedHome ] = usePagePerms('home', 'singleton', false)
   const [ , , canViewCarousel, isResolvedCarousel ] = usePagePerms('carousel', 'singleton', false)
   const [ , , canViewVcarousel, isResolvedVcarousel ] = usePagePerms('vcarousel', 'singleton', false)
   const [ , , canViewWall, isResolvedWall ] = usePagePerms('wall', 'singleton', false)
   const [ , , canViewDiary, isResolvedDiary ] = usePagePerms('diary', 'singleton', false)

   const prefix = (accountOverride === 0) ? "/allpages" : `/share/${accountOverride}`

     //get links
   useEffect(() => {
       if (isResolvedHome && isResolvedCarousel && isResolvedVcarousel && isResolvedWall && isResolvedDiary) {
          if (canViewHome) 
             history.replace(`${prefix}/home`);
          else if (canViewCarousel) 
             history.replace(`${prefix}/carousel`);
          else if (canViewVcarousel) 
             history.replace(`${prefix}/vcarousel`);
          else if (canViewDiary) 
             history.replace(`${prefix}/diary`);
          else if (canViewWall) 
             history.replace(`${prefix}/wall`);
          else
             history.replace("/");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [ isResolvedHome, isResolvedCarousel, isResolvedVcarousel, isResolvedWall, isResolvedDiary ])

     if (auth === false)
         return <div>Please Login to continue...</div>
     return <div>Loading</div>
  }

export default AllPages

