import isEmpty from 'lodash/isEmpty'
import React  from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bulma-components';

const SlideshowButton = (props) => {

   const { toggleSlideshowFunc, buttonLabel } = props;

   const label = useSelector(state => state.carouselButtonText);

   return (
    <>
    <a href="#" className="card-footer-item footer-buttons" onClick={() => toggleSlideshowFunc()} ><strong>{ isEmpty(label)? buttonLabel : label }</strong></a> 
    </>
    )
  }

export default SlideshowButton

