import { Card, Box, Section, Container, Heading } from 'react-bulma-components';
import './landing.css'

const TOC = (props) => {

   const styles =
   { borders: { border:"thick double var(--primary)", margin:".5rem"},
     bshadow: { boxShadow:"rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"},
     shim:  {  display: "inline-block", height: 0,  width: "10em "},
   }

   return (
     <>
<Section style={{padding:"2vw"}}>
<div className="sibs-subheading has-text-primary-NOT is-size-3 has-text-centered" style={{padding:0, margin:0}}>
SibsForever Terms of Use
</div>

<center>
<Box className="column is-narrow width" style={{boxShadow:"3px 3px var(--footer-background-color), -1em 0 .4em olive"}}>
<div style={{textAlign:"left", margin:"1%"}}>
<p className="subtitle mb-6">

<b>Thank you for choosing to use SibsForever (<a href="https://sibsforever.org">sibsforever.org</a>)</b>.
SibsForever is a non-profit focusing on sibling relationships cut short due to the death of a sibling. The cornerstone of the non-profit is a web application where sibling relationships can be documented, shared, and honored to help understand and manage sadness stemming from sibling loss.

<br/><br/><strong className="is-size-4">Account Creation</strong><br/>
</p>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
To use SibsForever, you will be asked to create an account. As part of the account creation process, you will be asked to <b>provide an email address</b>. Until an account is created, access to SibsForever is limited to what is available to the general public. When registering an account, you <b>must provide true, accurate, current and complete information</b> about yourself and the sibling relationship associated with the account, as requested during the account creation process. You must also keep that information true, accurate, current and complete after the creation of your account.
</div>

<p className="subtitle mb-6">
<strong className="is-size-4">Uploaded and Authored Content</strong><br/>

</p>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
All uploaded and authored content is <b>owned by you</b>. You hereby grant to SibsForever a non-exclusive, worldwide license to host and use your content only in providing SibsForever services.&nbsp; 
<strong>SibsForever cannot be held liable for any lost content or missing data</strong>.
</div>

<p className="subtitle mb-6">
<strong className="is-size-4">Sharing Your Content</strong><br/>
</p>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>

SibsForever provides features that allow you to share your content with others that use the service.  Please consider carefully what you choose to share. <b>SibsForever has no responsibility</b> for that activity. Your SibsForever account creator has the <b>ability to "unshare" and make private any content deemed inappropriate</b> or content that violates the Acceptable Use standards enumerated in this policy.
</div>

<p className="subtitle mb-6">

<strong className="is-size-4">Confidentiality</strong><br/>
</p>

<div className="subtitle mb-6" style={{marginLeft: "0"}}>
<b>You will not disclose any Confidential Information</b> learned or acquired from using the SibsForever service to any third party. <b>Confidential Information includes personal and identifying data</b> such as name, email address, family names and circumstances.
</div>

<p className="subtitle mb-6">
<strong className="is-size-4">Acceptable Use</strong>
</p>    
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
The following is <b>not an all-inclusive list but rather a summary of Acceptable Use violations</b> that could lead to the inactivation of your SibsForever account.
<br /><br />
<ol> 
       <li>Attempting to <b>bypass or break any SibsForever security, privacy or authentication mechanism</b> or using the service in any manner that would pose a security risk or privacy breach</li> 
       <li>Using <b>temporary or publicly accessible email addresses</b> or disclosing authentication credentials to any third party</li>
       <li><b>Impersonating another person</b> or misrepresenting a connection with another person</li>
       <li>Using the service for <b>any other purpose outside of the SibsForever mission</b> stated at the top of this policy</li>
       <li>Publishing or sharing material that is <b>defamatory, libelous, unlawfully pornographic or indecent</b></li>
       <li>Authoring content (including comments) that <b>advocates hatred, hostility or violence</b></li>
       <li>Interactions classified as <b>bullying, harassment, disrepect or mistreatment</b> of others</li>

</ol>
</div>

<strong className="is-size-4">Modifications</strong><br/><br/>
<div className="subtitle mb-6" style={{marginLeft: "0"}}>
<b>SibsForever may amend the Terms of Use from time to time</b>; please review it periodically. When it's modified, SibsForever will post a notice online to alert users of policy changes. The continued use of sibsforever.org after the date that such notices are posted will be deemed to be an agreement to the changed terms.
</div>

<p className="subtitle mb-6">
If we conclude, at our discretion, that there has been a material <b>breach of any provision of these terms</b> or that you have misused these usage standards with respect to the SibsForever service, your account may be <b>inactivated such that you can no longer use the service</b>. Additionally, your content can be made private so that it will not be seen by other SibsForever users.
</p>

<p className="subtitle mb-6">
<strong>Published date of this Terms of Use Policy:  December 15, 2021</strong>
</p>
</div>
</Box>

</center>
</Section>

     </>
    )
  }

export default TOC;
