import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { getBannerText } from '../components/loggedIn/functions/location_fcns'
import { NEW_STUFF, getSessionStorage, updateSessionStorage, clearSessionStorage } from '../components/loggedIn/functions/session_fcns'

   //**Auth is already checked
//const line=`<li><a href="/share/${p.account_id}">${getBannerText("", o)}</a></li>`
export const checkNew = async (user, logging=false) => 
{
//clearSessionStorage([NEW_STUFF])
     const now = new Date().getTime()
     let cachedRet = {}

     const sess = getSessionStorage(NEW_STUFF)
     if (!isEmpty(sess) && sess.account_id === user.account_id)
     {  if (sess.origNow === undefined)
             sess.origNow = sess.now
        if  (now - sess.now <= 1000 * 60 * 60 * 2) //2 hours
        {   const myRet = { ret:sess }
            return myRet
        }
        if  (now - sess.origNow <= 1000 * 60 * 60 * 24) //One-day
            cachedRet = { ret: sess }
     }
     let ret = { card1: "", card2: "", card3: "", card4: "", card5: "" }
     let res = await axios.get('/api/get_new_pieces', { params: {logging} })
     const {  newMessages, newAccounts, newFollowers, minTime } = isEmpty(res.data) ? { newMessages: [], newAccounts: [], newFollowers: [], minTime: 0 } : res.data
     if (!isEmpty(newMessages))
        //ret.card1 = ""//`${newMessages[0].message}`
        ret.card1 = '<strong>Support for video has been added&nbsp;to SibsForever!</strong></p><ul><li style="margin-left: 20px;">Checkout the&nbsp;new&nbsp;<strong>Video Gallery page</strong>&nbsp;</li><li style="margin-left: 20px;">Videos can be uploaded anywhere (Memorial Home Page, Journal&nbsp;and&nbsp;Wall entries)</li><li style="margin-left: 20px;">Videos are private, like everything else in SibsForever,&nbsp;with configurable sharing options</li></ul>'

     if (!isEmpty(newAccounts)) {
          ret.card5 = `<strong>There are new shared SibSites, including:</strong>`
          if (newAccounts.length  > 0) 
          {  ret.card5 += "<ul>"
             newAccounts.map(    p =>  {  const o = { departed_first_name: p.departedFirstName, departed_last_name: p.departedLastName, departed_dob: p.departedDob, departed_dod: p.departedDod, sibling_first_name: p.siblingFirstName } 
                                          const line=`<li><a href="/share/${p.id}">${getBannerText("", o)}</a></li>`
                                          ret.card5 += line
                                       }
                             )
             ret.card5 += "</ul>"
             const pronoun = newAccounts.length === 1 ? "It" : "They"
             ret.card5 += `${pronoun} can also be viewed using <a href='/community'>the Community tab</a>`
          }
     }
     if (!isEmpty(newFollowers)) {
        const followers = newFollowers.length === 1 ? "follower" : "followers"
        ret.card3 = `<strong>You have ${newFollowers.length} new ${followers}:</strong>` 
          if (newFollowers.length  > 0) 
          {  ret.card3 += "<ul>"
             newFollowers.map(  p =>  {  const o = { departed_first_name: p.departed_first_name, departed_last_name: p.departed_last_name, departed_dob: p.departed_dob, 
                                                     departed_dod: p.departed_dod, sibling_first_name: p.sibling_first_name } 
                                         const line=`<li><a href="/share/${p.account_id}">${getBannerText("", o)}</a></li>`
                                         ret.card3 += line
                                      }
                             )
             ret.card3 += "</ul>"
             const pronoun = newFollowers.length === 1 ? "It" : "They"
             ret.card3 += `${pronoun} can also be viewed using <a href='/auth/shared/followers'>the Connection tab</a>`
          }
     }



     let resC = await axios.get('/api/get_comment_activity_details', { params:  { unseen: true }})
     const unseenComments = isEmpty(resC.data) ? false : resC.data.unseenComments;
     if (unseenComments)
        ret.card2 = `<strong>There are new comments for your SibSite:</strong> <ul><li> <a href="/share/${user.account_id}">${getBannerText("", user)}</a></ul>`

     let resF = await axios.get('/api/get_my_following', { params: {minTime}})
     let newContent = 0, newComments = 0
     if (!isEmpty(resF.data))
     {  let num = 0;  
        resF.data.map(f =>  
        {  if (f.newSharedComments)  newComments += f.newSharedComments ? 1 : 0;
           if (f.newSharedDiaryEntries ||f.newSharedWallEntries) newContent++;
           if (f.newbie && (f.newSharedComments || f.newSharedDiaryEntries || f.newSharedWallEntries)) num++
        })
        if (num > 0)  {
           const are = num === 1 ? "is" : "are"
           const sibsites = num === 1 ? "SibSite" : "SibSites"
           const these = num === 1 ? "It" : "These"
           const have = num === 1 ? "has" : "have"
           const sibsites1 = newContent === 1 ? "SibSite" : "SibSites"
           const sibsites2= newComments === 1 ? "SibSite" : "SibSites"
           ret.card4 = `<strong>There ${are} ${num} ${sibsites} you follow that ${have} new data:</strong> <ul><li>${newContent} ${sibsites1} with new page content, and <li>${newComments} ${sibsites2} with newly posted comments</ul> ${these} can be seen under <a href='/auth/shared/following'>the Community tab</a>`
        }
     }
     ret.now = ret.origNow = now
     ret.account_id=user.account_id

     if (!isEmpty(cachedRet) && ret.card1 === cachedRet.card1 && ret.card2 === "" && ret.card3 === "" && ret.card4 === "" && ret.card5 === "")
     {   ret = cachedRet;
         ret.now = now
     }
     
     updateSessionStorage(NEW_STUFF, ret)
     return { ret }
}
