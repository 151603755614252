import React from 'react';
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import { Button, Heading } from 'react-bulma-components';
import 'react-responsive-modal/styles.css';
import useLockBodyScroll from '../../loggedIn/hooks/useLockBodyScroll'

const ConfirmPopup = ({ title, prompt, affirmativeButtonText, declineButtonText, initialState, setCloseData, setClose } ) => {

  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        center
      >
        {title}
        <br/><br/>
        <Heading size={5}>{prompt}</Heading>

           <Button style={{backgroundColor: '#B0B0B0', margin: "0 2%"}} size="small" fullwidth={false} onClick={()=>setClose()}>{declineButtonText}</Button>
           <Button style={{margin: "0 2%"}} size="small" fullwidth={false} className="is-pulled-right" onClick={()=>setCloseData({answer: true})}>{affirmativeButtonText}</Button>

      </Modal>
    </>
  );
};


export default ConfirmPopup;

