import { useLayoutEffect } from 'react';

export default function useLockBodyScroll(initialState) {
    //Disable main scrollbar so there is at most a single scrollbar
  useLayoutEffect(() => {
      let originalStyle = window.getComputedStyle(document.documentElement).overflow;
      if (initialState) {
           // Prevent scrolling on mount
        originalStyle = window.getComputedStyle(document.documentElement).overflow;
        if (originalStyle !== 'hidden')
        {   document.documentElement.style.overflow = 'hidden';
            document.documentElement.style.paddingRight = '15px';
        }
      }

         // Re-enable scrolling when component unmounts
      return () => {
            if (originalStyle !== 'hidden')
            {   document.documentElement.style.overflow = originalStyle;
                document.documentElement.style.paddingRight = '0px';
            }
      }
   }, [initialState]);  // eslint-disable-line react-hooks/exhaustive-deps
}
