import React from 'react';
import { Card } from 'react-bulma-components';

const AdminButtons = ({existingPhoto, photoOpen, departedOpen, sibOpen, userOpen}) => {  //Could use object destructuring for const XXX = ({prop1, prop2}) =>

   return (
     <Card className="is-paddingless">
        <Card.Footer> {/* eslint-disable-next-line */}
             <a href="#" className="card-footer-item footer-buttons" onClick={()=>photoOpen()}>{existingPhoto?"Change Account Photo":"Upload Account Photo"}</a> {/* eslint-disable-next-line */}
             <a href="#" className="card-footer-item footer-buttons" onClick={()=>departedOpen()}>Edit Departed Sibling</a> {/* eslint-disable-next-line */}
             <a href="#" className="card-footer-item footer-buttons" onClick={()=>sibOpen()}>Edit Sib Survivor</a> {/* eslint-disable-next-line */}
             <a href="#" className="card-footer-item footer-buttons" onClick={()=>userOpen()}>Edit Users</a> {/* eslint-disable-next-line */} 
        </Card.Footer>
     </Card>
    )
  }

export default AdminButtons;

