//**This is the SibsForever sidebar for Diary Entry View

import isEmpty from 'lodash/isEmpty'
import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import usePagePerms from '../hooks/usePagePerms'
import { NavLink } from 'react-router-dom';
import { Menu } from 'react-bulma-components';
import { isEsharePage, isPublicA, getHeaderText, getDiaryInstance, calcDiaryUrl, calcDiaryUrlPage, calcDiaryUrlBase } from '../functions/location_fcns'
import { curateDiaryPages } from '../../../actions/diaryPage'

const DiaryEntrySidebarRO = () => { 

const styles = {
    hrStyle: { borderTop: "2px solid #bbb" },
    spanStyle: { marginRight: "2px", marginTop:"5px", marginBottom:"5px" }
}

   const publicA = isPublicA()
   const pageInstance = getDiaryInstance()
   const pageUrlView = useRef(calcDiaryUrl())
   const pageUrl = calcDiaryUrl()
   let baseUrl = calcDiaryUrlBase()
   if (isEsharePage())
      baseUrl = '/auth/diary'
   const user = useSelector(state => state.user);

   const [ , , canView, isResolved ] = usePagePerms('diary', pageInstance)

   const [ viewLinks, setViewLinks ] = useState("")
   const [ TOCLinks, setTOCLinks ] = useState("")
   const [ nextLink, setNextLink ] = useState(null)
   const [ prevLink, setPrevLink ] = useState(null)
   const [ otherEntries, setOtherEntries ] = useState(null)

   const [ isInit, setIsInit ] = useState(false)
   const [ userResolved, setUserResolved ] = useState(false)

   useEffect(() => {
      const init = async () => {
         if ((!isEmpty(user) && !isEmpty(user.email)) || publicA) {
            const { displayedRows } = await curateDiaryPages(publicA?0:user.account_id, [], false)
            for (let i = 0; i < displayedRows.length; i++)
            {  if (displayedRows[i].pageInstance === pageInstance)
               {  if (i > 0)
                     setNextLink(calcDiaryUrlPage(displayedRows[i-1].pageInstance, true))
                  if (i < displayedRows.length-1)
                     setPrevLink(calcDiaryUrlPage(displayedRows[i+1].pageInstance, true))
                  pageUrlView.current = calcDiaryUrlPage(getDiaryInstance())
               }
            }
            setUserResolved(true);
         }
     }
     if ((!isEmpty(user) && !isEmpty(user.email)) || publicA)
         init()
   }, [publicA, user, pageInstance])


   useEffect(() => {
      if (isResolved && userResolved)
      {   
          const previousLink = (prevLink === null) ? "" :
            <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${prevLink}`}>
	        <span className="nospace"><span className="menu-icon">
	        <i className="fa fa-arrow-circle-left"  style={styles.spanStyle}></i>
	        </span>
	        Prev Entry</span>
            </NavLink></div>

         const subsequentLink = (nextLink === null) ? "" :
            <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${nextLink}`}>
	        <span className="nospace"><span className="menu-icon">
	        <i className="fa fa-arrow-circle-right"  style={styles.spanStyle}></i>
	        </span>
	        Next Entry</span>
            </NavLink></div>
         if (canView) setViewLinks(
            <>
            <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${pageUrlView.current}`}>
	        <span className="menu-icon">
	        <i className="fa fa-file"  style={styles.spanStyle}></i>
	        </span>
	        View 
            </NavLink></div>
            <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${pageUrl}/vsummary`}>
	        <span className="nospace"><span className="menu-icon">
	        <i className="fa fa-paragraph"  style={styles.spanStyle}></i>
	        </span>
	        Title & Summary</span>
            </NavLink></div>
            </>
            )
         if (canView) setTOCLinks(
            <div><NavLink className="xmenu-block" activeClassName="is-active" to={`${baseUrl}/toc/toc`}>
	        <span className="nospace"><span className="menu-icon">
	          <i className="fa fa-arrow-left"  style={styles.spanStyle}></i>
	        </span>
	          Table of Contents</span>
            </NavLink></div>
            )
         if (canView)
             setOtherEntries(
             <>
               {previousLink}
               {subsequentLink}
             </>
             )
             
         setIsInit(true);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isResolved, userResolved, canView, prevLink, nextLink ])

   if (!isInit)
     return <div>Loading...</div>

   return (
  
  <Menu>
    <p className="menu-label is-hidden-mobile">{getHeaderText()}</p>
        {viewLinks}
<hr className="is-hidden-mobile" style={styles.hrStyle} />
        {otherEntries}
<hr className="is-hidden-mobile" style={styles.hrStyle} />
        {TOCLinks}
  </Menu>

    )
  }

export default DiaryEntrySidebarRO

