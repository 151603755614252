import { useEffect, useState } from "react";
import {  useSelector } from 'react-redux';
import usePageSettings from './usePageSettings'
import useAccountOverridePages from './useAccountOverridePages'
import { isPublicA, augmentApi, isEsharePage } from '../functions/location_fcns'
import useIsMounted from './useMount'

import isEmpty from 'lodash/isEmpty'
import axios from 'axios'

  //**Works with shared page
export default function usePagePerms(pageName, pageInstance='singleton', fetch=false, noShared="DEFAULT", publicV=false) 
{
      //**Note: Header calls this a lot - but uses logged in users perms, so will send in noShared=true regardless of URL
   if (noShared === "DEFAULT")
       noShared = isEsharePage()
   const publicA = isPublicA()
   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user);

   const [ accountOverride, pageList, overrideResolved] = useAccountOverridePages(noShared)
   const [ pageSettings, isResolved ] = usePageSettings(pageName, pageInstance, fetch)

     //Returns
   const [ canAdmin, setCanAdmin ] = useState(false)
   const [ canEdit, setCanEdit ] = useState(false)
   const [ canView, setCanView ] = useState(false)
   const [ isInit, setIsInit ] = useState(false)

   const isMounted = useIsMounted();

   useEffect(() => {
      
      const getSettings = async () =>  {

         if (publicV || (publicA&&auth === false))
         {   setCanView(true)
             setIsInit(true);
         }
         else if (!isEmpty(user) && ! isEmpty(user.email) && (isResolved||accountOverride>0) && overrideResolved)
         {
             let pageAuthor = false;

             let role = user.role
             let origRole = role
             if (accountOverride > 0)
                origRole = role = 'guest'
             else if (role === 'admin' || role === 'sibling' || role === 'owner')
             {  origRole = role
                role = 'admin'
             }
   
               //**Question - do we need to know which diary pages and wall entries are shared? - those come up as pageRef API calls using accountOverride
               //**We do need to know for diary since it is separate page
               //**NOTE: the AccountSettings are for the loggedIn user's account, NOT account override
             if (accountOverride > 0 && pageList.includes(pageName) && pageInstance === 'singleton' && isMounted.current)  
                 setCanView(true)
             else if (accountOverride > 0 && pageInstance !== 'singleton' && isMounted.current) {
                 if (pageName === 'diary' && pageList.includes('diary') && pageList.includes(pageInstance))
                    setCanView(true)
                 else
                    setCanView(false)
             }
         
             if (accountOverride === 0) {
                if ((pageName === 'wall' || pageName === 'diary') && (pageInstance === 'singleton')) //No page_author for these guys
                {      pageAuthor = false
                       if (pageSettings.editPagePrivacy === 1 && role === 'admin')
                           setCanEdit(true)
                }
                else if (pageSettings.viewPagePrivacy === 1 || pageSettings.editPagePrivacy === 1)
                {  const res = await axios.get(augmentApi('/api/is_user_author'),  { params:  { pageName: pageName, pageInstance: pageInstance }})
                   const content = res.data;
                   if (!isEmpty(user) && !isEmpty(user.email) && !isEmpty(content) && (content.author === true || content.author === 'true'))
                       pageAuthor = true;
                }

                    //**If viewable, admin can hide the entry (as admin) in case it is innapropriate - but canot change it.
                if (((role === 'admin' && pageSettings.viewPagePrivacy > 1) || (pageAuthor && pageInstance !== 'singleton')) && isMounted.current)
                    setCanAdmin(true);
                if (pageSettings.editPagePrivacy === 1 && pageAuthor && isMounted.current) // && (origRole === 'sibling' || origRole === 'owner' || pageAuthor))  //sibling/owner or page owner only
                    setCanEdit(true);
                else if (pageSettings.editPagePrivacy === 1 && pageInstance === 'singleton' && role === 'admin' && isMounted.current) //**Home and Gallerys - editPagePrivacy === 1 -> admins can edit (newly added)
                    setCanEdit(true);
                if (pageSettings.editPagePrivacy === 2 && role !== 'guest' && isMounted.current)
                    setCanEdit(true);
                if (pageSettings.editPagePrivacy === 3 && isMounted.current)
                    setCanEdit(true);
                if (pageSettings.viewPagePrivacy === 1 && pageAuthor && isMounted.current) //(role === 'admin' || pageAuthor)) 
                    setCanView(true);
                else if (pageSettings.viewPagePrivacy === 1 && pageInstance === 'singleton' && role === 'admin' && isMounted.current) //Home and Gallerys viewPagePrivacy === 1 --> admins can view
                    setCanView(true);
                if (pageSettings.viewPagePrivacy === 2 && role !== 'guest' && isMounted.current)
                    setCanView(true);
                if (pageSettings.viewPagePrivacy === 3 && isMounted.current)
                    setCanView(true)
             }
             setIsInit(true);
         }

      }
      if (auth===true || publicV || publicA)
         getSettings();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ auth, isResolved, overrideResolved, user, accountOverride  ])

   //console.log("Page Perms return: admin/edit/view ", accountOverride, pageName, pageInstance, canAdmin, canEdit, canView)
   return [ canAdmin, canEdit, canView, isInit ]
}

