//**This is the Home Page Viewer

import React, {useEffect} from 'react';
import HomeSidebar from './HomeSidebar'
import AllPagesSidebar from '../AllPagesSidebar'
import HomeViewPanel from './HomeViewPanel'
import { Container, Section} from 'react-bulma-components';
import  { isSharePage, isAllPages } from '../functions/location_fcns'
import '../../overrides.scss'
import '../sidebar.css'
import { useLocation } from "react-router-dom";

const HomeView = (props) => {


   const location = useLocation();
   useEffect(() => {
     window.scrollTo(0,0);
   }, [location]);

   const homePageSide = (isAllPages() || isSharePage())
       ? <AllPagesSidebar isActive="Home" className="menu"/>
       : <HomeSidebar isActive="View" className="menu"/>

   return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     <div className="aside">{homePageSide}</div>
     <Container className="column is-9" style={{paddingTop:0}}>
     <HomeViewPanel/>
     </Container>
     </Section>
     </>
   )
  }

export default HomeView;

