/*** Methods related to session Storage
***/
export const DIARY_ENTRY_SUMMARY = 'DiaryEntrySummary'
export const DIARY_ENTRY_TITLE = 'DiaryEntryTitle'
export const DIARY_ENTRY_ACCOUNT = 'DIARY_ENTRY_ACCOUNT'
export const DIARY_CURATED_ROWS = 'DIARY_CURATED_ROWS'
export const DIARY_CURATED_ROWS_ACCOUNT = 'DIARY_CURATED_ROWS_ACCOUNT'
export const DIARY_CURATED_ROWS_ACCOUNT_RO = 'DIARY_CURATED_ROWS_ACCOUNT_RO'
export const DIARY_BREADCRUMB_PAGE = 'DIARY_BREADCRUMB_PAGE'
export const BROWSE_LAST_FETCH = 'BROWSE_LAST_FETCH'
export const NEW_STUFF = 'NEW STUFF'  //For What's New
export const WALL_ENTRY_KEY = 'WallKey'
export const WALL_ENTRY_RECORD = 'WallRecord'
export const DIARY_NEWBY_JSON = "Diary_Newby"
export const DIARY_NEWBY_ACCOUNT = "Diary_Newby_Account"
export const CAROUSEL_NEWBY_ACCOUNT = "Carousel_Newby_Account"
export const VCAROUSEL_INDEX = "Video_Carousel_Index"
export const CAROUSEL_INDEX = "Carousel_Index"

export function getSessionStorage(key) {
  const stored = sessionStorage.getItem(key);
  if (stored == null || typeof stored == 'undefined' || stored === 'undefined' || stored === "") {
    return "";
  }
  let ret = ""
  try {
     ret = JSON.parse(stored);
  } catch(err) {
    sessionStorage.removeItem(key)
    ret = ""
  }
  return ret
}

export function clearSessionStorage(keys)
{   
    for (let i=0; i < keys.length; i++)
       sessionStorage.removeItem(keys[i])
}

export function updateSessionStorage(key, value)
{   if (value !== null && typeof value !== 'undefined')   
        sessionStorage.setItem(key, JSON.stringify(value))  
    else
        clearSessionStorage([ key ])
}

