import { UPDATE_DONOR_INFO } from '../actions/types'

const initialState = null; 

export default function donorReducer (state=initialState, action={} )
{
  switch(action.type) {
    case UPDATE_DONOR_INFO:   //{ FirstName, LastName, Email, Zip, Ammount }
        return { ...state, FirstName: action.payload.FirstName,
                           LastName: action.payload.LastName, 
                           Email: action.payload.Email,
                           Zip: action.payload.Zip,
                           Amount: action.payload.Amount }; 
    default:
        return state;
  }
};
