import React from 'react'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import DepartedPanel from './DepartedPanel'
import useLockBodyScroll from '../hooks/useLockBodyScroll'

const DepartedPopup = ({ initialState, setClose, setCloseData, departedFirst, departedLast, departedDob, departedDod} ) => {

  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        center
      >
        <DepartedPanel first={departedFirst} 
                       last={departedLast} 
                       dob={departedDob} 
                       dod={departedDod} 
                       onEnd={setCloseData} 
       />

      </Modal>
    </>
  );
};

export default DepartedPopup

