import React from 'react'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import UserPanel from './UserPanel'
import useLockBodyScroll from '../hooks/useLockBodyScroll'

const UserPopup = ({ initialState, setClose, setCloseData, account_id} ) => {

  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        center
      >
        <UserPanel account_id={account_id} 
                   onEnd={setCloseData} 
       />

      </Modal>
    </>
  );
};

export default UserPopup

