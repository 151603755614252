import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react';
import { Navbar } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import usePagePerms from '../loggedIn/hooks/usePagePerms'

const AuthOptions = () => {
     const [ , canEditHome, , isResolvedHome ] = usePagePerms('home', 'singleton', true, true)
     const [ , canEditCarousel, , isResolvedCarousel ] = usePagePerms('carousel', 'singleton', false, true)
     const [ , canEditVcarousel, , isResolvedVcarousel ] = usePagePerms('vcarousel', 'singleton', false, true)
     const [ , canEditWall, , isResolvedWall ] = usePagePerms('wall', 'singleton', false, true)
     const [ , canEditDiary, , isResolvedDiary ] = usePagePerms('diary', 'singleton', false, true)
     const [ homeLink, setHomeLink ] = useState("")
     const [ carouselLink, setCarouselLink ] = useState("")
     const [ vcarouselLink, setVcarouselLink ] = useState("")
     const [ wallLink, setWallLink ] = useState("")
     const [ diaryLink, setDiaryLink ] = useState("")

     const [ isInit, setIsInit ] = useState(false)

     useEffect(() => {
       let homeReady = false;
       let carouselReady = false;
       let vcarouselReady = false;
       let wallReady = false;
       let diaryReady = false;
       if (isResolvedHome) {
          if (canEditHome) {
             setHomeLink(<Navbar.Item href="/auth/home/edit"> SibsForever Home Page </Navbar.Item>)
          }
         homeReady = true;
       }
       if (isResolvedCarousel) {
          if (canEditCarousel) {
             setCarouselLink(<Navbar.Item href="/auth/carousel/edit">Picture Gallery </Navbar.Item>)
          }
          carouselReady = true;
       }
       if (isResolvedVcarousel) {
          if (canEditVcarousel) {
             setVcarouselLink(<Navbar.Item href="/auth/vcarousel/edit">Video Gallery </Navbar.Item>)
          }
          carouselReady = true;
       }
       if (isResolvedWall) {
          if (canEditWall) {
             setWallLink(<Navbar.Item href="/auth/wall/edit"> Remembrance Wall</Navbar.Item>)
          }
          wallReady = true;
       }
       if (isResolvedDiary) {
          if (canEditDiary) {
             setDiaryLink(<Navbar.Item href="/auth/diary/toc">Journal</Navbar.Item>)
          }
          diaryReady = true;
       }
       if (homeReady && carouselReady && wallReady && diaryReady)
          setIsInit(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [ isResolvedHome, isResolvedCarousel, isResolvedVcarousel, isResolvedWall, isResolvedDiary, canEditHome, canEditCarousel, canEditVcarousel, canEditWall, canEditDiary ])


     if (!isInit)
        return <div>Loading...</div>

     return  (
               <>
               {homeLink}
               {carouselLink}
               {vcarouselLink}
               {diaryLink}
               {wallLink}
               </>
             )
}

const LoginHead = ({auth, user}) => {

     if (auth !== null && auth !== true && isEmpty(auth))
        auth = false;
     if (auth === null || (auth === true && (user === null || isEmpty(user) || isEmpty(user.email))))
        return null;

     const isNew = true;   //**TODO - set this properly
     let classes = "fa fa-bell"
     if (isNew)
        classes = `${classes} is-warning`
    
     switch (auth){
       case null:
          return null;
       case false:
           return  (<>
           <Navbar.Item renderAs="a" href="/account" alt="CreateAccount"> Create Account </Navbar.Item>
           <Navbar.Item dropdown hoverable href="#">
             <Navbar.Link>
{/*<span style={{verticalAlign: "middle"}}> //Might be needed for Firebox */}
                Login
{/*</span>*/}
             </Navbar.Link>
             <Navbar.Dropdown>
               <Navbar.Item renderAs="a" href={window.location.hostname !== 'home.sibsforever.org'?"https://node.sibsforever.org/auth/google":"/auth/google"}>Login with Google</Navbar.Item>
               <Navbar.Item renderAs="a" href={window.location.hostname !== 'home.sibsforever.org'?"https://node.sibsforever.org/auth/facebook":"/auth/facebook"}>Login with Facebook</Navbar.Item>
               <Navbar.Item href="/login/link"> Request Login Link </Navbar.Item>
             </Navbar.Dropdown>
           </Navbar.Item>
           </> )
       default:
         return (<>
             <Navbar.Item dropdown hoverable href="#">
                <Navbar.Link>
                 View Site
                </Navbar.Link>
                <Navbar.Dropdown>
                 <Navbar.Item href="/allpages/all" alt="View as me">View as Self</Navbar.Item>
                 <Navbar.Item href={`/share/${user.account_id}`} alt="browse">View as Follower</Navbar.Item>
               </Navbar.Dropdown>
           </Navbar.Item>

           <Navbar.Item dropdown hoverable href="#">
             <Navbar.Link>
                Edit Site
             </Navbar.Link>
             <Navbar.Dropdown>
               <AuthOptions />
             </Navbar.Dropdown>
           </Navbar.Item>
            </> )
    }
  }

export default LoginHead;
