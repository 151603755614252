import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBannerText, getAccountOverride, isPublicA } from '../functions/location_fcns'
import { getAccountObject } from '../../../actions/account'

export default function  useGetHeader(headingPiece="") {

   const accountOverride = getAccountOverride()

   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user);
   const headerObj = useSelector(state => state.accountObject);

   const [ heading, setHeading ] = useState("")

   const dispatch = useDispatch();

     //**Get the header
   useEffect( () => {

      let publicA = isPublicA();
      if (auth === null) return;
      if (publicA && auth === true)  publicA = false
      if (publicA || (auth === true && accountOverride > 0))
      {   if (isEmpty(headerObj) )
             dispatch(getAccountObject(accountOverride))
          else
             setHeading(getBannerText(headingPiece, headerObj))
      }
      if (accountOverride === 0 && !isEmpty(user) && !isEmpty(user.email))
          setHeading(getBannerText(headingPiece, user))
          // eslint-disable-next-line
   }, [auth, user, headerObj])

   return heading;
}




