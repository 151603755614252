//https://www.valvers.com/open-software/webstacks/using-reactjs-and-bulma/
import axios from 'axios'
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Container } from 'react-bulma-components';
import Header from './header/Header'
import Feeter from './footer/Feeter'
import Privacy from './privacy'
import TOS from './tos'
//import Markup from './Markup'
import Landing from './Landing'
import AllPages from './loggedIn/AllPages';
import SharedView from './loggedIn/shared/SharedView';
import DonorForm from './donate/DonorForm';
import DeletePolicy from './deletePolicy';
import ContactForm from './contact/ContactForm';
import BugForm from './contact/BugForm';
import SubmitPhoto from './contact/SubmitPhoto';
import AccountForm from './account/AccountForm';
import MagicLink from './account/LinkForm';
import InviteForm from './loggedIn/invite/invite'
import StripeContainer from './stripe/StripeContainer';
import FAQ from './faq/faq'
import LinkLogin from './loggedIn/linkLogin'
import AccountLogin from './loggedIn/accountLogin'
import InviteLogin from './loggedIn/inviteLogin'
import HomeEdit from './loggedIn/homePage/HomeEdit'
import HomeSettings from './loggedIn/homePage/HomeSettings'
import HomeView from './loggedIn/homePage/HomeView'
import WallNewEntry from './loggedIn/wall/WallNewEntry'
import WallEdit from './loggedIn/wall/WallEdit'
import WallView from './loggedIn/wall/WallView'
import WallSettings from './loggedIn/wall/WallSettings'
import DiarySettings from './loggedIn/diary/DiarySettings'
import DiarySummaryChangePage from './loggedIn/diary/SummaryChangePage'
import DiarySummaryShowPage from './loggedIn/diary/SummaryShowPage'
import DiaryViewPage from './loggedIn/diary/DiaryViewPage'
import DiaryTOCPage from './loggedIn/diary/DiaryTOCPage'
import DiaryNewPage from './loggedIn/diary/DiaryNewPage'
import DiaryEntryEditPage from './loggedIn/diary/DiaryEntryEditPage'
import DiaryEntryViewPage from './loggedIn/diary/DiaryEntryViewPage'
import DiaryEntrySettings from './loggedIn/diary/DiaryEntrySettings'
import CarouselNewPage from './loggedIn/carousel/CarouselNewPage'
import VideoCarouselNewPage from './loggedIn/carousel/VideoCarouselNewPage'
import CarouselReorderPage from './loggedIn/carousel/CarouselReorderPage'
import CarouselViewPage from './loggedIn/carousel/CarouselViewPage'
import CarouselEditPage from './loggedIn/carousel/CarouselEditPage'
import CarouselSettingsPage from './loggedIn/carousel/CarouselSettingsPage'
import SharedSearch from './loggedIn/shared/SharedSearch'
import SharedLastSearch from './loggedIn/shared/SharedLastSearch'
import Following from './loggedIn/shared/Following'
import Followers from './loggedIn/shared/Followers'
import EditAccount from './loggedIn/admin/EditAccount'
import Library from './resources/Library'
import Blog from './blog'
import { Handle401, DonateSuccess, DonateFailed, AccountSuccess, AccountFailed, InviteSent, LinkSent, ContactFormSubmit, BugFormSubmit, PhotoSubmit, AuthFailed } from './Response'
import AuthFailedLink from './account/authFailed'
import * as actions from '../actions' //Assign all actions to object actions
import 'bulma/css/bulma.min.css'
import "./App.css"  //**MUST BE LAST**

/*
FOR DEBUGGING
axios.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})

axios.interceptors.response.use(response => {
  console.log('Response:', JSON.stringify(response, null, 2))
  return response
})
*/
//Session expiration
axios.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response.status === 401) {
     window.location.replace("/login/expired");
  }
  return Promise.reject(error);
});

class App extends Component {

   constructor(props)
   {   super(props);
       this.state = {    }
   }


   componentDidMount() //when component starts up
   {  const { dispatch } = this.props;
      dispatch(actions.fetchUser());
   }

/*Dynamic route - different routes and pages (including sidebars)
   <Route path="/share/:account_override/all">
   <Route path="/share/:account_override/home">
   <Route path="/share/:account_override/wall">
   <Route path="/share/:account_override/carrousel">
   <Route path="/share/:account_override/diary">
   <Route path="/share/:account_override/diary/:diary_instance">
FUTURE
   <Route path="/share/:account_override/timeline">
   <Route path="/share/:account_override/scrapbook">
*/

   render() {

     return (
       <>
       {/*eslint-disable-next-line*/}
       <a name="top" id="top" href="/" aria-label="hidden link to top of page" ></a>
       <BrowserRouter forceRefresh={false}>
       {/*<Container fluid> makes it full-width*/}
       <Container fluid style={{minHeight: "calc(100vh - 82px)", paddingBottom: "82px", flex: "1 1 auto"}}>
          <Header />  {/*always shows on the screen - no route*/}
          <Route exact path='/' component={Landing} />
          {/*<Route exact path='/markup' component={Markup} />*/}
          <Route exact path='/home' component={Landing} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/tos' component={TOS} />
          <Route exact path='/donate' component={DonorForm} />
          <Route exact path='/blog' component={Blog} />
          <Route path='/blog/:entryid' component={Blog} />
          <Route exact path='/account' component={AccountForm} />
          <Route exact path='/browse' component={SharedView} />
          <Route exact path='/browse1' component={SharedView} />
          <Route exact path='/community' component={SharedView} />
          <Route path='/policy/deletion' component={DeletePolicy} />
          <Route exact path='/unauthorized' component={AuthFailed} />
          <Route path='/unauthorized/link' component={AuthFailedLink} />
          <Route path='/donate/success' component={DonateSuccess} />
          <Route path='/login/expired' component={Handle401} />
          <Route path='/donate/failed' component={DonateFailed} />
          <Route path='/account/success' component={AccountSuccess} />
          <Route path='/account/failed' component={AccountFailed} />
          <Route path='/stripe/donate' component={StripeContainer} />
          <Route path='/faq' component={FAQ} />
          <Route exact path='/contact' component={ContactForm} />
          <Route exact path='/home/photo' component={SubmitPhoto} />
          <Route exact path='/requests' component={BugForm} />
          <Route path='/contact/submit' component={ContactFormSubmit} />
          <Route path='/bugs/submit' component={BugFormSubmit} />
          <Route path='/photo/submit' component={PhotoSubmit} />
          <Route exact path='/accountlogin' component={AccountLogin} />
          <Route exact path='/invitelogin' component={InviteLogin} />
          <Route exact path='/linklogin' component={LinkLogin} />
          <Route exact path='/invite' component={InviteForm} />
          <Route exact path='/invite/sent' component={InviteSent} />
          <Route path='/login/link' component={MagicLink} />
          <Route path='/login/linksent' component={LinkSent} />
          <Route path='/auth/shared/search' component={SharedSearch} />
          <Route path='/auth/shared/last_search' component={SharedLastSearch} />
          <Route path='/auth/shared/following' component={Following} />
          <Route path='/auth/shared/followers' component={Followers} />
          <Route path='/auth/admin' component={EditAccount} />
          <Route path='/auth/home/edit' component={HomeEdit} />
          <Route path='/auth/home/settings' component={HomeSettings} />
          <Route path='/auth/home/view' component={HomeView} />
          <Route exact path='/auth/diary/toc' component={DiaryTOCPage} />
          <Route path='/auth/diary/toc/toc' component={DiaryTOCPage} />
          <Route path='/allpages/diary/toc/toc' component={DiaryViewPage} />
          <Route path='/share/:accountOverride/diary/toc/toc' component={DiaryViewPage} />
          <Route path='/eshare/:accountOverride/diary/toc/toc' component={DiaryViewPage} />
          <Route path='/auth/diary/new' component={DiaryNewPage} />
          <Route path='/auth/diary/settings' component={DiarySettings} />
          <Route path='/auth/diary/:diary_instance/edit' component={DiaryEntryEditPage} />
          <Route path='/auth/diary/:diary_instance/summary' component={DiarySummaryChangePage} />
          <Route path='/auth/diary/:diary_instance/vsummary' component={DiarySummaryShowPage} />
          <Route path='/auth/diary/:diary_instance/settings' component={DiaryEntrySettings} />
          <Route path='/auth/diary/:diary_instance/eview' component={DiaryEntryViewPage} />
          <Route path='/auth/diary/:diary_instance/view' component={DiaryEntryViewPage} />
          <Route path='/auth/diary/:diary_instance/see' component={DiaryEntryViewPage} />
          <Route path='/auth/diary/:diary_instance/tview' component={DiaryEntryViewPage} />
          <Route exact path='/allpages' component={AllPages} />
          <Route exact path='/share/:accountOverride' component={AllPages} />
          <Route path='/allpages/home' component={HomeView} />
          <Route path='/share/:accountOverride/home' component={HomeView} />
          <Route path='/eshare/:accountOverride/home' component={HomeView} />
          <Route exact path='/allpages/diary' component={DiaryViewPage} />
          <Route path='/auth/diary/view' component={DiaryViewPage} />
          <Route exact path='/share/:accountOverride/diary' component={DiaryViewPage} />
          <Route exact path='/eshare/:accountOverride/diary' component={DiaryViewPage} />
          <Route path='/allpages/diary/:diary_instance/see' component={DiaryEntryViewPage} />
          <Route path='/share/:accountOverride/diary/:diary_instance/see' component={DiaryEntryViewPage} />
          <Route path='/eshare/:accountOverride/diary/:diary_instance/see' component={DiaryEntryViewPage} />
          <Route path='/allpages/diary/:diary_instance/summary' component={DiarySummaryShowPage} />
          <Route path='/allpages/diary/:diary_instance/view' component={DiaryEntryViewPage} />
          <Route path='/share/:accountOverride/diary/:diary_instance/view' component={DiaryEntryViewPage} />
          <Route path='/eshare/:accountOverride/diary/:diary_instance/view' component={DiaryEntryViewPage} />
          <Route path='/share/:accountOverride/diary/:diary_instance/summary' component={DiarySummaryShowPage} />
          <Route path='/share/:accountOverride/diary/:diary_instance/vsummary' component={DiarySummaryShowPage} />
          <Route path='/eshare/:accountOverride/diary/:diary_instance/summary' component={DiarySummaryShowPage} />
          <Route path='/eshare/:accountOverride/diary/:diary_instance/vsummary' component={DiarySummaryShowPage} />
          <Route path='/allpages/all' component={AllPages} />
          <Route path='/share/:accountOverride/all' component={AllPages} />
          <Route path='/auth/wall/new' component={WallNewEntry} />
          <Route path='/auth/wall/edit' component={WallEdit} />
          <Route path='/auth/wall/view' component={WallView} />
          <Route path='/auth/wall/settings' component={WallSettings} />
          <Route path='/auth/carousel/new' component={CarouselNewPage} />
          <Route path='/auth/vcarousel/new' component={VideoCarouselNewPage} />
          <Route path='/auth/carousel/reorder' component={CarouselReorderPage} />
          <Route path='/auth/vcarousel/reorder' component={CarouselReorderPage} />
          <Route path='/auth/carousel/edit' component={CarouselEditPage} />
          <Route path='/auth/vcarousel/edit' component={CarouselEditPage} />
          <Route path='/auth/carousel/view' component={CarouselViewPage} />
          <Route path='/auth/vcarousel/view' component={CarouselViewPage} />
          <Route path='/auth/carousel/settings' component={CarouselSettingsPage} />
          <Route path='/auth/vcarousel/settings' component={CarouselSettingsPage} />
          <Route path='/allpages/carousel' component={CarouselViewPage} />
          <Route path='/allpages/vcarousel' component={CarouselViewPage} />
          <Route path='/share/:accountOverride/carousel' component={CarouselViewPage} />
          <Route path='/share/:accountOverride/vcarousel' component={CarouselViewPage} />
          <Route path='/eshare/:accountOverride/carousel' component={CarouselViewPage} />
          <Route path='/eshare/:accountOverride/vcarousel' component={CarouselViewPage} />
          <Route path='/allpages/wall' component={WallView} />
          <Route path='/share/:accountOverride/wall' component={WallView} />
          <Route path='/eshare/:accountOverride/wall' component={WallView} />
          <Route path='/library' component={Library} />
       </Container>
       <Route path="/" render={() => <Feeter />}></Route>
     </BrowserRouter>
     </>
      )
  }
}

//export connect(mapStateToProps, mapDispatchToProps)(App);
//actions is an array of functions.  But if key and value have the same name,
//it only needs to be there once.
export default connect()(App); //This makes dispatch available as a prop
