//**Editor Component for Home Page

import React, { Suspense, useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import axios from 'axios';
//import JoditEditor from '../../common/editor/JoditEditor2'
const JoditEditor = React.lazy(()=>import('../../common/editor/JoditEditor2'))
import { updateHomePage } from '../../../actions/homePage'
import usePagePerms from '../hooks/usePagePerms'
import { augmentApi } from '../functions/location_fcns'
import { processDeletedImages } from '../functions/editor_fcns'
import {isMobile} from 'react-device-detect';

const HomePageEditor = (props) => {

const dispatch = useDispatch();
//State
const [ pageLoadDone, setPageLoadDone ] = useState(false) 
const [ currentContent, setCurrentContent ] = useState("");
//Redux State
const editorContent = useSelector(state => state.homePageContent); //Get from Redux Store (if there)
const auth = useSelector(state => state.auth); //Logged in?
//Hooks
const [ , canEditHome, , isResolvedHome ] = usePagePerms('home', 'singleton', false)

  //Automatically save using debouncing
const persistContent = () => { if (editorContent !== currentContent) {  processDeletedImages(editorContent, currentContent); dispatch(updateHomePage(currentContent)); console.log("SAVE"); } }
               // eslint-disable-next-line react-hooks/exhaustive-deps
const delayedPersist = useCallback(debounce(persistContent, 350), [currentContent])

useEffect( () => {
   const loadAndSyncData = async () => {
      if (auth && isEmpty(editorContent) && pageLoadDone === false) {
         let res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'home', pageInstance: 'none' }})
         let content = res.data.pageData;
         if (!isEmpty(content))
            content = JSON.parse(content);  
         if (isMobile) {  //Make images unselectable to avoid moving and dragging images
           content = content.replaceAll('pointer-events:auto; user-select:auto;', 'pointer-events: none; user-select: none;');
           content = content.replaceAll('pointer-events: auto; user-select: auto;', 'pointer-events: none; user-select: none;');
         }
         else  {  //disable making the images unselectable
           content = content.replaceAll('pointer-events:none; user-select:none;', 'pointer-events: auto; user-select: auto;');
           content = content.replaceAll('pointer-events: none; user-select: none;', 'pointer-events: auto; user-select: auto;');
         }

         setCurrentContent(content); dispatch(updateHomePage(content, false)); 
         setPageLoadDone(true);
      }
      else if (auth && pageLoadDone === false)
      {  setCurrentContent(editorContent); 
         setPageLoadDone(true)
      }
      else if (auth)
      {  delayedPersist(); }
   }
   loadAndSyncData();
   return () =>  delayedPersist.cancel() 
            // eslint-disable-next-line react-hooks/exhaustive-deps
}, [auth, currentContent, delayedPersist ])

if (auth === false) {
      return <div>Please Login to continue...</div>
   }
if (isResolvedHome && !canEditHome) {
      return <div>You are not authorized to edit this page</div>
}
if (!pageLoadDone || !isResolvedHome) {
      return <div>Loading...</div>
   }
return (
    <div style={{position: "relative", overflowY: "visible", zIndex: "11"}}>
    <Suspense fallback={<span>Loading...</span>}>
    <JoditEditor style={{margin: "3px"}}
                 onChange={content=>{setCurrentContent(content); }} 
                 startingContent={editorContent}
                 placeholder='Write about your sibling relationship.  <p>Capture the spirit which endures and lives on through you.</p>'
                 imagePrompt='Upload Image for Home Page'
                 videoPrompt='Upload Video for Home Page'
    />
    </Suspense>
    </div>
)
};


export default HomePageEditor;
