import { GET_SHARED_PAGE_TYPES } from '../actions/types'

const initialState = []
export default function pageTypesReducer (state=initialState, action={} )
{
  switch(action.type) {
    case GET_SHARED_PAGE_TYPES:   //pageType array
        return action.payload.slice()
    default:
        return state;
  }
};

