import React, { useRef } from 'react'
import { useForm, Controller } from "react-hook-form";
import { Button, Box, Columns, Form, Section, Heading } from "react-bulma-components";
import { checkName } from '../../common/formFunctions'
import ReactDatePicker from 'react-date-picker'

  //**Ensure date is not converted to local time which could change the day 
const getDate = dstr => {
  const pieces = dstr.split('-')
  return new Date(pieces[0], parseInt(pieces[1]-1), parseInt(pieces[2]))
}
const DatePicker = (date, onChange) => { 
    return (
        <ReactDatePicker
            className="input"
            showLeadingZeros={true}
            isOpen={false}
            openCalendarOnFocus={false}
            clearIcon={null}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            disableCalendar={true}
            onChange={onChange}
            maxDate={new Date()}
            minDate={new Date(1925, 0, 1)}
        />
    )
};

const DepartedPanel = ({first, last, dob, dod, onEnd}) => {  

  const birthDate = useRef(getDate(dob))
  const deathDate = useRef(getDate(dod))

  const checkBDate = d  => {
      birthDate.current = d
      if (deathDate.current !== null && d.getTime() >= deathDate.current.getTime())  return false
      return true
  }
  const checkDDate = d  => {
      deathDate.current = d
      if (birthDate.current !== null && d.getTime() <= birthDate.current.getTime())  return false
      return true
  }

//{departed_first_name":"Robin","departed_last_name":"Ralston",Audree, "departed_dob":"6/17/1953","departed_dod":"11/17/1967"}

  const { handleSubmit, errors, control } = useForm();
  const onSubmit = async data => {
      onEnd(data)
  }

  return (
    <>
    <Section style={{padding:0}}>
      <Box style={{padding:"1% 2%"}}>
 
      <Heading subtitle size={4}>Edit Departed Sibling Details</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
          <Columns>
          <Columns.Column className="is-one-half">
	  <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
                <Controller
                    as={Form.Input}
                   name="departFirst"
                    placeholder="Departed Sibling's First Name"
                    defaultValue={first}
                    control={control}
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc }
                          }}

                />
            <Form.Help textColor="danger">
                  {errors.departFirst && errors.departFirst.type === "required" && "Departed sibling's first name is required"}
                  {errors.departFirst && errors.departFirst.type === "validate" && "Name must contain between 2-31 valid letters."}
            </Form.Help>
            </Form.Control>
          </Form.Field>
          </Columns.Column>

          <Columns.Column className="is-one-half">
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control>
             <Controller
                    as={Form.Input}
                    name="departLast"
                    control={control}
                    defaultValue={last}
                    placeholder="Departed Sibling's Last Name"
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.departLast && errors.departLast.type === "required" && "Departed sibling's last name is required"}
                  {errors.departLast && errors.departLast.type === "validate" && "Name must contain between 2-31 valid letters."} 
            </Form.Help>
          </Form.Field>
          </Columns.Column>
          </Columns>

          <Columns>
          <Columns.Column className="is-one-half">
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control>
               <Controller
                    as={DatePicker(getDate(dob), date => date)}
                    onChange={date => date}
                    type="date"
                    style={{width:'12em'}}
                    name="departDob"
                    control={control}
                    defaultValue={getDate(dob)}
                    placeholder="Departed Sibling's Birth Date"
                    rules={{ required: true,
                             validate: dd => { const rc = checkBDate(dd); return rc }
                          }}
               />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.departDob && errors.departDob.type === "required" && "Sibling's birth date is required"}
                  {errors.departDob && errors.departDob.type === "validate" && "Birth date must come before death date"}
            </Form.Help>
          </Form.Field>

          </Columns.Column>
          <Columns.Column className="is-one-half">
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Death</Form.Label>
            <Form.Control>
                <Controller
                    as={DatePicker(getDate(dod), date=>date)}
                    onChange={date => date}
                    type="date"
                    style={{width:'12em'}}
                    name="departDod"
                    control={control}
                    defaultValue={getDate(dod)}
                    placeholder="Departed Sibling's Death Date"
                    rules={{ required: true, 
                             validate: dd => { const rc = checkDDate(dd); return rc }
                          }}
                  />
            <Form.Help textColor="danger">
                  {errors.departDod && errors.departDod.type === "required" && "Sibling's death date is required"}
                  {errors.departDod && errors.departDod.type === "validate" && "Death date must come after birth date" }
            </Form.Help>
            </Form.Control>
          </Form.Field>
          </Columns.Column>
          </Columns>
          <Form.Field>
            <Form.Control>
              <center><Button fullwidth={false}>Save Changes</Button></center>
            </Form.Control>
          </Form.Field>
       </form>
       </Box>
     </Section>
     </>
  );
}

export default DepartedPanel;
