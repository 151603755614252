import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Container, Form, Section, Button, Box, Heading } from "react-bulma-components";
import { updateDonorInfo } from "../../actions/donor";
import { checkName } from '../common/formFunctions'

const DonorForm = (props) => {  

  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, errors, control } = useForm();
  const onSubmit = data => {
    //const { FirstName, LastName, Email, Zip, Amount } = data;
    console.log(data);
       //Dispatch the action
    dispatch(updateDonorInfo(data)) 
    history.push('/stripe/donate/');
  };

  return (
    <Container>
    <Section>
      <Box>
        <Heading subtitle size={4}>Thank you for donating to SibsForever.org</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
              <Controller
                as={Form.Input}
                name="FirstName"
                control={control}
                defaultValue=""
                placeholder="First Name"
                maxLength={24}
                rules={{ required: true,
                          validate: name => { const rc = checkName(name); return rc }
                      }}
              />
            </Form.Control>
            <Form.Help textColor="danger">
              {errors.FirstName && errors.FirstName.type === "required" && "First name is required"}
              {errors.FirstName && errors.FirstName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Label>Last Name</Form.Label>
            <Form.Control>
              <Controller
                as={Form.Input}
                name="LastName"
                control={control}
                defaultValue=""
                placeholder="Last Name"
                maxLength={24}
                rules={{ required: true,
                         validate: name => { const rc = checkName(name); return rc }
                      }}
              />
            </Form.Control>
            <Form.Help textColor="danger">
              {errors.LastName && errors.LastName.type === "required" && "Last name is required"}
              {errors.LastName && errors.LastName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Label>Email Address</Form.Label>
            <Form.Control>
              <Controller
                as={Form.Input}
                name="Email"
                control={control}
                defaultValue=""
                placeholder="Email"
                type="email"
                rules={{ required: true }}
              />
            </Form.Control>
            <Form.Help textColor="danger">
              {errors.Email && "Email address is required"}
            </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Label>Donor's Zip Code</Form.Label>
            <Form.Control>
              <Controller
                as={Form.Input}
                name="Zip"
                control={control}
                defaultValue=""
                placeholder="Zip Code"
                pattern="[0-9]{5}"
                maxLength={5}
                minLength={5}
                rules={{ required: true }}
              />
            </Form.Control>
            <Form.Help textColor="danger">
              {errors.Zip && "Zip Code is required and must contain five numeric digits"}
            </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Label>Amount (USD)</Form.Label>
            <Form.Control>
              <Controller
                as={Form.Input}
                name="Amount"
                control={control}
                defaultValue=""
                placeholder="100.00"
                type="number"
                min="0.00"
                max="10000.00"
                step="0.01"
                rules={{ required: true, pattern: '^[0-9]+(.[0-9]{1,2})?$' }}
              />
            </Form.Control>
            <Form.Help textColor="danger">
              {errors.Amount && "Amount is required"}
            </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <Button fullwidth={false}>Continue with Donation</Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </Section>
    </Container>
  );
}

export default DonorForm;
