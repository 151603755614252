import React, { useState, useRef } from 'react'
import "./stripe.css"
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Container, Form, Section, Button, Box, Heading } from "react-bulma-components";
import ReCAPTCHA from 'react-google-recaptcha'
import Loader from "react-spinners/BeatLoader";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements'
import axios from 'axios';

/*
   https://github.com/stripe-archive/react-stripe-elements/tree/v1.3.0#using-an-existing-stripe-instance
   Getting a warning: controller-434c05ca4450da95572242b154355d4d.js:1 Unrecognized Stripe() parameter: stripe is not a recognized parameter.
*/
const StripeCheckoutForm = (props) => { 

  const { stripe } = props;
  const history = useHistory();
  const [ captcha, setCaptcha ] = useState(""); 
  const [loading, setLoading] = useState(false);
  const recaptcha = useRef();

     //Get Donor info from Redux Store
  const donor  = useSelector( state => state.donor )

    //**Validate deconstruction can occur 
  if (donor === null)  {
     window.open('/donate/', '_self');
 }

  const { FirstName, LastName, Amount, Email, Zip } = donor;
  let iAmount = Amount*100;  iAmount = iAmount.toFixed(0);

  const { handleSubmit } = useForm();
  const onSubmit = async data => {
    setLoading(true);  //Put on spinner
    const { token } = await stripe.createToken({
        type: 'card',
        name: `${FirstName} ${LastName}`,
        address_zip: Zip
      })
    console.log('Received Stripe Token', token);

    let pageName = '/donate/failed';
    if (token)  // Valid token - send to the server
    {   const { status } = await axios.post('/api/donate', {  
          amount:  iAmount,
          source: token.id,
          firstName: FirstName,
          lastName: LastName,
          zip: Zip,
          receipt_email: Email.toLowerCase(),
          captcha: captcha
        },
        { validateStatus: (status) => {
           return true;
        }})
        if (status === 200) {
           console.log("Charge successful");
           pageName = '/donate/success';
         }
     }
     setLoading(false);  //disable spinner
      //**This is an async call - needs to be on the end
     history.push(pageName);
  }

  return (
<Container>
<Section>
    <Box>
        <Heading subtitle size={4}>Charging ${Amount}</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
           <Form.Label> Card details </Form.Label>
           <CardNumberElement className="StripeElement" onReady={(el) => el.focus()} />
           <Form.Control />
        </Form.Field>
        <Form.Field>
          <Form.Label>Expiration date</Form.Label>
          <CardExpiryElement className="StripeElement" />
        </Form.Field>
        <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>
        <Form.Field>
           <Form.Label>CVC</Form.Label>
           <CardCVCElement className="StripeElement" />
        </Form.Field>
         <Form.Field>
        <ReCAPTCHA
            ref={() => recaptcha }
            sitekey="6LcR3V0aAAAAAM6uO6Wa8X4P4XV56cmdTZ81PvBj"
            onChange={value => setCaptcha(value)}
        />
        </Form.Field>
         <Form.Field>
           <Form.Control>
             <Button  disabled={!captcha} fullwidth={false}>Donate</Button>
            </Form.Control>
          </Form.Field>
        </form>
    </Box>
</Section>
</Container>

  )
} 

export default injectStripe(StripeCheckoutForm) 
