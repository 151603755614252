//**This is the panel that displays the HomePage for viewing

import React, { useRef, useEffect, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content'  //This executes scripts
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty'
import axios from 'axios';
import { Heading } from 'react-bulma-components';
import CommentButtons from '../../common/comments/CommentButtons'
import { updateHomePage } from '../../../actions/homePage'
import usePagePerms from '../hooks/usePagePerms'
import useGetHeader from '../hooks/useGetHeader'
import '../../editor.css';
import { isPublicA, augmentApi } from '../functions/location_fcns'
import { shimmy, applyShim, insert_videos } from '../functions/editor_fcns'

const HeadingPiece = "Home Page"

const HomeViewPanel = (props) => {

const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

const publicA = useRef(isPublicA())

const auth = useSelector(state => state.auth); //Logged in?
const user = useSelector(state => state.user);
const [ , , canViewHome, isResolvedHome ] = usePagePerms('home', 'singleton', false)

const dispatch = useDispatch();
const [ pageLoadDone,  setPageLoadDone ] = useState(false);
const [ numComments,  setNumComments ] = useState(0);
const homePageContent = useSelector(state => state.homePageContent); //Get from Redux Store (if there)
const headerObj = useSelector(state => state.accountObject);

const heading = useGetHeader(HeadingPiece)

useEffect( () => {
   const loadData = async () => {

      if ((auth || publicA.current) && isEmpty(homePageContent) && (pageLoadDone === false)) {
         let res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'home', pageInstance: 'singleton' }})
         let content = res.data.pageData;
         if (!isEmpty(content))
            content = JSON.parse(content);  
         else
            content = "Empty home page"
         setNumComments(res.data.numComments);
         dispatch(updateHomePage(content, false));
         setPageLoadDone(true);
      }
      else if (auth || publicA.current)
        setPageLoadDone(true); 
   }
   loadData();
     // eslint-disable-next-line
}, [auth, user, headerObj])

if (auth === false && !publicA.current) {
      return <div>Please Login to continue...</div>
   }
if (!pageLoadDone || isEmpty(homePageContent) || !isResolvedHome)
      return <div>Loading...</div>

if (isResolvedHome && !canViewHome)
      return <div>You are not authorized to view this page</div>

return (
<>
<Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
<div style={{backgroundColor: 'white', maxWidth: "1022px"}}>
<CommentButtons pageType='home' pageInstance='singleton' numComments={numComments}/>
<InnerHTML style={{padding:'8px 8px', margin:0, textAlign:"left"}} className="jodit-wysiwyg" html={shimmy(insert_videos(homePageContent))}/>
</div>
</>
)
}

export default HomeViewPanel;
