//Reorder
import './slideshow.css'
const imgWithClick = { cursor: "pointer" };

const Photo = ({ index, onClick, photo, margin, direction, top, left }) => {
  const imgStyle = { margin: margin };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };

    //**Disable drag and R) context menu for all images
  return (
    <div className="container" key={photo.key}>
    <img
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      onClick={onClick ? handleClick : null}
      onDragStart={e => e.preventDefault()}
      onContextMenu={e => e.preventDefault()}
      alt={photo.caption}
      title={photo.caption}
    />
    {photo.islocked === "true"?<i className="fa fa-lock icon" style={{fontSize: "1.3em", position: "absolute", top: "2px", left: "2px", color: "#fada5e"}}></i>:""}
   </div>
  );
};

export default Photo;
