import React from 'react'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import SettingsPage from './SettingsPage'
import { Button } from 'react-bulma-components';
import useLockBodyScroll from '../hooks/useLockBodyScroll'

const SettingsPopup = ({ initialState, setClose, pageType, pageInstance } ) => {
  
  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
      >
        <SettingsPage pageType={pageType} pageInstance={pageInstance} />
        <div style={{padding: "1%"}} />
        <center><Button size="small" onClick={()=>setClose()}>Done</Button></center>

      </Modal>
    </>
  );
};

export default SettingsPopup
