import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import Textarea from 'react-textarea-autosize'
import '../../break.css'
import { Button } from 'react-bulma-components';
import { isPublicA, getAccountOverride } from '../../loggedIn/functions/location_fcns'
import { postNewComment } from '../../../actions/comment'
import useLockBodyScroll from '../../loggedIn/hooks/useLockBodyScroll'
/*
 author_id     | integer                  |           | not null | 
 page_name     | text                     |           | not null | 
 page_instance | text                     |           | not null | 
 date_time     | bigint                   |           | not null | 
 comment       | text                     |           | not null | 
 parent_id 
*/

const AddCommentPopup = ({ initialState, setCloseData, setClose, pageType, pageInstance } ) => {
  
  const accountOverride = getAccountOverride()
  const publicA = isPublicA()
  const textareaRef = useRef()
  const commentValue = useRef("")
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth); //Logged in?
  const cc = useSelector(state => state.commentCount); 

  const saveComment = async () => 
  {  //console.log("SaveComment", pageType, pageInstance); 
     const payload = {
        page_name: pageType,
        page_instance: pageInstance,
        date_time: Date.now(),
        comment: commentValue.current.trim(),
        parent_id: 0
    }
    if (!isEmpty(commentValue.current.trim())) {
       await axios.post('/api/new_comment', { details: payload, accountOverride });
       dispatch(postNewComment(cc))
    }
  }

  useLockBodyScroll(initialState);

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        center
      >
        <div><h2><b>Add a Comment</b></h2></div>
        <Textarea
           onChange={e => commentValue.current = e.target.value}
           placeholder={publicA&&auth===false?"Disabled for the example SibSite":"Enter comment here ..."}
           ref={textareaRef}
           minRows={6}
           maxRows={16}
           className="textarea"
           style={{width: "min(400px, 75vw)"}}
           maxLength={3000}
           readOnly={publicA&&auth===false}
        />
       <div className="break"></div>
       <Button style={{backgroundColor: '#B0B0B0', margin: "0 0"}} size="small" fullwidth={false} onClick={()=>setClose()}>Cancel</Button>
       <Button style={{margin: "0 0"}} size="small" className="is-pulled-right" fullwidth={false} onClick={()=> {saveComment(); setCloseData({answer: true})}}>Add</Button>
      </Modal>
    </>
  );
};

export default AddCommentPopup
