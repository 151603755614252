/*** Methods related to Local Storage
***/
export const BROWSE_QUERY = 'BrowseQuery'
export const NEW_WALL_ENTRY_KEY = 'WallKey'
export const NEW_WALL_ENTRY_RECORD = 'WallRecord'

export function getLocalStorage(key) {
  const stored = localStorage.getItem(key);
  if (stored == null || typeof stored == 'undefined' || stored === 'undefined' || stored === "") {
    return "";
  }

  let ret = ""
  try {
     ret = JSON.parse(stored);
  } catch(err) {
    localStorage.removeItem(key)
    ret = ""
  }
  return ret
}

export function clearLocalStorage(keys)
{   
    for (let i=0; i < keys.length; i++)
       localStorage.removeItem(keys[i])
}

export function updateLocalStorage(key, value)
{   if (value !== null && typeof value !== 'undefined')   
        localStorage.setItem(key, JSON.stringify(value))  
    else
        clearLocalStorage([ key ])
}

