import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import { Container, Form, Section, Button, Box, Heading } from "react-bulma-components";
import Loader from "react-spinners/BeatLoader";
import axios from 'axios';
import { uniqueEmail, checkName } from '../../common/formFunctions'
import { InvitingUsersPageHelp } from '../common/help/InviteHelp'

const InviteForm = (props) => {  

     //**State
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);

  const history = useHistory();
  const user = useSelector(state => state.user);
  const auth = useSelector(state => state.auth);
  const snip = isEmpty(user) || isEmpty(user.email)?"":`${user.departed_first_name} ${user.departed_last_name} ( ${user.departed_dob} - ${user.departed_dod}) and ${user.sibling_first_name}`
  const { handleSubmit, errors, control } = useForm();

  useEffect( () => {
   if (!isEmpty(user) && !isEmpty(user.email) && !init)
      setInit(true)  //eslint-disable-next-line 
  }, [user])

  const onSubmit = async data => {
    setLoading(true);  //Put on spinner

    const { FirstName, LastName, Email, Role } = data;
    let pageName = '/invite/sent';
    const { status } = await axios.post('/api/invite', {
          firstName: FirstName,
          lastName: LastName,
          email: Email, 
          role: Role,
          account_id: user.account_id,
          accountName: snip,
        },
        { validateStatus: (status) => {
           return true; 
        }})
        if (status === 200) {
           console.log("Invite successful");
        }
       setLoading(false);  //disable spinner
       history.push(pageName);
  };

  const heading = isEmpty(user) || isEmpty(user.email)?"Send Invitation":`Send Invitation to join Sibsforever account for ${snip}`

  if (!init)
    return <div>Loading</div>

  if (!auth)
      return <div>Please Login to continue...</div>

  if (user.role !== 'sibling' && user.role !== 'admin' && user.role !== 'owner')
      return <div>You are not authorized to view this page</div>

  const help = <InvitingUsersPageHelp />

  return (
    <Container>
    <Section>
      <Box>
        <div style={{float: "right"}}>{help}</div>
        <Heading subtitle size={4}>{heading}</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="FirstName"
                    placeholder="First Name"
                    defaultValue=""
                    control={control}
                    maxLength={24}
                    rules={{ required: true,
                              validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
             </Form.Control>
             <Form.Help textColor="danger">
                  {errors.FirstName && errors.FirstName.type === "required" && "First name is required"}
                  {errors.FirstName && errors.FirstName.type === "validate" && "Name must contain between 2-31 valid letters." }
             </Form.Help>
          </Form.Field>
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="LastName"
                    control={control}
                    defaultValue=""
                    placeholder="Last Name"
                    maxLength={24}
                    rules={{ required: true,
                              validate: name => { const rc = checkName(name); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.LastName && errors.LastName.type === "required" && "Last name is required"}
                  {errors.LastName && errors.LastName.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
          </Form.Field>

          <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>

          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Email Address</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="Email"
                    control={control}
                    defaultValue=""
                    placeholder="Email"
                    type="email"
                    rules={{ required: true, 
                             validate: async (value) => { const rc = await uniqueEmail(value); return rc }
                          }}
                  />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.Email && errors.Email.type === "required" && "Email address is required" }
                  {errors.Email && errors.Email.type === "validate" && "Email address is already being used" }
            </Form.Help>
          </Form.Field>
          <Form.Field style={{marginLeft:"10px"}}>
           <Form.Label>Role</Form.Label>
           <Form.Control>
                <Controller
                  as={
                   <Form.Select>
                     <option value="">Select dropdown</option>
                     <option value="admin">Admin</option>
                     <option value="editor">Editor</option>
                     <option value="guest">Guest</option>
                   </Form.Select>
                   }
                  style={{marginBottom:"8px"}}
                  control={control}
                  name="Role"
                  defaultValue="editor"
                  rules={{ required: true }}
                />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.Role && "Security setting is required"}
            </Form.Help>
          </Form.Field>

          <Form.Field>
            <Form.Control>
              <Button fullwidth={false}>Send Invitation</Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </Section>
    </Container>
  );
}

export default InviteForm;
