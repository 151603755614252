//**This is the Diary Entry Viewer

import React from 'react';
import DiaryEntrySidebar from './DiaryEntrySidebar'
import DiaryEntryViewPanel from './DiaryEntryViewPanel'
import { Container, Section } from 'react-bulma-components';
import '../sidebar.css'

const SummaryShowPage = (props) => {

   const DiaryBreadcrumb = 
     <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
     <ul>
        <li><a href="/auth/diary/toc">&nbsp;Diary Table of Contents</a></li>
        <li className="is-active"><a href="/auth/diary/edit" aria-current="page">View Diary Entry</a></li>
      </ul>
    </nav>

   return (
     <>
    {DiaryBreadcrumb}
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
        <div className="aside"><DiaryEntrySidebar className="menu"/></div>
     <Container className="column is-9" style={{paddingTop:0}}>
       <DiaryEntryViewPanel isShow={true}/>
     </Container>
     </Section>
     </>
   )
  }

export default SummaryShowPage;

