import { POST_NEW_COMMENT, SEE_COMMENTS } from '../actions/types'

const initialState = 0
export const postCommentReducer = (state=initialState, action={} ) =>
{
  switch(action.type) {
    case POST_NEW_COMMENT:   //commentCount
        return action.payload+1
    default:
        return state;
  }
};

export const seeCommentReducer = (state=initialState, action={} ) =>
{
  switch(action.type) {
    case SEE_COMMENTS:      //seeCommentCount
        return action.payload+1
    default:
        return state;
  }
};

