import isEmpty from 'lodash/isEmpty'
import React from 'react';
import { useSelector } from 'react-redux'
import CarouselSidebar from './CarouselSidebar'
import CarouselGallery from './CarouselGallery'
import { Heading, Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import { getHeaderText, isVideoCarouselPage, getBannerText } from '../functions/location_fcns'
import {isMobile} from 'react-device-detect';

const CarouselReorderPage = (props) => {

   const isVideo = isVideoCarouselPage()
   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText(getHeaderText(), user) 
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   const gallery = isMobile ? <CarouselGallery lightboxEdit={true}/> : <CarouselGallery reorder={true}/>

   return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     <div className="aside"><CarouselSidebar isActive="Reorder" className="menu"/></div>
     <Container className="column is-9"  style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     {gallery}
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default CarouselReorderPage;

