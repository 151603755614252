//Home Page Editor  

import isEmpty from 'lodash/isEmpty'
import React from 'react';
import { useSelector } from 'react-redux'
import HomeSidebar from './HomeSidebar'
import HomePageEditor from './HomePageEditor1'
import { Heading, Container, Section} from 'react-bulma-components';
import '../../overrides.scss'
import '../sidebar.css'
import { getBannerText } from '../functions/location_fcns'

const HomeEdit = (props) => {

   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText("Home Page", user)
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop: "0px"}}>
     <div className="aside"><HomeSidebar isActive="Edit" className="menu"/></div>

     <Container className="column is-9" style={{paddingTop:0}}> 
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <HomePageEditor />
     </Container>
     </Section>
     </>
   )
  }

export default HomeEdit;

