  //**The user object is a join of User + Accounts
import { GET_USER } from '../actions/types'
import { UPDATE_ACCOUNT, UPDATE_ACCOUNT_PICTURE } from '../actions/types'

const initialState = null; //don't know yet if user logged in (isPending)

export default function userReducer (state=initialState, action={})
{
  switch(action.type) {
    case GET_USER:
    case UPDATE_ACCOUNT:
    case UPDATE_ACCOUNT_PICTURE:
        return  { ...state, ...action.payload }
    default:
        return state;
  }
};
