import isEmpty from 'lodash/isEmpty'
import React from 'react';
import { useSelector } from 'react-redux'
import DiaryEntrySidebar from './DiaryEntrySidebar'
import SettingsPage from '../common/SettingsPage'
import { getBannerText, getDiaryInstance } from '../functions/location_fcns'
import { Heading, Container, Section} from 'react-bulma-components';
import '../sidebar.css'

const DiaryEntrySettings = (props) => {

      //Redux
   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText("Journal Entry", user) 
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
     <DiaryEntrySidebar isActive="Settings" className="menu"/>
     <Container className="column is-9" style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <SettingsPage pageType="diary" pageInstance={getDiaryInstance()} sharingCheckbox={true} />
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default DiaryEntrySettings;
