//**This is the panel that displays the HomePage for viewing

import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { Card, Columns, Content } from 'react-bulma-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable'
import CommentButtons from '../../common/comments/CommentButtons'
import { isPublicA } from '../functions/location_fcns'
import { FaLock} from "@react-icons/all-files/fa/FaLock";

import './card.css'

class WallViewEntryCard extends Component {
  constructor(props)
  {   super(props);
      this.state = {
         title: "",
         body:"",
         image:"",
         video: {},  //url1, url2, thumb, poster, posterp, w300, w400, poster300, poster300p, poster400, poster400p
         imageWidth:400,
         date:"",
         pageInstance: props.instance,
         author_first: props.firstName,
         author_last: props.lastName, 
         isPrivate: props.isPrivate,
         pageLoadDone: false
         }
      this.vidRef = null;
      this.videoLoaded = false;
      this.isPlaying=false;
  }

  componentDidMount() 
  {  if (!isEmpty(this.props.instance))  //This is an edit, so go get the page
     {    const loadData = async () => {
            if (this.state.pageLoadDone === false) {

               let res = await axios.get(this.props.url)  //Direct S3 URL
               const ct = res.data 
               const content = (typeof ct != "object") ? JSON.parse(ct) : ct;
               if (!isEmpty(content))
               {   this.setState({date:  content.date});
                   this.setState({title:  content.title });
                   this.setState({body:  content.body});
                   this.setState({image:  content.image});
                   this.setState({video:  isEmpty(content.video)?{}:content.video});
                   this.setState({imageWidth:  content.imageWidth});
               }
               this.setState({"pageLoadDone": true})
            }
         }
        loadData();
     }
  }

  render() {

  const publicA = isPublicA()

  if (this.props.auth === false && !this.props.publicV && !publicA) {
      return <div>Please Login to continue...</div>
   }

     //Don't show empty entries
  if (isEmpty(this.state.title) && isEmpty(this.state.body) && isEmpty(this.state.image))
      return <></>

  const lock = this.state.isPrivate?
               <span>&nbsp;&nbsp;<FaLock size="12" /></span>
               : ""
  const userInstance=
             <small>{`${this.state.author_first} ${this.state.author_last}, ${new Date(this.state.date).toLocaleString()}`}</small>

  const image = isEmpty(this.state.image)?"":
         <Columns.Column className="is-narrow" style={{padding:"1% 1.25%", margin:"0 .5rem"}}>
            <img src={this.state.image} alt="Wall entry" width={this.state.imageWidth>400?400:this.state.imageWidth} onContextMenu={e => e.preventDefault()} onDragStart={e => e.preventDefault()}/> 
         </Columns.Column>

  const video = isEmpty(this.state.video)?"":
           <Columns.Column className="is-narrow" style={{padding:"1% 1.25%", margin: "0 .5rem"}}>
              <video ref={ref => this.vidRef = ref} controls width={this.state.video.w400} controlsList="nodownload" disablePictureInPicture playsInline poster={this.state.video.poster400}
                       key={this.state.video.url1} preload="metadata"
                           onClick={e => { e.preventDefault();  if (this.videoLoaded) { if (this.isPlaying) this.vidRef.pause(); else this.vidRef.play(); this.isPlaying = !this.isPlaying; }}}
                           onLoadedMetadata={(e) => { this.videoLoaded = true; }}
                           onTouchStart={e => { e.stopPropagation(); if (this.videoLoaded) { if (this.isPlaying) this.vidRef.pause(); else this.vidRef.play(); this.isPlaying = !this.isPlaying; }}}
              >
                  <source src={this.state.video.url1} type="video/webm" />
                  <source src={this.state.video.url2} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
              </video>
           </Columns.Column>

  return (
   <>
      {this.props.publicV?"":<CommentButtons pageType='wall' pageInstance={this.props.instance} numComments={this.props.numComments}/>}
   <Card className="box" style={{padding:"1% 2%"}}>
      <Columns>
         <Columns.Column style={{padding:"1em 2%",margin:0}}>
            <span className="is-italic is-pulled-right">
               {userInstance}
               {lock}
            </span>
         </Columns.Column>
      </Columns>
      <Columns className="is-centered is-vcentered">
       {image}
       {video}
        <Columns.Column style={{padding:"1% 12px", margin:0}}>
           <ContentEditable
              html={this.state.title} // innerHTML of the editable div
              disabled={true} // use true to disable edition
              className="vtitle"
           />
         </Columns.Column>
      </Columns>
      <Content>
         <ContentEditable
            html={this.state.body} // innerHTML of the editable div
            disabled={true} // use true to disable edition
            className="vbody"
         />
      </Content>
   </Card>
  </>
)
}
}

const  mapStateToProps = state => { return { auth: state.auth, user: state.user } };

export default connect(mapStateToProps)(WallViewEntryCard);

