import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { SET_DIARY_INSTANCE, UPDATE_DIARY_PAGE, UPDATE_DIARY_INDEX_PAGE,  UPDATE_DIARY_INDEX_PAGE_NODISK, UPDATE_DIARY_PAGE_NODISK } from './types'
import { isPageRO, augmentApi, getAccountOverride } from '../components/loggedIn/functions/location_fcns'
import { DIARY_NEWBY_JSON, DIARY_NEWBY_ACCOUNT, DIARY_CURATED_ROWS, DIARY_CURATED_ROWS_ACCOUNT, DIARY_CURATED_ROWS_ACCOUNT_RO, getSessionStorage, updateSessionStorage, clearSessionStorage } from '../components/loggedIn/functions/session_fcns'

/*
export const getDiaryIndexPage = () => async dispatch => //dispatches html that is the page contents
  {
        const res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'diary', pageInstance: 'singleton' }}) 
        dispatch(updateDiaryPage(res.data.pageData, false))
  }
export const getDiaryPage = (pageInstance) => async dispatch => //dispatches html that is the page contents
  {
        const res = await axios.get(augmentAPi('/api/get_page'), { params:  { pageType: 'diary', pageInstance  }}) 
        dispatch(updateDiaryPage(pageInstance, res.data.pageData, false))
        dispatch(setDiaryInstance(pageInstance);
  }
*/

  //Action creator function to return Plain Object containing action with dynamic data
export const setDiaryInstance = (instance_id) => {
     //put in session storage
   sessionStorage.setItem('diary_instance', instance_id);
   return { type: SET_DIARY_INSTANCE,  payload: instance_id }  //type is a required field
};

  //Action creator function to return Plain Object containing action with dynamic data
export const updateDiaryPage = (pageInstance, pageContents, updateDisk=true) => {
   return { type: updateDisk?UPDATE_DIARY_PAGE:UPDATE_DIARY_PAGE_NODISK,  payload: { pageInstance, pageContents }}  //type is a required field
};

  //Action creator function to return Plain Object containing action with dynamic data
export const updateDiaryIndexPage = (pageContents, updateDisk=true) => {
   return { type: updateDisk?UPDATE_DIARY_INDEX_PAGE:UPDATE_DIARY_INDEX_PAGE_NODISK,  payload: { pageInstance: 'singleton', pageContents }} 
};

  //Middleware : 
export const updateDiaryPageMiddleware = ({ getState, dispatch }) => next => async action =>
{  const result = next(action);
   switch (action.type) {
     case UPDATE_DIARY_PAGE:
     case UPDATE_DIARY_INDEX_PAGE:
       await axios.post('/api/save_page', { pageType: 'diary', pageInstance: action.payload.pageInstance, pageData: action.payload.pageContents }); 
          //dispatch(anotherAction(asyncResult));
       break;
       /***
       case SOME_OTHER_ACTION:
         const { slice: { stateVariable } } = getState();
         await someProcess(stateVariable);
         break;
      ***/
      default:
   }
   return result
}

const utc2local = dt => {
    const d = new Date(dt)
    const  dtOffset = new Date(d.setMinutes(d.getMinutes() - d.getTimezoneOffset()));
    return dtOffset.toISOString()
}

  //*Do decorated as a second pass after the base has been displayed
export const curateDiaryPages = async (accountId, toc=[], force=true) =>
{
      const accountOverride = getAccountOverride()
      if (accountOverride > 0)  accountId = accountOverride
      if (!force)
      {     //See if in session store and accountId's match
          const rows = getSessionStorage(DIARY_CURATED_ROWS)
          const account = getSessionStorage(DIARY_CURATED_ROWS_ACCOUNT)
          const RO = getSessionStorage(DIARY_CURATED_ROWS_ACCOUNT_RO)
          if (!isEmpty(rows) && account === accountId && RO === isPageRO())
             return { displayedRows: rows };
      } 

        //**Timing sometimes skips one just added
      let haveNewby = false
      const newbyJson = getSessionStorage(DIARY_NEWBY_JSON)  //title, summary, pageInstance, key, author
      const newbyAccount = getSessionStorage(DIARY_NEWBY_ACCOUNT)
      if (!isEmpty(newbyJson) && newbyAccount === accountId)
          haveNewby = true
      let res = await axios.get(augmentApi('/api/get_pageRef_lists'), { params:  { page_type: "diary"}})
      const viewable = res.data.viewable;
      const editable = res.data.editable;
      const pageInfo = res.data.pageInfo;  //Display Order
      const newbies = res.data.newbies;   //Unseen instances

      if (isEmpty(toc))
      {   res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'diary', pageInstance: 'singleton'}})
          const ct = JSON.parse(res.data.pageData);
          toc = (typeof ct != "object") ? JSON.parse(ct) : ct
      }
   
      let displayedRows = []
      for (let i = 0 ; i < pageInfo.length; i++)
      {     const p = pageInfo[i]
            const isViewable = viewable.includes(p.pageInstance)
            const isEditable = isViewable ? editable.includes(p.pageInstance):false
            const isNew = newbies.includes(p.pageInstance)
            const tline = toc.find(e => e.pageInstance === p.pageInstance)

            if (isViewable && !isEmpty(tline)) {
               const row = { pageInstance: p.pageInstance, numComments: p.numComments, author: `${p.firstName} ${p.lastName}`, title: tline.title, summary: tline.summary, editable: isEditable, updatedAt: utc2local(p.updatedAt), private: p.private, unseen: isNew }
               displayedRows.push(row)
           }
      }
      if (haveNewby && !editable.includes(newbyJson.pageInstance))  //** Missed it - need to add it in
      {       const row = { pageInstance: newbyJson.pageInstance, numComments: 0, author: newbyJson.author, title: newbyJson.title, summary: newbyJson.summary, editable: true, updatedAt: utc2local(new Date().toISOString()), private: true, unseen: false }
              displayedRows.unshift(row)
              console.log("Added diary row in curateDiaryPages()", row)
      }
      updateSessionStorage(DIARY_CURATED_ROWS, displayedRows)
      updateSessionStorage(DIARY_CURATED_ROWS_ACCOUNT, accountId)
      updateSessionStorage(DIARY_CURATED_ROWS_ACCOUNT_RO, isPageRO())
      clearSessionStorage([DIARY_NEWBY_JSON, DIARY_NEWBY_ACCOUNT])

      return { toc, displayedRows }
}


/** normally, if synchronous)
  request = axios.get(...)
  return {
      type: FETCH_USER,
      payload: requests
}**/
