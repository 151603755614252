import React, {Component} from 'react';
import { connect } from 'react-redux'; 
import { Hero, Navbar } from 'react-bulma-components';
import LoginHead from './LoginHead'
import LoginTail from './LoginTail'
import { isPublicA, getHeaderText } from '../loggedIn/functions/location_fcns'
import ReactLogo from './logo.js';

/**
 * Dropdown menu
            <img src="http://myeport.net/sibs.svg" alt="SibsForever"/>
 */

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {}

    this.hamburgerMenu = () => {
         const menus = document.querySelectorAll('.navbar-burger');
         const dropdowns = document.querySelectorAll('.navbar-menu');

         if (menus.length && dropdowns.length) {
             for (var i = 0; i < menus.length; i++) {
                 menus[i].addEventListener('click', () =>  {
                     for (var j = 0; j < dropdowns.length; j++) {
                         dropdowns[j].classList.toggle('is-active');
                     }
                 });
             }
         }
     }
  }
  
  componentDidMount()
  {
     window.addEventListener('DOMContentLoaded',  this.hamburgerMenu);
  }

  componentWillUnmount()
  {
     window.removeEventListener('DOMContentLoaded',  this.hamburgerMenu);
  }

  render() {
    //console.log("Props", this.props);
    let loginHead = <></>;
    let loginTail = <></>;
    if (typeof this.props.auth !== 'undefined' && this.props.auth !== null && window.location === window.parent.location) //Not in an iframe
    {   loginHead = <LoginHead auth={this.props.auth} user={this.props.user}/>;
        loginTail = <LoginTail auth={this.props.auth} user={this.props.user} />
    }

       //In an Iframe - no links
    if (window.location !== window.parent.location) return  ( 
      <Navbar className="is-tab"> 
           <div style={{paddingLeft: "48%"}}>
           </div>
        <Navbar.Brand>
          <Navbar.Item>
            <ReactLogo width="100px" height="40px"/>
          </Navbar.Item>
        </Navbar.Brand>
     </Navbar>
   )
    else if (this.props.auth === false && isPublicA() ) return (
      <Navbar className="is-tab"> 
           <div style={{paddingLeft: "48%"}}>
           </div>
        <Navbar.Brand>
          <Navbar.Item renderAs="a" href="/">
            <ReactLogo width="100px" height="40px"/>
          </Navbar.Item>
        </Navbar.Brand>
     </Navbar>
   )

    return (<>
      <Navbar className="is-tab">
        <Navbar.Menu>
           <div className="navbar-start navbar-start--centered">
             { loginHead } 
           </div>
        </Navbar.Menu>
        <Navbar.Brand>
          <Navbar.Item renderAs="a" href='/'>
            <ReactLogo width="100px" height="40px"/>
          </Navbar.Item>
        <Navbar.Item className="is-hidden-tablet" style={{pointerEvents:"none"}}>{getHeaderText()}</Navbar.Item>
        <Navbar.Burger> 
            <span></span>
            <span></span>
            <span></span>
        </Navbar.Burger> 
        </Navbar.Brand>
        <Navbar.Menu>
           <div className="navbar-start navbar-start--centered">
               {loginTail}
           </div>
        </Navbar.Menu>
      </Navbar>
    </>)
  }
}

const mapStateToProps = ({auth, user}) => {  //state is the full Redux store, destructure state object
    //return the object to be passed to the header as this.props.
    return { auth, user }  //{ auth: auth }, condense when same names
}
export default connect(mapStateToProps)(Header);
