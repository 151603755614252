import { Card, Box, Section, Container, Heading } from 'react-bulma-components';
import './landing.css'

const DeletePolicy = (props) => {

   const styles =
   { borders: { border:"thick double var(--primary)", margin:".5rem"},
     bshadow: { boxShadow:"rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"},
     shim:  {  display: "inline-block", height: 0,  width: "10em "},
   }

   return (
     <>
<Section style={{padding:"2vw"}}>
<div className="sibs-subheading has-text-primary-NOT is-size-3 has-text-centered" style={{padding:0, margin:0}}>
Data Deletion Policy
</div>

<center>
<Box className="column is-narrow width" style={{boxShadow:"3px 3px var(--footer-background-color), -1em 0 .4em olive"}}>
<div style={{textAlign:"left", margin:"1%"}}>
<p className="subtitle mb-6">

<strong className="is-size-4">What Personally Identifiable Information is Collected?</strong><br/><br/>
Users who sign up for a SibsForever account and/or submit an inquiry form, voluntarily provide us with contact information (such as name and e-mail address). After an inquiry is received and processed, the request along with any contact information is archived. For active SibsForever accounts, user information such as a user's name and email address are persisted in an encrypted database with state-of-the-art access controls in place. 

<br/><br/><strong className="is-size-4">Requesting that Data be Deleted</strong><br/><br/>
Active SibsForever users who wish to inactivate their accounts and delete all associated user data should login to SibsForever and complete this <a href="https://sibsforever.org/contact">request form</a>. Once the request is fulfilled and acknowledged, all inquiry data will be removed as well.

<br/><br/>Users who have questions, concerns or asks should complete this <a href="https://sibsforever.org/contact">general inquiry form</a>.  Similar to what is described above, any inquiry data will be deleted once the request is fulfilled and acknowledged.
<br/><br/>
<strong>Published date of this Data Deletion Policy:  October 31, 2021</strong>


</p></div>
</Box>

</center>
</Section>

     </>
    )
  }

export default DeletePolicy;
