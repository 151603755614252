//**This is the panel that displays Diary Entry for viewing

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InnerHTML from 'dangerously-set-html-content'  //This executes scripts
import isEmpty from 'lodash/isEmpty'
import axios from 'axios';
import { Heading } from 'react-bulma-components';
import CommentButtons from '../../common/comments/CommentButtons'
import { updateDiaryPage, setDiaryInstance } from '../../../actions/diaryPage'
import { seeComments } from '../../../actions/comment'
import usePagePerms from '../hooks/usePagePerms'
import useGetHeader from '../hooks/useGetHeader'
import '../../editor.css';
import { isPublicA, augmentApi, getDiaryInstance } from '../functions/location_fcns'
import { shimmy, applyShim, insert_videos } from '../functions/editor_fcns'
import ShowSummaryPopup from './SummaryPopup'

const HeadingPiece = "Journal Entry"

const DiaryEntryPanel = ({isShow=false}) => {

const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

//Fcns
const pageInstance =  getDiaryInstance();
const publicA = isPublicA()

//Hooks
const auth = useSelector(state => state.auth); //Logged in?
const see_cc = useSelector(state => state.seeCommentCount);
const [ , , canViewEntry, isResolvedEntry ] = usePagePerms('diary', pageInstance, false)

const dispatch = useDispatch();
const diaryInstance = useSelector(state => state.diaryInstance);
const diaryEntryContent = useSelector(state => state.diaryPageContent); //Get from Redux Store (if there)

//State
const [ pageLoadDone,  setPageLoadDone ] = useState(false);
const [ numComments,  setNumComments ] = useState(0);

const heading = useGetHeader(HeadingPiece)

//Load data
useEffect( () => {
   let isCorrectInstance = "unknown"
   const checkInstance = () => {
       if (!isEmpty(diaryInstance))
       {    if (diaryInstance !== pageInstance)
               isCorrectInstance = "false"
            else
               isCorrectInstance = "true"
       }
   }
   const loadData = async () => {
      if ((auth||publicA) && isCorrectInstance !== "unknown" && (isCorrectInstance === "false" || isEmpty(diaryEntryContent)) && pageLoadDone === false)
      {  let res = await axios.get(augmentApi('/api/get_page'), { params:  { pageType: 'diary', pageInstance: pageInstance }})
         let content = res.data.pageData;
         if (!isEmpty(content))
            content = JSON.parse(content);  
         setNumComments(res.data.numComments);
         dispatch(updateDiaryPage(pageInstance, content, false)); dispatch(setDiaryInstance(pageInstance)) 
         setPageLoadDone(true);
         dispatch(seeComments(see_cc))  //Cause sidebar to update decorators 
      }
      else if (pageLoadDone === false && isCorrectInstance === "true" && !isEmpty(diaryEntryContent) && (auth||publicA))
         setPageLoadDone(true);
   }
   if (isCorrectInstance === "unknown")
      checkInstance()
   loadData();
      // eslint-disable-next-line
}, [auth, diaryInstance, diaryEntryContent])

if (auth === false && !publicA) {
      return <div>Please Login to continue...</div>
}

if (!pageLoadDone || isEmpty(diaryEntryContent) || !isResolvedEntry)
      return <div>Loading</div>

if (isResolvedEntry && !canViewEntry)
      return <div>You are not authorized to view this page</div>

return (
<>
<Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
<div style={{backgroundColor: 'white', maxWidth: "1022px" }}>
<CommentButtons pageType='diary' pageInstance={pageInstance} numComments={numComments}/>
<InnerHTML style={{padding:'8px 8px', margin: 0, textAlign:"left"}} className="jodit-wysiwyg" html={insert_videos(shimmy(diaryEntryContent))}/>
</div>
<ShowSummaryPopup  initialState={isShow} />
</>
)
}

export default DiaryEntryPanel;

