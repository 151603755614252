import React, { useState, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Content, Container, Form, Section, Button, Box, Heading } from "react-bulma-components";
import ReCAPTCHA from 'react-google-recaptcha'
import Loader from "react-spinners/BeatLoader";
import axios from 'axios';

const AuthFailedLink = (props) => {  

     //**State
  const [ captcha, setCaptcha ] = useState("");
  const [loading, setLoading] = useState(false);

      //**Ref
  const recaptcha = useRef();

  const history = useHistory();
  const { handleSubmit, errors, control } = useForm();
  const onSubmit = async data => {
    setLoading(true);  //Put on spinner

    const { Email } = data;
    let pageName = '/login/linksent';
    const { status } = await axios.post('/api/magic-link', {
          email: Email, 
          captcha
        },
        { validateStatus: (status) => {
           return true; 
        }})
        if (status === 200) {
           console.log("Magic-link successful");
        }
       setLoading(false);  //disable spinner
       history.push(pageName);
  };

  return (
   <>
    <Container>
    <Section>
      <Box style={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"}}>
      <Content>
          <center>
          <h4>Your login attempt was not successful</h4>
          If you were using an expired link or reusing a one-time link, please request a new login link below.
          </center>
      </Content>

        <form onSubmit={handleSubmit(onSubmit)}>

          <Form.Field>
            <Form.Label>Email Address</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="Email"
                    control={control}
                    defaultValue=""
                    placeholder="Your Email"
                    type="email"
                    rules={{ required: true }}
                  />
            </Form.Control>
           <Form.Help textColor="danger">
                 {errors.Email && "Your email address is required"}
            </Form.Help>
          </Form.Field>

          <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>

          <Form.Field>
             <ReCAPTCHA
               ref={() => recaptcha }
               sitekey="6LcR3V0aAAAAAM6uO6Wa8X4P4XV56cmdTZ81PvBj"
               onChange={value => setCaptcha(value)}
            />
          </Form.Field>

          <Form.Field>
            <Form.Control>
              <Button  disabled={!captcha} fullwidth={false}>Get Sibsforever Login Link</Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </Section>
    </Container>
   </>
  );
}

export default AuthFailedLink;
