import isEmpty from 'lodash/isEmpty'
import React, { useState, useRef, useCallback } from 'react';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import { Heading } from 'react-bulma-components';
import Loader from "react-spinners/BeatLoader";
import  Dropzone, {useDropzone} from 'react-dropzone'; 
import styled from 'styled-components';
import { MdCloudUpload } from "@react-icons/all-files/md/MdCloudUpload";
import 'react-responsive-modal/styles.css';
import useLockBodyScroll from '../../loggedIn/hooks/useLockBodyScroll'
import {isVideoCarouselPage, augmentApi} from '../../loggedIn/functions/location_fcns'
import { CAROUSEL_NEWBY_ACCOUNT, VCAROUSEL_INDEX, updateSessionStorage } from '../../loggedIn/functions/session_fcns'
import CarouselGallery from '../../loggedIn/carousel/CarouselGallery'
import { getVideoCarouselPage, updateVideoCarouselPage }  from '../../../actions/carouselPage'
import ProgressBar from "@ramonak/react-progress-bar";

const MultiPartMax = 6
const FileTimeout = 1000 * 60 * 5  

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
  }

  const DZContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: solid;
    background-color: #fafafa;
    color: #2484FF;
    font-family: 'Helvetica', sans-serif;
    font-size: 20px;
    font-weight: 600;

    outline: none;
    transition: border .24s ease-in-out;
    overflow: scroll;
  `;

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label className="checkbox">
      <input type="checkbox" checked={value} onChange={onChange} />
      &nbsp;&nbsp;{label}&nbsp;&nbsp;&nbsp;&nbsp;
    </label>
  );
};

const styles = {
  modal: {
    maxWidth: "90vw",
    margin:0, padding:"1%",
  },
};

const DragPopupVideo = ({ prompt, initialState, setCloseData, setClose, resize } ) => {

    let ChunkSize = 5 * 1024 * 1024
       //**Loader - could be slow with large images - give a visual indicator
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const [useVideoGallery, setUseVideoGallery] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const user = useSelector(state => state.user);

    const slices = useRef([])
    const urls = useRef([])
    const params = useRef()
    const addToCarousel = useRef(true)
    const numUploadArrays = useRef(1)
    const iteration = useRef(1)

    useLockBodyScroll(initialState);

      //Load VideoCarouselPage.singleton into Redux store
    const dispatch = useDispatch();

       //Slice file into Chunks
    const  sliceFile = file => {
       var chunks = [];
       const numChunks = Math.ceil(file.size/ChunkSize,ChunkSize);
       let chunk = 0;
  
       for (let index = 0; index < numChunks; index++)
       {   const start = index * ChunkSize
           const end = (index + 1) * ChunkSize
           const blob = index < numChunks-1 ? file.slice(start, end) : file.slice(start)
           chunks.push(blob)
       }
    slices.current = chunks;
}

      //Execute promises that put multi-part files over to S3
const putFiles = async (promises, j, ftype) =>
{   const axios = Axios.create()
    delete axios.defaults.headers.put['Content-Type']  

    const ret = []
    const resParts = await Promise.allSettled(promises)
    for (let k = 0; k < resParts.length; k++)
    {  if (!isEmpty(resParts[k].value))
            ret[k] = { PartNumber: j*MultiPartMax+k+1, ETag: resParts[k].value.headers.etag }
       else  {
            //Retry
            try  {
                const r = await axios.put(urls.current[j*MultiPartMax+k], slices.current[j*MultiPartMax+k],
                    {   headers: { 'AccessControlAllowOrigin': '*' },
                        timeout: FileTimeout
                    } );

                ret[k] = { PartNumber: j*MultiPartMax+k+1, ETag: r.headers.etag }
            }
            catch(err)
            {  console.log("axios.put of multipart failed", err); 
               await axios.post('/api/delete_image', { path: urls.current[j*MultiPartMax+k], video: true})
            }
        }
    }
    return ret
}

   //Video chosen from gallery
const handleSelect = async src => {
    setLoading(true);  //Put on spinner
    const p = src.split('/')
    const pieces = p[p.length-1].split('.')
    addToCarousel.current = false;

    try {
         const axios = Axios.create()
         const qresult = await axios.post(augmentApi('/api/copyFile'), { source: pieces[0], suffix: pieces[pieces.length-1], isVideo: true   } )
         await finish(qresult.data.key, qresult.data.w300, qresult.data.w400, qresult.data.wThumb)
    } catch(err) 
    {  console.log("Video Select failed", src) 
       setErrors("Video Select  failed")
       setLoading(false);  //disable spinner
       setUseVideoGallery(false)
    }
}

const advanceProgressBar = progressEvent => {
      const percentage= parseInt(Math.round((progressEvent.loaded * 100) /(progressEvent.total*numUploadArrays.current)+((iteration.current-1)/numUploadArrays.current)*100));
      setUploadPercentage(percentage);
}

//Called after file selected
const onDrop = useCallback(async aFiles => { aFiles.forEach(async file => {
    const axios = Axios.create()
    delete axios.defaults.headers.put['Content-Type']
    setLoading(true)

    numUploadArrays.current = 1   //Initialize to one chunk
    let uploadConfig = {}
    let key = null
    let w400 = 400
    let w300 = 300
    let wThumb = 0
    let error = false
    //Upload all at once
    if (file.size > 500 * 1024 * 1024)
        ChunkSize = 60 * 1024 * 1024;
    else if (file.size > 200 * 1024 * 1024)
        ChunkSize = 40 * 1024 * 1024;
    else if (file.size > 100 * 1024 * 1024)
        ChunkSize = 20 * 1024 * 1024;
    else if (file.size > 50 * 1024 * 1024)
        ChunkSize = 10 * 1024 * 1024;
    if (file.size <= ChunkSize) { 
        try
        {   uploadConfig = await Axios.get(augmentApi('/api/upload'), { params: { 'ContentType': file.type, 'video': true }});  //Get pre-signed URL for put
            key = uploadConfig.data.key
            await Axios.put(uploadConfig.data.url, file,
            {   headers: { 'ContentType': file.type, 'AccessControlAllowOrigin': '*' },
                timeout: FileTimeout,
                onUploadProgress : advanceProgressBar,
            });
            params.current = { Key: key }
            const ret = await Axios.post('/api/process_video', { params: params.current });
            key = ret.data.key;
            w300 = ret.data.w300
            w400 = ret.data.w400
            wThumb = ret.data.wThumb
            setUploadPercentage(0)
        }
        catch(err)
        {  console.log("axios.put of file failed", err);
           error = true
           setUploadPercentage(0)
           if (!isEmpty(uploadConfig) && !isEmpty(uploadConfig.data))
              await axios.post('/api/delete_image', { path: uploadConfig.data.url, video: true})
        }
    }
    //Multi-part upload
    else {
        sliceFile(file, ChunkSize)
        //Get presigned URLs from server
        let res = await Axios.get(augmentApi('/api/multipart_upload_start'), { params:  { numParts:  Math.ceil(file.size/ChunkSize,ChunkSize), ContentType: file.type }})
        params.current = res.data.params
        urls.current = res.data.urls;
           //Send pieces as multipart upload
        const all = []
        //Upload the sliced blog: Send 6 at a time due to browser thread limit
        let numArrays = Math.ceil(urls.current.length / MultiPartMax)
        numUploadArrays.current = numArrays
        let empty = true
        for (let j = 0; j < numArrays; j++)
        {   const promises = []
            iteration.current = j+1
            for (let index = 0; j*MultiPartMax+index < urls.current.length && index < MultiPartMax; index++)
            {   const ii = j*MultiPartMax+index   
                const blob = slices.current[ii] 
                if (index == 0)  //get progress (for progress bar) on first file in set only
                   promises.push(axios.put(urls.current[ii], blob,
                   { timeout: FileTimeout,
                     onUploadProgress : advanceProgressBar,
                   }));
                else
                   promises.push(axios.put(urls.current[ii], blob,
                   { timeout: FileTimeout,
                   }));
            }
            //**Execute promises to put data
            const ret = await putFiles(promises, j, file.type)
            //Grab the response headers
            for (let k = 0 ; k < promises.length; k++)
            {  if (!isEmpty(ret[k]))
               {    all[j*MultiPartMax+k] = ret[k]; empty = false }
                else  
                    error = true
            }
        }
        if (empty)
            error = true
        //*Complete the multipart upload and run the lambda
        if (error === false)
        {   const res_end = await Axios.post('/api/multipart_upload_end', { params: params.current, eArray: all})
            try {
                const ret = await Axios.post('/api/process_video', { params: params.current });
                key = ret.data.key;
                w300 = ret.data.w300
                w400 = ret.data.w400
                wThumb = ret.data.wThumb
                setUploadPercentage(0)
             }
             catch(err)
             {  console.log("process_video of file failed", err);
                error = true;
             }
        }
        else
           res = await Axios.post('/api/multipart_upload_fail', { params: params.current })

        }
             //Video is processed finish it up
        if (error === false)
        {  addToCarousel.current = isVideoCarouselPage() ? false : true
           await finish(key, w300, w400, wThumb)
        }
        else  {
           await setClose(); //*Close popup
           setErrors("Video processing failed")
           setLoading(false);  //disable spinner
           setUseVideoGallery(false)
           setUploadPercentage(0)
        }
      }) },[] )

  const finish = async (key, w300, w400, wThumb) => {
         //**This uses cloudfront signed cookies to ensure that content can only be accessed through this app (on the sibsforever domain)
         //**At home, it is http.  And it is a CORS violation if there is mismatch between the cloudfront URL and the cookie's URL
      const currentURL = window.location.href
      let protocol = "https"
      if (currentURL.startsWith("http://"))
         protocol = "http"
        //Get the urls
      const pieces = key.split('.')
      const url1 = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.webm`
      const url2 = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.mp4`
      const thumb = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.thumb.jpg`
      const poster = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.poster.jpg`
      const posterp = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.poster-p.jpg`
      const poster300 = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.300.jpg`
      const poster300p = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.300-p.jpg`
      const poster400 = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.400.jpg`
      const poster400p = `${protocol}://cf.sibsforever.org/video/${pieces[0]}.400-p.jpg`
  
          //Return object
      let retObj = { video : { isVideo: "true", key: pieces[0], url1, url2, thumbnailUrl: thumb, poster, posterp, poster300, poster300p, w300, poster400, poster400p, w400, thumbWidth: wThumb, thumbHeight: 200  } }
      setErrors("")
      setLoading(false);  //disable spinner
      setUseVideoGallery(false)
      await setCloseData(retObj); //*Close popup
      if (addToCarousel.current)
      {   //Save Carousel Page
          //Save Carousel.singleton page
          const axios = Axios.create()
          const  globalArray = await getVideoCarouselPage()

          let newby = {...retObj.video}
          const pageInstance = uuidv4()
          newby.pageInstance = pageInstance
          newby.key = pageInstance
          newby.caption = ""
          newby.user_id =  user.user_id
          globalArray.push(newby);
          dispatch(updateVideoCarouselPage(globalArray));
          updateSessionStorage(CAROUSEL_NEWBY_ACCOUNT, user.account_id)
          updateSessionStorage(VCAROUSEL_INDEX, globalArray)
          await axios.post('/api/save_page', { pageType: 'vcarousel', pageInstance, pageData: newby, videoSelect: 'true' });
      }
      addToCarousel.current = true
    }

    const MaxSize = 1024 * 1024 * 1024
    const {getRootProps, getInputProps, acceptedFiles, fileRejections, isDragActive, isDragAccept, isDragReject } =
              useDropzone({
                 onDrop,
                 onDropRejected: fileRejections => {
                    fileRejections.forEach(file => {
                        file.errors.forEach(err  => {
                          if (err.code === "file-too-large") 
                            setErrors(`Error: ${err.message}`);
                          if (err.code === "file-invalid-type") {
                            setErrors(`Error: ${err.message}`);
                          }
                        });
                      });
                    }, 
                 //accept: 'video/ogg,video/mp4,video/webm,video/3gp,video/3gpp,video/avi,video/x-msvideo,video/x-m4v,video/x-quicktime,video/quicktime',
                 accept: 'video/ogg,video/mp4,video/webm,video/3gp,video/3gpp,video/avi,video/quicktime',
                 maxFiles:1,
                 multiple: false,
                 maxSize: MaxSize,
          });
  return (
    <>
      <Modal
        open={initialState}
        onClose={() => {  setClose(); setErrors(""); setUseVideoGallery(false); setLoading(false) }}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        styles={styles}
        container={document.getElementById('portal')} //Added to display outside of the body
        center
      >
        <Heading style={{padding:0, margin:0}}>&nbsp;</Heading>

        <div style={{textAlign: 'center', display:uploadPercentage > 0 ? "block" : "none"}}> 
           <Loader loading={loading} color='#007bff' size={60} />
           <ProgressBar completed={uploadPercentage} bgColor='#007bff' margin="10px 2px" />
        </div>
        

      {!useVideoGallery?
          <div className="container"> 
          <DZContainer {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
            <input {...getInputProps()} />
            <p>{prompt}</p>
            <MdCloudUpload style={{ fontSize: 120, paddingTop: 30, color: "#bdbdbd" }} />
            <p style={{ color: "#bdbdbd", fontSize: 18}}> Drag 'n' drop a file here, or click to select file</p>
            <p style={{color: "black", fontSize: 14, fontWeight: "normal"}}> (Large files could take minutes to upload. Please don't close popup during transfer) </p>
          </DZContainer>
          <p style={{ color: "red", padding: 5, margin: 0, fontSize: 14 }}>
             {errors}
          </p>
          </div>
          : <>
          <CarouselGallery
            choosePicture={true}
            videoGallery={true}
            onSelect={src => handleSelect(src)}
          />
          <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>
         </>
        }


      {isVideoCarouselPage() ? "" :
        <><br />
        <Checkbox  
            label="Choose Video from Gallery"
            value={useVideoGallery}
            onChange = {() => setUseVideoGallery(!useVideoGallery)}
        /></>}
      </Modal>
    </>
  );
};

export default DragPopupVideo;

