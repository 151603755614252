import isEmpty from 'lodash/isEmpty'
import React, { useState} from 'react';
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import WallSidebar from './WallSidebar'
import WallNewEntryCard from './WallNewEntryCard'
import { Heading, Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import usePagePerms from '../hooks/usePagePerms'
import { getBannerText } from '../functions/location_fcns'
import { NEW_WALL_ENTRY_KEY, getLocalStorage, updateLocalStorage } from '../functions/localStore'

const WallNewEntry = (props) => {

   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText("Remembrance Wall", user) 
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   const [ , canEdit, , isResolved ] = usePagePerms('wall', 'singleton')
   const [ imagePop, setImagePop ] = useState(false)
   const [ videoPop, setVideoPop ] = useState(false)

   if (!isResolved || isEmpty(user) || isEmpty(user.email))
     return <div>Loading...</div>

   else if (!canEdit)
     return <Container className="column is-9">
            <div>You are not authorized to edit this page</div>
            </Container>

   let key = getLocalStorage(NEW_WALL_ENTRY_KEY)
   if (!isEmpty(key))
   {   key = uuidv4()
       updateLocalStorage(NEW_WALL_ENTRY_KEY, key)
   }
   return (
     <>
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
      <div className="aside"><WallSidebar isActive="New Entry" className="menu"/></div>

     <Container className="column is-9" style={{paddingTop:0}}>
     <div style={{maxWidth: "1152px"}}>
        <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
        <WallNewEntryCard firstName={user.first_name} lastName={user.last_name} numComments={0} showComments={false} num={1} newby={true} instance={key} key={key} isAuthor={true} imagePopState={imagePop} videoPopState={videoPop}
                          setVideoPopState={state => setVideoPop(state)} setImagePopState={state => setImagePop(state)} />
     </div>
     </Container>
     </Section>
     </>
   )
  }

export default WallNewEntry;

