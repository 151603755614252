import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import SearchPopup from './SearchPopup'
import SharedView from './SharedView'
import { BROWSE_LAST_FETCH, getSessionStorage } from '../functions/session_fcns'


const SharedLastSearch = (props) => {  

   const history = useHistory();
   const auth = useSelector(state => state.auth); //Logged in?

   const [ popupOpen, setPopupOpen] = useState(true); //**Start out popup open


   const onPopupEnd = async data => {

       let qs = data.url

       history.push(`/community?${qs}`)
   }

   if (auth === false) {
      return <div>Please Login to continue...</div>
   }

   let url = "/community"
   const last = getSessionStorage(BROWSE_LAST_FETCH)
   if (last === "following")
       url = "/auth/shared/following"
   else if (last === "followers")
       url = "/auth/shared/followers"
   else if (!isEmpty(last))
       url = "/community?"+last

   return (
     <>
       <SharedView useNothing={true}/>

       <SearchPopup
        useSavedQuery={true}
        initialState={popupOpen}
        setCloseData={data => onPopupEnd(data) }
        setClose={()=> { setPopupOpen(false); history.push(url) } }
      />
     </>
  )
}

export default SharedLastSearch;
