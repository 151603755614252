import axios from 'axios'
import { GET_SHARED_PAGE_TYPES, SET_ACCOUNT_OBJECT, UPDATE_ACCOUNT, UPDATE_ACCOUNT_PICTURE } from './types'
import { augmentApi } from '../components/loggedIn/functions/location_fcns'

export const getAccountObject = (id=0) => async dispatch => 
{
        const res = await axios.get(augmentApi('/api/get_account_info'), { params:  { id  }})
        dispatch(setAccountObject(res.data));
}

export const getSharedPageTypes = (id=0) => async dispatch => 
{
    const res = await axios.get(augmentApi('/api/get_shared_page_types'))
    dispatch(setSharedPageTypes(res.data.pages));
}

//Action creator function to return Plain Object containing action with dynamic data
export const setSharedPageTypes = types => {
   return { type: GET_SHARED_PAGE_TYPES,  payload: types}  //type is a required field
};

//Action creator function to return Plain Object containing action with dynamic data
export const setAccountObject = (account_obj) => {
   return { type: SET_ACCOUNT_OBJECT,  payload: account_obj }  //type is a required field
};

  //Action creator function to return Plain Object containing action with dynamic data
export const updateAccount = (accountInfo) => {
   return { type: UPDATE_ACCOUNT,  payload: accountInfo }  //type is a required field
};

export const updateAccountPict = (accountInfo) => {
   return { type: UPDATE_ACCOUNT_PICTURE,  payload: accountInfo }  //type is a required field
};

  //Middleware : 
export const updateAccountMiddleware = ({ getState, dispatch }) => next => async action =>
{  const result = next(action);
   switch (action.type) {
     case UPDATE_ACCOUNT:
       await axios.post('/api/update_account', { photo: false, fields: action.payload });
          //dispatch(anotherAction(asyncResult));
       break;
     case UPDATE_ACCOUNT_PICTURE:
       await axios.post('/api/update_account', { photo: true, fields: action.payload });
       break;
     default:
   }
   return result
}


/** normally, if synchronous)
  request = axios.get(...)
  return {
      type: FETCH_USER,
      payload: requests
}**/
