import React, {useRef} from 'react'
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import 'react-responsive-modal/styles.css';
import TOS from '../tos'
import Privacy from '../privacy'
import { Heading, Button } from 'react-bulma-components';
import useLockBodyScroll from '../loggedIn/hooks/useLockBodyScroll'

/**Privacy and TOS hooked into Account Creation form**/
const AccountPopups = ({ initialState, setClose, tos=true, privacy=false }) => {

   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"},
  }

  useLockBodyScroll(initialState);
  const refTos = useRef()
  const refPrivacy = useRef()

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => setClose()}
        closeOnOverlayClick={false}
        showCloseIcon={true}
        initialFocusRef={tos?refTos:refPrivacy}
        center
      >
        <Heading ref={tos?refTos:refPrivacy} subtitle style={styles.subStyle} className="has-text-weight-semibold">{tos?"Terms of Use":"Privacy Policy"}</Heading>
        {tos?<TOS ref={refTos}/>:<Privacy ref={refPrivacy}/>}
        <center><Button size="small" onClick={()=>setClose()}>Done</Button></center>
 
      </Modal>
    </>
  );
};
 
export default AccountPopups

