import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Container, Form, Section, Button, Box, Heading } from "react-bulma-components";
import Loader from "react-spinners/BeatLoader";
import DragPopupSingle from '../common/popup/DragPopup1'
import axios from 'axios';

const SubmitPhoto = (props) => {  

     //**State
  const [loading, setLoading] = useState(false);
  const [imagePopup, setImagePopup] = useState(true)
  const [imageUrl, setImageUrl] = useState("")

  const auth = useSelector(state => state.auth); //Logged in?
  const user = useSelector(state => state.user)

  const history = useHistory();
  const { handleSubmit, errors, control } = useForm();
  const onSubmit = async data => {
    setLoading(true);  //Put on spinner

    const { Caption } = data;
    let pageName = '/photo/submit';
    const { status } = await axios.post('/api/photo-form', {
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email, 
          account_id:  user.account_id,
          imageUrl: imageUrl,
          caption: Caption,
        },
        { validateStatus: (status) => {
           return true;
        }})

        if (status === 200) {
           console.log("Photo submission form successful");
        }
       setLoading(false);  //disable spinner
       history.push(pageName);
  };

   const onPictPopupEnd = (data) =>
   {   setImageUrl(data.url)
          //Popup down
       setImagePopup(false);
   }

   const pict = !isEmpty(imageUrl) ? <img src={imageUrl} width="240px" style={{marginBottom: "16px"}} height="180px" alt="Sibs Home Page Picture" onClick={()=> setImagePopup(true)} onDragStart={e => e.preventDefault()} onContextMenu={e => e.preventDefault()}/>
                                     : ""

  if (auth === null || isEmpty(user))
     return <div>Loading...</div>
  if (auth === false)
    return <div>Please Login to Continue</div>

  return (
    <Container>
    <Section>
      <Box>
        <Heading subtitle size={4}>Submit Photo for SibsForever Front Page</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
 
        {pict}

          <Form.Field>
            <Form.Label>Caption</Form.Label>
            <Form.Control>
                  <Controller
                    as={Form.Input}
                    name="Caption"
                    placeholder="Image caption"
                    defaultValue=""
                    control={control}
                    maxLength={256}
                  />
             </Form.Control>
          </Form.Field>

          <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>

          <Form.Field>
            <Form.Control>
              <Button disabled={imageUrl===""} fullwidth={false}>Send</Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </Section>
    <DragPopupSingle
        initialState={imagePopup}
        setCloseData={data => onPictPopupEnd(data) }
        setClose={()=>setImagePopup(false)}
        prompt="Upload SibsForever Front Page Picture"
        resize={{exactHeight: 900, exactWidth: 1200, dcopy: true}}
    />
    </Container>
  );
}

export default SubmitPhoto;
