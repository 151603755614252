import { CAROUSEL_ACTIVE_INDEX, CAROUSEL_BUTTON_LABEL, UPDATE_CAROUSEL_PAGE, UPDATE_CAROUSEL_PAGE_NODISK } from '../actions/types'

const initialState = null
export const carouselPageReducer = (state=initialState, action={} ) =>
{
  switch(action.type) {
    case UPDATE_CAROUSEL_PAGE: 
    case UPDATE_CAROUSEL_PAGE_NODISK: 
          //This is an array of objects.  The application has already done a slice() and made the change before dispatching it
          //return cloneDeep(action.payload)
        return  action.payload 
    default:
        return state;
  }
};

const initialState1 = ""
export const setCarouselButtonText = (state=initialState1, action={} ) =>
{
  switch(action.type) {
    case CAROUSEL_BUTTON_LABEL: 
        return  action.payload 
    default:
        return state;
  }
}

const initialState2 = 0 
export const setCarouselActiveIndex = (state=initialState2, action={} ) =>
{
  switch(action.type) {
    case CAROUSEL_ACTIVE_INDEX: 
        return  action.payload 
    default:
        return state;
  }
}

