
/***

NOTE:  Put in Redux to avoid repeated server accesses
  allPages and allPagesSidebar dispatch the fetch
   
**/

import isEmpty from 'lodash/isEmpty'
import  { useRef, useEffect, useState } from "react";
import useIsMounted from './useMount';
import { useSelector, useDispatch } from 'react-redux';
import { isPublicA, getAccountOverride } from '../functions/location_fcns'
import { getSharedPageTypes } from '../../../actions/account'

  //Get page_types that are shared for the account
  //called from usePagePerms hook
export default function  useAccountOverridePages(noShared=false, fetch=false) { 

  const dispatch = useDispatch()
  const accountOverride = useRef(noShared?0:getAccountOverride())
  const isMounted = useIsMounted();

  const auth = useSelector(state => state.auth); //Logged in?
  const sharedPages = useSelector(state => state.sharedPageTypes); //Includes diary instances in addition to page types

  const [ isDone, setIsDone ] = useState(false)
  const [ pages, setPages ] = useState([])

   //Get shared page types - one time - hook uses Redux to avoid multiple calls to get it
   useEffect(() => {
       if (fetch && accountOverride.current > 0 && isEmpty(sharedPages))
           dispatch(getSharedPageTypes())
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

     if (auth === null || sharedPages === null) return;
     const publicA = isPublicA();
     if (auth === false && !publicA)
     {  //console.log("!auth & !publicA means user is not authenticated")
        return;
     }

     if (accountOverride.current > 0 && !isEmpty(sharedPages) && isMounted.current)
     {   setPages(sharedPages)
         setIsDone(true)
     }
     else if (accountOverride.current === 0)
         setIsDone(true)

         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, sharedPages])

    return [ accountOverride.current, pages, isDone ]
}

