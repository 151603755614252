import isEmpty from 'lodash/isEmpty'
import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import { Heading } from 'react-bulma-components';
import Loader from "react-spinners/BeatLoader";
import  Dropzone from 'react-dropzone-uploader'; //doc: https://react-dropzone-uploader.js.org/docs/quick-start
import 'react-responsive-modal/styles.css';
//import 'react-dropzone-uploader/dist/styles.css'
import '../../DropZoneStyles.css' //Made local to disable the progress bar that was stuck displaying blue
import useLockBodyScroll from '../../loggedIn/hooks/useLockBodyScroll'
import {augmentApi} from '../../loggedIn/functions/location_fcns'
import { MdCloudUpload } from "@react-icons/all-files/md/MdCloudUpload";
import ProgressBar from "@ramonak/react-progress-bar";

const FileTimeout = 1000 * 60 * 3
const ConcurrentNum = 6

const DragPopup = ({ prompt, initialState, setCloseData, setClose, resize={} } ) => {

  const styles = {
    modal: {
      maxWidth: "90vw",
      margin:0, padding:"1%",
    },
  };

    //**Loader - could be slow with large images - give a visual indicator
  const [loading, setLoading] = useState(false);
  const [submitBtnDisabled , setSubmitButtonDisabled] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const getUploadParams = () => {
    return { url: 'https://httpbin.org/post' }
  }

  const handleChangeStatus = ({ meta }, status) => {
    console.log("Status Changed", status, meta)
  }

  useLockBodyScroll(initialState);

  const sizes = useRef({
        grandTotal: 0,
        iteration: 0,
        iterationTotals: [0,0,0,0,0],
        totalSent:0 })

  const advanceProgressBar = progressEvent => {
      const percentage= parseInt(Math.round((progressEvent.loaded * 100 / progressEvent.total) * (sizes.current.iterationTotals[sizes.current.iteration]/sizes.current.grandTotal)
                         + ((sizes.current.totalSent/sizes.current.grandTotal)*100)))
      setUploadPercentage(percentage);
  }
    
  const handleSubmit = async (files, allFiles) => {
    setSubmitButtonDisabled(true)
    setLoading(true);  //Put on spinner
    sizes.current =  {
        grandTotal: 0,
        iteration: 0,
        iterationTotals: [0,0,0,0,0],
        totalSent:0 }
    const lgth = files.length
    const retArray = []
    let promises = []
    let index = 0
    let maxSize = 0
        //Get the largest file
    for (let j = 0; j < lgth && j < ConcurrentNum; j++)  {
        if (files[j].meta.size > maxSize)
        {  index = j; maxSize = files[j].meta.size;  }
        sizes.current.iterationTotals[sizes.current.iteration] += files[j].meta.size
    }
    for (let i = 0; i < lgth; i++)
        sizes.current.grandTotal += files[i].meta.size
          //**S3 logic here
    for (let i = 0; i < lgth; i++)
    {   const uploadConfig = await axios.get(augmentApi('/api/upload'), { params: { 'ContentType': files[i].meta.type }});  //Get pre-signed URL
        console.log("Image Put to S3", uploadConfig.data.url, resize);

        const currentURL = window.location.href
        let protocol = "https"
        if (currentURL.startsWith("http://"))
           protocol = "http"
        const url = protocol+"://cf.sibsforever.org/"+uploadConfig.data.key;
        let resz = isEmpty(resize) ? null : { ...resize }
        if (resz !== null)
           resz.image = url
        resz.key = i;  //lookup the result

          //Return object
        let retObj = { url: url, key: i, ...resize }

            //Queue the promise to push the file and resize it if requested
        let  options = 
              {   headers: { 'ContentType': files[i].meta.type,
                             'AccessControlAllowOrigin': '*'
                           },
                  timeout: FileTimeout
              } 
        if (i === index)
              options.onUploadProgress = advanceProgressBar

        promises.push(
           axios.put(uploadConfig.data.url, files[i].file, options)
           .then (() => (resz !== null) ? axios.post('/api/resize_image', resz) : null) 
           .then(ret => { if (ret != null) 
                          {  retObj = {...retObj, ...ret.data}
                             retArray.push(retObj);
                          }
                          else if (resz === null)
                             retArray.push(retObj);
                        })
           .catch(err=>{ console.log("Image Resize Failed", err); axios.put('/api/delete_image', { path: uploadConfig.data.url })})
       )

       if ( (i+1) % ConcurrentNum === 0)  //Browser only supports 6 hreads for API calls
       {    await Promise.allSettled(promises)
                         .then(()=> console.log("Drag Promises[] completed") )
                         .catch(err=>console.log("Drag Promises[] error", err))
            promises = []
               //**Get next max
            sizes.current.totalSent += sizes.current.iterationTotals[sizes.current.iteration]
            sizes.current.iteration++; maxSize = 0
            for (let j = i+1; j < lgth && j < ConcurrentNum*(sizes.current.iteration+1); j++)  {
                if (files[j].meta.size > maxSize)
                {  index = j; maxSize = files[j].meta.size;  }
                sizes.current.iterationTotals[sizes.current.iteration] += files[j].meta.size
            }
       }
    }

    if (promises.length > 0)
       await Promise.allSettled(promises)
         .then(()=> console.log("Drag Promises[] completed") )
         .catch(err=>console.log("Drag Promises[] error", err))

    for (let i=0; i < lgth; i++)
           //**cleanup
        files[i].remove();

    setSubmitButtonDisabled(false)
    await setCloseData(retArray); 
    setLoading(false);  //disable spinner
    setUploadPercentage(0)
  }

  return (
    <>
      <Modal
        open={initialState}
        onClose={() =>  {  setLoading(false); setSubmitButtonDisabled(false); setClose()  }}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        styles={styles}
        container={document.getElementById('portal')} //Added to display outside of the body
        center
      >
        <Heading style={{padding:0, margin:0}}>&nbsp;</Heading>

        <div style={{textAlign: 'center', display:uploadPercentage > 0 ? "block" : "none"}}>
           <Loader loading={loading} color='#007bff' size={60} />
           <ProgressBar completed={uploadPercentage} bgColor='#007bff' margin="10px 2px" />
        </div>

        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          submitButtonDisabled={submitBtnDisabled}
          maxFiles={12}
          multiple={true}
          accept="image/gif, image/jpg, image/jpeg, image/png, image/bmp, image/x-bmp, image/tiff, image/apng, image/avif, image/webp"
          inputContent={(files, extra) => (extra.reject ? 'Image files only' :

              <center>
              <p style={{paddingTop: 18, paddingBottom:18}}>{prompt}</p>
              <MdCloudUpload style={{ fontSize: "4em", padding: "40 0 0 0", margin: 0, color: "#bdbdbd" }} />
              <div style={{ color: "#bdbdbd", fontSize: 18, padding:"0 5 5 5", margin: 0}}> Drag 'n' drop files here, or click to select files</div>
              </center>
          )}

          classNames={{ submitButtonContainer: "Button" }}
          styles={{ dropzone: { minHeight: 280, width: "min(500px, 90vw)", maxHeight: 800},
                    submitButton: { backgroundColor: "#007bff" },
                    dropzoneActive: { backgroundColor: "green", borderColor: "green" },
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {})
                  }}
        />
        <small><i>* &nbsp;&nbsp; At most 12 pictures at a time</i></small>
      </Modal>
    </>
  );
};

export default DragPopup

