import { Box, Section, Content, Container } from "react-bulma-components";

/** Responses from forms
**/

const FormResponse = ({title, message, detail})  => {

   return  (
    <Container>
    <Section>
      <Box style={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"}}>
      <Content>
          <center>
          <h4>{message}</h4>
          {detail}
          </center>
      </Content>
      </Box>
    </Section>
    </Container>
   )
}

export function Handle401() 
{   return  <FormResponse
                title="Login Session Ended"
                message="Your login session has expired."
                detail="Log back in to continue working in your private SibsForever account."
            />
}

export function AuthFailed() 
{   return  <FormResponse
                title="Problems"
                message="Your login attempt was not successful."
                detail="Please ensure that you have an active SibsForever account and your credentials were entered correctly."
            />
}

export function DonateSuccess() 
{   return  <FormResponse
                title="Success"
                message="Your donation has been successfully processed."
                detail="You will receive an email receipt shortly.  Thank you so much for supporting the SibsForever mission and community."
            />
}

export const DonateFailed = () =>
{   return  <FormResponse
                title="Problems"
                message="An error was encountered when processing your donation."
                detail="Please try again and make sure that all information is entered correctly. Thank you so much for supporting the SibsForever mission and community."
            />
}

export const AccountSuccess = () =>
{   return  <FormResponse
                title="Success"
                message="The creation of your SibsForever account is underway."
                detail="You will receive an email shortly containing a one-time link that when selected, will log you into the new account.  Thank you so much for supporting the SibsForever mission and community."
            />
}

export const AccountFailed = () =>
{   return  <FormResponse
                title="Problems"
                message="An error was encountered when creating your SibsForever account"
                detail="Please try again and make sure that all information is entered correctly. Thank you so much for supporting the SibsForever mission and community."
            />
}

export const InviteSent = () =>
{   return  <FormResponse
                title="Success"
                message="An invitation to your SibsForever account has been sent out"
                detail="The new member will receive an email containing a one-link that when selected, will give them access to your sibsForever site with the security settings you have configured."
            />
}

export function LinkSent() 
{   return  <FormResponse
                title="Login Link"
                message="Your request for a login link has been processed."
                detail="If you have an active SibsForever account, you will receive an email with your login link"
            />
}

export const ContactFormSubmit = () =>
{   return  <FormResponse
                title="Contact Data Sent"
                message="Thank you for contacting SibsForever."
                detail="Someone from SibsForever will respond to your inquiry shortly."
            />
}

export const PhotoSubmit = () =>
{   return  <FormResponse
                title="Photo Submitted"
                message="Thank you for submitting a photo to be included on the SibsForever front page."
                detail="Upon approval, your picture will be incorporated and a notification will be sent to you via email."
            />
}

export const BugFormSubmit = () =>
{   return  <FormResponse
                title="Bug data sent"
                message="Thank you for reporting bugs and/or communicating ideas to SibsForever"
                detail="Someone from SibsForever will get back to you with current status and related future plans."
            />
}
