import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react';
import { Card, Box, Section, Container, Heading } from 'react-bulma-components';
import CarouselGallery from './loggedIn/carousel/CarouselGallery'
import { useSelector } from 'react-redux'
import { checkNew  } from '../actions/sibSite'
import './landing.css'

const WhatsNew = (props) => {

   const styles = {
     card1: { backgroundColor: "white"},
     card2: { backgroundColor: "var(--card-footer-color)"}
   }

   let rstyles = {}

   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user);

   const [ isReady, setIsReady ] = useState(false)
   const [ cards, setCards ] = useState( {card1: "", card2: "", card3: "", card4: "", card5: "" })

   useEffect( () => {
       const loadData = async () => {
          let myCards =  {card1: "", card2: "", card3: "", card4: "", card5: "" }
          const myRet = await checkNew(user, true)  //**logging turned on.  This would expire messages and change query data
          if (!isEmpty(myRet))
          {   myCards.card1 = myRet.ret.card1
              myCards.card2 = myRet.ret.card2
              myCards.card3 = myRet.ret.card3
              myCards.card4 = myRet.ret.card4
              myCards.card5 = myRet.ret.card5
              setCards(myCards)
          }
       }

       if (auth === true && !isEmpty(user) && !isEmpty(user.email))
       {  loadData()
          setIsReady(true)
       }
       if (auth === false)
          setIsReady(true)
   }, [auth, user])

   if (auth === null || isEmpty(user) || isEmpty(user.email) || isReady === false)
      return <div>Loading...</div>
   if (auth === false)
      return ""
      //Zebra effect
   let c = 1;
   if (cards.card1 !== "")
   {  rstyles.card1 = c==1 ? styles.card1 : styles.card2;  
      c = c == 1 ? 2 : 1
   }
   if (cards.card2 !== "")
   {  rstyles.card2 = c==1 ? styles.card1 : styles.card2;  
      c = c == 1 ? 2 : 1
   }
   if (cards.card3 !== "")
   {  rstyles.card3 = c==1 ? styles.card1 : styles.card2;  
      c = c == 1 ? 2 : 1
   }
   if (cards.card4 !== "")
   {  rstyles.card4 = c==1 ? styles.card1 : styles.card2;  
      c = c == 1 ? 2 : 1
   }
   if (cards.card5 !== "")
      rstyles.card5 = c==1 ? styles.card1 : styles.card2;  

    return (
       <> 
       <center><Container className="is-narrow"> 
        {cards.card1!==""?<div className="box card has-text-justified width bshadow-outline narrow" style={rstyles.card1} dangerouslySetInnerHTML={{__html:cards.card1}}></div>:""}
        {cards.card2!==""?<div className="box card has-text-justified width bshadow-outline narrow" style={rstyles.card2}  dangerouslySetInnerHTML={{__html:cards.card2}}></div>:""}
        {cards.card3!==""?<div className="box card has-text-justified width bshadow-outline narrow" style={rstyles.card3}  dangerouslySetInnerHTML={{__html:cards.card3}}></div>:""}
        {cards.card4!==""?<div className="box card has-text-justified width bshadow-outline narrow" style={rstyles.card4}  dangerouslySetInnerHTML={{__html:cards.card4}}></div>:""}
        {cards.card5!==""?<div className="box card has-text-justified width bshadow-outline narrow" style={rstyles.card5}  dangerouslySetInnerHTML={{__html:cards.card5}}></div>:""}
      </Container></center>
      </>
      
    )
  }

export default WhatsNew;
