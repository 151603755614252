import React from 'react';
import Collapsible from 'react-collapsible';
import { Wrapper } from './style';

const FaqItem = ({ title, children }) => (
<Wrapper>
    <Collapsible
      className="faq"
      openedClassName="faq active"
      triggerClassName="faq-title down-arrow"
      triggerOpenedClassName="faq-title up-arrow"
      triggerTagName="article"
      contentInnerClassName="faq-content"
      trigger={title}
      transitionTime={300}
      easing="ease-out"
    >
      {children}
    </Collapsible>
</Wrapper>
);

export default FaqItem;
