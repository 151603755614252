import isEmpty from 'lodash/isEmpty'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, {useEffect, useState, useRef}  from 'react';
import { NavLink } from 'react-router-dom';
import { updatePageSettings } from '../../../actions/settings'
import usePagePerms from '../hooks/usePagePerms'
import Tooltip from 'rc-tooltip'
//import 'rc-tooltip/assets/bootstrap_white.css';
import 'rc-tooltip/assets/bootstrap.css';


  //Published Checkbox on sidebar which changes the sharedPage setting more visibly
const PublishedCheckbox = ({pageType, pageInstance='singleton'}) => { 

   const dispatch = useDispatch();
   const accountSettings  = useSelector(state => state.accountSettings, shallowEqual);

   const [sharedPage, setSharedPage] = useState(false)
   const [ canAdmin, canEdit, canView, isResolved ] = usePagePerms(pageType, pageInstance)  //This will add the AccountSettings record if it is not there
   const row = useRef({})

   useEffect(() => {
      if (!isEmpty(accountSettings))
      {   row.current = accountSettings.find(rec => rec.pageName === pageType && rec.pageInstance === pageInstance)
          if (!isEmpty(row.current))  
             setSharedPage(row.current.sharedPage)
      }

     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [accountSettings]);
   const toggleSharedPage = () => { dispatch(updatePageSettings(pageType, pageInstance, !sharedPage, row.current.editPagePrivacy, !sharedPage?3:row.current.viewPagePrivacy)); setSharedPage(!sharedPage); }

   if (isEmpty(accountSettings) || !isResolved)
      return <div>Loading...</div>
   if (!canAdmin)
      return <div>You are not Authorized</div>

   let pp = "page"
   if (pageType === 'home')
      pp = "home page"
   else if (pageType === "diary")
      pp = "journal entry"
   else if (pageType === 'carousel')
      pp = "picture gallery"
   else if (pageType === 'vcarousel')
      pp = "video gallery"
   else if (pageType === "wall")
      pp = "wall"

   return (
     <>
     <label className="checkbox xmenu-block is-hidden-moblie">
       <input type="checkbox" className="is-hidden-mobile" checked={sharedPage} onChange={toggleSharedPage}/>
       <span className="is-hidden-mobile"> &nbsp;Shared</span>
     </label>
       <Tooltip clasName="is-hidden-mobile" placement="right" trigger={['click']} 
                                  overlay={<span>Select the <b>Shared checkbox</b> when you are ready to publish your {`${pp}`}<br/>(pages are only visible to other SibsForever users)</span>}>
           <i className="is-hidden-mobile fa fa-question-circle"></i>
       </Tooltip>
     </>
    )
}

export default PublishedCheckbox
