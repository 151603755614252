export const FETCH_USER = 'FETCH_USER';
export const GET_USER = 'GET_USER'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_PICTURE = 'UPDATE_ACCOUNT_PICTURE'
export const UPDATE_DONOR_INFO = 'UPDATE_DONOR_INFO';
export const UPDATE_HOME_PAGE = 'UPDATE_HOME_PAGE';
export const UPDATE_HOME_PAGE_NODISK = 'UPDATE_HOME_PAGE_NODISK';
export const UPDATE_CAROUSEL_PAGE = 'UPDATE_CAROUSEL_PAGE';
export const UPDATE_VCAROUSEL_PAGE = 'UPDATE_VCAROUSEL_PAGE';
export const UPDATE_CAROUSEL_PAGE_NODISK = 'UPDATE_CAROUSEL_PAGE_NODISK';
export const UPDATE_VCAROUSEL_PAGE_NODISK = 'UPDATE_VCAROUSEL_PAGE_NODISK';
export const UPDATE_PAGE_SETTINGS = 'UPDATE_PAGE_SETTINGS'
export const UPDATE_PAGE_SETTINGS_NODISK = 'UPDATE_PAGE_SETTINGS_NODISK'
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS'
export const UPDATE_DIARY_PAGE = 'UPDATE_DIARY_PAGE';
export const UPDATE_DIARY_INDEX_PAGE = 'UPDATE_DIARY_INDEX_PAGE';
export const UPDATE_DIARY_PAGE_NODISK = 'UPDATE_DIARY_PAGE_NODISK';
export const UPDATE_DIARY_INDEX_PAGE_NODISK = 'UPDATE_DIARY_INDEX_PAGE_NODISK';
export const SET_DIARY_INSTANCE = 'SET_DIARY_INSTANCE'
export const SET_ACCOUNT_OBJECT = 'SET_ACCOUNT_OBJECT'
export const GET_SHARED_PAGE_TYPES = 'GET_SHARED_PAGE_TYPES'
export const POST_NEW_COMMENT = 'POST_NEW_COMMENT'
export const SEE_COMMENTS = 'SEE_COMMENTS'
export const CAROUSEL_BUTTON_LABEL = 'CAROUSEL_BUTTON_LABEL'
export const CAROUSEL_ACTIVE_INDEX = 'CAROUSEL_ACTIVE_INDEX'
