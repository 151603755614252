//Diary Page Editor

import isEmpty from 'lodash/isEmpty'
import React from 'react';
import { useSelector } from 'react-redux'
import DiaryEntrySidebar from './DiaryEntrySidebar'
import DiaryEntryEditor from './DiaryEntryEditor'
import { Heading, Container, Section} from 'react-bulma-components';
import '../sidebar.css'
import { getBannerText } from '../functions/location_fcns'

const SummaryChangePage = (props) => {

   const user = useSelector(state => state.user);
   const heading = isEmpty(user) || isEmpty(user.email)?"": getBannerText("Journal Entry", user) 
   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"}
   }

   const DiaryBreadcrumb = 
     <nav className="breadcrumb is-hidden-mobile has-succeeds-separator" aria-label="breadcrumbs" style={{margin:"0", padding:0}}>
     <ul>
        <li><a href="/auth/diary/toc">&nbsp;Diary Table of Contents</a></li>
        <li className="is-active"><a href="/auth/diary/edit" aria-current="page">Edit Diary Entry</a></li>
      </ul>
    </nav>


   return (
     <>
     {DiaryBreadcrumb}
     <Section className="columns" style={{padding:"1vw", marginTop:0}}>
      <div className="aside"><DiaryEntrySidebar isActive="Summary" className="menu"/></div>

     <Container className="column is-9" style={{paddingTop:0}}>
     <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{heading}</Heading>
     <DiaryEntryEditor isChange={true}/>
     </Container>
     </Section>
     </>
   )
  }

export default SummaryChangePage

