import { isPageRO } from '../functions/location_fcns'
import DiaryEntrySidebarRO from './DiaryEntrySidebarRO'
import DiaryEntrySidebarRW from './DiaryEntrySidebarRW'

const DiaryEntrySidebar = () => { 

   if (isPageRO())
       return <DiaryEntrySidebarRO />
   else
       return <DiaryEntrySidebarRW />
}

export default DiaryEntrySidebar

