import isEmpty from 'lodash/isEmpty'
import React, {useRef, useState, useEffect} from 'react';
import { Card } from 'react-bulma-components';
import { useSelector } from 'react-redux';
import SettingsPopup from './SettingsPopup'
import { FaLock } from "@react-icons/all-files/fa/FaLock";
import { WALL_ENTRY_KEY, WALL_ENTRY_RECORD, getSessionStorage, clearSessionStorage } from '../functions/session_fcns'

const SettingsButton = ({pageType, pageInstance='singleton'}) => {  
   const [open, setOpen] = useState(false); //**Start out popup not-open
   const [isPrivate, setIsPrivate] = useState("")
   const isDone = useRef(pageType!=='wall'||pageInstance!=='singleton'?true:false )

     //*Redux to see if private -> put up lock icon (new wall entry posted)
   const accountSettings = useSelector(state => state.accountSettings)

     //**See if we need to start out with the popup open
   useEffect(() => {
       if (pageType === 'wall' && pageInstance !== 'singleton') {
           const instance = getSessionStorage(WALL_ENTRY_KEY);
           if (!isEmpty(instance) && instance === pageInstance)
           {   setOpen(true)
               clearSessionStorage([WALL_ENTRY_KEY, WALL_ENTRY_RECORD])  //**Only once
           }
           isDone.current = true;
       }
   }, []);

   useEffect(() => {
      if (!isEmpty(accountSettings))
      {   const row = accountSettings.find(rec => rec.pageName === pageType && rec.pageInstance === pageInstance)
          if (!isEmpty(row) && row.viewPagePrivacy === 1)
             setIsPrivate(<span><FaLock size="12" />&nbsp;</span>) 
          else if (!isEmpty(row) && (pageType === 'carousel' || pageType === 'vcarousel') && pageInstance !== 'singleton' && row.sharedPage === false)
             setIsPrivate(<span><FaLock size="12" />&nbsp;</span>) 
          else if (!isEmpty(row) && row.viewPagePrivacy > 1)
             setIsPrivate("")
      }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [accountSettings]);
   
   if (!isDone.current)
       return <div>Loading...</div>
   return (
      <>

       <a href="#" className="card-footer-item footer-buttons" onClick={() => setOpen(true)}>
               {isPrivate}
               Sharing Settings
       </a>
       <SettingsPopup initialState={open} setClose={() => setOpen(false)} pageType={pageType} pageInstance={pageInstance}/>
    </>
    )
  }

export default SettingsButton;
