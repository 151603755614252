/** Page Help **/

import React, { useRef, useState } from 'react'
import { Button, Heading } from 'react-bulma-components';
import { Modal } from 'react-responsive-modal';
import useLockBodyScroll from '../../hooks/useLockBodyScroll'

const HelpLink = ({linkText, header, body, scrolltop=true, isLink=true}) => {

   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"},
     helpStyle: { fontSize: "1.3em", marginLeft: "2px" }
   }

   const [ helpActive, setHelpActive ] = useState(false)
   const ref = useRef()

   const rComp = isLink ?
          <a href="#" style={{whiteSpace:"nowrap"}} className="sibs-link" onClick={ () => setHelpActive(true)}>{linkText}</a>
           :
          <Button className="button is-link is-medium" style={{margin: "0 0"}} size="medium" onClick={ () => setHelpActive(true)}>{linkText} </Button>

   useLockBodyScroll(helpActive);

   return (
       <>
       {rComp}

       <Modal
          open={helpActive}
          onClose={() => setHelpActive(false)}
          closeOnOverlayClick={true}
          showCloseIcon={true}
          initialFocusRef={scrolltop?ref:undefined}
          center
       >

          <Heading ref={ref} subtitle style={styles.subStyle} className="has-text-weight-semibold">{header}</Heading>
          <div style={{backgroundColor: 'white', maxWidth: "1022px"}}>
          <div style={{padding:'8px 8px', margin:0, textAlign:"left"}} className="jodit-wysiwyg" dangerouslySetInnerHTML={{__html: body}}></div>
          </div>

      </Modal>
      </>
   )
}


export default HelpLink
