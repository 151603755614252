import { useForm, Controller } from "react-hook-form";
import { Button, Box, Columns, Form, Section, Heading } from "react-bulma-components";
import { checkName } from '../../common/formFunctions'
import ReactDatePicker from 'react-date-picker'

  //**Ensure date is not converted to local time which could change the day
const getDate = dstr => {
  const pieces = dstr.split('-')
  return new Date(pieces[0], parseInt(pieces[1]-1), parseInt(pieces[2]))
}
const DatePicker = (date, onChange) => {
    return (
        <ReactDatePicker
            className="input"
            showLeadingZeros={true}
            isOpen={false}
            openCalendarOnFocus={false}
            clearIcon={null}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            disableCalendar={true}
            onChange={onChange}
            maxDate={new Date()}
            minDate={new Date(1925, 0, 1)}
        />
    )
};


const SibPanel = ({first, dob, onEnd}) => {  


const { handleSubmit, errors, control } = useForm();
  const onSubmit = async data => {
    onEnd(data)
  }

  return (
    <>
    <Section style={{padding:0}}>
      <Box style={{padding:"1% 2%"}}>
 
      <Heading subtitle size={4}>Edit Surviving Sibling Details</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
          <Columns>
          <Columns.Column className="is-one-half">
	  <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>First Name</Form.Label>
            <Form.Control>
                <Controller
                    as={Form.Input}
                    name="sibFirst"
                    placeholder="Surviving Sib's First Name"
                    defaultValue={first}
                    control={control}
                    maxLength={24}
                    rules={{ required: true,
                             validate: name => { const rc = checkName(name); return rc }
                          }}

                />
            <Form.Help textColor="danger">
                  {errors.sibFirst && errors.sibFirst.type === "required" && "Sibling's first name is required"}
                  {errors.sibFirst && errors.sibFirst.type === "validate" && "Name must contain between 2-31 valid letters." }
            </Form.Help>
            </Form.Control>
          </Form.Field>
          </Columns.Column>

          <Columns.Column className="is-one-half">
          <Form.Field style={{marginLeft:"10px"}}>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control>
               <Controller
                    as={DatePicker(getDate(dob), date => date)}
                    onChange={date => date}
                    type="date"
                    style={{width:'12em'}}
                    name="sibDob"
                    control={control}
                    defaultValue={getDate(dob)}
                    placeholder="Suviving Sib's Birth Date"
                    rules={{ required: true }}
               />
            </Form.Control>
            <Form.Help textColor="danger">
                  {errors.sibDob && errors.sibDob.type === "required" && "Sibling's birth date is required"}
            </Form.Help>
          </Form.Field>
          </Columns.Column>
          </Columns>
          <Form.Field>
            <Form.Control>
              <center><Button fullwidth={false}>Save Changes</Button></center>
            </Form.Control>
          </Form.Field>
       </form>
       </Box>
     </Section>
     </>
  );
}

export default SibPanel;
