import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-responsive-modal';  //doc: https://react-responsive-modal.leopradel.com/
import { Heading } from 'react-bulma-components';
import Loader from "react-spinners/BeatLoader";
import  Dropzone from 'react-dropzone-uploader'; //doc: https://react-dropzone-uploader.js.org/docs/quick-start
import 'react-responsive-modal/styles.css';
//import 'react-dropzone-uploader/dist/styles.css'
import '../../DropZoneStyles.css' //Made local to disable the progress bar that was stuck displaying blue
import useLockBodyScroll from '../../loggedIn/hooks/useLockBodyScroll'
import CarouselGallery from '../../loggedIn/carousel/CarouselGallery'
import {augmentApi} from '../../loggedIn/functions/location_fcns'
import { MdCloudUpload } from "@react-icons/all-files/md/MdCloudUpload";
import ProgressBar from "@ramonak/react-progress-bar";

const FileTimeout = 1000 * 60 * 2

const DragPopupSingle = ({ prompt, initialState, setCloseData, setClose, resize, imageGalleryStart=false } ) => {

  const styles = {
    modal: {
      maxWidth: "90vw",
      margin:0, padding:"1%",
    },
  };

  const Checkbox = ({ label, value, onChange }) => {
  return (
    <label className="checkbox">
      <input type="checkbox" checked={value} onChange={onChange} />
      &nbsp;&nbsp;{label}&nbsp;&nbsp;&nbsp;&nbsp;
    </label>
  );
};

  const [useImageGallery, setUseImageGallery ] = useState(imageGalleryStart)

    //**Loader - could be slow with large images - give a visual indicator
  const [loading, setLoading] = useState(false);
  const [submitBtnDisabled, setSubmitButtonDisabled] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const getUploadParams = () => {
    return { url: 'https://httpbin.org/post' }
  }

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta)
  }

  useLockBodyScroll(initialState);

     //Image chosen from gallery
  const handleSelect = async src => {
    setLoading(true);  //Put on spinner
    const p = src.split('/')
    const pieces = p[p.length-1].split('.')

    try {
       const newKey = await axios.post(augmentApi('/api/copyFile'), { source: pieces[0], suffix: pieces[pieces.length-1]   } )
       resize.dcopy = false;  //**disable copyng to gallery (since pic chosen from gallery)
       await finishSubmit(newKey.data.newKey)
    } catch(err) { console.log("Copy of file failed", src) }
  }

  const advanceProgressBar = progressEvent => {
      const percentage= parseInt(Math.round((progressEvent.loaded * 100) /progressEvent.total));
      setUploadPercentage(percentage);
  }

    //**Picture chosen from client computer
  const handleSubmit = async (files, allFiles) => {
    setSubmitButtonDisabled(true)
    setLoading(true);  //Put on spinner
          //**S3 logic here
    const uploadConfig = await axios.get(augmentApi('/api/upload'), { params: { 'ContentType': files[0].meta.type }});  //Get pre-signed URL
    try {
       await axios.put(uploadConfig.data.url, files[0].file,
             { headers: { 'ContentType': files[0].meta.type,
                          'AccessControlAllowOrigin': '*'
                        },
               timeout: FileTimeout,
               onUploadProgress : advanceProgressBar,
             } );
       console.log("Image Put to S3", uploadConfig.data.url, files[0].meta.width, files[0].meta.height, resize);
       await finishSubmit(uploadConfig.data.key, files)
    } catch(err) {  console.log("Put of file failed", uploadConfig.data.url, err); 
                    await axios.put('/api/delete_image', { path: uploadConfig.data.url })
                    errReturn();
                 }
  }

  const finishSubmit = async (key, files=null) => {
         //**This uses cloudfront signed cookies to ensure that content can only be accessed through this app (on the sibsforever domain)
         //**At home, it is http.  And it is a CORS violation if there is mismatch between the cloudfront URL and the cookie's URL
    const currentURL = window.location.href
    let error = false
    let protocol = "https"
    if (currentURL.startsWith("http://"))
       protocol = "http"
    const url = protocol+"://cf.sibsforever.org/"+key

      //Return object
    let retObj = !isEmpty(resize) ? { url: url, ...resize} : { url: url }
      //Resize the image here - as quickly as possible
    if (!isEmpty(resize))
    {   resize.image = url
        if (files === null)
           resize.quality = 100
        try {
           const ret = await axios.post('/api/resize_image', resize);
           retObj = { ...retObj, ...ret.data }
        }
        catch(err)   {  console.log("Image resize failed", err)
                        error = true;
        }
    }
          //**cleanup
    if (!isEmpty(files))
       files[0].remove();

    if (error === false) {
       setSubmitButtonDisabled(false)
       setUseImageGallery(false)
       setLoading(false);  //disable spinner
       setUploadPercentage(0)
       await setCloseData(retObj); //*Close popup
     }
     else
       errReturn();
  }

  const errReturn = () =>
  {   setSubmitButtonDisabled(false)
      setUseImageGallery(false)
      setLoading(false);  //disable spinner
      setUploadPercentage(0)
      setClose()
  }

  return (
    <>
      <Modal
        open={initialState}
        onClose={() => { setUseImageGallery(false); setLoading(false); setSubmitButtonDisabled(false); setClose()}}
        closeOnOverlayClick={false}
        classNames={{closeIcon: "delete"}}
        styles={styles}
        container={document.getElementById('portal')} //Added to display outside of the body
        center
      >
        <Heading style={{padding:0, margin:0}}>&nbsp;</Heading>

        <div style={{textAlign: 'center', display:uploadPercentage > 0 ? "block" : "none"}}>
           <Loader loading={loading} color='#007bff' size={60} />
           <ProgressBar completed={uploadPercentage} bgColor='#007bff' margin="10px 2px" />
        </div>


        {!useImageGallery?
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          submitButtonDisabled={() => submitBtnDisabled}
          maxFiles={1}
          multiple={false}
          accept="image/gif, image/jpg, image/jpeg, image/png, image/bmp, image/x-bmp, image/tiff, image/apng, image/avif, image/webp, image/x-icon"
          inputContent={(files, extra) => (extra.reject ? 'Image files only' : 
             <>
              <center> 
              <p style={{paddingTop: 18, paddingBottom:18}}>{prompt}</p>
              <MdCloudUpload style={{ fontSize: "4em", padding: "40 0 0 0", margin: 0, color: "#bdbdbd" }} />
              <div style={{ color: "#bdbdbd", fontSize: 18, padding:"0 5 5 5", margin: 0}}> Drag 'n' drop a file here, or click to select file</div>
              </center>
              </>
          )}

          classNames={{ submitButtonContainer: "Button" }}
          styles={{ dropzone: { minHeight: 280, maxHeight: 800, width: "min(500px, 90vw)"},
                    submitButton: { backgroundColor: "#007bff" },
                    dropzoneActive: { backgroundColor: "green", borderColor: "green" },
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {})
                  }}
        />
        : <>
          <CarouselGallery
          choosePicture={true}
          onSelect={src => handleSelect(src)}
          />
          <div style={{textAlign: 'center'}}> <Loader loading={loading} color='#007bff' size={60} /></div>
         </>
        }
        <br/>
        <Checkbox
            label="Choose Image from Gallery"
            value={useImageGallery}
            onChange = {() => setUseImageGallery(!useImageGallery)}
        />
      </Modal>
    </>
  );
};

export default DragPopupSingle;

