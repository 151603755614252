/** Page Help **/

import React, { useState } from 'react'
import { Heading } from 'react-bulma-components';
import { Modal } from 'react-responsive-modal';
import useLockBodyScroll from '../../hooks/useLockBodyScroll'

const PageHelp = ({header, body}) => {

   const styles = {
     subStyle: { marginTop:"8px", marginBottom: "8px"},
     helpStyle: { fontSize: "1.3em", marginLeft: "2px" }
   }

   const [ helpActive, setHelpActive ] = useState(false)

   useLockBodyScroll(helpActive);

   return (
       <>
       <a href="#" onClick={ () => setHelpActive(true)}> <i className="fa fa-info-circle" style={styles.helpStyle}></i></a>
       <Modal
          open={helpActive}
          onClose={() => setHelpActive(false)}
          closeOnOverlayClick={true}
          showCloseIcon={true}
          center
       >

          <Heading subtitle style={styles.subStyle} className="has-text-weight-semibold">{header}</Heading>
          <div style={{backgroundColor: 'white', maxWidth: "1022px"}}>
          <div style={{padding:'8px 8px', margin:0, textAlign:"left"}} className="jodit-wysiwyg" dangerouslySetInnerHTML={{__html: body}}></div>
          </div>

      </Modal>
      </>
   )
}


export default PageHelp
