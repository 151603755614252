//**Editor functions for HOme Page and Diary

import axios from 'axios'
import { augmentApi } from './location_fcns'

//Check for any deleted images so we can flag them and remove the files
export const processDeletedImages = async (text1, text2) => {
   const MATCH_STR = '//cf.sibsforever.org/'

   const lines1 = text1.split(MATCH_STR)
   const lines2 = text2.split(MATCH_STR)

   const size = lines1.length
   if (size <= lines2.length)
      return false
   for (let i = 1; i < size; i++)
   {   const fileName = lines1[i].slice(0, lines1[i].indexOf('"'));
       if (text2.indexOf(MATCH_STR+fileName) < 0)
           await axios.post(augmentApi('/api/remove_image'), { path: fileName })
   }
   return true
}


    //Apply shim on the end of each image (!video)
export const applyShim = html =>
{
    const pieces = html.split('//cf.sibsforever.org')
    const len = pieces.length;
    let output = pieces[0]

    for (let i = 1; i < len; i++)
    {  const end = pieces[i].indexOf('>')
       if (pieces[i].startsWith("/video"))  //**Video tag, not image
          output = output.concat('//cf.sibsforever.org', pieces[i].substr(0))
       else
          output = output.concat('//cf.sibsforever.org', pieces[i].substr(0, end+1), '<div style="display: inline-block; height: 0; width: 6em;"></div>', pieces[i].substr(end+1))
    }
    output = output.concat('<br clear="all"/><div style="clear:both"></div><p></p>')
    return output
}

/***
 *  In the editor, we show only poster images.  However for all Previews and for the SibSite, we convert the posters to their respective videos.  
 *  This is the logic that does this
 *
 * **/
export const insert_videos = html => {
    let output = ""

    const pieces = html.split('<img src="https://cf.sibsforever.org/video/')
    const len = pieces.length;
    if (len <= 1)  return html;  //**No movies to process

    else
    {    //JS to support video tags
        output=`<script>  
     try {              //Loop to make sure all videos loaded, if they are not - then we sleep and try to load again (looping while video is being processed)
                      const loaded = []
                      const playing = []
                      const b = setInterval(()=>{
                        if (loaded.length > 0)
                        {  let allLoaded = true;
                           for (let i = 0; i < loaded.length; i++)
                           {    if (loaded[i] === false)
                                    allLoaded=false
                           }
                           if (allLoaded)
                              clearInterval(b); 
                           else for (i = 0; i < loaded.length; i++)
                           {    if (typeof videoList[i] === 'undefined')    
                                {  clearInterval(b);   break; }  //Left the page
                                if (loaded[i] === false)
                                {   videoList[i].load()  }
                           }
                        }
                      }, 5000);
                            //Events on videos
                      const videoList = document.getElementsByTagName('video'); 
                      for (let i = 0; i < videoList.length; i++)
                          loaded[i] = playing[i] = false 
                      for (let i = 0; i < videoList.length; i++) {
                          videoList[i].addEventListener('loadedmetadata', e => {  //Video is not processing and the load has started
                              if (loaded[i] === false)
                              {   loaded[i] = true
                                  const poster = videoList[i].getAttribute('poster')
                                  videoList[i].setAttribute('poster', poster.replace(".300-p.jpg", ".300.jpg"))  //Swap out the processing poster for video poster
                                  videoList[i].load();
                              }
                             })
                          videoList[i].addEventListener('touchstart', e => {
                              e.stopPropagation()
                              if (loaded[i] === false) 
                                  videoList[i].load()
                              else if (playing[i] === false)
                              {   videoList[i].play()
                                  playing[i] = true;
                              }
                              else 
                              {   videoList[i].pause()
                                  playing[i] = false;
                              }
                             })
                          videoList[i].addEventListener('click', e => {
                              e.preventDefault()
                              if (loaded[i] === false)
                                  videoList[i].load()
                              else if (playing[i] === false)
                              {   videoList[i].play()
                                  playing[i] = true;
                              }
                              else 
                              {   videoList[i].pause()
                                  playing[i] = false;
                              }
                             })
                      }
            }
     catch(err)  {  console.log("Err", err);     }
</script>`

    output=output.concat(pieces[0])
    for (let i = 1; i < len; i++)
    {     
        const theEnd = pieces[i].indexOf('>')
        const segment = pieces[i].substring(0, theEnd+1)
        let end = segment.indexOf(' ')
        const eol = segment.substring(end+1)
        end = segment.indexOf('.')
        const key = segment.substring(0,end)

        let movie = `<video controls controlsList="nodownload" disablePictureInPicture playsInline preload="metadata" poster="https://cf.sibsforever.org/video/${key}.300-p.jpg" ${eol}
                          <source src="https://cf.sibsforever.org/video/${key}.mp4" type="video/mp4" />
                          <source src="https://cf.sibsforever.org/video/${key}.webm" type="video/webm" />
                          Sorry, your browser doesn't support embedded videos.
                    </video>`
        output=output.concat(movie, pieces[i].substring(theEnd+1))
    }

    output = output.replaceAll('pointer-events:none; user-select:none;', 'pointer-events: auto; user-select: auto;');
    output = output.replaceAll('pointer-events: none; user-select: none;', 'pointer-events: auto; user-select: auto;');

    return output
} }

  //On smaller screens, add a shim after each image so we don't end up with just a few chars flowing around the image
export const shimmy = html => {
    if (window.innerWidth >= 768)
       return html.concat('<br clear="all"/><div style="clear:both"></div><p></p>')
    else
       return applyShim(html)
}
