import React, { useState } from 'react';
import { Card } from 'react-bulma-components';
import AddCommentPopup from './AddCommentPopup'

const WriteCommentButton = ({pageType, pageInstance, onClickFunc=undefined}) => {  //Could use object destructuring for const XXX = ({prop1, prop2}) =>

   const [open, setOpen] = useState(false); //**Start out popup not-open

   const onEnd = (data) =>
   {  setOpen(false); //**Close the popup
   }

   return (
    <>
     <a href="#" className="card-footer-item footer-buttons" onClick={() => { if (onClickFunc !== undefined) onClickFunc(); setOpen(true)}}>Comment</a>
     <AddCommentPopup
           initialState={open}
           setCloseData={data => onEnd(data) }
           setClose={()=>setOpen(false) }
           pageType={pageType}
           pageInstance={pageInstance}
       />
    </>
    )
  }

export default WriteCommentButton;

