import { Footer, Container, Content } from 'react-bulma-components';

const Feeter = (props) => {  

   if ( window.location !== window.parent.location) //In an iframe
      return ""
   else return (
      <>
       <hr style={{margin:0, padding:0}}/>
       <Footer className="is-bold" style={{minHeight:"82px", padding:"10px 3vw", position: "relative", width: "100vw" }}>
          <Container>
            <Content style={{ textAlign:'center', color: "var(--primary)" }}>
                 SibsForever is a registered 501(c)(3) tax-exempt non-profit organization registered in the United States (EIN: 85-2863304)
                 <br/>
                <a href="/contact">Contact</a>&nbsp; | &nbsp;
                <a href="/privacy">Privacy</a>&nbsp; | &nbsp;
                <a style={{whitespace: "nowrap"}} href="/tos">Terms of Use</a>&nbsp; | &nbsp;
                <a style={{whiteSpace: "nowrap"}} href="/policy/deletion">Data Deletion</a>
                <center>
                <a href="https://facebook.com/sibsforever"><img src="https://sibsforever.org/home/facebook.png" width="32px" style={{display: "inline"}} /></a>
                 &nbsp;  &nbsp;
                <a href="https://instagram.com/sibs.forever"><img src="https://sibsforever.org/home/instagram.png" width="32px" style={{display: "inline"}} /></a>
                </center>
                <small>&copy; Copyright 2022, SibsForever. All Rights Reserved.</small>
            </Content>
          </Container>
        </Footer>
     </>
    )
}

export default Feeter
