import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import React, { useRef, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import axios from 'axios'
import { getCarouselPage, getVideoCarouselPage, updateCarouselPage, updateVideoCarouselPage } from '../../../actions/carouselPage'
import './slideshow.css'
import LightboxOverlay from './LightboxOverlay'
import usePagePerms from '../hooks/usePagePerms'
import Gallery from "react-photo-gallery";
import Photo from "./Photo";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import CarouselNew from './CarouselNew'
import VideoCarouselNew from './VideoCarouselNew'
import { augmentApi, isVideoCarouselPage, isAllPages,  getParam, isPublicA, isAuthPage, isEsharePage, getAccountOverride, isPageRO } from '../functions/location_fcns'
import { CAROUSEL_NEWBY_ACCOUNT, VCAROUSEL_INDEX, CAROUSEL_INDEX, getSessionStorage, clearSessionStorage } from '../functions/session_fcns'

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement(item => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items }) => (
  <Gallery photos={items} targetRowHeight={200} renderImage={props => <SortablePhoto {...props} />} />
));

   //This component serves these three useCases
const CarouselGallery = ({reorder=false, lightboxView=false, lightboxEdit=false, publicV=false, isNew=false, choosePicture=false, onSelect, videoGallery=false}) => {  

   const styles = 
   {  lock: {fontSize: "1.3em", position: "absolute", top: "2px", left: "2px", color: "#fada5e"},
      changes: { fontSize: "1.3em", position: "absolute", top: "2px", left: "2px"}
   }

   if (!videoGallery && isVideoCarouselPage()) videoGallery = true
   const pageName = videoGallery ? 'vcarousel' : 'carousel'
   const publicA = isPublicA(); //Aud's sample
   const dispatch = useDispatch();
   const auth = useSelector(state => state.auth); //Logged in?
   const user = useSelector(state => state.user); //Logged in?
   const [ , canEditCarousel, canViewCarousel, isResolvedCarousel ] = usePagePerms(pageName, 'singleton', publicV?true:false, isEsharePage(), publicV)
   const carouselContent = useSelector(state => videoGallery ? state.videoCarouselPageContent : state.carouselPageContent);
   const accountSettings  = useSelector(state => state.accountSettings, shallowEqual);

   const [pageLoadDone,  setPageLoadDone ] = useState(false);
   const [imagesLoaded,  setImagesLoaded ] = useState(false);
   const [items, setItems] = useState([]);
   const [numLoadTries, setNumLoadTries] = useState(0)
   const [failedItems, setFailedItems] = useState([])
   const [containerName, setContainerName] = useState("slideshow-container-0")

   const [currentImage, setCurrentImage] = useState(0);
   const [lightboxOpen, setLightboxOpen] = useState(false)
   const [lightboxCount, setLightboxCount] = useState(0);
   const [lockedArray, setLockedArray] = useState([])

   const savedContent = useRef([]); 
   const firstFlg = useRef(true)
   const accountOverride = getAccountOverride()

   const findLocked = async () => {
     let ret = []
     if (accountOverride === 0 && !isEmpty(accountSettings))
     {   const p =  filter(accountSettings, function(rec) {return rec.pageName === pageName && rec.pageInstance !== 'singleton' && rec.sharedPage === false})
         p.forEach(e => ret.push(e.pageInstance))
         if (isEsharePage())  //If whole page is not shared, then all instances are private
         {   const pp =  filter(accountSettings, function(rec) {return rec.pageName === pageName && rec.pageInstance === 'singleton' && rec.sharedPage === false})
             if (pp.length > 0)
                 ret = carouselContent.map(c => c.pageInstance)
         }
     }
     else if (accountOverride !== 0)
     {  let res = await axios.get(augmentApi('/api/get_shared_page_instances'), { params:  { pageName: pageName }})
        ret = res.data.instances
        if (ret.includes('singleton')) //If whole page is not shared, then all instances are private
            ret = carouselContent.map(c => c.pageInstance)
     }
     if (!isEqual(ret, lockedArray))
        setLockedArray(ret)
     return ret
   }

      //*Persist new ordering or click to change
   const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex)
      { /* setCurrentImage(oldIndex);
           setLightboxOpen(true)
         */;
      }
      else  {
         setItems(arrayMove(items, oldIndex, newIndex));
         savedContent.current = arrayMove(savedContent.current, oldIndex, newIndex);
         if (videoGallery)
            dispatch(updateVideoCarouselPage(savedContent.current))
         else
            dispatch(updateCarouselPage(savedContent.current))
      }
   };

   const getSessionValues = isVideo  =>
   {   const a = getSessionStorage(CAROUSEL_NEWBY_ACCOUNT)
       if (a === "") return ""
       if (auth !== true) return ""
       if (isEmpty(user) || user.account_id !== a)  return ""
       const ret = getSessionStorage(isVideo ? VCAROUSEL_INDEX : CAROUSEL_INDEX)
       clearSessionStorage([CAROUSEL_NEWBY_ACCOUNT, VCAROUSEL_INDEX, CAROUSEL_INDEX])
       return ret;
   } 
   useEffect( () => {
      const photos = []
      const loadAndSyncData = async () => {
         if ((auth||publicV||publicA) && (publicV || isEmpty(carouselContent)) && pageLoadDone === false) {
            const content = videoGallery ? await getVideoCarouselPage(publicV) : await getCarouselPage(publicV)
            dispatch(videoGallery ? updateVideoCarouselPage(content, false) : updateCarouselPage(content, false)); 
            setPageLoadDone(true);
            return;
         }
         else if ((auth||publicV||publicA) && pageLoadDone === false)
         {  setPageLoadDone(true); }

            //**OVerride with last change that might be stuck due to S3 issues
         if (!publicV && !publicA && auth === true)
         {   const content = getSessionValues(videoGallery);
             if (content !== "" && content !== carouselContent)
                dispatch(videoGallery ? updateVideoCarouselPage(content, false) : updateCarouselPage(content, false)); 
         }

             //**Set up the photo array for PhotoGallery (apect ration's are by 50 pixel units
         if (!isEmpty(carouselContent))
         {   const lgth = carouselContent.length;
             const results = publicV||!isPageRO()? false
                             : await axios.get(augmentApi('/api/get_all_decorated_instances'), { params: { page_name: pageName, unseenCommmentPages: true, allCommentPages: true }})
             const changes = results&&auth===true ? results.data.unseenCommentInstances : []
             const changes1 = results&&auth===true ? results.data.allCommentInstances : []
             const locked = publicV ? [] : await findLocked();
  
             for (let i = 0; i < lgth; i++) 
             {   const c = carouselContent[i];  
                 if (typeof c.thumbWidth !== 'number' || typeof c.thumbHeight !== 'number')
                     continue;
                 const elt = { src: c.thumbnailUrl, pageinstance: c.pageInstance, width: c.thumbWidth/50, height: c.thumbHeight/50, sort: c.sort, caption: c.caption, key: c.pageInstance }  //Units of 50 pixels
                 if (!isEmpty(changes) && changes.includes(c.pageInstance) )
                     elt.changes = "true";
                 else if (!isEmpty(changes1) && changes1.includes(c.pageInstance) )
                     elt.changes1 = "true";
                 const isLocked = !isEmpty(locked) && locked.includes(c.pageInstance) 
                 if (isLocked)
                     elt.islocked = "true"
                 const skip = accountOverride !== 0 && isLocked;
                 if (!skip)
                    photos.push(elt);
             }
                //Prevent small # of thumbs from displaying huge
             if (photos.length === 1)
                setContainerName(choosePicture?"choose-picture-1":"slideshow-container-1")
             else if (photos.length === 2)
                setContainerName(choosePicture?"choose-picture-2":"slideshow-container-2")
             else if (photos.length === 3)
                setContainerName(choosePicture?"choose-picture-3":"slideshow-container-3")
             else if (photos.length === 4)
                setContainerName(choosePicture?"choose-picture-4":"slideshow-container-4")
             else if (photos.length === 5)
                setContainerName(choosePicture?"choose-picture-5":"slideshow-container-5")
             else if (photos.length === 6)
                setContainerName(choosePicture?"choose-picture-6":"slideshow-container-6")
             else
                setContainerName(choosePicture?"choose-picture-0":"slideshow-container-0")
             setItems(photos)
             savedContent.current = carouselContent.slice();
             if (firstFlg.current)
             {  const cc = getParam("current")  //if added new images, will want to edit them, so open lightbox
                if (cc && lightboxEdit)
                {  const icc = typeof cc === 'string' ? parseInt(cc) : cc
                   if (icc >= 0 && icc < photos.length)  
                   {   setCurrentImage(icc)
                       setLightboxOpen(true)
                   }
                }
                else if (lightboxView&&!publicV&&!isAuthPage()&&!isAllPages()&&photos.length > 0)
                   setLightboxOpen(true)
                firstFlg.current = false;
             }
         }
         else  {
           setItems([])
           savedContent.current = []
         }
      }
      loadAndSyncData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [carouselContent, auth, publicV, lightboxCount, lockedArray])


     //**Get all pictures to display as they are being loaded
   useEffect( () => {
      let failedPhotos = []

      function is_cached(img_url){
         var imgEle = document.createElement("img");
         imgEle.src = img_url;
         return imgEle.complete || (imgEle.width+imgEle.height) > 0;
      }
   
         //Litle timeout function
      function timeout({delay}) { return new Promise(resolve => { setTimeout(() => { resolve('resolved'); }, delay); }); }

        //see which images have not been loaded yet
      const testAll = () => {
         failedPhotos = []  
         const testPhotos = (numLoadTries === 0) ? carouselContent : failedItems;
         const lgth = testPhotos.length;
         for (let i = 0; i < lgth; i++) 
         {   const c = testPhotos[i];  
             if (!is_cached(c.thumbnailUrl)) {
               failedPhotos.push({ thumbnailUrl: c.thumbnailUrl}) 
             }
         }
      }

        //**see if images are all loaded
      async function checkImages()
      {   if (pageLoadDone && !isEmpty(carouselContent) && !imagesLoaded)
          {  for (let j = 0; j < 10; j++)
                testAll();
             if (failedPhotos.length > 0)
             {   await timeout({delay:10});   
                 setNumLoadTries(numLoadTries+1);
                 setFailedItems(failedPhotos);
                 //console.log("# of failed photos", failedPhotos.length);
             }
             else  
             {  //console.log("SUCCESSFUL LOAD");
                setImagesLoaded(true);
             }
          }
      }

      checkImages();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pageLoadDone, numLoadTries])

   if (!pageLoadDone || !isResolvedCarousel)
      return <div>Loading...</div>

   if (auth === false && lightboxView && publicV === false && publicA === false) {
      return <div>Please Login to continue...</div>
   }
   if (isResolvedCarousel && (lightboxEdit||reorder) && !canEditCarousel) 
      return <div>You are not authorized to edit this page</div>
   else if (publicV === false && publicA === false && isResolvedCarousel && lightboxView && !canViewCarousel) 
      return <div>You are not authorized to view this page</div>

   let message1 = videoGallery ? "Reorder Videos" : "Reorder Pictures" 
   let message2 = "Just grab and drag"
   if (publicV === true)
         message1 = message2 = ""
    else if (publicA === true)
   {     message1 = ""
         message2 = videoGallery ? "Select any picture to see video" : "Select any picture to see slideshow"
   }
   else if (isNew)
   {     message1 = videoGallery ? "Upload video" : "Upload Pictures"
         message2= "Select or Drag files onto the popup window"
   }
   else if (lightboxView)
   {     message1 = videoGallery ? "View Video Gallery" : "View Picture Gallery"
         message2 = videoGallery ? "Select any picture to see video and add comments" : "Select any picture to add comments and see slideshow"
   }
   else if (reorder)
   {     message1 = videoGallery ? "Reorder videos" : "Reorder Pictures"
         message2= "Drag to reorder"
   }
   else if (lightboxEdit)
   {     message1 = "Edit Carousel"
         message2= videoGallery ? "Select to add captions, remove videos or make vidoes private" : "Select to add captions, remove pictures, or make pictures private"
   }
   else if (choosePicture)
   {     message2 = videoGallery ? "Select Video for SibSite" : "Select Image for SibSite"
         message1 = ""
   }
   

     //**Suppress context menu and make images not draggable so they cannot be swiped, overlay comment icons
   const imageRenderer = ({ margin, index, photo }) =>  
       <div className="container" key={photo.key}>
       <img src={photo.src} key={photo.key} style={{ margin, height: photo.height, width: photo.width }} 
            alt={photo.caption}
            title={photo.caption}
            onDragStart={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
            onClick={e => {  setCurrentImage(index); 
                             if (publicV) ;
                             else if (choosePicture) 
                                 onSelect(photo.src) 
                             else 
                                 setLightboxOpen(true); 
                           }}
       />
       {photo.islocked === "true"?<i className="fa fa-lock icon" style={styles.lock}></i>:""}
       {photo.changes === "true"?<i className="fa fa-comments fa-cog icon" style={styles.changes}></i>:""}
       {photo.changes1 === "true"?<i className="fa fa-comments fa-read icon" style={styles.changes}></i>:""}
       {lightboxEdit?<i className="fa fa-edit icon" onClick={()=>{setCurrentImage(index); setLightboxOpen(true)}} style={{fontSize: "2em", position: "absolute", top: "10px", left: "10px", color: "white"}}></i>:""}
       </div>

   let newPopup = ""
   if (isNew) 
        newPopup = videoGallery ? <VideoCarouselNew /> : <CarouselNew />

//When I combined Edit and Reorder, the combined page is Reorder (so the logic after the : )
   return lightboxView || lightboxEdit || choosePicture ?
      <>
      <div className={containerName}>
      <i>{message1} (<b>{message2}</b>)</i>
      <Gallery photos={items} targetRowHeight={choosePicture?150:200} direction={"row"} renderImage={imageRenderer} />
      </div>
      {!choosePicture ? 
      <LightboxOverlay
        initialState={lightboxOpen}
        setClose={()=>{if (!publicV && !publicA && !choosePicture)
                          setLightboxCount(lightboxCount+1); 
                       firstFlg.current = false;
                       if (lightboxEdit)  findLocked()
                       setLightboxOpen(false) 
                  }    }  
        activeIndex={currentImage}
        view={(lightboxView && !publicV && (auth||publicA))?true:false}
        edit={lightboxEdit?true:false}
        publicV={(publicV||(auth===false&&publicA))}
        lockedArray={lockedArray}
     /> : "" }
     </>

   :
      <>
      <div className={containerName}>
      <i>{message1} (<b>{message2}</b>)</i>
      <SortableGallery items={items} onSortEnd={onSortEnd} axis={"xy"} />
      </div>
      {newPopup}
      <LightboxOverlay
        initialState={lightboxOpen}
        setClose={()=>{ findLocked();  setLightboxOpen(false)} } 
        activeIndex={currentImage}
        view={false}
        edit={true}
        publicV={false}
      />
      </>
}

export default CarouselGallery;
